import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


declare var jQuery :any;
@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css','../dispatcher-home/dispatcher-home.css', '../dispatcher.component.css']
})
export class AccountsComponent implements OnInit {
 
 

  constructor() { }

  ngOnInit() {
    jQuery('#back_button').show();
    jQuery('#godsView').show();
   
  }
}






