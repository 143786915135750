import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent }   from './login/login.component';
import { DispatcherComponent }   from './dispatcher/dispatcher.component';
import { DispatcherHomeComponent }   from './dispatcher/dispatcher-home/dispatcher-home.component';
import { TasksComponent }   from './dispatcher/tasks/tasks.component';
import { AccountsComponent }   from './dispatcher/accounts/accounts.component';
import { GodsviewComponent }  from './dispatcher/godsview/godsview.component';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'dispatcher', component: DispatcherComponent,  
    children: [
        {path: '', redirectTo: 'dispatcherhome', pathMatch: 'full'},
        { path: 'godsView', component: GodsviewComponent },
        { path: 'dispatcherhome', component: DispatcherHomeComponent },
        { path: 'tasks', component: TasksComponent },
        { path: 'account', component: AccountsComponent }
    ]
    }
  ];  
   

  
  @NgModule({
    imports: [ RouterModule.forRoot(routes, { useHash: true })],   
    exports: [ RouterModule ]
  })
  export class AppRoutingModule {}