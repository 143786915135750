import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers } from '@angular/http';
import { Observable, Subject } from 'rxjs';
import { Paho } from 'ng2-mqtt/mqttws31';
import 'rxjs/Rx';
declare var jQuery: any;
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { EventEmitter } from 'events';

@Injectable()
export class mqttManager {
  cityId: any;

  public _client: Paho.MQTT.Client;
  public mqttResponse = new EventEmitter();
  private approvalStageMessage = new Subject();
  currentApprovalStageMessage = this.approvalStageMessage.asObservable();
  id: any;
  constructor(private cookie: CookieService) {
    var time = moment().format();
    // var id = sessionStorage.getItem('loginUser');
    this.id = this.cookie.get("loginUser");
    this._client = new Paho.MQTT.Client("mqtt.shegamuyadev.com", 2083, "/mqtt", this.id);
  }

  connect() {
    this._client.connect({ useSSL: true, onSuccess: this.onConnected.bind(this) });
  }

  connectionLost() {
    let observable = new Observable(observer => {
      this._client.onConnectionLost = (responseObject: Object) => {
        // console.log("onConnectionLost", responseObject);
        observer.next(responseObject);
        this._client.connect({ useSSL: true, onSuccess: this.onConnected.bind(this) });
      };
    })
    return observable;
  }


  getMessages() {
    let observable = new Observable(observer => {
      this._client.onMessageArrived = (message: any) => {
        // console.log('messages', message);
        observer.next(message);
      };
      return () => {
        this._client.disconnect();
      };
    })
    return observable;
  }

  private onConnected(): void {
    // this.cityId = sessionStorage.getItem("cityId");
    this.cityId = this.cookie.get("cityId")
    if (this.cityId == "0") {
      this.cityId = '';
      // this._client.subscribe("cities/#", { qos: 2 })
      // this._client.subscribe("jobStatus/" + this.id, { qos: 2 });
      this._client.subscribe("cities/#", { qos: 2 });
      // this._client.subscribe("dispatcher/#", { qos: 2 });
      console.log('Connected to broker all city.');
    } else {
      this._client.subscribe("cities/" + this.cityId + "/#", { qos: 2 });
      // this._client.subscribe("dispatcher/" + this.cityId + "/#", { qos: 2 });
      console.log('Connected to broker cityid.');
    }
    // this._client.subscribe("cities/"+cityId+"/dispatcher/provider/providerLocation/#", { qos: 0 });
    // this._client.subscribe("cities/"+cityId+"/dispatcher/provider/providerStatus/#", { qos: 2 });
    // this._client.subscribe("cities/"+cityId+"/dispatcher/dispatcher/bookingStatus",{ qos: 2 });
    // this._client.subscribe("dispatcher/booking",{ qos: 2 });
    // this._client.subscribe("provider/" + sessionStorage.getItem('loginUser'), { qos: 2 });
    //conole.log("this._client",this._client)
  }
  getMqttMsg(message: any) {
    this.approvalStageMessage.next(message);
  }
}


