import { Component, OnInit, NgModule, ViewChild, AfterViewInit, Input, Inject, EventEmitter } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ElementRef, Renderer2 } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';

import { FormControl } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
// import { ToastModule, ToastsManager } from 'ng2-toastrService/ng2-toastrService';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/add/operator/retry';
import "rxjs/Rx";
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import { apiServiceManager } from '../service/api-service-manager';
import { mqttManager } from '../service/mqtt-manager';
// import * as screenfull from 'screenfull';
import { DOCUMENT } from '@angular/common';
import { ScreenfullService } from '@ngx-extensions/screenfull';


declare var jQuery: any;
declare var $: any;
declare var google: any;
declare var flatpickr: any;
declare var AWS: any;

import * as moment from 'moment';
import swal from 'sweetalert2';

@Component({
  selector: 'app-dispatcher',
  templateUrl: './dispatcher.component1.html',
  styleUrls: ['./dispatcher-home/dispatcher-home.css', './godsview/godsview.component.css', './dispatcher.component.css'],
})

export class DispatcherComponent implements OnInit {
  @Input() radiochecked: boolean = false; optionsChecked: boolean = false;
  @Input() radioSelected = false;

  uniqueCustomerEmail: boolean = false;
  uniqueuniqueCustomerPhoneNumber: boolean = false;
  tags = [
    { value: 'Home', viewValue: 'Home' },
    { value: 'Office', viewValue: 'Office' },
    { value: 'Others', viewValue: "Others" },
  ];
  selectedTimeSlot = '';
  MorningSlots = [];
  AfterNoonSlots = [];
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
  SeletcedTimeDuration: any;
  EveningSlot = [];
  NightSlot = [];
  forBiddingDate: any;
  forBiddingTime: any;
  enableQuestionsForBidding: boolean = true;
  inCallTime: any;
  CurrentDateMulti: any;
  CurrentDateMultiEndDate: any = "";
  FullScreenToggle: boolean = true;
  reducedQuestionsCount: number = 0;
  dateCount = 0;
  pastDateChoosen: any;
  modelValueAsDate = moment(new Date()).format('DD MMM, YYYY');
  TimePickerMul = moment().format('h:mm A');
  durationPicker = "1:00";
  hourlyTotalAmount: any = 0;
  timeOrSame = 58;
  BookingMode: any = 'determinate';
  allMandatoryQuestions: boolean = true;
  futureDateChoosen: any;
  CurrentDateTimSlot: any;
  TimeSlot = [];
  CategoryError: any = false;
  SelectedFile: any = 0;
  ImageFile = { name: '', id: '' };
  selectDays = [];
  answeredCount = 0;
  checkBoxAnswer = [];
  radioAnswer = [];
  textAreaAnswer = [];
  pastCurrentAns = [];
  currentFutureAns = [];
  Ischecked: boolean
  radiochecked1: any;
  RepeatShift: any;
  HourlTotalPrice: any = 0;
  ShiftOffer: any;
  ShiftourlyCount: any;
  QuantityCount = 0;
  Allchecked: any = false;
  EndDateForMulshift: any;
  HourlyPrice: boolean = false;
  Date3: any;
  Date4: any;
  date1: any;
  date2: any;
  diffrence: any;
  ForBidFuture: any = [];
  ForBidText: any = [];
  ForBidCurrent: any = [];
  Others: any;
  description: string = '';
  RemoveShiftOption: boolean = false;
  ShiftValue: any = 0;
  CurrentDate: any;
  NextOption: any = [0];
  index: any = 0;
  shiftOption: any = 0;
  CustomDate: any;
  addDay = [];
  selectedRadioOption: any;
  ResultantEndTime: any;
  ShiftDuration1: any;
  RadioOptions = new FormControl();
  SearchCategory: any = "";
  myControl = new FormControl();
  ConfirmMultipleShift = 0;
  shiftDuration: any;
  bookingShift = [];
  ShowDays = [];
  // variable declarations
  Billing_model: any = 0;
  imgUrl = [];
  Timer: any;
  SelectTime: boolean = false;
  SelectDuration: boolean = false;
  MultipleShifting: boolean = false;
  ShiftToggle: any;
  ShiftOptions = [{ value: 1, viewValue: 'This Week', checked: false },
  { value: 2, viewValue: 'This Month', checked: false },
  { value: 3, viewValue: 'Custom Date', checked: false },]
  questionType: any;
  ShifthNext: any = 0;
  ShifthNexts = 0;
  startDate: any;
  endDate: any;
  DaysSelected = [];
  curerentDateForWeekOrMonth: any;
  LastDateForWeekOrMonth: any;
  TimeForWeekOrMonth: any;
  DurationForWeekOrMonth: any;
  other_Location: any = "";
  ForOtherTag: any = false;
  ForBidding: any = [];
  AnswerArr: any = [];
  BiddingCheckList: any = [];
  WalleteDisable: any = false;
  CashSelectDisable: any;
  selectedCard: any;
  paymentTypeValue: any = 0;
  Service_type: any;
  QuestionSet: any = [];
  MandatoryQuestionsCount: number = 0;
  enablePostDispatch: boolean = false;
  AmountForBidding: any = "";
  ForBiddingTotalAmount: boolean = false;
  NewCustomer: boolean = false;
  valueOfCard: any;
  isLinear = true;
  itsLinear = true;
  firstFormGroup: FormGroup;
  questionAnsArr = [];
  // secondFormGroup: FormGroup;
  visitFee = 0;
  LocationForBid: any;
  billing_model: number = 0;
  days = new FormControl();
  // daysList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  daysList = [{ days: "Sunday", checked: false, date: [] }, { days: "Monday", checked: false, date: [] }, { days: "Tuesday", checked: false, date: [] }, { days: "Wednesday", checked: false, date: [] },
  { days: "Thursday", checked: false, date: [] }, { days: "Friday", checked: false, date: [] }, { days: "Saturday", checked: false, date: [] }]
  CheckBoxList = new FormControl();
  map: any;
  BidDateTimeId: any;
  CheckBoxChecked: any;
  BidAmount: any;
  DateTimeForBid: any;
  jobLocation: any;
  pickuplong: any;
  pickuplat: any;
  bindPosition: any;
  address: any;
  selectedSchedule: boolean = true;
  bookingType: any = 0;
  selected: boolean = false;
  online$: Observable<boolean>;
  environmentSource = environment.envName;
  notify_sid = 1;
  notify_bid: any;
  totalNotification: any;
  autoFillData: any = [];
  customerName: any;
  customerPhone: any;
  customerPhoneCode: any;
  customerMail: any;
  customerId: any;
  SubCategory: string;
  Category: string;
  value = 33.33;
  bufferValue = 66.66;
  selectedItemArray: any = [];
  categoryList: any = [];
  catSelected: any = [];
  subCategorySelected: any = [];
  categoryOpted: any = [];
  customer_Location: any;
  addressId: any;
  previousLocationList: any = [];
  recentAddress: any;
  selectedDays: any = '';
  haveSelectedProvider: boolean = false;
  disableHourly: boolean = true;
  disableFixed: boolean = true;
  enableType1: boolean = false;
  enableType2: boolean = false;
  enableType3: boolean = false;
  readOnlyAddress: boolean = true;
  providersList: any = [];
  availableList: any = [];
  unavailableList: any = [];
  selectedProvider: any;
  serviceList: any = [];
  walletDetails: any;
  forCat: any;
  todaysDate = new Date();
  forSubCat: any;
  myCartList: any;
  haveCart: boolean = false;
  WalletSliderChecked: any = false;
  customerRegisterName: any;
  customerRegisterEmail: any;
  customerRegisterPhone: any;
  customerRegisterCountryCode: any;
  BiddingQuestion: any;
  BiddingQuestionId: any;
  enableAutoDispatch: boolean = true;
  enableManualDispatch: boolean;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  selectedValue: any = 60;
  listBill: any;
  checkOutList: any;
  itemTotal: any = 0.00;
  servicePrice: any = 0.00;
  CheckOutValue: any = 0.00;
  CountOfBiddingQuestion: number = 0;
  email = new FormControl('', [Validators.required, Validators.email]);
  RegisterName = new FormControl('', [Validators.required]);
  RegisterPhone = new FormControl('', [Validators.required]);
  RegisterCountryCode = new FormControl('', [Validators.required]);
  BidAmtMandatory: number = 0;
  PrmCodeManMandatory: number = 0;
  FocusedValue: number = 1;
  Custemail = new FormControl('', [Validators.required, Validators.email]);
  BiddingPrice = new FormControl('', Validators.required);
  CustRegisterName = new FormControl('', [Validators.required, Validators.minLength(5)]);
  CustRegisterPhone = new FormControl('', [Validators.required]);
  CustRegisterCountryCode = new FormControl('', [Validators.required]);
  JobPaymentType = new FormControl('', [Validators.required]);
  cardSelect = new FormControl('', [Validators.required]);
  jobDescriptionController = new FormControl('');
  BiddingTextArea = new FormControl('');
  slideController = new FormControl('');
  jobDescription: any;
  bookLaterDate: any;
  bookLaterTime: any;
  newUser: boolean = false;
  newLocation: boolean = false;
  customerAddHeader: any = "CUSTOMER DETAILS";
  addressHeader: any = "LOCATION";
  TotalAmount = 0;
  addressTag: any;
  mqtMesage: any;
  bidDateTime: any;
  CardList: any;
  CardSelectDisable: boolean = true;
  payType = [
    { value: 1, viewValue: 'Cash' },
    { value: 2, viewValue: 'Card' },
  ];
  ForCheckout: number = 1;
  DurationForChangeProvider: any = '1:00';
  providersearch: any;
  promoDiscountAmount: any;
  promoId: any;
  promocode: any;
  minTime = moment(new Date(), 'h:mm A').add(1, 'hours').format('h:mm A');
  selectedIndex: any;
  PromoCode: any;
  PromoCodeId: any;
  PromoCodeQuestion: any;
  hourlyCharge: any = 0;
  hourlyCount: any = 1;
  hourlyTotal: any = 0;
  cartServiceType: any;
  PromoError: any;
  sliderChecked: boolean = false;
  WalletBalance: any = 0;
  Showerror: boolean = false;
  ErrorMsg: any;
  ConfirmMulShift: boolean = false;
  ShiftCount: any;
  documentElem: any;
  input: any;
  NotificationMsg: any = [];
  constructor(private _formBuilder: FormBuilder, private _router: Router, private ref: ChangeDetectorRef,
    private _apiServiceManager: apiServiceManager, private _mqttManager: mqttManager, private cookieService: CookieService,
    private _renderer: Renderer2, private _elementRef: ElementRef, private toastrService: ToastrService,
    private ngxSmartModalService: NgxSmartModalService,
    @Inject(DOCUMENT) private document: any,
    public readonly screenfullService: ScreenfullService) {
    // alert("constructor for dispatcher")

    // for internet connection check
    this.online$ = Observable.merge(
      Observable.of(navigator.onLine),
      Observable.fromEvent(window, 'online').map((data) => { return true; }),
      Observable.fromEvent(window, 'offline').map((data) => { return false; })
    );
    // this.online$ = Observable.merge(
    //   Observable.of(navigator.onLine),
    //   Observable.fromEvent(window, 'online').map(() => true),
    //   Observable.fromEvent(window, 'offline').map(() => false)
    // )
    this.online$.subscribe((data) => {
      //////conole.log("online", data)
      if (data) {
        console.log("isConnected", this._mqttManager._client.isConnected());
        if (this._mqttManager._client.isConnected() == false) {
          this._mqttManager.connect();
        } else {
          this.getMqttMessage();
        }
      }
    })

    this._mqttManager.currentApprovalStageMessage.subscribe(msg => {
      if (this.NotificationMsg && this.NotificationMsg.length != 50) {
        let msgToShow = '';
        let fullName = '';
        switch (msg['destinationName']) {
          case 'bookingStatus':
            //conole.log("the mqtt response issss :", msg);
            if (msg['status'] == 1) {
              msgToShow = 'did new booking';
              fullName = msg['customerData'].firstName + msg['customerData'].lastName;
              this.NotificationMsg.push({ name: fullName, message: 'is ' + msg['statusMsg'], serialNo: this.NotificationMsg.length + 1 });
            }
            else {
              msgToShow = msg['statusMsg'];
              fullName = msg['providerData'].name;
              this.NotificationMsg.push({ name: fullName, message: msgToShow, serialNo: this.NotificationMsg.length + 1 });
            }
            break;


        }
      }
      else {
        this.NotificationMsg = [];
      }
    });
  }
  getMqttMessage() {
    this._mqttManager.connectionLost().subscribe((responseObject) => {
      this.mqtMesage = responseObject;
    });
  }
  DispatcherName: string = '';
  uploader: FileUploader;
  elem: any;
  uploaderOptions: FileUploaderOptions =
    this._apiServiceManager.imageUploaderOptions();
  ngOnInit() {
    this.newUser = true;
    this.intlTelInput();
    if (this.cookieService.get('CityName') === "ALL") {
      this.DispatcherName = 'Central';
    }
    else {
      this.DispatcherName = this.cookieService.get('CityName') + ' City';
      this.getLatlongBasedOnDispatcher(this.DispatcherName);
    }
    this.elem = document.documentElement;
    this.uploader = new FileUploader(this.uploaderOptions);
    this.cartServiceType = 1;
    $("#promoDiscountAmount").show();
    $("#focus8").hide();
    this.listBill = [
      { 'id': 1, 'qnt': 1, 'name': 'TapReplace', 'amount': 50.00, 'basePrice': 50.00, 'status': 'activeBill' },
      { 'id': 2, 'qnt': 1, 'name': 'Leak Fix', 'amount': 100.00, 'basePrice': 100.00, 'status': 'inactiveBill' },
      { 'id': 3, 'qnt': 1, 'name': 'New Sanitary Installation', 'basePrice': 200.00, 'amount': 200.00, 'status': 'activeBill' },
      { 'id': 4, 'qnt': 1, 'name': 'TapReplace', 'amount': 50.00, 'basePrice': 50.00, 'status': 'activeBill' }
    ];


    // $('[data-toggle="tooltip"]').tooltip({ placement: "bottom" });
    //***************************** Browser Idle Mode Function ******************************
    var timeoutID;

    function setup() {
      document.addEventListener("mousemove", resetTimer, false);
      document.addEventListener("mousedown", resetTimer, false);
      document.addEventListener("keypress", resetTimer, false);
      document.addEventListener("DOMMouseScroll", resetTimer, false);
      document.addEventListener("mousewheel", resetTimer, false);
      document.addEventListener("touchmove", resetTimer, false);
      document.addEventListener("MSPointerMove", resetTimer, false);
    }
    setup();

    function startTimer() {
      // wait 10 mins before calling goInactive
      timeoutID = window.setTimeout(
        goInactive, 600000);
    }

    function resetTimer(e) {
      window.clearTimeout(timeoutID);
      goActive();
    }

    function goInactive() {
      $("#appt_loader_loggedOut").show();
    }

    function goActive() {
      startTimer();
    }


    $(document).ready(() => {
      $('#example-getting').multiselect();

    });

    // var token = sessionStorage.getItem('user-token');
    var token = this.cookieService.get("user-token");
    if (!token) {
      this._router.navigate(['login']);
    }

    this.firstFormGroup = this._formBuilder.group({
      customerName: [''],
      customerPhone: [''],
      customerPhoneCode: [''],
      customerMail: [''],
      customerLocation: [''],
      bookLaterDateControl: [''],
      bookLaterTimeControl: [''],
    });
    this.documentElem = this.document.documentElement;
  }
  //getDispatcherDetails
  getLatlongBasedOnDispatcher(cityName) {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': cityName }, (results, status) => {
      if (status == 'OK') {
        // console.log('results', results);
        let co_ornitates = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        }
        // console.log('results', results, co_ornitates);
        this.cookieService.set('dispatcherLat', co_ornitates.lat);
        this.cookieService.set('dispatcherLng', co_ornitates.lng);
        // map.setCenter(results[0].geometry.location);
        // var marker = new google.maps.Marker({
        //   map: map,
        //   position: results[0].geometry.location
        // });
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }
  // for full screen and exit screen
  fullScreen(caseValue) {
    console.log("FullScreenToggle = treu ", caseValue)
    switch (caseValue) {
      case 1: /* for full screen */
        // this.screenfullService.request();
        if (this.elem.requestFullscreen) {
          this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
          /* Firefox */
          this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
          /* IE/Edge */
          this.elem.msRequestFullscreen();
        }
        this.FullScreenToggle = false;
        break;

      case 2: /* for exit full screen */
        // this.screenfullService.exit();
        if (this.document.exitFullscreen) {
          this.document.exitFullscreen();
        } else if (this.document.mozCancelFullScreen) {
          /* Firefox */
          this.document.mozCancelFullScreen();
        } else if (this.document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          this.document.webkitExitFullscreen();
        } else if (this.document.msExitFullscreen) {
          /* IE/Edge */
          this.document.msExitFullscreen();
        }
        this.FullScreenToggle = true;
        break;

    }
  }
  emmualLogout() {
    this._apiServiceManager.logOut().subscribe(result => {
      this.clearAndMove();
      this.closeMyModal();

      if (this.ref !== null &&
        this.ref !== undefined &&
        !(this.ref as ChangeDetectorRef)['destroyed']) {
        this.ref.detectChanges();
      }
    }, error => {
      this.clearAndMove();
    });
  }
  clearAndMove() {
    sessionStorage.clear();
    this._router.navigate(['login']);
    this.cookieService.delete('loginUser')
    this.cookieService.delete('user-token');
    // this.cookieService.deleteAll();
    // window.location.reload();
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }

  getAddress(place: Object) {

  }

  BookingTrigger() {
    $("#DisableButtonsForCard").hide();
    $("#EnableButtonsforCard").show();
    this.AnswerArr = [];
  }

  myModal() {
    setTimeout(() => {
      this.show = false;
      this.ngxSmartModalService.open('AddNewJobModal');
      this.setRepeatPicker();
    }, 50)
    this.durationPicker = "1:00";
    this.selectedSchedule = true;
    this.selected = true;
    this.newUser = true;
    this.WalleteDisable = true;
    this.intlTelInput();
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }

  openMap() {
    $('#mapModal').appendTo("body").modal('show');
  }

  openRegistration() {
    $('#addCustomer').appendTo("body").modal('show');
  }
  LocationCheck: any;
  setMap() {

    setTimeout(() => {
      var origin_place_id = null;
      var destination_place_id = null;
      var travel_mode = 'WALKING';
      var map = new google.maps.Map(document.getElementById('jobmap'), {
        // mapTypeControl: false,
        center: { lat: 12.9716, lng: 77.5946 },
        zoom: 10
      });
      this.map = map;

      var directionsService = new google.maps.DirectionsService;
      var directionsDisplay = new google.maps.DirectionsRenderer;
      directionsDisplay.setMap(map);
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push();
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push();
      // this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(modes);

      // MAP LOCATION LOADER
      var origin_input = document.getElementById('origin-input3');
      var origin_autocomplete = new google.maps.places.Autocomplete(origin_input);
      origin_autocomplete.bindTo('bounds', this.map);
      if (origin_autocomplete) {
        var marker = new google.maps.Marker({
          map: this.map,
          anchorPoint: new google.maps.Point(0, -29)
        });

        origin_autocomplete.addListener('place_changed', () => {
          // alert("hi")
          jQuery('#origin-input3').css({ "borderColor": "#f4f1f1" });
          jQuery('#map_marker').css({ "color": "#555" });
          jQuery('#place-error').hide();

          var place = origin_autocomplete.getPlace();
          marker.setVisible(false);
          if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("No details available for input: '" + place.name + "'");
            return;
          }

          // If the place has a geometry, then present it on a map.
          if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport);
          } else {
            this.map.setCenter(place.geometry.location);
            this.map.setZoom(17);  // Why 17? Because it looks good.
          }
          marker.setPosition(place.geometry.location);
          marker.setVisible(true);

          if (place.geometry) {
            jQuery('#origin-input3').css({ "borderColor": "#6fd653" });
            jQuery('#map_marker').css({ "color": "#6fd653" });
            jQuery('#place-error').hide();
            console.log("place", place.formatted_address);
            this.customer_Location = place.name + "," + place.formatted_address;
            let LocationCheck = [];
            LocationCheck = place.formatted_address.split(",");
            this.LocationCheck = LocationCheck;
            for (var i = 0; i < place.address_components.length; i += 1) {
              var addressObj = place.address_components[i];
              for (var j = 0; j < addressObj.types.length; j += 1) {
                if (addressObj.types[j] === 'locality') {
                  this.LocationCheck['city'] = addressObj.long_name;
                }
                if (addressObj.types[j] === 'administrative_area_level_1') {
                  this.LocationCheck['state'] = addressObj.long_name;
                }
                if (addressObj.types[j] === 'country') {
                  this.LocationCheck['country'] = addressObj.long_name;
                }
              }
            }
            LocationCheck[0] !== place.name ? (this.customer_Location = place.name + "," + place.formatted_address)
              : (this.customer_Location = place.formatted_address);
            this.jobLocation = place;
            jQuery("#map_loader_parent").show();
            origin_place_id = place.place_id;
            this.pickuplat = place.geometry.location.lat();
            this.pickuplong = place.geometry.location.lng();
            var values = {
              "lng": this.pickuplong,
              "lat": this.pickuplat
            }
            $("#promoCode").css({ 'background': '#eee', 'cursor': 'not-allowed' });
          }
          this.getCategory();
        });
      }
      $("#selectCategory").multiselect();
      $("#SubCategories").multiselect();
    }, 300);
  }

  ForSaveAddress: any = 0;
  addAddress() {
    this.newLocation = false;
    this.ForSaveAddress = 1;
    let placeName = $("#origin-input3").val();
    let address = {
      addLine1: placeName,
      latitude: this.pickuplat,
      longitude: this.pickuplong,
      customerId: this.customerId,
    }
    this.ForOtherTag == true ? address["taggedAs"] = this.other_Location : address["taggedAs"] = this.addressTag;
    this._apiServiceManager.addCustomerAddress(address).subscribe(result => {
      let index = this.previousLocationList.findIndex(x => x.taggedAs === result.data.taggedAs);
      index > -1 ? this.previousLocationList[index].addLine1 = result.data.addLine1 : this.previousLocationList.push(result.data);
      //////conole.log("addCustomerAddress", result,this.previousLocationList);
      if (result.message) {
        this.toastrService.info(result.message);
      }
      if (result.data) {
        this.addressId = result.data._id;
      }
      this.resetForAddress();
      this.getCategory();
    }, error => {
      //////conole.log("Error from rquest in Checkout ", error)
      //let errorMessage = JSON.parse(error._body);
      error.status == 498 || error.status === 440 ? this.closeAllNgxModalsAtTokenExpired() : $("#appt_loader_loggedOut").hide();
    })
  }

  getCategory() {
    this.categoryList = [];
    let searchCat;
    this._apiServiceManager.getCategoryService(this.pickuplat, this.pickuplong).subscribe(result => {
      // this._apiServiceManager.getCategoryService(this.pickuplat, this.pickuplong).subscribe(result => {
      this.categoryList = result.data;
      console.log("the result is : ", result);
      if (this.categoryList == null) {
        swal.fire({ title: "Information", text: "No such service found at selected location", icon: "warning" });
        this.customer_Location = '';
        this.SearchCategory = '';
        // this.schedule(1);
      }
      result && result.paymentMethod ? this.CashSelectDisable = result.paymentMethod.cash : this.CashSelectDisable = true;
      // result === null || !result ? this.CashSelectDisable = true : this.CashSelectDisable = result.paymentMethod.cash;
      this.CardList && this.CardList.length == 0 ? this.CardSelectDisable = false : this.CardSelectDisable = true;
    }, error => {
      //////conole.log("Error from rquest in Checkout ", error)
      //let errorMessage = JSON.parse(error._body);
      if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() }
      else { $("#appt_loader_loggedOut").hide(); }
    })
    this.boxFocus(3)
  }
  //Card #26 fixed for new customer
  hourlyData: any;
  getServiceList() {
    let subCat = this.forSubCat || 0;
    let providerId = this.selectedProvider._id || 0;
    this._apiServiceManager.getService(this.forCat, subCat, providerId).subscribe(result => {
      this.serviceList = result.data;
      this.hourlyData = result.categoryData;
      console.log("this.serviceList", this.serviceList);
      (this.walletDetails.enableWallet && parseInt(this.WalletBalance) > 0) ? this.WalleteDisable = false : this.WalleteDisable = true;
      this.hourlyCharge = result.categoryData.price;
      (this.hourlyCount === 0) ? this.hourlyTotalAmount = 0 : this.hourlyTotalAmount = this.hourlyCharge;
      // card-id: #225, bug: for hourly category, the hourly amount showing 0, fixedDesr : put hourlyCharge to hourlyTotalAmount, developer: sowmya sv
      if (this.disableFixed && !this.disableHourly && this.bookingType == 3 && this.DurationForChangeProvider) {
        //CardId : #226, bug: when each time selecting provider, quantity is adding. fixed desc: calling cart api. developer:sowmya sv 
        setTimeout(() => {
          this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 2)
        }, 100);
        setTimeout(() => {
          this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 1)
        }, 200);
      }
      else if (this.disableFixed == false && this.disableHourly == false && this.bookingType == 3 && this.DurationForChangeProvider) {
        setTimeout(() => {
          this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 2)
        }, 100);
        setTimeout(() => {
          this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 1)
        }, 200);
      }
      result.data ? this.serviceList = result.data : this.serviceList = [];
      !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
    }, error => {
      //////conole.log("Error from rquest in Checkout ", error)
      //let errorMessage = JSON.parse(error._body);
      (error.status == 498 || error.status === 440) ? this.closeAllNgxModalsAtTokenExpired() : $("#appt_loader_loggedOut").hide();
    })
    if (this.disableFixed && !this.disableHourly) {
      if (this.bookingType != 3 && this.callTypeValue == 2) {
        setTimeout(() => { this.getOldCart(); }, 100);
      }
    }
    else if (!this.disableFixed && this.disableHourly) {
      if (this.bookingType != 3 && this.callTypeValue == 2) {
        setTimeout(() => {
          this.getOldCart();
        }, 100);
      }
    }
    else if (this.disableFixed == false && this.disableHourly == false) {
      if (this.bookingType != 3 && this.callTypeValue == 2) {
        setTimeout(() => { this.getOldCart(); }, 100);
      }
    }
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }

  callCheckOut(CountValue, Case) {
    switch (Case) {
      case 1:
        this.checkOutForMultipleShift('', 1, 2, CountValue);
        break;

      case 2:
        this.checkOutForMultipleShift('', 2, 2, CountValue);
        break;
    }
  }
  serviceSearch(searchString) {
    let oldservice = this.serviceList;
    let subCat = this.forSubCat || 0;
    let providerId = this.selectedProvider._id || 0;
    let serviceSearchData = {
      searchStr: searchString,
      catId: this.forCat,
      subCatId: subCat,
      providerId: providerId
    }
    if (searchString.length > 0) {
      this._apiServiceManager.addJobServiceSearch(serviceSearchData).subscribe(result => {
        this.serviceList = result.data;
        !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
      }, error => {
        //let errorMessage = JSON.parse(error._body);
        (error.status == 498 || error.status === 440) ? this.closeAllNgxModalsAtTokenExpired() : $("#appt_loader_loggedOut").hide();
      })
      this.getOldCart();
    } else {
      this.getServiceList();
    }
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }

  clearBeforeSelectingCategory() {
    // this.SearchCategory = "";
    this.forBiddingDate = moment(new Date, 'D MMM, YYYY').format('D MMM, YYYY');
    this.forBiddingTime = '';
    this.enableQuestionsForBidding = true;
    this.PromoCode = "";
    this.PromoCodeId = "";
    this.PromoCodeQuestion = "";
    this.BiddingQuestion = "";
    this.BiddingQuestionId = "";
    this.BiddingPrice.reset();
    this.enablePostDispatch = false;
    this.ForBiddingTotalAmount = false;
    this.selectedTimeSlot = '';
    this.timeSlotError = '';
    this.serviceList = [];
    this.forCat = 0;
    this.forSubCat = 0;
    this.providersList = [];
    this.availableList = [];
    this.unavailableList = [];
    this.selectedProvider = [];
    this.enableType1 = false;
    this.enableType2 = false;
    this.enableType3 = false;
    this.checkOutList = [];
    this.myCartList = [];
    this.enableAutoDispatch = true;
    this.enableManualDispatch = false;
    this.visitFee = 0;
    this.disableHourly = true;
    this.disableFixed = true;
    this.hourlyCharge = 0;
    this.hourlyCount = 0;
    this.hourlyTotal = 0;
    this.cartServiceType = '';
    this.promocode = '';
    this.promoDiscountAmount = '';
    this.addressTag = "";
    this.AnswerArr = [];
    this.ForCheckout = 1;
    $("#DisableButtonsForCard").hide();
    $("#EnableButtonsforCard").show();
    $("#VisitFees").hide();
    this.NightSlot = [];
    this.ForBidding = [];
    this.AfterNoonSlots = [];
    this.MorningSlots = [];
    this.EveningSlot = [];
    this.CurrentDateTimSlot = '';
    this.providerTimeSlot = [];
    this.callTypeValue = 2;
    this.callInst = [];
    this.answerAllError = false;
    this.dateCount = 0;
    this.responsedQuestions = [];
    this.imgUrl = [];
  }
  action: any;
  ShiftOffers: any;
  callType = [];
  callTypeCount = 0;
  paymentQuestion: any;
  consultancyFee: any;
  onSelectCat(selectedC) {
    console.log('selected category', selectedC);
    $(".listTab").removeClass("active");
    $("#bookNow").addClass("active");
    this.callTypeCount = 0;
    this.answeredCount = 0;
    this.CategoryError = false;
    this.questionAnsArr = [];
    this.newLocation = false;
    this.action = 1;
    this.hourlyCharge = 0;
    this.clearBeforeSelectingCategory();
    this.myCartList = [];
    this.checkOutList = [];
    this.callType = [];
    let indx = this.categoryList.findIndex((item) => item._id == selectedC);
    this.ShiftOffers = this.categoryList[indx].offers;
    this.catSelected = this.categoryList[indx].subCategory;
    this.categoryOpted = this.categoryList[indx];
    this.forCat = this.categoryList[indx]._id;
    this.Service_type = this.categoryList[indx].service_type;
    // check if question array is there or not 
    if (this.categoryList[indx].hasOwnProperty('questionArr') && this.categoryList[indx].questionArr.length > 0 && this.categoryOpted.billing_model !== '7') {
      this.questionAnsArr = this.categoryList[indx].questionArr;
      this.reduceQuestionArr(this.questionAnsArr, 1);
    }
    if (this.categoryList[indx].hasOwnProperty('callType')) {
      if (this.categoryList[indx].callType.incall && this.categoryList[indx].callType.outcall && this.categoryList[indx].callType.telecall) {
        this.callTypeCount = 3;
        if (this.callTypeCount >= 3) {
          this.toastrService.info("Please choose callType");
          // this.ngxSmartModalService.open('CallType');
          this.callType = [{ viewValue: 'InCall', value: 1, checked: false }, { viewValue: 'OutCall', value: 2, checked: false }, { viewValue: 'TeleCall', value: 3, checked: false }];
        }
      }
      else if (this.categoryList[indx].callType.incall && this.categoryList[indx].callType.telecall) {
        this.callTypeCount = 2;
        if (this.callTypeCount >= 2) {
          this.toastrService.info("Please choose callType");
          this.callType = [{ viewValue: 'In Call', value: 1, checked: false }, { viewValue: 'Tele Call', value: 3, checked: false }];
        }
      }
      else if (this.categoryList[indx].callType.incall && this.categoryList[indx].callType.outcall) {
        this.callTypeCount = 2;
        if (this.callTypeCount >= 2) {
          this.toastrService.info("Please choose callType");
          this.callType = [{ viewValue: 'In Call', value: 1, checked: false }, { viewValue: 'Out Call', value: 2, checked: false }];

        }
      }
      else if (this.categoryList[indx].callType.outcall && this.categoryList[indx].callType.telecall) {
        this.callTypeCount = 2;
        if (this.callTypeCount >= 2) {
          this.toastrService.info("Please choose callType");
          this.callType = [{ viewValue: 'Out Call', value: 2, checked: false }, { viewValue: 'Tele Call', value: 2, checked: false }];
        }
      }
      else if ((this.categoryList[indx].callType.incall == true && this.categoryList[indx].callType.telecall === false && this.categoryList[indx].callType.outcall === false)) {
        if (this.questionAnsArr && this.questionAnsArr.length > 0 && this.categoryOpted.billing_model !== '7') {
          this.ngxSmartModalService.open('QuestionAns');
        }
        this.enableType1 = false;
        this.enableType2 = false;
        this.enableType3 = false;
        this.DisableAutoManualDispatch(2);
        this.toastrService.info("Please choose the Provider");
        this.swtichBillingModalUi(this.categoryOpted.billing_model);
        this.schedule(2);
        this.getServiceList();
        setTimeout(() => { this.filteredProvider(); }, 50);
      }
      else if (this.categoryList[indx].callType.incall === false && this.categoryList[indx].callType.telecall === true && this.categoryList[indx].callType.outcall === false) {
        if (this.questionAnsArr && this.questionAnsArr.length > 0 && this.categoryOpted.billing_model !== '7') {
          this.ngxSmartModalService.open('QuestionAns');
        }
        this.enableType1 = false;
        this.enableType2 = false;
        this.enableType3 = false;
        this.DisableAutoManualDispatch(2);
        this.toastrService.info("Please choose the Provider");
        this.swtichBillingModalUi(this.categoryOpted.billing_model);
        this.schedule(2);
        this.getServiceList();
        setTimeout(() => { this.filteredProvider(); }, 50);
      }
      else if (this.categoryList[indx].callType.outcall) {
        this.questionAnsArr && this.questionAnsArr.length > 0 && this.categoryOpted.billing_model !== '7' ?
          this.ngxSmartModalService.open('QuestionAns') : '';
        this.enableType1 = this.categoryOpted.bookingTypeAction.now;
        this.enableType2 = this.categoryOpted.bookingTypeAction.schedule;
        this.enableType3 = this.categoryOpted.bookingTypeAction.repeat;
        // to enable/disable the booking type buttons
        this.Service_type == '1' ? (this.getServiceList(), this.swtichBillingModalUi(this.categoryOpted.billing_model)) : '';
        // setTimeout(() => { this.filteredProvider(); }, 50);
      }
    }
    else {
      if (this.questionAnsArr && this.questionAnsArr.length > 0 && this.categoryOpted.billing_model !== '7') {
        this.ngxSmartModalService.open('QuestionAns');
      }
      this.enableType1 = this.categoryOpted.bookingTypeAction.now;
      this.enableType2 = this.categoryOpted.bookingTypeAction.schedule;
      this.enableType3 = this.categoryOpted.bookingTypeAction.repeat;
    }
    if (this.categoryList[indx].questionArr && this.questionAnsArr && this.questionAnsArr.length > 0 && this.categoryOpted.billing_model !== '7') {
      // this.reduceQuestionArr(this.questionAnsArr,indx);
      this.checkBoxAnswer = [];
      this.radioAnswer = [];
      this.textAreaAnswer = [];
      this.pastCurrentAns = [];
      this.currentFutureAns = [];
      this.responsedQuestions = [];
      this.jobDescriptionController.reset();
    }
    // }
    if (this.categoryList[indx].questionArr && this.categoryOpted.billing_model === '7') {
      this.QuestionSet = this.categoryList[indx].questionArr;
      let BidDateTimeIndx = this.QuestionSet.findIndex((item) => item.questionType == 2);
      let BidLocation = this.QuestionSet.findIndex((item) => item.questionType == 3);
      this.QuestionSet = this.QuestionSet.filter((v, i, a) => a.findIndex(t => (t.questionType === 2 || t.questionType === 3 || t.questionType === 4)) === i);
      console.log('this.question', this.QuestionSet);
      // this.BidDateTimeId = this.QuestionSet[BidDateTimeIndx];
      // this.LocationForBid = this.QuestionSet[BidLocation];
      this.reduceQuestionArr(this.QuestionSet, 2);
      for (let i = this.QuestionSet.length - 1; i >= 0; i--) {
        if (this.QuestionSet[i].questionType == 4) {
          this.paymentQuestion = this.QuestionSet[i].question;
        }
        else if (this.QuestionSet[i].questionType == 3) {
          this.addressHeader = this.QuestionSet[i].question;
        }
        if (this.QuestionSet[i].isManadatory == 1)
          this.CountOfBiddingQuestion++;
      }
      this.swtichBillingModalUi(this.categoryOpted.billing_model);
    }
    // market place category
    if (this.Service_type == 2 && this.categoryList[indx].hasOwnProperty('callType')) {
      //conole.log("this service type", this.Service_type)
      this.DisableAutoManualDispatch(2);
      this.toastrService.info("Please choose the Provider");
      this.switchEnable(1);
    }
    // for bidding category
    else if ((this.Service_type == 3)) {
      ($("#DisableButtonsForCard").show(), $("#EnableButtonsforCard").hide());
    }
    else {
      $("#DisableButtonsForCard").hide(); $("#EnableButtonsforCard").show();
    }
    this.boxFocus(7);
    this.myCartList.currencySymbol = this.categoryOpted.currencySymbol;
    this.categoryOpted.price_per_fees ? this.hourlyCharge = this.categoryOpted.price_per_fees : this.hourlyCharge = 0;
    this.categoryOpted.price_per_fees ? this.hourlyTotal = this.categoryOpted.price_per_fees : this.hourlyTotal = 0;
    this.categoryOpted.visit_fees > 0 ? this.visitFee = this.categoryOpted.visit_fees : this.visitFee = 0;
    this.visitFee > 0 ? $("#VisitFees").show() : $("#VisitFees").hide();
    if (this.catSelected.length <= 0) {
      this.boxFocus(4);
      if (this.categoryOpted.service_type == 1) {
        // if service is On demand
        this.switchEnable(1);
      }
    }
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }

  //functionName: reduceQuestionArr, developer:sowmya sv,desr: remove the questions which has question type 0,1,3,4
  reduceQuestionArr(questionArr, indx) {
    let questionArrray = questionArr;
    this.reducedQuestionsCount = 0;
    let OuterQuestArr = [];
    for (let index = 0; index < questionArrray.length; index++) {
      if (indx == 1) {
        if (questionArrray[index].questionType > 4 && questionArrray[index].questionType != 11 && questionArrray[index].isManadatory == 1) {
          this.reducedQuestionsCount++;
        }
        let modifyiedQueArr = [];
        if (questionArrray[index].questionType > 4 && questionArrray[index].questionType != 11) {
          if (questionArrray[index].hasOwnProperty('preDefined') && questionArrray[index].preDefined.length > 0) {
            questionArrray[index].preDefined.forEach(element1 => {
              if (element1.hasOwnProperty('checked')) {
                element1['checked'] = false;
                modifyiedQueArr.push(element1);
              }
              else {
                element1['checked'] = false;
                modifyiedQueArr.push(element1);
              }
            });
            questionArrray[index].preDefined = modifyiedQueArr;
          }
          if (questionArrray[index].hasOwnProperty('checked')) {
            questionArrray[index]['checked'] = false;
            OuterQuestArr.push(questionArrray[index]);
          }
          else {
            questionArrray[index]['checked'] = false;
            OuterQuestArr.push(questionArrray[index]);
          }
        }
        this.questionAnsArr = OuterQuestArr;
      }
      else {
        let modifyiedQueArr1 = [];
        if (questionArrray[index].questionType != 11) {
          if (questionArrray[index].hasOwnProperty('preDefined') && questionArrray[index].preDefined.length > 0) {
            questionArrray[index].preDefined.forEach(element1 => {
              if (element1.hasOwnProperty('checked')) {
                element1['checked'] = false;
                modifyiedQueArr1.push(element1);
              }
              else {
                element1['checked'] = false;
                modifyiedQueArr1.push(element1);
              }
            });
            questionArrray[index].preDefined = modifyiedQueArr1;
          }
          if (questionArrray[index].hasOwnProperty('checked')) {
            questionArrray[index]['checked'] = false;
            OuterQuestArr.push(questionArrray[index]);
          }
          else {
            questionArrray[index]['checked'] = false;
            OuterQuestArr.push(questionArrray[index]);
          }
          this.QuestionSet = OuterQuestArr;
          this.questionAnsArr = OuterQuestArr;
        }
      }
    }
    console.log("the outer array :", OuterQuestArr, this.questionAnsArr, this.reducedQuestionsCount, OuterQuestArr.length)
  }

  selectedIndexChange(val: number) {
    this.selectedIndex = val;
    val == 0 ? this.cartServiceType = 1 : this.cartServiceType = 2;
    this.disableFixed == false && this.disableHourly == false ? this.getServiceList() : '';

  }
  swtichBillingModalUi(billing_model) {
    this.Billing_model = billing_model;
    switch (billing_model) {
      case '1': case '5':
        this.selectedIndex = 0;
        this.cartServiceType = 1;
        this.disableFixed = false;
        this.disableHourly = true;
        break;
      case '3': case '4':
        this.selectedIndex = 1;
        this.cartServiceType = 2;
        this.disableFixed = true;
        this.disableHourly = false;
        break;
      case '2': case '6':
        this.selectedIndex = 0;
        this.disableFixed = false;
        this.disableHourly = false;
        this.selectedIndex == 0 ? this.cartServiceType = 1 : this.cartServiceType = 2;
        this.myCartList = [];
        break;

      case '7':
        this.myCartList = [];
        this.cartServiceType = 7;
        this.bookingType = 2;
        this.paymentTypeValue = 0;
        this.enableManualDispatch = false;
        this.enableAutoDispatch = false;
        this.enablePostDispatch = true;
        this.forBiddingDate = moment(new Date, 'D MMM, YYYY').format('D MMM, YYYY');
        this.forBiddingTime = '';
        this.enableQuestionsForBidding = true;
    }
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }
  onSelectSubCat(value) {
    this.forSubCat = 0;
    this.subCategorySelected = [];
    this.promoDiscountAmount = '';
    this.answeredCount = 0;

    if (value != 0) {
      let indx = this.catSelected.findIndex((item) => item._id == value);
      this.subCategorySelected = this.catSelected[indx];
      if (this.subCategorySelected) {
        this.boxFocus(4);
      }
      this.forSubCat = this.subCategorySelected._id;
    }

    this.enableType1 = this.categoryOpted.bookingTypeAction.now;
    this.enableType2 = this.categoryOpted.bookingTypeAction.schedule;
    this.enableType3 = this.categoryOpted.bookingTypeAction.repeat;
    this.getServiceList();
    this.switchEnable(1);
    // }
    //////conole.log("this.subCategorySelected ", this.subCategorySelected)
  }
  autoFillCustomer(e, type, val) {
    // //conole.log("evnt for phone numnber",e)
    this.autoFillData = [];
    if (val.length < 1 && e.keyCode !== 9 && e.ctrlKey && e.keyCode !== 86) {
      //conole.log("autofill data")
      setTimeout(() => {
        //////conole.log("the refresh ath edata after new customer entered");
        this.closeAutoFill();
        this.refreshData();
      });
      $(".autofillCustomer").hide('slow');
      $(".myDate").val("");
      $(".book_later_time").val("");
    }
    else if (type === 3 && e.keyCode !== 9) {
      // phoneNumber
      !((this.customerMail && this.customerMail.length > 0) || (this.customerName && this.customerName.length > 0)) ?
        (this.userSelected = false,
          this.newUser = true,
          this.autoComplete(type, val)) : this.validatePhoneNumber(type, val);
    }
    else if (type === 2 && val.length >= 4 && e.keyCode !== 9) {
      //customer email
      !((this.customerPhone && this.customerPhone.length > 0) || (this.customerName && this.customerName.length > 0)) ?
        (this.userSelected = false,
          this.newUser = true,
          this.autoComplete(type, val)) : '';
    }
    else if (type === 1 && val.length >= 2 && e.keyCode !== 9) {
      // customer name
      !((this.customerPhone && this.customerPhone.length > 0) || (this.customerMail && this.customerMail.length > 0)) ?
        (this.userSelected = false,
          this.newUser = true,
          this.autoComplete(type, val)) : '';
    }
    else if (val.length < 1) {
      this.closeAutoFill();
      this.refreshData();
      $(".autofillCustomer").hide('slow');
      $(".myDate").val("");
      $(".book_later_time").val("");
    }
  }
  //phoneNumberValidation 

  validatePhoneNumber(type, val) {
    if (type === 3) {
      // this.telInput = intlTelInput(input);
      this.input.val(val.replace(' ', ''));
      // //conole.log("val", val)
      if (this.input.intlTelInput("isValidNumber")) {
        // //conole.log("valid")
        this.isphoneValid = true;
        // this.CustRegisterPhone.disable();
      }
      else {
        // //conole.log("invalid")
        this.isphoneValid = false;

      }
    }
  }

  dontAllowSpace(e) {
    if (e.keyCode == 32) { return false; }
  }
  dontAllowAlphabets(e) {
    let returnValue;
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
    //////conole.log("the return value is:",this.BidAmount)
  }
  telInput: any;
  isphoneValid: any;
  autoComplete(type, val) {
    this.uniqueCustomerEmail = false;
    this.uniqueuniqueCustomerPhoneNumber = false;
    if (val.replace(/\s/g, "").length > 0) {

      this._apiServiceManager.autocompleteCustomer(type, val).subscribe(result => {
        this.autoFillData = result.data;
        //////conole.log("the auto complete user", result)
        if (this.userSelected == true) {
          this.autoFillData = [];
          !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
        }
        else {
          if (this.autoFillData.length > 0) {
            this.newUser = false;
            this.uniqueCustomerDetails(this.autoFillData);
            this.customerAddHeader = "CUSTOMER DETAILS";
            $(".autofillCustomer").show(500);

          }
          else {
            $(".autofillCustomer").hide();
            this.newUser = true;
            this.intlTelInput();
            this.customerAddHeader = "ADD NEW CUSTOMER";
            // //conole.log("val type", val,type)
            this.validatePhoneNumber(type, val);

          }
        }
      }, error => {
        //////conole.log("Error from rquest in Checkout ", error)
        //let errorMessage = JSON.parse(error._body);
        if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() } else { $("#appt_loader_loggedOut").hide(); }
      });
    }
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }
  //phone number validation
  intlTelInput() {
    let countryCode1;
    // $.get('https://ipapi.co/json/', () => { }).always((resp) => {
    //   var countryCode = (resp && resp.country) ? resp.country : "";
    //   countryCode1 = resp.country;
    //   ////conole.log('the response for ',resp,countryCode1);
    //   this.cookieService.set('countryCode', countryCode1);

    // });
    var countryCode = 'US';
    this.cookieService.get('countryCode') != "undefined" ? countryCode = this.cookieService.get('countryCode') : '';
    //conole.log("country,countryCode",countryCode,this.cookieService.get('countryCode'))
    setTimeout(() => {
      this.input = $("#phone");

      this.input.intlTelInput({
        nationalMode: true,
        separateDialCode: true,
        initialCountry: countryCode,
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.10/js/utils.js" // just for formatting/placeholders etc
      });
    }, 200);
    ////


  }
  //functionName: uniqueCustomerDetails, desc: to check unique customer details, developer:sowmya sv, date: 28-11-18
  uniqueCustomerDetails(CustomerData) {
    if (CustomerData.findIndex((item) => item.phone == this.customerPhone) >= 0) {
      this.uniqueuniqueCustomerPhoneNumber = true;
    }
    if (CustomerData.findIndex((item) => item.email == this.customerMail) >= 0) {
      this.uniqueCustomerEmail = true;
    }

  }
  userSelected: boolean = false;


  selectFromAutoComplete(item) {
    this.userSelected = true;
    //////conole.log("the selected ", this.userSelected);
    this.customerName = item.firstName + " " + item.lastName;
    this.customerPhoneCode = item.countryCode;
    this.customerPhone = item.phone;
    this.customerMail = item.email;
    this.customerId = item.customerId;
    this.Category = "";
    setTimeout(() => {
      this.autoFillData = [];
      this.closeAutoFill();
    }, 10)
    this.getCards();
    this.getRecentAddress();
    this.boxFocus(2);
    this.clearBeforeSelectingCategory();
    this.autoFillData = [];
    // this.ref.detectChanges();
    this.autoFillData = [];
  }


  getCards() {
    this._apiServiceManager.getCard(this.customerId).subscribe(result => {
      if (typeof result.data === "undefined") {
        swal.fire({
          imageUrl: 'assets/images/user1.png',
          imageWidth: 50,
          imageHeight: 50,
          imageAlt: 'Customer image',
          animation: false,
          html: '<p>Please select another customer or register with new customer</p>'
        }
        ).then((result) => {
          if (result.value) {
            this.refreshData();
          }
        });
      }
      else {
        this.WalletBalance = result.wallet.walletAmount;
        result.data ? this.CardList = result.data : this.CardList = [];
        result.wallet ? this.walletDetails = result.wallet : '';
        this.CardList.length == 0 ? this.CardSelectDisable = false : this.CardSelectDisable = true;
        //////conole.log("***********************getCard******************************", this.CardList,this.walletDetails);
        $("#walletBalance").show();
      }
    }, error => {
      //////conole.log("Error from rquest in Checkout ", error);
      //let errorMessage = JSON.parse(error._body);
      if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() } else { $("#appt_loader_loggedOut").hide(); }
    })
  }
  closeAutoFill() {
    $(".autofillCustomer").hide("slow");
    this.autoFillData = [];
    $("#others").hide();
  }

  refreshData() {
    this.BookingMode = 'determinate';
    this.currentDefaultTime = "Please Select Time";
    this.enableQuestionsForBidding = false;
    this.countCheck = 0;
    this.WalleteDisable = true;
    this.LocationCheck = null;
    this.HourlyPrice = false;
    this.callTypeValue = 2;
    this.uniqueCustomerEmail = false;
    this.uniqueuniqueCustomerPhoneNumber = false;
    this.CategoryError = false;
    this.ConfirmMultipleShift = 0;
    this.CardList = [];
    this.isphoneValid = '';
    this.categoryOpted = '';
    this.CardSelectDisable = false;
    this.SeletcedTimeDuration = -1;
    this.selectedTimeSlot = '';
    this.CurrentDateTimSlot = moment(new Date()).format('ddd MMM DD, YYYY');
    this.ShifthNexts = 0;
    this.minTime = moment().add(1, 'hours').format('hh:mm A');;
    this.TimePickerMul = moment().format("h:mm A");
    this.durationPicker = "1:00";
    this.allMandatoryQuestions = true;
    // $('#Multipleshifting').appendTo("body").modal('hide');
    this.ShifthNexts = 0;
    this.todaysDate = new Date();
    this.radiochecked = false;
    $(".shiftOptionClass").each(function () {
      if ($(".shiftOptionClass").hasClass("mat-radio-checked")) {
        ($(".shiftOptionClass").removeClass("mat-radio-checked"));
      }
    });
    this.ConfirmMulShift = false;
    this.optionsChecked = false;
    this.shiftOption = 0;
    this.Billing_model = '1';
    this.ShiftourlyCount = 0;
    // this.CustomDate = false;
    this.ShiftCount = 0;
    this.bookingShift = [];
    this.ShowDays = [];
    this.Showerror = false;
    this.DaysChecked = false;
    this.startDate = "";
    this.endDate = "";
    this.ResultantEndTime = '';
    this.shiftOption = 0;
    this.diffrence = 0;
    this.myControl.reset();
    this.SearchCategory = '';
    this.other_Location = '';
    $("#otherTag").val('');
    this.SelectedFile = 0;
    this.userSelected = false;
    this.BiddingCheckList = [];
    this.Custemail.reset();
    this.CustRegisterName.reset();
    this.CustRegisterPhone.reset();
    this.CustRegisterCountryCode.reset();
    this.JobPaymentType.reset();
    this.jobDescriptionController.reset();
    this.BiddingTextArea.reset();
    this.cardSelect.reset();
    this.slideController.reset();
    this.BiddingPrice.reset();
    this.RegisterPhone.reset();
    $("#VisitFees").hide();
    $("#walletBalance").hide();
    $("#scheduleDate").val('');
    $("#scheduleTime").val('');
    $("#StartDate").val('');
    $("#EndDate").val('');
    $("#StartTimeShift").val('');
    $("#StartDuration").val('');
    this.PromoCode = "";
    this.ForCheckout = 1;
    this.PromoCodeId = "";
    this.PromoCodeQuestion = "";
    this.BiddingQuestion = "";
    this.BiddingQuestionId = "";
    this.AnswerArr = [];
    this.enablePostDispatch = false;
    this.newUser = false;
    this.sliderChecked = false;
    this.customerAddHeader = "CUSTOMER DETAILS";
    this.walletDetails = [];
    this.customerId = "";
    this.addressTag = "";
    this.promoDiscountAmount = '';
    this.previousLocationList = [];
    this.clearDefaultAddress(0);
    this.categoryList = [];
    this.catSelected = [];
    this.selectedDays = '';
    this.selectedProvider = [];
    this.providersList = [];
    this.availableList = [];
    this.unavailableList = [];
    this.serviceList = [];
    this.checkOutList = [];
    this.myCartList = [];
    this.haveSelectedProvider = false;
    this.enableType1 = false;
    this.enableType2 = false;
    this.enableType3 = false;
    this.visitFee = 0;
    this.Category = "";
    this.SubCategory = "";
    this.ForCheckout = 1;
    this.enableAutoDispatch = true;
    this.enableManualDispatch = false;
    this.FocusedValue = 0;
    this.disableHourly = true;
    this.disableFixed = true;
    this.MandatoryQuestionsCount = 0;
    this.hourlyCharge = 0;
    this.hourlyCount = 0;
    this.hourlyTotal = 0;
    this.cartServiceType = '';
    this.promocode = '';
    this.promoDiscountAmount = '';
    this.Service_type = -1;
    this.resetForAddress();
    this.BidAmount = "";
    this.PromoCode = "";
    this.CountOfBiddingQuestion = 0;
    $(".autoFillAddress").hide();
    // this.ref.detectChanges();
    this.bookingType = 0;
    this.BookingTrigger();
    this.TotalAmount = 0;
    this.callType.forEach((item) => item.checked = false);
    this.paymentTypeValue = 0;
    this.closeAutoFill();
  }
  clearQuestionArray() {
    this.answeredCount = 0;

    for (let index = 0; index < this.questionAnsArr.length; index++) {
      if (this.questionAnsArr[index].hasOwnProperty('preDefined') && this.questionAnsArr[index].preDefined.length > 0) {
        this.questionAnsArr[index].preDefined.forEach(element1 => {
          element1['checked'] = false;
        });
      }
      this.questionAnsArr[index]['checked'] = false;
    }
    this.checkBoxAnswer = [];
    this.radioAnswer = [];
    this.textAreaAnswer = [];
    this.pastCurrentAns = [];
    this.currentFutureAns = [];
    this.responsedQuestions = [];
    this.jobDescriptionController.reset();
    this.answerAllError = false;
    this.imgUrl = [];
  }
  show: boolean = false;
  closeMyModal() {
    this.AnswerArr = [];
    this.bookingType = 0;
    this.ngxSmartModalService.close('AddNewJobModal');
    this.show = true;
    setTimeout(() => {
      this.refreshData();
    }, 50);
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }

  matSlideToggleChange(e) {
    this.sliderChecked = true;
    this.WalletSliderChecked = e;
  }

  getRecentAddress() {
    this._apiServiceManager.getCustomerAddress(this.customerId).subscribe(result => {
      let condition1 = false;
      let condition2 = false;

      if (result.data.defaultAddress.addLine1) {
        condition1 = true;
        // if recent address exist add it to address input
        this.customer_Location = result.data.defaultAddress.addLine1;
        this.addressId = result.data.defaultAddress._id;
        // select the array 
        this.recentAddress = result.data.defaultAddress;

        this.pickuplat = parseFloat(result.data.defaultAddress.latitude);
        this.pickuplong = parseFloat(result.data.defaultAddress.longitude);
        this.getCategory();
      };
      if (result.data.allAddress.length > 0) {
        condition2 = true;
        // old addresses selected by customer 
        this.previousLocationList = result.data.allAddress;
      }
      if (!condition2 && !condition1) {
        this.setForNewAddress();
      }

    }, error => {
      //let errorMessage = JSON.parse(error._body);
      if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() } else { $("#appt_loader_loggedOut").hide(); }



    })
  }

  clearDefaultAddress(val) {
    if (val == 2 || val == 0) {
      this.customer_Location = "";
      this.addressId = "";
      this.recentAddress = [];
      this.pickuplat = '';
      this.pickuplong = '';
      this.newLocation = true;
      // Card #267, bug: categories should refresh after cloing calltype popup withput saving it. desc: put empty arrya for categoryLis, developer: sowmya sv
      this.categoryList = [];
    }
    this.AnswerArr = [];
    this.customer_Location = "";
    this.addressId = "";
    this.recentAddress = [];
    this.pickuplat = '';
    this.pickuplong = '';
    this.addressTag = "";
    this.CountOfBiddingQuestion = 0;
    this.ForCheckout = 1;
    this.other_Location = '';
    $("#VisitFees").hide();
    this.setForNewAddress();
  }

  setForNewAddress() {
    this.AnswerArr = [];
    this.newLocation = true;
    this.readOnlyAddress = false;
    this.addressHeader = "ADD NEW ADDRESS";
    // this.ref.detectChanges();
  }

  resetForAddress() {
    this.AnswerArr = [];
    this.newLocation = false;
    this.readOnlyAddress = true;
    this.addressHeader = "LOCATION";
    $("#others").hide();
    // this.ref.detectChanges();
  }
  clearadressBar() {
    this.AnswerArr = [];
    this.customer_Location = "";
    this.addressId = "";
    this.recentAddress = [];
    this.pickuplat = '';
    this.pickuplong = '';
    this.CountOfBiddingQuestion = 0;
    this.resetForAddress();
    this.MandatoryQuestionsCount--;
  }

  showAddressbook() {
    //////conole.log("the mandatory question:",this.MandatoryQuestionsCount);
    if (this.customer_Location && parseInt(this.Billing_model) == 7) {
      ++this.MandatoryQuestionsCount;
    }
    if (this.previousLocationList.length > 0) {
      $(".autoFillAddress").show('slow');
    }
    this.newLocation = false;
    this.categoryList = [];

  }

  closeAddress() {
    $(".autoFillAddress").hide('slow');
  }

  selectAddress(address) {
    this.customer_Location = address.addLine1;
    if (this.customer_Location && parseInt(this.Billing_model) == 7) {
      ++this.MandatoryQuestionsCount;
    }
    this.addressId = address._id;
    this.pickuplat = address.latitude;
    this.pickuplong = address.longitude;
    this.getCategory();
    this.closeAddress();
  }

  boxFocus(box) {
    //////conole.log("focused box ", box);
    $(".focus").removeClass("highLight")
    switch (box) {
      case 0:
        break;
      case 1:
        $("#focus1").addClass("highLight");
        break;
      case 2:
        $("#focus2").addClass("highLight");
        break;
      case 3:
        $("#focus3").addClass("highLight");
        // this.newLocation=false;
        break;
      case 4:
        $("#focus4").addClass("highLight");
        break;
      case 5:
        $("#focus5").addClass("highLight");
        break;
      case 6:
        $("#focus6").addClass("highLight");
        break;
      case 7:
        $("#focus7").addClass("highLight");
        break;
      case 8:
        $("#focus8").addClass("highLight");
        break;
    }
  }
  DaysChecked: boolean = false;
  DaysArray = [];
  checkedArray: any = [];
  daysSelected(Days, index) {
    let indx = this.DaysSelected.findIndex((item) => item.days === Days.days);
    this.addDay = [];
    if (indx == -1) {
      this.checkedArray.push(index);
      this.DaysSelected.push(Days);
      this.daysList[index].checked = true;
      ////conole.log("the days ", this.DaysSelected);
    }
    else {
      this.checkedArray.splice(indx, 1);
      this.DaysSelected.splice(indx, 1);
      this.daysList[index].checked = false;
    }
    this.Showerror = false;
    this.DaysChecked = true;
    this.Ischecked = false;
    // this.filteredProvider();
  }
  getProperShiftCount(daysList) {
    let currentDate = moment(new Date, "DD-MM-YYYY").format('DD-MM-YYYY');
    let currentTime = moment(new Date, "H:mm:ss").format("H:mm:ss");
    let timeChosen = moment(this.TimePickerMul, "h:mm A").format("HH:mm:ss");
    //conole.log("the time chosen currentTime",currentTime,"timechosen",timeChosen,this.TimePickerMul,"currentDate",currentDate);
    ////conole.log("the daylys are:", daysList)
    var curTime = new Date();
    curTime.setHours(parseInt(currentTime.split(":")[0]), parseInt(currentTime.split(":")[1]), parseInt(currentTime.split(":")[2]));
    let chosenTime = new Date();
    chosenTime.setHours(parseInt(timeChosen.split(":")[0]), parseInt(timeChosen.split(":")[1]), parseInt(timeChosen.split(":")[2]));
    daysList.forEach((element, indx) => {
      if (element.date && element.date.length === 1) {
        if (curTime > chosenTime)
          element.date[0] === currentDate ? daysList.splice(indx, 1) : '';
      }
      else if (element.date && element.date.length > 1) {
        element.date.forEach((item, index) => {
          if (curTime > chosenTime)
            item === currentDate ? daysList.splice(indx, 1) : '';
        });
      }
    });
    return daysList;
  }
  filteredProvider() {
    let subcat = this.forSubCat || 0;
    let DataForBooking = {};
    let filterData = {
      latitude: this.pickuplat,
      longitude: this.pickuplong,
      categoryId: this.forCat,
      bookingType: this.bookingType,
      searchStr: '',
      deviceTime: ''
      // city: this.LocationCheck['city'],
      // state: this.LocationCheck['state'],
      // country: this.LocationCheck['country'],
    };
    if (this.providersearch) {
      filterData['searchStr'] = this.providersearch;
    }
    else {
      filterData['searchStr'] = "";
    }
    // this.providersearch ? (filterData['searchStr'] = this.providersearch,this.haveSelectedProvider = false) : filterData['searchStr'] = "";
    let date = $("#MyDate").val() + " " + $("#MyTime").val() + ":00";
    // For Bidding 
    if ($("#MyTime").val()) {
      let TimeArray = ($("#MyTime").val()).split(":");
      let TimeFor = moment(TimeArray, "h:mm A").format("h:mm A");
      this.DateTimeForBid = TimeFor;
    }
    if (this.bookingType == 2) {
      if (this.callTypeValue == 1 || this.callTypeValue == 3) {
        this.callTypeValue == 1 ? filterData['callType'] = 1 : filterData['callType'] = 3;
        this.disableFixed = true;
        this.disableHourly = true;
      }
      let coutnDuration = [];
      // let StartDateForCustomShift = $(".scheduleDate").val();
      // let TimeSelected = $(".scheduleTime").val();
      let StartDateForCustomShift = this.forBiddingDate;
      let TimeSelected = this.forBiddingTime;
      (StartDateForCustomShift) ? filterData["scheduleDate"] =
        moment(StartDateForCustomShift + " " + TimeSelected, 'DD MMM YYYY hh:mm a ').unix() :
        filterData["scheduleDate"] = moment().unix();
      this.categoryOpted.billing_model === '7' ? filterData["scheduleDate"] =
        moment(this.forBiddingDate + " " + this.forBiddingTime, 'DD MMM YYYY hh:mm a ').unix() : '';
      (this.selectedValue) ? filterData["scheduleTime"] = this.selectedValue : filterData["scheduleTime"] = 60;
    }
    if (this.bookingType == 3) {
      // 2 is for Provider
      DataForBooking = this.ForMultiAndLaterBooking(this.bookingType, this.ShiftValue, 2);
      (DataForBooking["scheduleDate"]) ? filterData["scheduleDate"] = parseFloat(DataForBooking["scheduleDate"]) : filterData["scheduleDate"] = moment().unix();
      (DataForBooking["scheduleTime"]) ? filterData["scheduleTime"] = DataForBooking["scheduleTime"] : filterData["scheduleTime"] = 60;
      (DataForBooking["endTimeStamp"]) ? filterData["endTimeStamp"] = DataForBooking["endTimeStamp"] : filterData["endTimeStamp"] = moment().unix();;
      (DataForBooking["days"]) ? filterData["days"] = DataForBooking["days"] : filterData["days"] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    }
    this._apiServiceManager.getFilteredProviders(filterData).subscribe(result => {
      if ((result.data)) {
        //////conole.log("the filter provider", result);
        // this.providersList = result.data;
        this.availableList = result.data.available;
        this.unavailableList = result.data.unAvailable;
        this.haveSelectedProvider = false;
        $(".filteredProList").fadeIn("slow");
        this.boxFocus(6);
      }
    }, error => {
      //////conole.log("Error from rquest in Checkout ", error)
      //let errorMessage = JSON.parse(error._body);
      if (error.status == 498 || error.status === 440) {
        this.closeAllNgxModalsAtTokenExpired();
      } else {
        $("#appt_loader_loggedOut").hide();
      }
    })
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }
  providerTimeSlot = [];

  selectProvider(item) {
    this.ProviderSeleted = 1;
    this.categoryOpted.billing_model !== 7 ? this.swtichBillingModalUi(this.categoryOpted.billing_model) : '';
    ////conole.log('the myCartList :',this.myCartList );
    if (this.myCartList) {
      this.myCartList.totalAmount = 0;
    }
    this.checkOutList = [];
    (this.disableFixed && !this.disableHourly && this.callTypeValue == 2) ? this.getdeleteCart() : '';

    (!this.disableFixed && this.disableHourly && this.callTypeValue == 2) ? this.getdeleteCart() : '';
    (!this.disableFixed && !this.disableHourly && this.callTypeValue == 2) ? this.getServiceList() : '';
    (this.callTypeValue == 1 || this.callTypeValue == 3) ? this.TotalAmount = this.categoryOpted.consultancyFee : '';

    // this.checkOutList=[];
    this.selectedProvider = item;
    this.haveSelectedProvider = true;
    if (this.Service_type == 2) {
      this.DisableAutoManualDispatch(0);
    }
    $(".filteredProList").fadeOut('slow');
    setTimeout(() => {
      this.boxFocus(7);
    }, 100)
    this.switchEnable(2);
    if (this.callTypeValue == 1 || this.callTypeValue == 3) {
      this.getProviderSlot();
    }
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';

  }
  // get delete previous cart data
  getdeleteCart() {
    if (this.myCartList && this.myCartList._id) {
      let data = {
        cartId: this.myCartList._id,
        customerId: this.customerId
      };
      this._apiServiceManager.deleteCart(data).subscribe(result => {
        this.myCartList = [];
        this.checkOutList = [];
      });
    }
    // this.getServiceList();
  }
  getProviderSlot() {
    this.MorningSlots = [];
    this.EveningSlot = [];
    this.AfterNoonSlots = [];
    this.NightSlot = [];
    let presentDate = moment(new Date()).format('ddd MMM DD, YYYY');
    let currentDate1 = moment(new Date()).format('ddd L');
    // let currentDate1 = moment(new Date()).add(1,'days').format('ddd L');
    let currentTime = moment(new Date(), 'HH:mm A').format('HH:mm A')
    let timestamp3 = moment(currentDate1 + ', 5:30:00 AM', 'ddd L, hh:mm:ss A').format('ddd DD MMM YYYY hh:mm:ss A');
    if (this.dateCount && this.dateCount > 0) {
      currentDate1 = moment(new Date()).add(1, 'days').format('ddd L');
      timestamp3 = moment(this.CurrentDateTimSlot + ', 5:30:00 AM', 'ddd MMM DD YYYY, hh:mm:ss A').format('ddd MMM DD YYYY hh:mm:ss A');
    }
    else {
      this.CurrentDateTimSlot = moment(new Date()).format('ddd MMM DD, YYYY');
    }
    let requestData = {
      providerId: this.selectedProvider._id,
      categoryId: this.forCat,
      date: moment(timestamp3).unix(),
      callType: this.callTypeValue
    }
    this._apiServiceManager.getProviderTimeSlots(requestData).subscribe((res) => {
      this.providerTimeSlot = res.data;
      if (this.callTypeValue == 1 || this.callTypeValue == 3) {
        // if selected schedule is not available
        if (this.SeletcedTimeDuration && this.SeletcedTimeDuration.length > 0) {
          this.providerTimeSlot.splice(this.SeletcedTimeDuration, 1);
        }
        this.disableFixed = true;
        this.disableHourly = true;
        this.checkOutList = [];
        this.ngxSmartModalService.open('InCallTimeSlot');
        this.DisableAutoManualDispatch(2);
        // this.toastrService.info("Please choose the Provider");
        let currentTime = moment(new Date(), 'HH:mm A').add(1, 'hours').format('HH:mm A')
        for (let inst = 0; inst < this.providerTimeSlot.length; inst++) {
          this.providerTimeSlot[inst].from = moment.unix(this.providerTimeSlot[inst].from).format("HH:mm");
          //morning Slot
          if (this.providerTimeSlot[inst].from >= '06:00' && this.providerTimeSlot[inst].from < '11:59' && this.providerTimeSlot[inst].isBook == 1) {
            if (this.CurrentDateTimSlot == presentDate) {
              if (this.providerTimeSlot[inst].from > currentTime && this.providerTimeSlot[inst].from < '11:59') {
                let timestampConvertor = moment(this.providerTimeSlot[inst].from, 'hh:mm A').format('hh:mm A');
                this.providerTimeSlot[inst].from = timestampConvertor;
                this.MorningSlots.push(this.providerTimeSlot[inst]);
              }
            }
            else {
              let timestampConvertor = moment(this.providerTimeSlot[inst].from, 'hh:mm A').format('hh:mm A');
              this.providerTimeSlot[inst].from = timestampConvertor;
              this.MorningSlots.push(this.providerTimeSlot[inst]);
            }
          }
          //AfterNoon slot
          else if (this.providerTimeSlot[inst].from >= '12:00' && this.providerTimeSlot[inst].from < '15:59' && this.providerTimeSlot[inst].isBook == 1) {

            if (this.CurrentDateTimSlot == presentDate) {
              if (this.providerTimeSlot[inst].from > currentTime && this.providerTimeSlot[inst].from < '15:59') {
                let timestampConvertor = moment(this.providerTimeSlot[inst].from, 'hh:mm A').format('hh:mm A');
                this.providerTimeSlot[inst].from = timestampConvertor;
                this.AfterNoonSlots.push(this.providerTimeSlot[inst]);
              }
            }
            else {
              let timestampConvertor = moment(this.providerTimeSlot[inst].from, 'hh:mm A').format('hh:mm A');
              this.providerTimeSlot[inst].from = timestampConvertor;
              this.AfterNoonSlots.push(this.providerTimeSlot[inst]);
            }
          }
          //Evening slot
          else if (this.providerTimeSlot[inst].from >= '16:00' && this.providerTimeSlot[inst].from < '19:59' && this.providerTimeSlot[inst].isBook == 1) {
            if (this.CurrentDateTimSlot == presentDate) {
              if (this.providerTimeSlot[inst].from > currentTime && this.providerTimeSlot[inst].from < '19:59') {
                let timestampConvertor = moment(this.providerTimeSlot[inst].from, 'hh:mm A').format('hh:mm A');
                this.providerTimeSlot[inst].from = timestampConvertor;
                this.EveningSlot.push(this.providerTimeSlot[inst]);
              }
            }
            else {
              let timestampConvertor = moment(this.providerTimeSlot[inst].from, 'hh:mm A').format('hh:mm A');
              this.providerTimeSlot[inst].from = timestampConvertor;
              this.EveningSlot.push(this.providerTimeSlot[inst]);
            }
          }
          //Night slot
          else if (this.providerTimeSlot[inst].from >= '20:00' && this.providerTimeSlot[inst].from <= '24:00' && this.providerTimeSlot[inst].isBook == 1) {
            if (this.CurrentDateTimSlot == presentDate) {
              if (this.providerTimeSlot[inst].from > currentTime && this.providerTimeSlot[inst].from <= '24:00') {
                let timestampConvertor = moment(this.providerTimeSlot[inst].from, 'hh:mm A').format('hh:mm A');
                this.providerTimeSlot[inst].from = timestampConvertor;
                this.NightSlot.push(this.providerTimeSlot[inst]);
              }
            }
            else {
              if (this.providerTimeSlot[inst].from >= '00:00' && this.providerTimeSlot[inst].from <= '05:59') {
                let timestampConvertor = moment(this.providerTimeSlot[inst].from, 'hh:mm A').format('hh:mm A');
                this.providerTimeSlot[inst].from = timestampConvertor;
                this.NightSlot.push(this.providerTimeSlot[inst]);
              }
              else {
                let timestampConvertor = moment(this.providerTimeSlot[inst].from, 'hh:mm A').format('hh:mm A');
                this.providerTimeSlot[inst].from = timestampConvertor;
                this.NightSlot.push(this.providerTimeSlot[inst]);
              }
            }
          }
        }
      }
    });
  }
  ProviderSeleted: any;
  removeSelected() {
    this.ShiftourlyCount = 0;
    //////conole.log("the service type is : ", this.Service_type);
    if (this.Service_type == 2) {
      //changes the haveSelectedProvider here
      this.haveSelectedProvider = false;
      this.selectedProvider = [];
      this.checkOutList = [];
      setTimeout(() => {
        this.getOldCart();
      }, 100);
      this.DisableAutoManualDispatch(2);
      this.toastrService.info("Please choose the Provider");
    }
    else if (this.Service_type == 1) {
      //changes the haveSelectedProvider here
      this.haveSelectedProvider = false;
      this.selectedProvider = [];
      this.checkOutList = [];
      this.DisableAutoManualDispatch(2);
      this.toastrService.info("Please choose the Provider");
    }
    else if (this.disableFixed && !this.disableHourly) {
      this.myCartList.totalAmount = 0;
      this.checkOutList = [];
      setTimeout(() => {
        this.getOldCart();
      }, 100);
      this.haveSelectedProvider = false;
      this.ProviderSeleted = 0;
      this.selectedProvider = [];
      this.enableAutoDispatch = true;
      this.enableManualDispatch = false;

    } else if (!this.disableFixed && !this.disableHourly || !this.disableFixed && this.disableHourly) {
      this.ProviderSeleted = 0;
      this.selectedProvider = [];
      this.checkOutList = [];
      this.haveSelectedProvider = false;
      this.enableAutoDispatch = true;
      this.enableManualDispatch = false;
      this.hourlyCount = 1;
      setTimeout(() => {
        this.getOldCart();
      }, 100);

    }
    $(".filteredProList").fadeIn();
    this.boxFocus(6);
  }


  openProviderList() {
    $(".filteredProList").fadeToggle();
  }
  checkOutForMultipleShift(serviceItem, actionValue, bookingmodal, quntityAdd) {
    let data;
    data = {
      customerId: this.customerId,
      categoryId: this.categoryOpted._id,
      quntity: quntityAdd,
      action: actionValue,
      callType: this.callTypeValue
    }
    bookingmodal == 1 ? data['serviceId'] = serviceItem.serviceId : data['serviceId'] = "1";
    this.bookingType ? data['bookingType'] = this.bookingType : 1;
    this.selectedProvider ? data["providerId"] = this.selectedProvider._id : data["providerId"] = "";
    // this.selectedProvider && this.selectedProvider._id ? data['providerId'] = this.selectedProvider._id : '';
    this.cartServiceType ? data['serviceType'] = this.cartServiceType : '';
    this._apiServiceManager.cart(data).subscribe(result => {
      //////conole.log("Result----> Cart Added", result)
      this.myCartList = result.data;
      if (this.myCartList) {
        let CartArray = result.data.item
        this.checkOutList = result.data.item;
        this.TotalAmount = this.myCartList.totalAmount;
        this.hourlyTotalAmount = this.myCartList.totalAmount;
        // this.hourlyCharge =   this.TotalAmount ;
        if (this.WalletSliderChecked == true && this.WalletBalance > 0 && this.TotalAmount > this.WalletBalance) {
          $("#DisableButtonsForCard").show();
          $("#EnableButtonsforCard").hide();
        }
        else if (this.promoDiscountAmount) {
          this.toastrService.info("This promocode may not be applicable if payment price or quantity is changed");
          this.switchPromoCode();
        }

        else if (bookingmodal == 1) {
          this.hourlyCount = 1;
          //////conole.log("the service list :", this.serviceList);
          let cartIndex = CartArray.findIndex((item) => item.serviceId == serviceItem.serviceId);
          let indx = this.serviceList.findIndex((item) => item.serviceId == serviceItem.serviceId);
          if (indx > -1) {
            this.serviceList[indx].status = 1;
          }
          this.serviceList[indx].quntity = CartArray[cartIndex].quntity;
          (CartArray[cartIndex].amount > 0) == true ? (this.serviceList[indx].amount = CartArray[cartIndex].amount) : (this.serviceList[indx].amount = CartArray[cartIndex].unitPrice);
          this.serviceList[indx].status = CartArray[cartIndex].status;

        } else {
          if (this.myCartList.serviceType == 2) {
            this.hourlyCount = result.data.item[0].quntity;
            this.hourlyCharge = result.data.item[0].unitPrice;
            $("#ForMarketingPlace").show();
            $("#ForOtherCategory").hide();

          } else {
            this.hourlyCount = result.data.item[0].quntity;
            this.hourlyCharge = result.data.item[0].unitPrice;
            $("#ForMarketingPlace").hide();
            $("#ForOtherCategory").show();
          }
        }
      }
      this.ShiftPerAmount();
      !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
    }, error => {
      //////conole.log("Error from rquest in Checkout ", error)
      //let errorMessage = JSON.parse(error._body);
      if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() } else { $("#appt_loader_loggedOut").hide(); }
    });
  }
  deleteAllCartdsForFixedServices() {
    if (this.serviceList && this.serviceList.length > 0 && this.selectedIndex == 0) {
      this.serviceList.map((item, index) => {
        console.log('deleet all cards', item);
        let data = {
          cartId: item._id,
          customerId: this.customerId
        };
        this._apiServiceManager.deleteCart(data).subscribe(result => {
          this.myCartList = [];
          this.checkOutList = [];
        });
      });
    }
    else if (this.selectedIndex == 1 && this.hourlyData) {
      let data = {
        cartId: this.hourlyData._id,
        customerId: this.customerId
      };
      this._apiServiceManager.deleteCart(data).subscribe(result => {
        this.myCartList = [];
        this.checkOutList = [];
        // this.getdeleteCart();
      });
    }
    // else {
    //   this.getdeleteCart();
    //   // this.clearAllCartsForMultiShift(bookingmodal, serviceItem);
    // }

  }
  deleteAllCarts() {
    if (this.serviceList && this.serviceList.length > 0) {
      this.serviceList.map((item, index) => {
        console.log('deleet all cards', item)
        // this.clearAllCartsForMultiShift(bookingmodal, item);
        let data = {
          cartId: item._id,
          customerId: this.customerId
        };
        this._apiServiceManager.deleteCart(data).subscribe(result => {
          this.myCartList = [];
          this.checkOutList = [];
        });
      });
    }
    // this.getServiceList();
    // let data = {
    //   cartId: this.myCartList._id,
    //   customerId: this.customerId
    // };
    // this._apiServiceManager.deleteCart(data).subscribe(result => {
    //   this.myCartList = [];
    //   this.checkOutList = [];
    // });
  }
  clearAllCartsForMultiShift(bookingmodal, serviceItem) {
    let data;
    data = {
      customerId: this.customerId,
      categoryId: this.categoryOpted._id,
      quntity: 1,
      action: 0,
      callType: this.callTypeValue
    }
    bookingmodal == 1 ? data['serviceId'] = serviceItem.serviceId : data['serviceId'] = "1";
    this.bookingType ? data['bookingType'] = this.bookingType : 1;
    this.selectedProvider ? data["providerId"] = this.selectedProvider._id : data["providerId"] = "";
    // this.selectedProvider && this.selectedProvider._id ? data['providerId'] = this.selectedProvider._id : '';
    this.cartServiceType ? data['serviceType'] = this.cartServiceType : '';
    this._apiServiceManager.cart(data).subscribe(result => {
      console.log("Result----> Cart Added", result)
      this.myCartList = result.data;
      if (this.myCartList) {
        let CartArray = result.data.item
        this.checkOutList = result.data.item;
        this.TotalAmount = this.myCartList.totalAmount;
        this.hourlyTotalAmount = this.myCartList.totalAmount;
        // this.hourlyCharge =   this.TotalAmount ;
        if (this.WalletSliderChecked == true && this.WalletBalance > 0 && this.TotalAmount > this.WalletBalance) {
          $("#DisableButtonsForCard").show();
          $("#EnableButtonsforCard").hide();
        }
        else if (this.promoDiscountAmount) {
          this.toastrService.info("This promocode may not be applicable if payment price or quantity is changed");
          this.switchPromoCode();
        }

        else if (bookingmodal == 1) {
          this.hourlyCount = 1;
          //////conole.log("the service list :", this.serviceList);
          let cartIndex = CartArray.findIndex((item) => item.serviceId == serviceItem.serviceId);
          let indx = this.serviceList.findIndex((item) => item.serviceId == serviceItem.serviceId);
          indx > -1 ? this.serviceList[indx].status = 1 : '';
          cartIndex > -1 ? this.serviceList[indx].quntity = CartArray[cartIndex].quntity : '';
          (cartIndex > -1 && CartArray[cartIndex].amount > 0) ? (this.serviceList[indx].amount = CartArray[cartIndex].amount) :
            cartIndex > -1 ? (this.serviceList[indx].amount = CartArray[cartIndex].unitPrice) : '';
          cartIndex > -1 ? this.serviceList[indx].status = CartArray[cartIndex].status : '';

        } else {
          if (this.myCartList.serviceType == 2) {
            this.hourlyCount = result.data.item[0].quntity;
            this.hourlyCharge = result.data.item[0].unitPrice;
            $("#ForMarketingPlace").show();
            $("#ForOtherCategory").hide();

          } else {
            this.hourlyCount = result.data.item[0].quntity;
            this.hourlyCharge = result.data.item[0].unitPrice;
            $("#ForMarketingPlace").hide();
            $("#ForOtherCategory").show();
          }
        }
      }
      this.ShiftPerAmount();
      !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
    }, error => {
      //////conole.log("Error from rquest in Checkout ", error)
      //let errorMessage = JSON.parse(error._body);
      error.status == 418 ? (this.myCart(serviceItem, 1, 1, 1), this.getServiceList()) : '';
      if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() } else { $("#appt_loader_loggedOut").hide(); }
    });
  }
  ShiftPerAmount() {
    if (this.ShiftOffer) {
      if (this.ShiftOffer.discountType == 2) {
        let finalAmount = this.TotalAmount + this.visitFee;
        let total = (this.ShiftCount * finalAmount * this.ShiftOffer.value) / 100;
        let totalDis = (this.ShiftCount * finalAmount) - (total);
        let totalShift = totalDis / this.ShiftCount;
        this.TotalAmount = totalDis;
        this.TotalAmount = (this.TotalAmount - this.visitFee) * this.ShiftCount;
        this.promoDiscountAmount ? this.TotalAmount -= this.promoDiscountAmount : '';
        this.ShiftourlyCount = totalShift.toFixed(2);

      }
      else {
        let finalAmount = this.TotalAmount + this.visitFee;
        let totalDisCount = finalAmount - this.ShiftOffer.value;
        this.TotalAmount = totalDisCount;
        this.TotalAmount -= this.visitFee;
        this.promoDiscountAmount ? this.TotalAmount -= this.promoDiscountAmount : '';
        let totalShift = totalDisCount / this.ShiftCount;
        this.TotalAmount = (this.TotalAmount) * this.ShiftCount;
        this.ShiftourlyCount = totalShift.toFixed(2);
      }
    }
    else {
      this.TotalAmount = (this.TotalAmount) * this.ShiftCount;
    }
    this.hourlyCharge = this.TotalAmount / this.ShiftCount;
    console.log('per shift ', this.hourlyCharge, this.TotalAmount)
    //////conole.log("the shift value ", this.ShiftourlyCount)  
  }

  myCart(serviceItem, actionValue, bookingmodal, quntityToAdd) {
    //conole.log("this.shiftDuration",this.shiftDuration)
    let data;
    this.HourlyPrice = true;
    this.bookingType === 3 ? quntityToAdd = this.DurationForChangeProvider.split(':')[0] : quntityToAdd = quntityToAdd;
    data = {
      customerId: this.customerId,
      categoryId: this.categoryOpted._id,
      // quntity: quntityToAdd,
      action: actionValue,
      bookingType: this.bookingType,
      callType: this.callTypeValue
    }
    quntityToAdd ? data['quntity'] = parseInt(quntityToAdd) : data['quntity'] = 1;

    // bookingmodal == 1 ? data['serviceId'] = serviceItem.serviceId : data['serviceId'] = "1";
    if (this.selectedIndex == 0) {
      data['serviceId'] = serviceItem.serviceId;
      bookingmodal = 1;
    }
    else if (this.selectedIndex == 1) {
      data['serviceId'] = "1";
      bookingmodal = 2;
    }
    // this.bookingType ? data['bookingType'] = this.bookingType : 1;
    this.selectedProvider && this.selectedProvider._id ? data['providerId'] = this.selectedProvider._id : '';
    if (this.callTypeValue == 1 || this.callTypeValue == 3) {
      data['serviceType'] = 2;
    }
    else {
      this.cartServiceType ? data['serviceType'] = this.cartServiceType : '';
    }
    this._apiServiceManager.cart(data).subscribe(result => {
      console.log("Result----> Cart Added", result)
      this.myCartList = result.data;
      if (this.myCartList) {
        let CartArray = result.data.item;
        if (this.callTypeValue == 1 || this.callTypeValue == 3) {
          this.checkOutList = [];
        }
        else {
          this.checkOutList = result.data.item;
          this.TotalAmount = this.myCartList.totalAmount;
          this.hourlyTotalAmount = this.myCartList.totalAmount;
        }
        if (this.WalletSliderChecked == true && this.WalletBalance > 0 && this.TotalAmount > this.WalletBalance) {
          $("#DisableButtonsForCard").show();
          $("#EnableButtonsforCard").hide();
        }
        if (this.promoDiscountAmount) {
          this.toastrService.info("This promocode may not be applicable if payment price or quantity is changed");
          this.switchPromoCode();
        }

        if (bookingmodal == 1) {
          this.hourlyCount = 1;
          let cartIndex = CartArray.findIndex((item) => item.serviceId == serviceItem.serviceId);
          let indx = this.serviceList.findIndex((item) => item.serviceId == serviceItem.serviceId);
          if (indx > -1) {
            this.serviceList[indx].status === 0 ? this.hourlyCharge = this.serviceList[indx].is_unit : '';
            this.serviceList[indx].status = 1;
          }
          if (cartIndex > -1) {
            this.serviceList[indx].quntity = CartArray[cartIndex].quntity;
            (CartArray[cartIndex].amount > 0) ? (this.serviceList[indx].amount = CartArray[cartIndex].amount) :
              (this.serviceList[indx].amount = CartArray[cartIndex].unitPrice);
            this.serviceList[indx].status = CartArray[cartIndex].status
          }
          // cartIndex > -1 ? this.serviceList[indx].quntity = CartArray[cartIndex].quntity : '';
          // (CartArray[cartIndex].amount > 0 && cartIndex > -1) ? (this.serviceList[indx].amount = CartArray[cartIndex].amount) :
          //   (this.serviceList[indx].amount = CartArray[cartIndex].unitPrice);
          // cartIndex > -1 ? this.serviceList[indx].status = CartArray[cartIndex].status : '';
          // this.serviceList[indx].maxquantity <= 0 && this.serviceList[indx].plusXCostForQuantity === "1" ? this.toastrService.error('You reached maximum quantity.'):'';

          if (this.bookingType == 3) {
            this.ShiftPerAmount();
          }
        } else {
          if (this.myCartList.serviceType == 2) {
            this.hourlyCount = result.data.item[0].quntity;
            this.QuantityCount = result.data.item[0].quantity;
            this.hourlyCharge = result.data.item[0].unitPrice;
            if (this.bookingType == 3) {
              if (this.hourlyCount >= 1) {
                this.showEndTimeForCart(actionValue);
              }
              else {
                this.hourlyCount = 1;
                this.QuantityCount = 1;
              }
            }
            // result.data.item[0].maxquantity <= 0 && result.data.item[0].plusXCostForQuantity === "1" ? this.toastrService.error('You reached maximum quantity.'):'';
            // this.TotalAmount= this.myCartList.totalAmount;
            $("#ForMarketingPlace").show();
            $("#ForOtherCategory").hide();

          } else {
            this.hourlyCount = result.data.item[0].quntity;
            this.QuantityCount = result.data.item[0].quantity;
            this.hourlyCharge = result.data.item[0].unitPrice;
            // result.data.item[0].maxquantity <= 0 && result.data.item[0].plusXCostForQuantity === "1" ? this.toastrService.error('You reached maximum quantity.'):'';

            if (this.bookingType == 3) {
              if (this.hourlyCount >= 1) {
                this.showEndTimeForCart(actionValue);
              }
              else {
                this.hourlyCount = 1;
                this.QuantityCount = 1;
              }
            }
            $("#ForMarketingPlace").hide();
            $("#ForOtherCategory").show();
          }
          if (this.bookingType == 3) {
            this.ShiftPerAmount();
          }
        }
      }
    }, error => {
      //let errorMessage = JSON.parse(error._body);
      let errorMessage = error.error.message;
      if (error.status == 411) {
        this.toastrService.warning(errorMessage);
        this.deleteAllCartdsForFixedServices();
      }
      else if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() } else { $("#appt_loader_loggedOut").hide(); }
    });
  }
  showEndTimeForCart(actionValue) {
    let addToDate = "";
    this.endDate += addToDate;
    if (actionValue == 1) {
      let time = this.TimePickerMul;
      this.DurationForWeekOrMonth += 60;
      this.ResultantEndTime = moment(time, 'h:mm A').add(this.DurationForWeekOrMonth.toString(), 'minutes').format('h:mm A');
      let TimeForNow = moment().format("A");
      let StartTimer2 = moment(this.ResultantEndTime, 'h:mm A').format('A');
      let addToDate = "";
      if (TimeForNow != StartTimer2) {
        if (this.endDate.indexOf("(+1)") == -1) {
          addToDate = "(+1)";
          this.endDate += addToDate;
        }
      }
      else {
        if (this.endDate.indexOf("(+1)") != -1) {
          let index = this.endDate.indexOf("(+1)");
          this.endDate = this.endDate.substr(0, index);
        }
        else {
          addToDate = "";
          this.endDate += addToDate;
        }
      }
    }
    else {
      let time = this.TimePickerMul;
      this.DurationForWeekOrMonth -= 60;
      this.ResultantEndTime = moment(time, 'h:mm A').add(this.DurationForWeekOrMonth.toString(), 'minutes').format('h:mm A');
      let TimeForNow = moment().format("A");
      let StartTimer2 = moment(this.ResultantEndTime, 'h:mm A').format('A');
      let addToDate = "";
      if (TimeForNow != StartTimer2) {
        if (this.endDate.indexOf("(+1)") == -1) {
          addToDate = "(+1)";
          this.endDate += addToDate;
        }
      }
      else {
        if (this.endDate.indexOf("(+1)") != -1) {
          let index = this.endDate.indexOf("(+1)");
          this.endDate = this.endDate.substr(0, index);
        }
        else {
          addToDate = "";
          this.endDate += addToDate;
        }
      }
    }
  }

  getOldCart() {
    this.promoDiscountAmount = '';
    this.promocode = '';
    this.HourlyPrice = false;
    let providerId = this.selectedProvider._id || 0;
    this._apiServiceManager.getCart(this.forCat, this.customerId, providerId, this.callTypeValue).subscribe(result => {
      //////conole.log("Cart List", result);
      let cart = result.data.item;
      let cartLength = cart.length;
      this.myCartList = result.data;
      this.TotalAmount = this.myCartList.totalAmount;
      this.checkOutList = result.data.item;
      for (let i = 0; i < cartLength; i++) {
        let cartIndex = this.serviceList.findIndex((item) => item._id == cart[i].serviceId);
        if (!(cartIndex < 0)) {
          this.serviceList[cartIndex].quntity = cart[i].quntity;
          this.serviceList[cartIndex].amount = cart[i].amount;
          this.serviceList[cartIndex].status = cart[i].status;
          this.hourlyTotalAmount = 0;
        }
        else {
          this.hourlyTotalAmount = this.myCartList.totalAmount;
        }
      }
      if (this.disableFixed == true && this.disableHourly == false) {
        if (this.bookingType == 3 && this.DurationForChangeProvider) {
          setTimeout(() => {
            this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 2);
          }, 10)
          setTimeout(() => {
            this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 1);
          }, 100)
        }
        else {
          this.QuantityCount = result.data.item[0].quntity;
          this.hourlyCount = result.data.item[0].quntity;
          this.hourlyCharge = result.data.item[0].unitPrice;
        }
      } else if (this.disableFixed == false && this.disableHourly == false) {
        if (result.data.serviceType == 1) {
          this.hourlyCount = 0;
          if (this.bookingType == 3 && this.DurationForChangeProvider) {
            setTimeout(() => {
              this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 2);
            }, 10)
            setTimeout(() => {
              this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 1);
            }, 100)
          }
        } else {
          this.hourlyCount = result.data.item[0].quntity;
          this.QuantityCount = result.data.item[0].quntity;
          this.hourlyCharge = result.data.item[0].unitPrice;
        }
      }
      // }

    }, error => {
      //////conole.log("the error for old cart",error )
      let errorMessage = error;
      if (this.disableFixed && !this.disableHourly) {
        this.myCartList.totalAmount = 0;
        // this.hourlyCount = 1;
        this.checkOutList = [];
        if (this.bookingType == 3 && this.DurationForChangeProvider) {
          setTimeout(() => {
            this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 2);
          }, 100)
          setTimeout(() => {
            this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 1);
          }, 200)
        }
        else {
          setTimeout(() => {
            this.myCart('', this.action, 0, this.hourlyCount);
          }, 100);
        }
      }
      else if (!this.disableFixed && !this.disableHourly) {
        this.hourlyCount = 0;
        if (this.bookingType == 3 && this.DurationForChangeProvider) {
          setTimeout(() => {
            this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 2);
          }, 100)
          setTimeout(() => {
            this.callCheckOut(this.DurationForChangeProvider.split(':')[0], 1);
          }, 100)
        }
      }
      if (error.status == 409) {
        if (this.myCartList) {
          this.myCartList.totalAmount = 0;
        }
        this.hourlyCount = 0;
        this.checkOutList = [];
        this.deleteAllCartdsForFixedServices();
        // this.selectedIndex == 1 ? this.getdeleteCart() : this.selectedIndex == 0 ? this.deleteAllCartdsForFixedServices() : '';
        // this.myCart('', this.action, 0, 1);
      }
      else if (error.status == 416) {
        this.myCart('', this.action, 0, 1);
      }

      //////conole.log("Error from rquest in Checkout ", error, error.status );
      if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() } else { $("#appt_loader_loggedOut").hide(); }
    })
  }




  removeCart() {
    swal.fire({
      title: 'Are you sure to delete this cart?',
      // text: "You want to delete this cart!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      // confirmButtonClass: 'btn btn-success',
      // cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        let data = {
          cartId: this.myCartList._id,
          customerId: this.customerId
        };
        this._apiServiceManager.deleteCart(data).subscribe(result => {
          this.myCartList = [];
          this.checkOutList = [];
          this.getServiceList();
          swal.fire(
            'Deleted!',
            'Your cart has been deleted.',
            'success'
          )
        })
      } else {
        swal.fire(
          'Cancelled',
          'Your cart is safe :)',
          'error'
        )
      }

    }, error => {
      //////conole.log("Error  rquest in Checkout ", error)
      //let errorMessage = JSON.parse(error._body);
      error.status == 498 || error.status == 440 ? this.closeAllNgxModalsAtTokenExpired() : $("#appt_loader_loggedOut").hide();
    })
  }
  MonthLastDate: any;
  ScheduleTime: any;
  ScheduleDate: any;
  getDetailsForCheckOut(dispatcherType) {
    //////conole.log("the values of myCart :",this.myCartList.totalAmount, this.myCartList.totalQuntity);
    let TimeStampSchedule;
    let placeName = $("#origin-input3").val();
    let data = {
      bookingModel: parseInt(this.categoryOpted.service_type),
      bookingType: this.bookingType,
      paymentMethod: this.CardSelectDisable === false ? this.paymentTypeValue = 1 : this.CardSelectDisable === true ? this.paymentTypeValue = 1 : this.paymentTypeValue = 0,
      addressId: this.addressId,
      categoryId: this.categoryOpted._id,
      dispatchType: dispatcherType,
      customerId: this.customerId,
      callType: this.callTypeValue
    }
    this.myCartList ? data["cartId"] = this.myCartList._id : swal.fire({ title: "Information", text: "Please select the cart", icon: "warning" });
    if (this.ForSaveAddress == 1) {
      data["addressId"] = this.addressId;
    } else {
      data["addLine1"] = placeName;
      data["latitude"] = this.pickuplat;
      data["longitude"] = this.pickuplong;

    }
    this.paymentTypeValue == 2 ? data["paymentCardId"] = this.selectedCard : "";
    this.sliderChecked ? data["paidByWallet"] = 1 : data['paidByWallet'] = 0;
    if (this.bookingType == 2) {
      if (this.callTypeValue == 1 || this.callTypeValue == 3) {
        let bookingDate1 = moment(this.CurrentDateTimSlot).format('DD MMM YYYY')
        TimeStampSchedule = moment(bookingDate1 + " " + this.selectedTimeSlot, 'DD MMM YYYY hh:mm A').unix();
        this.selectedValue = this.providerTimeSlot[this.SeletcedTimeDuration].durarion;
        data["endTimeStamp"] = this.providerTimeSlot[this.SeletcedTimeDuration].to;
      }
      else {
        // let DateForsChedule = $(".scheduleDate").val();
        // let timeForSchedule = $(".scheduleTime").val();
        let laterBookingDate = moment(this.forBiddingDate, 'DD MMM YYYY').format('DD MMM YYYY');
        TimeStampSchedule = moment(laterBookingDate + " " + this.forBiddingTime, 'DD MMM YYYY hh:mm A').unix();
      }
    }
    this.bookingType == 2 ? data["bookingDate"] = TimeStampSchedule : '';
    this.bookingType == 2 && this.selectedValue ? data['scheduleTime'] = this.selectedValue : data['scheduleTime'] = 60;
    this.bookingType == 2 ? data['deviceTime'] = moment().format('YYYY-MM-DD HH:mm:ss') : "";
    this.selectedProvider ? data["providerId"] = this.selectedProvider._id : data["providerId"] = "";
    this.jobDescription ? data["jobDescription"] = this.jobDescription : "";
    this.promocode ? data['promoCode'] = this.promocode : "";
    if (this.bookingType == 3) {
      let DataForShift = {};
      DataForShift = this.ForMultiAndLaterBooking(this.bookingType, this.ShiftValue, 1);
      data["bookingDate"] = DataForShift["scheduleDate"];
      (DataForShift["scheduleTime"] == 0) ? data["scheduleTime"] = 60 : data["scheduleTime"] = DataForShift["scheduleTime"];
      data["endTimeStamp"] = DataForShift["endTimeStamp"];
      data["days"] = DataForShift["days"];
    }
    // for bidding category questions and answers
    if (this.cartServiceType == 7) {
      data["bidPrice"] = this.BidAmount;
      data["questionAndAnswer"] = this.ForBidding;
      data['bookingType'] = 2;
    }
    // for marketPlace and onDemand question and answers
    if (this.responsedQuestions && this.responsedQuestions.length > 0) {
      data["questionAndAnswer"] = this.responsedQuestions;
    }
    this._apiServiceManager.checkOut(data).subscribe(result => {
      this.disabledButton = false;
      this.BiddingCheckList = [];
      swal.fire({
        title: 'success!',
        text: "Booking placed successfully",
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        timer: 2000,
        onClose: () => {
          this.disabledButton = false;
          this.refreshData();
          this.closeMyModal();
        }
      })
    }, error => {
      // let errorMessage = JSON.stringify(error._body);
      //conole.log("Error from rquest in Checkout ", error)
      let errorMessage = error.error.message;
      this.disabledButton = false;
      this.DisableAutoManualDispatch(0);
      // setTimeout(()=>{
      //   this.disabledButton = true;
      // },800)
      error.status === 498 || error.status === 440 ? this.closeAllNgxModalsAtTokenExpired() : $("#appt_loader_loggedOut").hide();
      if (errorMessage.message !== "Unable to find stripe account" && errorMessage.message !== "cart not found.") {
        swal.fire(
          'Failed!',
          errorMessage.message,
          'error'
        ).then((result) => {
          if (error.status == 414) {
            setTimeout(() => {
              this.getProviderSlot();
            }, 100);
          }
        })
      }
    });
  }

  ForMultiAndLaterBooking(bookingType, ShiftValue, Client) {
    let BookingDetails = {};
    let StartDateForCustomShift = this.startDate;
    let EndDateForCustomShift = this.endDate;
    this.Timer = this.TimePickerMul;
    this.shiftDuration = this.DurationForChangeProvider;
    StartDateForCustomShift = moment(StartDateForCustomShift + " " + this.TimePickerMul, 'MMM DD YYYY hh:mm A').unix();
    EndDateForCustomShift = moment(EndDateForCustomShift + "23:00", 'MMM DD YYYY hh:mm A').unix();
    BookingDetails = { scheduleDate: parseFloat(StartDateForCustomShift), scheduleTime: this.DurationForWeekOrMonth, endTimeStamp: EndDateForCustomShift, days: this.ShowDays }
    return BookingDetails;
  }
  disabledButton: boolean = false;
  checkOut(dispatcherType) {
    this.disabledButton = true;
    this.DisableAutoManualDispatch(2);
    setTimeout(() => {
      this.disabledButton = false;
    }, 1000);
    this.paymentTypeValue ? this.paymentTypeValue = this.paymentTypeValue : this.paymentTypeValue = 1;
    if (this.cartServiceType != 7) {
      if (this.myCartList) {
        if (this.myCartList.totalAmount < 0 && this.myCartList.totalQuntity < 0) {
          if (this.callTypeValue == 1 || this.callTypeValue == 3) {
            this.getDetailsForCheckOut(dispatcherType);
          }
          else {
            this.DisableAutoManualDispatch(0);
            swal.fire({
              imageUrl: 'assets/images/EmptyShop.png',
              imageWidth: 50,
              imageHeight: 50,
              imageAlt: 'Custom image',
              animation: false,
              html: '<p><b>No service is selected</b></p>'
            })
          }
        }
        else if (typeof this.myCartList.totalAmount == "undefined" && typeof this.myCartList.totalQuntity == "undefined") {
          if (this.callTypeValue == 1 || this.callTypeValue == 3) {
            this.getDetailsForCheckOut(dispatcherType);
          }
          else {
            this.DisableAutoManualDispatch(0);
            swal.fire({
              imageUrl: 'assets/images/EmptyShop.png',
              imageWidth: 50,
              imageHeight: 50,
              imageAlt: 'Custom image',
              animation: false,
              html: '<p><b>No service is selected</b></p>'
            })
          }
        }
        else {
          this.getDetailsForCheckOut(dispatcherType);
        }
      }
    }
    else if (this.cartServiceType == 7 && typeof this.myCartList.totalAmount == "undefined" && typeof this.myCartList.totalQuntity == "undefined") {
      this.getDetailsForCheckOut(dispatcherType);
    }
  }
  getBiddingPriceError() {
    if (this.BiddingPrice.hasError('required')) {
      if (this.Service_type == 3) {
        $("#DisableButtonsForCard").show();
        $("#EnableButtonsforCard").hide();
        this.JobPaymentType.reset();
      }
    }
    return this.BiddingPrice.hasError('required') ? 'Bidding Price is Mandatory' :
      '';
  }
  getErrorEmail() {
    return this.Custemail.hasError('required') ? 'You must enter a value' :
      this.Custemail.hasError('email') ? 'Not a valid email' :
        '';
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
      this.email.hasError('email') ? 'Not a valid email' :
        '';
  }
  getErrorName() {
    // //conole.log(this.CustRegisterName.hasError('minLength'))
    return this.CustRegisterName.hasError('required') ? 'You must enter a Name' :
      this.CustRegisterName.hasError('minLength') ? 'Name should have minimum  4 characters' : '';
  }
  getErrorMobile() {
    return this.CustRegisterPhone.hasError('required') ? 'You must enter a mobile number' :
      this.isphoneValid ? "Valid Number" : "InValid Number";
  }
  getErrorCcode() {
    return this.CustRegisterCountryCode.hasError('required') ? 'You must enter' :
      '';
  }

  getPaymentError() {
    return (this.paymentTypeValue == 0) ? 'Please Select Payment' : '';
  }
  getCardError() {
    return this.JobPaymentType.hasError('required') ? 'Please Select Payment' : '';
  }
  validateRegistrationForm() {
    let dialCode = $('.selected-dial-code').text();

    if (
      !(this.Custemail.hasError('required')) &&
      !(this.Custemail.hasError('email')) &&
      !(this.CustRegisterName.hasError('required')) &&
      !(this.CustRegisterPhone.hasError('required')) &&
      !this.uniqueCustomerEmail && !this.uniqueuniqueCustomerPhoneNumber && this.isphoneValid
    ) {
      let ip = this.cookieService.get('ip');
      let data = {
        firstName: this.customerName,
        email: this.customerMail,
        countryCode: dialCode,
        phone: this.customerPhone,
        ipAddress: ip,
        latitude: this.cookieService.get('dispatcherLat') ? this.cookieService.get('dispatcherLat') :
          this.cookieService.get('latitude') ? this.cookieService.get('latitude') : '',
        longitude: this.cookieService.get('dispatcherLng') ? this.cookieService.get('dispatcherLng') : this.cookieService.get('longitude') ?
          this.cookieService.get('longitude') : ''
      }

      //////conole.log("data", data);
      this.BookingMode = 'query';
      this._apiServiceManager.registerCustomer(data).subscribe(result => {
        let item = result.data;

        swal.fire(
          'success!',
          'Customer Registered successfully',
          'success'
        );

        this.newUser = false;
        this.customerAddHeader = "CUSTOMER DETAILS";
        this.userSelected = true;
        this.clearRegistrationForm();
        this.setForNewAddress();
        jQuery('#appt_loader_parent').hide();
        this.BookingMode = 'determinate';
        setTimeout(() => {
          this.customerName = item.firstName + " " + item.lastName;
          this.customerPhone = item.phone;
          this.customerPhoneCode = item.countryCode;
          this.customerMail = item.email;
          this.customerId = item.customerId;
        }, 100)
      }, error => {
        // jQuery('#appt_loader_parent').hide();
        let errorMessage = '';
        console.log('error._body', error)
        error._body ? errorMessage = JSON.parse(error._body) : '';
        this.newUser = false;
        this.customerAddHeader = "CUSTOMER DETAILS";
        this.userSelected = true;
        swal.fire(
          'error!',
          errorMessage['message'] ? errorMessage['message'] : error.error.message,
          'error'
        );
        this.clearRegistrationForm();
        // this.setForNewAddress();
        jQuery('#appt_loader_parent').hide();
        this.BookingMode = 'determinate';
        error.status == 498 || error.status === 440 ? this.closeAllNgxModalsAtTokenExpired() : $("#appt_loader_loggedOut").hide();
      })
    }
    else {
      this.toastrService.error("Please fill proper details");
    }
  }

  clearRegistrationForm() {
    this.newUser = false;
    this.Custemail.reset();
    this.CustRegisterName.reset();
    this.CustRegisterPhone.reset();
    this.CustRegisterCountryCode.reset();
    this.enableAutoDispatch == true;
    this.enableManualDispatch == false;
  }

  clearRegistration() {
    this.email.reset();
    this.RegisterName.reset();
    this.RegisterPhone.reset();
    this.RegisterCountryCode.reset();
  }

  switchEnable(switchValue) {
    switch (switchValue) {
      case 1:
        this.enableAutoDispatch = true;
        this.enableManualDispatch = false;
        break;

      case 2:
        this.enableAutoDispatch = false;
        this.enableManualDispatch = true;
        break;
    }
  }

  schedule(val) {
    switch (val) {

      case 0: this.selectedSchedule = false;
        this.selected = false;
        this.bookingType = 0;
        if (this.categoryOpted.bookingTypeAction) {
          this.enableType1 = this.categoryOpted.bookingTypeAction.now;
          this.enableType2 = this.categoryOpted.bookingTypeAction.schedule;
          this.enableType3 = this.categoryOpted.bookingTypeAction.repeat;
        }
        else {
          this.enableType1 = false;
          this.enableType2 = false;
          this.enableType3 = false;
        }
        break;

      case 1:
        this.selectedSchedule = true;
        this.selected = true;
        this.bookingType = 1;
        setTimeout(() => { this.filteredProvider(); }, 50);
        break;

      case 2:
        this.selectedSchedule = false;
        this.selected = true;
        this.forBiddingDate = moment(new Date, 'D MMM, YYYY').format('D MMM, YYYY');
        this.TimePickerMul = moment().add(1, 'hours').format('hh:mm A');
        this.minTime = moment().add(1, 'hours').format('hh:mm A');
        this.bookingType = 2;
        this.filteredProvider();
        break;

      case 3:
        setTimeout(() => {
          this.setRepeatPicker();
          this.ngxSmartModalService.open('mulTileShifting');
          this.ShiftOptions.forEach((x) => x.checked = false)
          this.daysList.forEach((x) => x.checked = false)
        }, 50);
        setTimeout(() => {
          this.selectedSchedule = false;
          this.selected = true;
          this.bookingType = 3;
          this.promoDiscountAmount = '';
          this.radiochecked = false;
          this.optionsChecked = false;
          this.ConfirmMultipleShift = 0;
          this.ShiftourlyCount = 0;
          this.shiftOption = 0;
          this.timeOrSame = 58;
          this.TimePickerMul = moment().add((60 - moment().minutes()), 'minutes').format("h:mm A");
          this.durationPicker = "1:00";
          this.DurationForChangeProvider = "1:00";
          this.availableList = [];
          this.unavailableList = [];
          this.selectDays = [];
          this.daysList = [];
          this.ShowDays = [];
          this.CurrentDateMulti = '';
          this.clearCartForRepeatShift();
        }, 300)
        break;
    }
    if (this.disableFixed && !this.disableHourly) {
      if (this.bookingType != 3 && this.callTypeValue == 2) {
        setTimeout(() => { this.getOldCart(); }, 100);
      }
    }
    else if (!this.disableFixed && this.disableHourly) {
      if (this.bookingType != 3 && this.callTypeValue == 2) {
        setTimeout(() => {
          this.getOldCart();
        }, 100);
      }
    }
    else if (this.disableFixed == false && this.disableHourly == false) {
      if (this.bookingType != 3 && this.callTypeValue == 2) {
        setTimeout(() => { this.getOldCart(); }, 100);
      }
    }
    this.boxFocus(5);
  }

  clearCartForRepeatShift() {
    if (this.disableFixed == true && this.disableHourly == false) {
      if (this.QuantityCount > 1) {
        setTimeout(() => {
          this.callCheckOut(this.QuantityCount, 1);
        }, 100)
      }
    }
    else if (this.disableFixed == false && this.disableHourly == false) {
      if (this.QuantityCount > 1) {
        if (this.selectedIndex == 1) {
          for (let i = 1; i <= this.QuantityCount; ++i) {
            setTimeout(() => {
              this.checkOutForMultipleShift('', 2, 2, 1);
            }, 100)
          }
        }
        else {
          this.deleteAllCarts();
        }
      }
    }
    else {
      if (this.QuantityCount > 1) {
        setTimeout(() => {
          this.callCheckOut(this.QuantityCount, 2);
        }, 100)
      }
    }
  }

  selectService(e, service) {
    if (e.target.checked) {
      this.selectedItemArray.push(service)
    } else {
      let index = this.selectedItemArray.findIndex((item) => item.id == service.id);
      if (index >= 0) {
        this.selectedItemArray.splice(index, 1);
      }
    }
    this.addAllCheckedOut();
  }

  set_flatpickr() {
    let current_time: any = moment().add(0, 'hour').startOf('hour');
    current_time = current_time.add(0.5, 'hours').format('hh:mm A');

    flatpickr(".flatpickr", {
      defaultDate: moment().format('YYYY-MM-DD'), // Date objects and date strings are also accepted
      allowInput: true,
      enableTime: false,
      enableSeconds: false,
      minDate: moment().format('YYYY-MM-DD'), // "today" / "2016-12-20" / 1477673788975
      // maxDate: "2018-12-20",
      dateFormat: "j M, Y",
      altInput: true,
      onChange: (x, dateobj, datestr) => {
        let date = $(".scheduleDate").val();

      },
    });
    //date and time option for (from current to future)
    let BidCalendar1 = flatpickr(".flatpickr1", {
      defaultDate: moment().format('YYYY-MM-DD '), // Date objects and date strings are also accepted
      allowInput: true,
      enableTime: false,
      enableSeconds: false,
      minDate: moment().format('YYYY-MM-DD'), // "today" / "2016-12-20" / 1477673788975
      // maxDate: "2018-12-20",
      dateFormat: "j M, Y",
      altInput: true,
      onOpen: (x, dateobj, datestr) => {
        let tomorrow = moment(new Date()).add(1, 'days');
        BidCalendar1.set("minDate", tomorrow);
      }

    });
    //date and time option for (from past to current)
    flatpickr(".flatpickr2", {
      defaultDate: moment().format('YYYY-MM-DD '), // Date objects and date strings are also accepted
      allowInput: true,
      enableTime: false,
      enableSeconds: false,
      // minDate: moment().format('YYYY-MM-DD'), // "today" / "2016-12-20" / 1477673788975
      // minDate:"today",
      maxDate: moment().format('YYYY-MM-DD'),
      altFormat: "M j, Y",
      altInput: true,
    });
    flatpickr(".timepicker", {
      defaultDate: moment().format('hh:mm A'),
      defaultHour: moment().format('hh'),
      defaultMinute: moment().format('mm'), // Date objects and date strings are also accepted
      enableTime: true,
      allowInput: true,
      enableSeconds: false,
      altFormat: "M j, Y",

      noCalendar: true,
      dateFormat: "h:i K",
    });
    flatpickr(".timepicker1", {
      enableTime: true,
      noCalendar: true,
      dateFormat: "h:i K",
      defaultDate: moment().format('hh:mm A'),// Date objects and date strings are also accepted
      allowInput: true,
      enableSeconds: false,
      minuteIncrement: 30,
      time_24hr: false, // AM/PM time picker is used by default


      // initial values for time. don't use these to preload a date
      defaultHour: moment().format('hh'),
      defaultMinute: moment().format('mm'),

    });
  }
  seletedDate: any;
  setRepeatPicker() {
    //////conole.log("vehicle");
    var current_time = moment().format("hh:mm a");

    flatpickr(".fromPicker", {
      defaultDate: moment().format('YYYY-MM-DD'), // Date objects and date strings are also accepted
      allowInput: true,
      enableTime: false,
      enableSeconds: false,
      minDate: moment().format('YYYY-MM-DD'), // "today" / "2016-12-20" / 1477673788975
      dateFormat: "j M, Y",
      // altInput: true,
      onChange: (dateObj, datestr) => {
        this.seletedDate = datestr;
        this.seletedDate = moment(this.seletedDate).add(1, 'days').format('YYYY-MM-DD')
        endpicker.set('minDate', this.seletedDate)
        // endpicker.set('defaultDate',this.seletedDate+1)
      },
    });
    let endpicker = flatpickr(".toPicker", { // Date objects and date strings are also accepted
      allowInput: true,
      enableTime: false,
      enableSeconds: false, // "today" / "2016-12-20" / 1477673788975
      dateFormat: "j M, Y",
      // altInput: true,
      onChange: (dateObj, datestr) => {
      },
    });
    let EndTimer = flatpickr(".timeRangePicker", {
      // defaultDate:moment().add(1, 'hour').add(1, 'minutes').format('hh:mm A'), // Date objects and date strings are also accepted
      enableTime: true,
      allowInput: true,
      defaultDate: moment().format('hh:mm A'), // Date objects and date strings are also accepted      
      enableSeconds: false,
      altFormat: "M j, Y",
      noCalendar: true,
      dateFormat: "h:i K",
      minDate: moment().format('hh:mm A'),
      minuteIncrement: 15,
      time_24hr: false,
      onOpen: (dateObj, datestr) => {
        // this.CustomDate = true;
        // EndTimer.set(" time_24hr","false")
        EndTimer.set("minDate", moment().format('hh:mm'));
        // EndTimer.set("defaultDate",moment().format('hh:mm'));
        this.Showerror = false;

      },
      onChange: (dateObj, datestr) => {
        this.Showerror = false;
        let selectedTime = $(".StartTimeShift").val();
        selectedTime = moment(selectedTime, "h:mm A");
        let TimeForNow = moment().format("h:mm A");
        let TimeForNow1 = moment(TimeForNow, "h:mm A");
        let TimerDiffrence = selectedTime.isBefore(TimeForNow1);
        if (TimerDiffrence) {
          this.timeOrSame = TimeForNow1.diff(selectedTime, 'minutes');
        }
        else {
          this.timeOrSame = selectedTime.diff(TimeForNow1, 'minutes');
        }
        //////conole.log("the time is selected ",this.timeOrSame )
      },
    });
    this.RepeatShift = flatpickr(".durationRangePicker", {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H",
      defaultDate: "1:00",// Date objects and date strings are also accepted
      allowInput: true,
      enableSeconds: false,
      minuteIncrement: 60,
      minDate: "1:00",
      time_24hr: true,
      onChange: (dateObj, datestr) => {
        this.Showerror = false;
        let selectedTime = $(".StartTimeShift").val();
        selectedTime = moment(selectedTime, "h:mm A");
        let TimeForNow = moment().format("h:mm A");
        let TimeForNow1 = moment(TimeForNow, "h:mm A");
        let TimerDiffrence = selectedTime.isBefore(TimeForNow1);
        if (TimerDiffrence) {
          this.timeOrSame = TimeForNow1.diff(selectedTime, 'minutes');
        }
        else {
          this.timeOrSame = selectedTime.diff(TimeForNow1, 'minutes');
        }
      },
    });
  }


  onQuantChange(item, val) {
    //////conole.log("val", val)
    item.qnt = val;
    item.amount = item.basePrice * val;
    this.addAllCheckedOut();
  }

  onCheckOutChange(item, val) {
    //////conole.log("val", val);
    item.qnt = val;
    item.amount = item.basePrice * val;
    this.addAllCheckedOut();
  }

  addAllCheckedOut() {
    let total = 0;
    for (var i = 0; i <= this.selectedItemArray.length; i++) {
      total = total + this.selectedItemArray[i].amount;
      this.itemTotal = total;
      this.CheckOutValue = this.itemTotal + this.servicePrice;
    }
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }

  eadURL(input) {
    //////conole.log("input", input);
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e: any) => {

        $('.js--image-preview')
          .css('background-image', e.target.result)
          .width(100)
          .height(100);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  readURL(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        //////conole.log("the image file is :", event);
        $('.js--image-preview').css('background-image', 'url(' + event.target.result + ')');
        $('.js--image-preview').addClass(' js--no-default');
      };
    }
    reader.readAsDataURL(event.target.files[0]);
  }
  checkPromoCode(promocode) {
    //////conole.log("promocode" , promocode);
    let params = {
      "customerId": this.customerId,
      "couponCode": promocode,
      "latitude": this.pickuplat,
      "longitude": this.pickuplong,
      "cartId": this.myCartList._id,
      "paymentMethod": this.paymentTypeValue,
    }

    this.sliderChecked ? params['paidByWallet'] = 1 : params['paidByWallet'] = 0;

    this._apiServiceManager.checkPromoCode(params).subscribe(result => {
      //////conole.log("Promocode result", result);
      this.PromoError = false;
      this.promoDiscountAmount = result.data.discountAmount;
      this.TotalAmount = this.myCartList.totalAmount;
      result.data.discountAmount && this.bookingType != 3 ? this.TotalAmount -= result.data.discountAmount : this.ShiftPerAmount();
      this.promoId = result.data.promoId;
      this.promocode = promocode;
      !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
    }, error => {
      if (error.status == 498 || error.status === 440) {
        this.closeAllNgxModalsAtTokenExpired()
      }
      else {
        $("#appt_loader_loggedOut").hide();
      }
      //////conole.log("Error from rquest in Checkout ", error)
      //let errorMessage = JSON.parse(error._body);
      if (error.status == 498 || error.status === 440) {
        this.closeAllNgxModalsAtTokenExpired()
      }
      else {
        $("#appt_loader_loggedOut").hide();
      }
      if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() } else { $("#appt_loader_loggedOut").hide(); }
      let errorMessage = error.error.message;
      this.PromoError = errorMessage;
      setTimeout(() => {
        this.PromoError = '';
        this.promocode = '';
      }, 2000)
      $("#closePromo").show();
      $("#checkPromo").hide();
    })
  }

  DisableAutoManualDispatch(value) {
    //////conole.log("the value from enable and disable ", value,this.paymentTypeValue);
    this.valueOfCard = value;
    // this.selectedCard = '';
    if (this.promoDiscountAmount) {
      // this.toastrService.info("This promocode may not be applicable if payment method is changed");
      this.switchPromoCode();
    }
    if (value == 1) {
      // $("#selectCards").hide();
      if (this.Service_type == 2 && this.haveSelectedProvider == false) {
        $("#DisableButtonsForCard").show();
        $("#EnableButtonsforCard").hide();
      }
      if (this.Service_type == 1) {
        $("#DisableButtonsForCard").hide();
        $("#EnableButtonsforCard").show();

      }
      if (this.Service_type == 2 && this.haveSelectedProvider == true) {
        $("#DisableButtonsForCard").hide();
        $("#EnableButtonsforCard").show();
      }
      if (this.Service_type == 3) {
      }

    }
    if (value == 2) {
      // $("#selectCards").show();
      if (this.Service_type == 2 && this.haveSelectedProvider == false) {
        $("#DisableButtonsForCard").show();
        $("#EnableButtonsforCard").hide();
      }
      if (this.Service_type == 2 && this.haveSelectedProvider == true) {
        $("#DisableButtonsForCard").show();
        $("#EnableButtonsforCard").hide();
      }
      if (this.Service_type == 1) {

        $("#DisableButtonsForCard").show();
        $("#EnableButtonsforCard").hide();

      }
      if (this.Service_type == 3) {
        $("#DisableButtonsForCard").show();
        $("#EnableButtonsforCard").hide();
      }
    }
    if (value == 0) {
      if (this.Service_type == 2 && this.haveSelectedProvider == false) {
        $("#DisableButtonsForCard").show();
        $("#EnableButtonsforCard").hide();
      }
      if (this.Service_type == 2 && this.haveSelectedProvider == true) {
        $("#DisableButtonsForCard").hide();
        $("#EnableButtonsforCard").show();
      }
      if (this.Service_type == 1) {
        $("#DisableButtonsForCard").hide();
        $("#EnableButtonsforCard").show();
      }
      if (this.Service_type == 3) {
        $("#DisableButtonsForCard").hide();
        $("#EnableButtonsforCard").show();
      }
    }
  }
  changedValue(event) {
    //////conole.log("the changed value is :", event);
  }
  AnswerForQuestion(questionIndex, selectedValue, predefinedIndex) {
    this.ForBiddingTotalAmount = true;
    let BooleanValue = false;
    let indx = -1;
    let returnedValue;
    let index = this.ForBidding.findIndex((item) => item._id === this.QuestionSet[questionIndex]._id);
    //////conole.log("the selected value :",selectedValue )
    switch (this.QuestionSet[questionIndex].questionType) {

      case 0: // for promoCode
        if (selectedValue.keyCode == 13) {
          this.checkPromoCode(this.promocode);
        }
        this.PromoCode = selectedValue.target.value;
        if (this.PromoCode && this.PromoCode.length > 0) {
          if (index > -1) {
            this.ForBidding[index].answer = this.PromoCode;
          }
          else {
            this.ForBidding.push(
              {
                "_id": this.QuestionSet[questionIndex]._id.toString(), 'questionType': this.QuestionSet[questionIndex].questionType,
                "name": this.QuestionSet[questionIndex].question.toString(), "answer": this.PromoCode
              });
            if (this.QuestionSet[questionIndex].isManadatory == 1) {
              ++this.MandatoryQuestionsCount;
            }
          }
        }
        else {
          if (this.QuestionSet[questionIndex].isManadatory == 1) {
            this.MandatoryQuestionsCount--;
            if (index > -1) {
              this.ForBidding.splice(index, 1);
            }
          }
        }
        break;

      case 1:
        var val = selectedValue.target.value.replace(/[^0-9\.]+/g, '');
        this.BidAmount = val;
        if (this.BidAmount && this.BidAmount.length > 0) {
          if (index > -1) {
            this.ForBidding[index].answer = this.BidAmount;
          }
          else {
            this.ForBidding.push(
              {
                "_id": this.QuestionSet[questionIndex]._id.toString(), 'questionType': this.QuestionSet[questionIndex].questionType,
                "name": this.QuestionSet[questionIndex].question.toString(), "answer": this.BidAmount
              });
            if (this.QuestionSet[questionIndex].isManadatory == 1) {
              ++this.MandatoryQuestionsCount;
            }
          }
        }
        else {
          if (this.QuestionSet[questionIndex].isManadatory == 1) {
            this.MandatoryQuestionsCount--;
            if (index > -1) {
              this.ForBidding.splice(index, 1);
            }
          }
        }
        break;
      case 5: // For TextArea
        if (selectedValue && selectedValue.length > 0) {
          if (index > -1) {
            let index = this.ForBidding.findIndex((item) => item._id === this.QuestionSet[questionIndex]._id);
            this.ForBidding[index].answer = selectedValue;
          }
          else {
            this.ForBidding.push(
              {
                "_id": this.QuestionSet[questionIndex]._id.toString(), 'questionType': this.QuestionSet[questionIndex].questionType,
                "name": this.QuestionSet[questionIndex].question.toString(), "answer": selectedValue
              });
            if (this.QuestionSet[questionIndex].isManadatory == 1) {
              ++this.MandatoryQuestionsCount;
            }
          }
        }
        break;

      case 6: // For RadioButton
        if (selectedValue) {
          if (index > -1) {
            this.ForBidding[index].answer = selectedValue.value.name;
          }
          else {
            this.ForBidding.push(
              {
                "_id": this.QuestionSet[questionIndex]._id.toString(),
                'questionType': this.QuestionSet[questionIndex].questionType,
                "name": this.QuestionSet[questionIndex].question.toString(), "answer": selectedValue.value.name
              });
            if (this.QuestionSet[questionIndex].isManadatory == 1) {
              ++this.MandatoryQuestionsCount;
            }
          }
        }
        break;

      case 7: //For Checkout 
        if (selectedValue) {
          if (index > -1) {
            if (selectedValue.checked) {
              this.ForBidding[index].answer = selectedValue.source.value.name;
            }
            else {
              let index = this.ForBidding.findIndex((item) => item._id === this.QuestionSet[questionIndex]._id);
              this.ForBidding.splice(index, 1);
              if (this.QuestionSet[questionIndex].isManadatory == 1) {
                this.MandatoryQuestionsCount--;
              }
            }
          }
          else {
            if (selectedValue.checked) {
              this.ForBidding.push(
                {
                  "_id": this.QuestionSet[questionIndex]._id.toString(), 'questionType': this.QuestionSet[questionIndex].questionType,
                  "name": this.QuestionSet[questionIndex].question.toString(), "answer": selectedValue.source.value.name
                });
              if (this.QuestionSet[questionIndex].isManadatory == 1) {
                ++this.MandatoryQuestionsCount;
              }
            }
          }
        }
        break;

      case 8: // For Date and time(from current to future)
        let futureDate = selectedValue.dateString;
        if (futureDate && futureDate.toString().length > 0) {
          if (index > -1) {
            this.ForBidding[index].answer = futureDate;
          }
          else {
            this.ForBidding.push(
              {
                "_id": this.QuestionSet[questionIndex]._id.toString(), 'questionType': this.QuestionSet[questionIndex].questionType,
                "name": this.QuestionSet[questionIndex].question.toString(), "answer": futureDate
              });
            if (this.QuestionSet[questionIndex].isManadatory == 1) {
              ++this.MandatoryQuestionsCount;
            }
          }
        }
        break;

      case 9:  // For Date and time(from past to current)
        let currentDate = selectedValue.dateString;
        if (currentDate && currentDate.toString().length > 0) {
          if (index > -1) {
            this.ForBidding[index].answer = currentDate;
          }
          else {
            this.ForBidding.push(
              {
                "_id": this.QuestionSet[questionIndex]._id.toString(), 'questionType': this.QuestionSet[questionIndex].questionType,
                "name": this.QuestionSet[questionIndex].question.toString(), "answer": currentDate
              });
            if (this.QuestionSet[questionIndex].isManadatory == 1) {
              ++this.MandatoryQuestionsCount;
            }
          }
        }
        break;
    }
    return;
  }
  AddressTag(value) {
    (value == "Others") ? (this.ForOtherTag = true, $("#others").show()) : (this.ForOtherTag = false, $("#others").hide());
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }

  imageAnswer = [];
  ImageLoader: any;
  uploadImg(event: EventEmitter<File[]>, question, Id) {

    // var bucket = new AWS.S3({ params: { Bucket: 'dayrunner' } });
    // var fileChooser = e.srcElement;
    // var file = fileChooser.files[0];
    let index;
    let questionIndex;

    this.uploader.clearQueue();
    const file: File = event[0];
    this.ImageLoader = true;
    const newImage = new File([file], file.name);
    this.uploader.onBeforeUploadItem = (fileItem: any): any => {
      fileItem.withCredentials = false;
      fileItem.secure = true;
      // this.uploader.options.additionalParameter = {
      //   uploadTo: 1,
      //   folder: 'customer',
      //   fileName: file.name.split('.')[0]
      // };
    }
    this.uploader.onAfterAddingFile = (fileItem: any) => {
      fileItem.withCredentials = false;
      // fileItem.upload_preset = this.Cloudinary.config().upload_preset;
      this.uploader.cancelAll();

    };
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      this.uploader.cancelAll();
      // form.append('file', form);
    };
    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      let cloudinaryImage = JSON.parse(response);
      this.imgUrl.push(cloudinaryImage.data);
      this.ImageLoader = false;
    }

    this.uploader.addToQueue([newImage]);
    if (this.imgUrl.length > -1 && this.SelectedFile == 0) {
      this.ImageFile.name = question;
      this.ImageFile.id = Id;
      questionIndex = this.QuestionSet.findIndex((item) => item._id === Id)
      index = this.ForBidding.findIndex((item) => item._id === Id);
      // for Bidding category
      if (this.QuestionSet && this.QuestionSet.length > 0) {
        if (index > -1) {
          this.ForBidding[index].answer = this.imgUrl;
        }
        else {
          this.ForBidding.push({
            "_id": this.QuestionSet[questionIndex]._id.toString(), 'questionType': this.QuestionSet[questionIndex].questionType,
            "name": this.QuestionSet[questionIndex].question.toString(), "answer": this.imgUrl
          });
          if (this.QuestionSet[questionIndex].isManadatory == 1) {
            ++this.MandatoryQuestionsCount;
          }
        }
      }
      // For MarketPalce and on-demand category 
      else if (this.questionAnsArr) {
        let index = this.questionAnsArr.findIndex((item) => item._id == Id);
        this.questionAnsArr[index].isManadatory == 1 && !this.questionAnsArr[index].checked ?
          this.answeredCount++ : '';
        this.questionAnsArr[index].checked = true;
        this.ImageFile['questionType'] = this.questionAnsArr[index].questionType;
        // this.questionAnsArr[index].isManadatory == 1 ? this.answeredCount++ : '';
      }
    }
    ++this.SelectedFile;
    return false;

  }
  switchPromoCode() {
    this.promoDiscountAmount = '';
  }
  nextDate = moment(new Date()).format("DD MMM,YYYY");
  MultipleShift(Value, index) {
    this.ShiftOptions.forEach(x => {
      x.checked = false;
    });
    this.DaysSelected = [];
    this.ShiftOptions[index].checked = true;
    setTimeout(() => {
      this.setRepeatPicker();
    }, 100);
    switch (Value) {

      case 1:
        this.Showerror = false;
        this.Ischecked = false;
        this.optionsChecked = false;
        this.shiftOption = Value;
        this.startDate = moment(this.CurrentDate).format('ll');
        this.endDate = moment().endOf('week').format('ll');
        let weekDay = moment(this.startDate).weekday();
        this.Date3 = moment(this.startDate).format("YYYY-MM-DD");
        this.Date4 = moment(this.endDate).format("YYYY-MM-DD");
        this.date1 = moment(this.Date3, "YYYY-MM-DD");
        this.date2 = moment(this.Date4, "YYYY-MM-DD");
        this.diffrence = this.date2.diff(this.date1, 'days');
        this.daysList = this.DaysCount(this.diffrence, new Date(), this.endDate);
        break;

      case 2: this.shiftOption = Value;
        this.Ischecked = false;
        this.Showerror = false;
        this.optionsChecked = false;
        this.startDate = moment(this.CurrentDate).format('ll');
        this.endDate = moment().endOf('month').format('ll');
        this.Date3 = moment(this.startDate).format("YYYY-MM-DD");
        this.Date4 = moment(this.endDate).format("YYYY-MM-DD");
        this.date1 = moment(this.Date3, "YYYY-MM-DD");
        this.date2 = moment(this.Date4, "YYYY-MM-DD");
        this.diffrence = this.date2.diff(this.date1, 'days');
        this.daysList = this.DaysCount(this.diffrence, new Date(), this.endDate);

        break;
      case 3: this.shiftOption = Value;
        // this.CurrentDateMultiEndDate = 'YYYY MM DD';
        // this.CurrentDateMulti = new Date();
        this.CurrentDateMultiEndDate = moment(new Date()).format("DD MMM,YYYY");
        // this.CurrentDateMulti = new Date();
        this.CurrentDateMulti = moment(new Date()).format("DD MMM,YYYY");
        let showNextDate = new Date();
        this.EndDateForMulshift = showNextDate.getDate() + 1;
        this.Ischecked = false;
        this.optionsChecked = false;
        this.Showerror = false;
        setTimeout(() => {
          this.setRepeatPicker();
        }, 50);
        break;

    }
  }
  reduceShifts: any;
  CustomeDate() {
    this.selectDays = [];
    this.daysList = [];
    let customStartDate = new Date(this.CurrentDateMulti);
    let customeEndDate = this.CurrentDateMultiEndDate;
    this.startDate = moment(customStartDate).format('ll');
    this.endDate = moment(customeEndDate).format('ll');
    this.Date3 = moment(this.startDate).format("DD-MM-YYYY");
    this.Date4 = moment(this.endDate).format("DD-MM-YYYY");
    this.date1 = moment(this.Date3, "DD-MM-YYYY");
    this.date2 = moment(this.Date4, "DD-MM-YYYY");
    this.diffrence = this.date2.diff(this.date1, 'days');
    if (this.diffrence <= 7) {
      for (let i = 0; i <= this.diffrence; i++) {
        let dt = moment(customStartDate.getDate() + i, "DD-MM-YYYY HH:mm:ss");
        let shiftdate = moment(customStartDate.getDate() + i, "DD-MM-YYYY").format('DD-MM-YYYY');
        var weekDayName = moment(dt).format('dddd');
        this.daysList.push({ "days": weekDayName, "checked": false, date: [shiftdate.toString()] });
      }
    }
    else {
      this.daysList = this.DaysCount(this.diffrence, customStartDate, customeEndDate);
      ////conole.log("caysList:--->",this.daysList)
    }
    console.log("this.daysList", this.daysList)
  }

  DaysCount(difference, startDate, endDate) {
    this.selectDays = [];
    if (difference >= 7) {
      this.daysList = this.addDatesTodaysList(difference, startDate, endDate);
    }
    else {
      if (difference > 0) {
        if (difference < 6) {
          ////conole.log("the dare list", this.diffrence)
          this.daysList = this.addDatesTodaysList(difference, startDate, endDate);
          for (let i = 0; i <= difference; i++) {
            let add = moment().add(i, 'days').format('dddd');
            let daysIndex = this.daysList.findIndex(m => m.days == add);
            this.selectDays.push(this.daysList[daysIndex]);
          }
          this.daysList = this.selectDays;
        }
        else {
          ////conole.log("the dare list", this.diffrence)
          this.daysList = this.addDatesTodaysList(difference, startDate, endDate);
        }
      }
    }
    return this.daysList;
  }
  addDatesTodaysList(difference, startDate, endDate) {
    let daysList = [{ days: "Sunday", checked: false, date: [] }, { days: "Monday", checked: false, date: [] }, { days: "Tuesday", checked: false, date: [] },
    { days: "Wednesday", checked: false, date: [] },
    { days: "Thursday", checked: false, date: [] }, { days: "Friday", checked: false, date: [] }, { days: "Saturday", checked: false, date: [] }]
    let dates = [];
    let fromDate = moment(startDate);
    let toDate = moment().add(difference, 'days');

    while (fromDate.isSameOrBefore(toDate)) {
      // dates.push(fromDate.format('M/D/YYYY'));
      let dt = fromDate.format('DD-MM-YYYY');
      let weekDayName = moment(fromDate).format('dddd');
      // dates.push(weekDayName);
      let index = daysList.findIndex(item => item.days === weekDayName);
      index !== -1 ? daysList[index].date.push(dt) : '';
      fromDate.add(1, 'days');
    }
    ////conole.log("daysList --->", daysList)
    return daysList;

  }
  change(i: number) {
    this.radiochecked = false;
    if (this.shiftOption == 0) {
      this.Showerror = true;
      this.ErrorMsg = "Please Select Any Shift Options";
    }
    else {
      this.Showerror = false;
      if (this.ShifthNexts == 0) {
        let endDate1 = this.CurrentDateMultiEndDate;
        let startDate1 = this.CurrentDateMulti;
        if (this.shiftOption == 1) {
          if (this.diffrence <= 0) {
            this.Showerror = true;
            this.ErrorMsg = "Please Select Other Shift Option. Because Start And EndDate Are Same";
          }
          else {
            this.ShifthNexts = Math.min(2, Math.max(0, this.ShifthNexts + i));
            this.CustomDate = false;
            this.Showerror = false;
          }
        }
        else if (this.shiftOption == 3) {
          if (endDate1 !== 'YYYY MM DD') {
            this.CustomeDate();
            if (this.diffrence <= 0) {
              this.Showerror = true;
              this.ErrorMsg = "Please Select End Date Greater Than Start Date";
            }
            else {
              this.ShifthNexts = Math.min(2, Math.max(0, this.ShifthNexts + i));
              this.CustomDate = false;
              this.Showerror = false;
            }
          }
          else {
            this.Showerror = true;
            this.ErrorMsg = "Please Select End Date";
          }
        }
        else {
          this.ShifthNexts = Math.min(2, Math.max(0, this.ShifthNexts + i));
        }
      }
      else if (this.ShifthNexts == 1) {
        if (this.DaysChecked) {
          let DaysCount = this.DaysCounting();
          ////conole.log("the data is ", DaysCount);
          if (DaysCount >= 1) {
            this.ShifthNexts = Math.min(2, Math.max(0, this.ShifthNexts + i));
            this.CustomDate = false;
            this.ConfirmMulShift = true;
            this.setRepeatPicker();
          }
          else {
            this.Showerror = true;
            this.ErrorMsg = "Please Select Minimum 1 Days";
          }
        }
        else {
          this.Showerror = true;
          this.ErrorMsg = "Please Select Any Days";
        }
      }
    }
  }
  BackChange(i: number) {
    let clickedShiftOption = this.shiftOption;
    this.ShifthNexts = Math.min(2, Math.max(0, this.ShifthNexts + i));
    this.ConfirmMulShift = false;
    this.Showerror = false;
    if (this.ShifthNexts == 0) {
      if (clickedShiftOption !== this.shiftOption) {
        this.daysList.forEach((x) => {
          x.checked = false;
        });
        this.DaysSelected = [];
        this.Ischecked = false;
      }
    }
    else if (this.ShifthNexts == 1) {
      if (clickedShiftOption !== this.shiftOption) {
        this.daysList.forEach((x) => {
          x.checked = false;
        });
        this.DaysSelected = [];
        this.Ischecked = false;
      }
    }
  }

  DaysCounting() {
    this.bookingShift = [];
    if (this.diffrence < 6) {
      this.DaysSelected = this.getProperShiftCount(this.DaysSelected);
      this.ShiftCount = this.DaysSelected.length;
    }
    else {
      for (let k = 0; k <= this.diffrence; k++) {
        let add = moment().add(k, 'days').format('dddd');
        let dates = moment().add(k, 'days').format('DD-MM-YYYY');

        this.addDay.push({ 'date': dates, days: add });
      }

      let datesArr = [];
      let daysArr = [];
      for (var j = 0; j < this.DaysSelected.length; j++) {
        for (var k = 0; k < this.addDay.length; k++) {
          if (this.DaysSelected[j].days == this.addDay[k].days) {
            daysArr = (this.addDay[k].days);
            datesArr.push(this.addDay[k].date);
          }
        }
        this.bookingShift.push({ 'date': datesArr, days: daysArr, checked: false });
        daysArr = [];
        datesArr = [];
      }
      this.bookingShift = this.getProperShiftCount(this.bookingShift);
      this.ShiftCount = this.bookingShift.length;
    }
    return this.ShiftCount;
  }

  // functionName: closeAllNgxModalsAtTokenExpired, developer: sowmya sv, desr: when token is expired , if any modals is there, then close them and show session expired modals.
  closeAllNgxModalsAtTokenExpired() {
    ////conole.log('the modals opened :', this.ngxSmartModalService.getOpenedModals())
    let modalsArray = this.ngxSmartModalService.getOpenedModals();
    if (modalsArray && modalsArray.length > 0) {
      for (let inst = 0; inst < modalsArray.length; inst++) {
        this.ngxSmartModalService.close(modalsArray[inst].id);
      }
    }
    setTimeout(() => {
      $("#appt_loader_loggedOut").show();
    }, 100)
  }

  //For Bidding to remove uploaded images
  removeImg(i) {
    //////conole.log("the image is removed",i)
    this.imgUrl.splice(i, 1);
    if (this.imgUrl.length <= -1) {
      this.MandatoryQuestionsCount--;
      this.SelectedFile = 0;
      this.ImageFile = { name: '', id: '' };
      this.questionAnsArr[i].checked = false;
    }
  }
  getCategoryByAutoComplete(SearchValue) {
    this.categoryList = [];
    this.CategoryError = false;
    if (SearchValue.length > 0) {
      this._apiServiceManager.getCategoryServiceAutoComplete(this.pickuplat, this.pickuplong, SearchValue).subscribe(result => {
        this.categoryList = result.data;
        this.callType = [];
        this.categoryOpted = '';
        this.schedule(0);
        //////conole.log("the result is : ", result);
        if (this.categoryList == null) {
          this.CategoryError = true;
        }
        else if (result.data) {
          $('#category').addClass('mat-focused mat-form-field-should-float').show();
          $(".mat-select-panel").css('transform', 'scaleY(1)')
        }
        result === null ? this.CashSelectDisable = true : this.CashSelectDisable = result.paymentMethod.cash;
        this.CardList.length == 0 ? this.CardSelectDisable = false : this.CardSelectDisable = true;
      }, error => {
        //////conole.log("Error from rquest in Checkout ", error)
        //let errorMessage = JSON.parse(error._body);
        this.CategoryError = false;
        if (error.status == 498 || error.status === 440) { this.closeAllNgxModalsAtTokenExpired() } else { $("#appt_loader_loggedOut").hide(); }
      })
      this.boxFocus(3);
    }
    else {
      this.getCategory();
      this.Billing_model = '1';
    }
  }

  //Closing the multiple shift popup
  closeShift() {
    this.ShowDays = [];
    let customStartDate1 = moment(this.startDate).format('ll');
    let todayDate = moment().format('ll');
    let Date3 = moment(customStartDate1).format("YYYY-MM-DD");
    let Date4 = moment(todayDate).format("YYYY-MM-DD");
    let date1 = moment(Date3, "YYYY-MM-DD");
    let date2 = moment(Date4, "YYYY-MM-DD");
    let diffrenceDays = date1.diff(date2, 'days');
  }
  CloseConfirmationMul() {

    this.ShowEndTime();
    if (this.ShiftOffers && this.ShiftOffers.length > 0) {
      this.ShiftOffers.forEach(x => {
        (this.ShiftCount >= x.minShiftBooking && this.ShiftCount <= x.maxShiftBooking) ? this.ShiftOffer = x : '';
      });
    }
    if (parseInt(this.durationPicker) > 0) {
      // //clear previous cart for multiple shift
      if (this.disableFixed == true && this.disableHourly == false || this.selectedIndex === 1) {
        let data = {
          cartId: this.myCartList._id,
          customerId: this.customerId
        };
        this._apiServiceManager.deleteCart(data).subscribe(result => {
          this.myCartList = [];
          this.checkOutList = [];
          this.callCheckOut(parseInt(this.DurationForChangeProvider), 1);
        });
      }
    }
    else {
      this.hourlyCount = 1;
      // this.ShiftPerAmount();
    }
    // this.daysList = this.getProperShiftCount(this.daysList);
    this.daysList.forEach(x => {
      if (x.checked) {
        this.ShowDays.push(x.days);
      }
    })
    this.RadioOptions.reset();
    this.Showerror = false;
    $(".CheckBoxValue").toggleClass('checkedActive');
    this.ConfirmMultipleShift = 1;
    this.ShifthNexts = 0;
    this.radiochecked = false;
    this.ConfirmMulShift = false;
    this.shiftOption = 0;
    this.checkedArray = [];
    this.DaysSelected = [];
    this.durationPicker = "1:00";
    this.ngxSmartModalService.close('mulTileShifting');
    this.Service_type == 2 ? this.toastrService.info('Please select any provider') : '';
    this.filteredProvider();
  }
  //End Time for multiple shift
  ShowEndTime() {
    let time = this.TimePickerMul;
    let duration = this.durationPicker;
    let duration1: any;
    duration1 = this.durationPicker;
    this.ShiftDuration1 = duration1.split(':')[0];
    let countOfDuration;
    if (parseInt(duration1) > 0) {
      countOfDuration = parseInt(duration1) * 60;
    }

    this.DurationForWeekOrMonth = countOfDuration;
    this.ResultantEndTime = moment(time, 'h:mm A').add(countOfDuration.toString(), 'minutes').format('h:mm A');
    // for 24 hours service in multiple shift 
    let timeDiff = (countOfDuration) / 60;
    let TimeForNow = moment(time, "h:mm A").format("h:mm A");
    let StartTimer2 = moment(TimeForNow, 'h:mm A').format('h:mm A');
    let StartTimerArray = StartTimer2.toString().split(":");
    let AMOrPM = StartTimerArray[1].split(" ")
    let timeDiff1 = this.ResultantEndTime.split(":");
    let AmOrPmInTime = timeDiff1[1].split(" ");
    let addToDate = "";
    this.endDate += "";
    if (AmOrPmInTime[1] != AMOrPM[1]) {
      if (this.endDate.indexOf("(+1)") == -1) {
        addToDate = "(+1)";
        this.endDate += addToDate;
      }
    }
    else {
      if (this.endDate.indexOf("(+1)") != -1) {
        let index = this.endDate.indexOf("(+1)");
        this.endDate = this.endDate.substr(0, index);
      }
      else {
        addToDate = "";
        this.endDate += addToDate;
      }
    }
    this.TimePickerMul = moment(this.TimePickerMul, 'h:mm A').format("h:mm A");
    this.ForMultiAndLaterBooking(0, 0, 1);
  }

  //Card #37 open  directly when click on multiple shifting.
  ShowMultiShift() {
    this.ShifthNexts = 0;
    this.radiochecked1 = false;
    this.ConfirmMulShift = false;
    this.shiftOption = 0;
    this.radioSelected = false;
  }

  //Days selected to mutiple shift
  SelectAllDays(value, checked) {
    this.DaysSelected = [];
    this.DaysChecked = true;
    this.daysList = value;
    if (this.optionsChecked) {
      this.daysList.forEach((x) => x.checked = false)
      this.optionsChecked = false;
      this.Ischecked = true;
    }
    else {
      this.daysList.forEach((x) => {
        x.checked = true;
      })
      for (let index = 0; index < value.length; index++) {
        this.DaysSelected.push(value[index]);
      }
      this.optionsChecked = true;
      this.Ischecked = false;
    }
  }
  flatPickrChanged(event, CaseValue) {
    let selectedTime: any;
    switch (CaseValue) {
      case 1:
        this.TimePickerMul = event.dateString + event.instance.amPM.innerText;
        this.DaysCounting();
        break;

      case 2:

        this.durationPicker = event.dateString;
        this.DurationForChangeProvider = this.durationPicker;
        break;
    }
    this.Showerror = false;
    // this.TimePickerMul = event.dateString;
    selectedTime = this.TimePickerMul;
    selectedTime = moment(selectedTime, "h:mm A");
    let TimeForNow = moment().format("h:mm A");
    let TimeForNow1 = moment(TimeForNow, "h:mm A");
    let TimerDiffrence = selectedTime.isBefore(TimeForNow1);
    if (TimerDiffrence) {
      this.timeOrSame = TimeForNow1.diff(selectedTime, 'minutes');
    }
    else {
      this.timeOrSame = selectedTime.diff(TimeForNow1, 'minutes');
    }


  }
  currentDefaultTime: any;
  FromToDateForMul(event, Case) {
    let remainingMandatoryQuestions = 0;
    let totalMandatoryQuestions = this.CountOfBiddingQuestion;
    switch (Case) {
      case 1:
        this.CurrentDateMultiEndDate = event.dateString;
        break;

      case 2:
        this.CurrentDateMultiEndDate = moment(event.dateString).format('DD MMM, YYYY');
        this.nextDate = moment(event.dateString).format('DD MMM, YYYY');
        this.CurrentDateMulti = moment(event.dateString).format('DD MMM, YYYY');
        break;

      case 3:
        this.forBiddingDate = event.dateString;
        this.currentDefaultTime = "Please Select Time";
        this.forBiddingDate && this.forBiddingTime ? this.enableQuestionsForBidding = false : '';
        break;

      case 4: case 5:
        // + event.instance.amPM.innerText 
        Case == 4 && this.Billing_model != '7' ? this.forBiddingTime = event.dateString : '';
        Case == 5 && this.Billing_model != '7' ? this.forBiddingTime = this.getCurrentDateFormat(this.bookingType) : '';
        Case == 4 && this.Billing_model == '7' ? (this.bookingType = 2, this.forBiddingTime = event.dateString) : '';
        Case == 5 && this.Billing_model == '7' ? (this.bookingType = 2, this.forBiddingDate = moment(this.forBiddingDate).format('DD MMM, YYYY'))
          : '';
        this.forBiddingDate && this.forBiddingTime ? this.enableQuestionsForBidding = false : '';
        this.forBiddingTime ? (this.getServiceList(), this.filteredProvider()) : '';
        //this.forBiddingDate = moment(this.forBiddingDate).format('DD MMM, YYYY')
        break;
      case 6:
        let index = this.QuestionSet.findIndex((item) => item.questionType == 2);
        let index2 = this.QuestionSet.findIndex((item) => item.questionType == 3);
        if (this.forBiddingDate && this.forBiddingTime) {
          this.enableQuestionsForBidding = false;
          if (this.QuestionSet && this.QuestionSet.length > 0) {
            this.QuestionSet.forEach((item) => {
              if (item.questionType == 4) {
                ++remainingMandatoryQuestions;
              }
            });
          }
          this.DateTimeForBid = this.forBiddingDate + "," + this.forBiddingTime;
          //for biddingDate and time
          if (index > -1 && this.ForBidding.findIndex((item) => item._id == this.QuestionSet[index]._id) <= -1) {
            this.ForBidding.push({
              "_id": this.QuestionSet[index]._id.toString(), 'questionType': this.QuestionSet[index].questionType,
              "name": this.QuestionSet[index].question.toString(), "answer": this.DateTimeForBid
            });
            ++this.MandatoryQuestionsCount;
          }
          else if (index > -1) {
            let index1 = this.ForBidding.findIndex((item) => item._id == this.QuestionSet[index]._id);
            index1 > -1 ? this.ForBidding[index1]['answer'] = this.DateTimeForBid : '';
          }
          //for biddingLocation
          if (index2 > -1 && this.ForBidding.findIndex((item) => item._id == this.QuestionSet[index2]._id) <= -1) {
            this.ForBidding.push({
              "_id": this.QuestionSet[index2]._id.toString(), 'questionType': this.QuestionSet[index2].questionType,
              "name": this.QuestionSet[index2].question.toString(), "answer": this.customer_Location
            });
            ++this.MandatoryQuestionsCount;
          }
          else if (index2 > -1) {
            let index3 = this.ForBidding.findIndex((item) => item._id == this.QuestionSet[index2]._id)
            index3 > -1 ? this.ForBidding[index3]['answer'] = this.customer_Location : '';
          }
        }
        this.ForBidding.length >= (this.CountOfBiddingQuestion - remainingMandatoryQuestions) ?
          this.allMandatoryQuestions = false : this.allMandatoryQuestions = true;

        break;
      // selecting duration for later booking
      case 7: this.selectedValue = parseInt(event.dateString) * 60;
        this.selectedValue >= 60 && this.disableFixed == true && this.disableHourly == false ?
          this.myCart(1, 1, 2, parseInt(event.dateString)) : '';
        this.filteredProvider();
        break;
      // if Open duration for book later again delete current cart
      case 8:
        if (this.selectedValue >= 60 && this.disableFixed == true && this.disableHourly == false) {
          let data = {
            cartId: this.myCartList._id,
            customerId: this.customerId
          };
          this._apiServiceManager.deleteCart(data).subscribe(result => {
            this.myCartList = [];
            this.checkOutList = [];
            this.myCart(1, 1, 2, 1);
            //////conole.log("deleted cart for book later",result)
          });
        }
        break;

    }

  }
  //functionName: questionAnswersSet, developer: sowmya sv,desc: check the count of the questions answered by the customer
  questionAnsweredByuser(questions, indxVal, mulValue, preInx) {
    this.answerAllError = false;
    switch (questions.questionType) {
      // text area question
      case 5: if (mulValue) {
        this.questionAnsArr[indxVal].checked = true;
        if (this.textAreaAnswer.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id) > -1) {
          let inde = this.textAreaAnswer.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id)
          this.textAreaAnswer[inde].answer.name = mulValue.target.value;
          this.textAreaAnswer[inde].answer.questionType = this.questionAnsArr[indxVal].questionType;
        }
        else {
          if (this.questionAnsArr[indxVal].isManadatory == 1) {
            this.answeredCount++;
          }
          this.textAreaAnswer.push({
            '_id': this.questionAnsArr[indxVal]._id, 'answer': {
              'name': mulValue.target.value, 'questionType': this.questionAnsArr[indxVal].questionType
            }
          });
        }
        break;
      }
      else {
        if (this.questionAnsArr[indxVal].isManadatory == 1) {
          --this.answeredCount;
        }
      }
      // radio buttons
      case 6: if (mulValue) {
        if (this.radioAnswer.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id) > -1) {
          this.questionAnsArr[indxVal].preDefined[preInx].checked = false;
          let inde = this.radioAnswer.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id);
          this.questionAnsArr[indxVal].checked = true;
          this.radioAnswer[inde].answer._id = mulValue._id;
          this.radioAnswer[inde].answer.name = mulValue.name;
          this.radioAnswer[inde].answer.questionType = this.questionAnsArr[indxVal].questionType;
        }
        else {
          this.questionAnsArr[indxVal].preDefined[preInx].checked = true;
          this.questionAnsArr[indxVal].checked = true;
          if (this.questionAnsArr[indxVal].isManadatory == 1) {
            this.answeredCount++;
          }
          this.radioAnswer.push({
            '_id': this.questionAnsArr[indxVal]._id, 'answer': {
              '_id': this.questionAnsArr[indxVal].preDefined[preInx]._id,
              'name': this.questionAnsArr[indxVal].preDefined[preInx].name,
              'questionType': this.questionAnsArr[indxVal].questionType
            }
          });
        }
      }
      else {
        if (this.questionAnsArr[indxVal].isManadatory == 1) {
          --this.answeredCount;
        }
      }
        break;
      // checkBox buttons
      case 7: if (mulValue) {

        if (this.checkBoxAnswer.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id) > -1) {
          // this.questionAnsArr[indxVal].checked = false;
          // this.questionAnsArr[indxVal].preDefined = false;
          let inde = this.checkBoxAnswer.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id);
          if (mulValue.checked) {
            if (this.checkBoxAnswer.findIndex((item) => item['answer']['_id'] == mulValue.source.value._id) > -1) {
              this.checkBoxAnswer[inde].answer._id = mulValue._id;
              this.checkBoxAnswer[inde].answer.name = mulValue.name;
              this.checkBoxAnswer[inde].answer.questionType = this.questionAnsArr[indxVal].questionType;
              // this.questionAnsArr[indxVal].preDefined[preInx]['checked'] = true;
              // this.questionAnsArr[indxVal].checked = true;

            }
            else {
              //  let addedValue = {};
              //  addedValue[i]
              mulValue.source.value['questionType'] = this.questionAnsArr[indxVal].questionType
              this.checkBoxAnswer[inde].answer.push(mulValue.source.value);
              // this.questionAnsArr[indxVal].preDefined[preInx]['checked'] = false;
              // this.questionAnsArr[indxVal].checked = false;
            }
          }
          else {
            let removeCheckVal = this.checkBoxAnswer[inde].answer.findIndex((item) => item['_id'] == mulValue.source.value._id);
            this.checkBoxAnswer[inde].answer.splice(removeCheckVal, 1);
            this.questionAnsArr[indxVal].preDefined[preInx]['checked'] = false;
            this.questionAnsArr[indxVal].checked = false;
          }
        }
        else {
          this.questionAnsArr[indxVal].preDefined[preInx]['checked'] = true;
          this.questionAnsArr[indxVal].checked = true;
          if (this.questionAnsArr[indxVal].isManadatory == 1) {
            this.answeredCount++;
          }
          this.checkBoxAnswer.push({
            '_id': this.questionAnsArr[indxVal]._id,
            'answer': [
              {
                '_id': this.questionAnsArr[indxVal].preDefined[preInx]._id,
                'name': this.questionAnsArr[indxVal].preDefined[preInx].name,
                'questionType': this.questionAnsArr[indxVal].questionType
              }
            ]

          });
        }
      }
        if (this.checkBoxAnswer.length < 0) {
          this.questionAnsArr[indxVal].checked = false;
          if (this.questionAnsArr[indxVal].isManadatory == 1) {
            --this.answeredCount;
          }
        }
        break;
      //from past to current option
      case 8: this.pastDateChoosen = mulValue.dateString;
        if (this.pastDateChoosen && this.pastDateChoosen.toString().length > 0) {
          this.questionAnsArr[indxVal].checked = true;
          if (this.pastCurrentAns.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id) > -1) {
            let inde = this.pastCurrentAns.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id)
            this.pastCurrentAns[inde].answer.name = this.pastDateChoosen;
            this.pastCurrentAns[inde].answer.questionType = this.questionAnsArr[indxVal].questionType;

          }
          else {
            if (this.questionAnsArr[indxVal].isManadatory == 1) {
              this.answeredCount++;
            }
            this.pastCurrentAns.push({
              '_id': this.questionAnsArr[indxVal]._id,
              'answer': {
                'name': this.pastDateChoosen,
                'questionType': this.questionAnsArr[indxVal].questionType
              }
            });
          }
        }
        else {
          if (this.questionAnsArr[indxVal].isManadatory == 1) {
            --this.answeredCount;
          }
        }
        break;
      //from current to future option
      case 9: this.futureDateChoosen = mulValue.dateString;
        if (this.futureDateChoosen && this.futureDateChoosen.toString().length > 0) {
          this.questionAnsArr[indxVal].checked = true;
          if (this.currentFutureAns.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id) > -1) {
            let inde = this.currentFutureAns.findIndex((item) => item['_id'] == this.questionAnsArr[indxVal]._id)
            this.futureDateChoosen[inde].answer.name = this.futureDateChoosen;
            this.futureDateChoosen[inde].answer.questionType = this.questionAnsArr[indxVal].questionType;
          }
          else {
            if (this.questionAnsArr[indxVal].isManadatory == 1) {
              this.answeredCount++;
            }
            this.currentFutureAns.push({
              '_id': this.questionAnsArr[indxVal]._id,
              'answer': {
                'name': this.futureDateChoosen,
                'questionType': this.questionAnsArr[indxVal].questionType
              }
            });
          }
        }
        else {
          if (this.questionAnsArr[indxVal].isManadatory == 1) {
            --this.answeredCount;
          }
        }
        break;
    }
  }
  responsedQuestions = [];
  answerAllError: boolean = false;
  callInst = [];
  callTypeValue = 2;
  timeSlotError = '';
  countCheck = 0;
  closeQuestionAnw(caseVal, inst) {
    switch (caseVal) {
      case 1:
        for (let inst = 0; inst < this.questionAnsArr.length; inst++) {
          if (this.questionAnsArr[inst].checked) {
            let index1 = this.responsedQuestions.findIndex((item) => item._id == this.questionAnsArr[inst]._id);
            //radio button answers
            if (this.questionAnsArr[inst].questionType == 6) {
              let index = this.radioAnswer.findIndex((item) => item._id == this.questionAnsArr[inst]._id);
              // //conole.log("index1 and index", index1,index)
              if (index1 > -1) {
                this.responsedQuestions[index1].answer = this.radioAnswer[index].answer.name;
              }
              else {
                this.responsedQuestions.push({
                  'name': this.questionAnsArr[inst].question, '_id': this.questionAnsArr[inst]._id,
                  'answer': this.radioAnswer[index].answer.name, 'questionType': this.radioAnswer[index].answer.questionType
                });
              }
            }
            //checkbox button answers
            else if (this.questionAnsArr[inst].questionType == 7) {
              let index = this.checkBoxAnswer.findIndex((item) => item._id == this.questionAnsArr[inst]._id);
              // //conole.log("index1 and index checkbox", index1,index)
              if (index1 > -1) {
                this.responsedQuestions[index1].answer = this.checkBoxAnswer[index].answer;
              }
              else {
                this.responsedQuestions.push({
                  'name': this.questionAnsArr[inst].question, '_id': this.questionAnsArr[inst]._id,
                  'answer': this.checkBoxAnswer[index].answer,
                  'questionType': this.questionAnsArr[inst].questionType
                });
              }
              // //conole.log("this.responsedQuestions checkbox",this.responsedQuestions)
            }
            //for text area answers
            else if (this.questionAnsArr[inst].questionType == 5) {
              let index = this.textAreaAnswer.findIndex((item) => item._id == this.questionAnsArr[inst]._id);
              if (index1 > -1) {
                this.responsedQuestions[index1].answer = this.textAreaAnswer[index].answer.name;
              }
              else {
                this.responsedQuestions.push({
                  'name': this.questionAnsArr[inst].question,
                  '_id': this.questionAnsArr[inst]._id,
                  'answer': this.textAreaAnswer[index].answer.name,
                  'questionType': this.textAreaAnswer[index].answer.questionType
                });
              }
            }
            // past to current date answers
            else if (this.questionAnsArr[inst].questionType == 8) {
              let index = this.pastCurrentAns.findIndex((item) => item._id == this.questionAnsArr[inst]._id);
              if (index1 > -1) {
                this.responsedQuestions[index1].answer = this.pastCurrentAns[index].answer.name;
                // this.responsedQuestions.push({'_id':this.questionAnsArr[inst]._id,'name':this.questionAnsArr[inst].question,'answer':this.pastCurrentAns[index].answer.name});
              }
              else {
                this.responsedQuestions.push({
                  'name': this.questionAnsArr[inst].question,
                  '_id': this.questionAnsArr[inst]._id,
                  'answer': this.pastCurrentAns[index].answer.name,
                  'questionType': this.pastCurrentAns[index].answer.questionType
                });

              }
            }
            // current to future date answers
            else if (this.questionAnsArr[inst].questionType == 9) {
              let index = this.currentFutureAns.findIndex((item) => item._id == this.questionAnsArr[inst]._id);
              if (index1 > -1) {
                this.responsedQuestions[index1].answer = this.currentFutureAns[index].answer.name;
              }
              else {
                this.responsedQuestions.push({
                  'name': this.questionAnsArr[inst].question,
                  '_id': this.questionAnsArr[inst]._id,
                  'answer': this.currentFutureAns[index].answer.name,
                  'questionType': this.currentFutureAns[index].answer.questionType
                });
              }
            }
            else if (this.questionAnsArr[inst].questionType == 10) {
              let index = this.questionAnsArr.findIndex((item) => item.checked == true);
              if (index1 > -1) {
                this.responsedQuestions[index1].answer = this.imgUrl;
              }
              else {
                this.responsedQuestions.push({
                  'name': this.questionAnsArr[inst].question,
                  '_id': this.questionAnsArr[inst]._id, 'answer': this.imgUrl,
                  'questionType': this.questionAnsArr[inst].questionType
                });
              }
            }
          }
        }

        if (this.reducedQuestionsCount == 0) {
          setTimeout(() => {
            this.ngxSmartModalService.close('QuestionAns');
            this.toastrService.info('Please Select Any Booking Type');
          }, 100);
          this.answerAllError = false;
          // this.jobDescriptionController.reset();
        }
        else if (this.answeredCount >= this.reducedQuestionsCount && this.responsedQuestions && this.responsedQuestions.length > 0) {
          setTimeout(() => {
            this.ngxSmartModalService.close('QuestionAns');
          }, 100);
          this.toastrService.info('Questions are Saved');
          this.toastrService.info('Please Select Any Booking Type');
          // this.clearQuestionArray();
          // this.imgUrl = [];
          this.answerAllError = false;
          // this.jobDescriptionController.reset();
        }
        else {
          this.answerAllError = true;
        }
        break;
      case 2:
        let SwalTitle = '';
        inst === 2 ? SwalTitle = 'Are you sure to save changes?' : inst !== 2 ? SwalTitle = 'Are you sure to close it?' : '';
        swal.fire({
          title: SwalTitle,
          // text: "You want to select different category!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Close it!',
          cancelButtonText: 'No, cancel!',
          // confirmButtonClass: 'btn btn-success',
          // cancelButtonClass: 'btn btn-danger',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            // Card #269, bug: after selecting any callType and close the calltype popup the category list is not refreshing. fixedDesc: refreshaddress , developer:sowmya sv
            this.selectedTimeSlot = '';
            this.timeSlotError = '';
            if (inst == 1) {
            }
            else if (inst == 2) {
              this.ngxSmartModalService.close('QuestionAns');
              this.closeQuestionAnw(1, 0);
            }
            else {
              this.ngxSmartModalService.close('InCallTimeSlot');
              this.selectedTimeSlot = '';
              this.timeSlotError = '';
              this.removeSelected();
            }
            this.answerAllError = false;
          }
          else {
            this.SearchCategory = '';
            if (this.answeredCount < this.reducedQuestionsCount) {
              this.answerAllError = true;
            }
          }
        });
        break;

      case 3:
        this.answeredCount = 0;
        if (inst == 1) {
          this.callType.forEach((item, index) => {
            if (item.checked) {
              // this.ngxSmartModalService.close('CallType');
              this.answerAllError = false;
              if (item.value == 1 || item.value === 3) {
                this.enableType1 = false;
                this.enableType2 = false;
                this.enableType3 = false;
                this.bookingType = 2;
                this.clearQuestionArray();
                // this.toastrService.info("Your ")
                this.toastrService.info('Please Select Any Provider')
                setTimeout(() => {
                  this.filteredProvider();
                }, 100);
              }
              else {
                this.questionAnsArr && this.questionAnsArr.length > 0 ? this.ngxSmartModalService.open('QuestionAns') : '';
                this.enableType1 = this.categoryOpted.bookingTypeAction.now;
                this.enableType2 = this.categoryOpted.bookingTypeAction.schedule;
                this.enableType3 = this.categoryOpted.bookingTypeAction.repeat;
              }
            }
            else {
              this.countCheck++;
              if (this.countCheck == 2) {
                this.answerAllError = true;
              }
            }
          });
        }
        else {
          this.callTypeValue = 2;
          this.callType.forEach((item) => item.checked = false);
          // this.ngxSmartModalService.open('CallType');
          this.ngxSmartModalService.close('QuestionAns');
          this.clearQuestionArray();
          this.countCheck = 0;
          this.bookingType = 1;
        }
        break;

      case 4:
        this.callTypeValue = this.callType[inst].value;
        // if incall booking type is selecting
        if (this.callTypeValue == 1 || this.callTypeValue == 3) {
          this.schedule(2);
          this.DisableAutoManualDispatch(2);
          // this.toastrService.info("Please choose the Provider");
        }
        else {
          // this.schedule(1);
          this.Service_type === 2 ? (this.toastrService.info('Please Select Any Provider')) : '';
          // this.Service_type === 2 ? (this.toastrService.info('Please Select Any Provider'),this.DisableAutoManualDispatch(2)) : '';
          setTimeout(() => {
            this.filteredProvider();
            if (this.callTypeValue == 2 && this.Service_type === '1' || this.Service_type === '2') {
              this.schedule(0);
              this.swtichBillingModalUi(this.categoryOpted.billing_model);
              this.getServiceList();
            }
          }, 100);
          this.DisableAutoManualDispatch(0);
        }
        if (this.callInst.findIndex((item) => item == inst) > -1) {
          this.callType[inst].checked ? this.callType[inst].checked = false : this.callType[inst].checked = true;
        }
        else {
          this.callInst.push(inst);
          this.callType[inst].checked = true;
        }
        this.closeQuestionAnw(3, 1);
        //card#83 after changing callType quetions are not getting saved
        this.clearQuestionArray();
        this.questionAnsArr && this.questionAnsArr.length == 0 && this.callTypeValue == 2 ? this.toastrService.info('Please Select Any Booking Type') : '';
        this.questionAnsArr && this.questionAnsArr.length == 0 ? this.toastrService.info('Please Select Any Booking Type') : '';
        this.questionAnsArr && this.questionAnsArr.length > 0 ? this.ngxSmartModalService.open('QuestionAns') : '';
        break;

      case 5: if (inst == 1) {
        let currentDate = moment(new Date()).format('ddd MMM DD, YYYY');
        this.dateCount--;
        if (this.CurrentDateTimSlot !== currentDate) {
          this.CurrentDateTimSlot = moment(new Date()).add(this.dateCount, 'days').format('ddd MMM DD, YYYY');
          this.getProviderSlot();
        }
        else {
          this.CurrentDateTimSlot = moment(new Date()).format('ddd MMM DD, YYYY');
          this.getProviderSlot();
        }
      }
      else {
        ++this.dateCount;
        this.CurrentDateTimSlot = moment(new Date()).add(this.dateCount, 'days').format('ddd MMM DD, YYYY');
        this.getProviderSlot();
      }
        break;

      case 6:
        this.selectedTimeSlot = inst.target.dataset.value;
        break;

      case 7:
        if (this.selectedTimeSlot && this.selectedTimeSlot.length > 0) {
          let currentTime = moment(new Date(), 'HH:mm A').format('HH:mm A');
          let selectedTimeSlot = moment(this.selectedTimeSlot, 'HH:mm A').format('HH:mm A');
          let currentDate = moment(new Date()).format('ddd MMM DD, YYYY');
          this.ngxSmartModalService.close('InCallTimeSlot');
          this.toastrService.info('Your Slot is selected successfully.')
          this.DisableAutoManualDispatch(0);
          this.myCart('', this.action, 0, 1);
          this.answerAllError = false;
          if (this.providerTimeSlot.findIndex((item) => item.from == this.selectedTimeSlot) > -1) {
            this.SeletcedTimeDuration = this.providerTimeSlot.findIndex((item) => item.from == this.selectedTimeSlot);
            this.selectedValue = this.providerTimeSlot[this.SeletcedTimeDuration].duration;
          }
          // this.questionAnsArr && this.questionAnsArr.length > 0 ? this.ngxSmartModalService.open('QuestionAns') : '';
          this.TotalAmount = this.categoryOpted.consultancyFee;
        }
        else {
          this.answerAllError = true;
          this.timeSlotError = 'Selete Time Slot';
        }
        break;
      //for closing multishift popup
      case 8:
        swal.fire({
          title: 'Are you sure to close this popup?',
          // text: "You want to select different category!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Close it!',
          cancelButtonText: 'No, cancel!',
          // confirmButtonClass: 'btn btn-success',
          // cancelButtonClass: 'btn btn-danger',
          buttonsStyling: false,
          reverseButtons: true
        }).then((result) => {
          // this.ngxSmartModalService.close('mulTileShifting');
          this.CloseConfirmationMul();
          this.schedule(0);
        });

    }
  }
  PaymentSelected(event, Case) {
    if (parseInt(this.Billing_model) == 7) {
      this.paymentTypeValue = event.value;
      let index = this.QuestionSet.findIndex((item) => item.questionType == 4);
      let index2 = index > -1 ? this.ForBidding.findIndex((item) => item._id == this.QuestionSet[index]._id) : '';
      if (index2 > -1 && index > -1) {
        switch (Case) {
          case 1:
            this.paymentTypeValue = event.value;
            if (this.paymentTypeValue > 0 && this.paymentTypeValue == 1) {
              if (index2 > -1) {
                this.ForBidding[index2].answer = this.paymentTypeValue;
              }
              else {
                this.ForBidding.push({
                  "_id": this.QuestionSet[index]._id,
                  'name': this.QuestionSet[index].question,
                  'questionType': this.QuestionSet[index].questionType,
                  'answer': this.paymentTypeValue
                });
                if (this.QuestionSet[index].isManadatory == 1) {
                  ++this.MandatoryQuestionsCount;
                }
              }
              if (this.MandatoryQuestionsCount >= this.CountOfBiddingQuestion) {
                this.DisableAutoManualDispatch(0);
              }
            }
            break;

          case 2: if (event.value.length > 0) {
            if (index2 > -1) {
              this.ForBidding[index2].answer = this.paymentTypeValue;
            }
            else {
              this.ForBidding.push({
                "_id": this.QuestionSet[index]._id,
                'name': this.QuestionSet[index].question,
                'questionType': this.QuestionSet[index].questionType,
                'answer': this.paymentTypeValue
              });
              if (this.QuestionSet[index].isManadatory == 1) {
                ++this.MandatoryQuestionsCount;
              }
            }
          }
            if (this.MandatoryQuestionsCount >= this.CountOfBiddingQuestion) {
              this.DisableAutoManualDispatch(0);
            }
            break;

        }
      }
      else {
        this.DisableAutoManualDispatch(0);
      }
    }
  }
  DisplayQuestions(value) {
  }
  getSlNoForQuestions(val) {
    return 1 + val;
  }
  ngAfterContentChecked() {
    !this.ref.detectChanges['destroyed'] ? this.ref.detectChanges() : '';
  }
  // default image
  imgAlt(event) {
    event.target.src = "assets/images/imgNo.png";
  }
  getCurrentDate() {
    return moment().format('MMM , DD YYYY');
  }
  //get answered quetions back
  getAnsweredQuertionsPopup() {
    (this.responsedQuestions && this.responsedQuestions.length > 0) || this.reducedQuestionsCount == 0 ? this.ngxSmartModalService.open('QuestionAns') : '';
  }
  getCurrentDateFormat(caseValue) {
    // this.updateTime();
    // casevalue is respective with bookingType selected 2--> booking later, 3--> repeat booking(multi shift)
    switch (caseValue) {
      case 2:
        //card:#146, issues: On Book Later > I can Select the time which is expired ( Current Time : 11:17 but i am able to select 11:13 ), 
        //fixedBy: sowmyaSV, date: 7-1-20
        return this.forBiddingDate === moment(new Date, 'D MMM, YYYY').format('D MMM, YYYY')
          ? moment().format("h:mm A") : moment('00:00', 'hh:mm A').format('hh:mm A');
      case 3:
        return this.DaysSelected && this.DaysSelected.length === 1 && this.DaysSelected[0].date[0] ===
          moment(new Date, 'DD-MM-YYYY').format('DD-MM-YYYY') ?
          moment().add((60 - moment().minutes()), 'minutes').format("h:mm A") : moment('00:00', 'hh:mm A').format('hh:mm A');
    }
  }
  // adding sound alert for booking
  BookingAlertToggle: any;
  enableBookingAlert(caseVal) {
    // this.bookingAlert.play();
    switch (caseVal) {

      case 1: this.BookingAlertToggle = false;
        this.cookieService.set('bookingAlert', 'true');
        break;

      case 2: this.BookingAlertToggle = true;
        this.cookieService.set('bookingAlert', 'false');
        break;
    }
  }
}

