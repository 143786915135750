<div id="appt_loader_loggedOut" style="display:none">
    <div id="loggedOut">
        <img src="assets/images/logout.png" id="drivers-busy_icn">
        <p>Your session has been expired , please login again</p>
        <button id="busy_drivers_ok" (click)="logOut()">OK</button>
    </div>
</div>
<div class="leftPane">
    <div class="" [ngClass]="{showLoader: show === true, hideLoader: show === false}">
        <div id="appt_loader"></div>
    </div>


    <div class="col-md-12 padding-0 fourDivsTab">
        <div class="col-md-12 padding-0">
            <mat-tab-group (selectedIndexChange)="clearRightSide1($event)">
                <mat-tab label="Unassigned">
                    <ng-template mat-tab-label class="countWindow">
                        <div class="joblist orderlist text-center padding-0 ">
                            <p class="numbers">{{unassignedCount}}</p>
                            <p class="booking-status-header">Unassigned</p>
                        </div>
                    </ng-template>
                    <div class="col-md-12 padding-0">
                        <mat-progress-bar *ngIf="bookingMode == 'query'" class="example-margin" [color]="color"
                            [mode]="bookingMode" [value]="value" [bufferValue]="bufferValue">
                        </mat-progress-bar>
                    </div>
                    <div class="col-md-12 left_window_header twoDivsTab padding-0" style="color: #090909; ">
                        <mat-tab-group style="background-color:#ffffff;">
                            <mat-tab label="Date Filter" style="background-color: #ffffff">
                                <ng-template mat-tab-label style="background-color: white">
                                    <div class="joblist orderlist text-center padding-0 active">
                                        <p class="booking-status-header" style="color: #090909;">Date Filter</p>
                                    </div>
                                </ng-template>
                                <div class="col-md-10 padding-0">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [matDatepicker]="picker" (click)="picker.open()"
                                                [formControl]="fromdate" [(ngModel)]="fromDateFilter"
                                                (ngModelChange)="observeDateFilter()" placeholder="From" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi="true" #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [matDatepicker]="picker2" (click)="picker2.open()"
                                                [attr.disabled]="!fromDateFilter" [min]="fromDateFilter"
                                                [formControl]="todate" [(ngModel)]="toDateFilter"
                                                (ngModelChange)="observeDateFilter()" placeholder="To" readonly
                                                style="color: #090909!important;">
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi="true" #picker2></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-2 padding-0 text-center marginTop10" (click)="resetAll()">
                                    <i id="search_pickerOne" title="refresh" style="cursor:pointer"
                                        class="fas fa-sync-alt"></i>
                                </div>
                            </mat-tab>
                            <mat-tab label="Search Booking">
                                <ng-template mat-tab-label>
                                    <div class="joblist orderlist text-center padding-0 active">
                                        <p class="booking-status-header" style="color:#090909">Search</p>
                                    </div>
                                </ng-template>
                                <div class="col-md-10">
                                    <mat-form-field class="w100">
                                        <input matInput placeholder="Search Booking By Name/PhoneNumber/Email"
                                            [(ngModel)]="stringFilter" [formControl]="searchController"
                                            (change)="getAllBookings(0,0)" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 padding-0 text-center marginTop10" (click)="resetAll()">
                                    <i id="search_pickerOne" title="refresh" style="cursor:pointer"
                                        class="fas fa-sync-alt"></i>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <div class="col-md-12 left_window_body BookingList text-center">
                        <p class="sorting-header">Today's Booking List</p>
                    </div>
                    <div class="scroll col-md-12 padding-0" id="unassigned" (scroll)="onScroll($event,1)">
                        <div *ngIf="unassigned.length <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>
                        <div class="BookingsContent">
                            <div *ngFor="let item of unassigned"
                                [ngClass]="{'bookingType1':item.bookingType == 1, 'bookingType2':item.bookingType == 2, 'bookingType3':item.bookingType == 3}"
                                id="booking-{{item.bookingId}}">
                                <div *ngIf="item.bookingType == 1" class="job_id_header col-md-12 padding-0">
                                    <div class="job_id_header_left col-md-12 padding-0">
                                        <div class="col-md-4 padding-0">
                                            <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                            <span title="Dispatch count" *ngIf="item.dispatchCount"
                                                class="dispatch_attempt">{{item.dispatchCount}}</span>
                                        </div>
                                        <div class="col-md-4 padding-0 text-center">
                                            <span class="job_id_task">{{item.statusMsg}}</span>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row pull-right">
                                                <div class="col-md-4 padding-5">
                                                    <a title="CancelBooking" (click)="cancelBooking(item,1)"
                                                        class="details_but" style="color:#fff">
                                                        <img src="assets/svgImages/cancel.svg" width="12px"
                                                            height="12px" title="CancelBooking">
                                                    </a>
                                                </div>
                                                <div class="col-md-4 padding-5">
                                                    <a title="Details" (click)="getBookingDetailsPopUp(item)"
                                                        class="details_but danger" style="color:#fff;">
                                                        <img src="assets/svgImages/menu3.svg" width="12px" height="12px"
                                                            title="Booking Details">
                                                    </a>
                                                </div>
                                                <!-- <div class="col-md-4 padding-5" *ngIf="item.bookingModel != 3 && item.bookingType != 3">
                                                        <a  title="Edit Cart" (click)="editingCards(item)" class="details_but danger" style="color:#fff">
                                                         <img src="assets/svgImages/exchange.svg" width="12px" height="12px" title="Not Interested For Booking">
                                                            <img src="assets/svgImages/cart2.svg" width="12px" height="12px" title="Show Cart">
                                                        </a>
                                                    </div> -->
                                            </div>

                                        </div>

                                    </div>
                                    <!-- <div class="job_id_header_left col-md-12 padding-0">
                                            <div class="col-md-4 padding-0">
                                                <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                                <span title="Dispatch count" *ngIf="item.dispatchCount" class="dispatch_attempt">{{item.dispatchCount}}</span>
                                            </div>
                                            <div class="col-md-4 padding-0 text-center">
                                                <span class="job_id_task">{{item.statusMsg}}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="row pull-right">
                                                    <div class="col-md-4 padding-5">
                                                        <a title="CancelBooking"(click)="cancelBooking(item,1)" class="details_but" style="color:#fff">
                                                            <img src="assets/svgImages/cancel.svg" width="12px" height="12px" title="CancelBooking">
                                                        </a>
                                                    </div>
                                                    <div class="col-md-4 padding-5" *ngIf="item.bookingModel != 3">
                                                        <a  title="Edit Cart" (click)="editingCards(item)" class="details_but danger" style="color:#fff">
                                                           <img src="assets/svgImages/cart2.svg" width="12px" height="12px" title="Show Cart">
                                                       </a>
                                                   </div> 
                                                    <div class="col-md-4 padding-5">
                                                        <a  title="Details" (click)="getBookingDetailsPopUp(item)" class="details_but danger" style="color:#fff;">
                                                            <img src="assets/svgImages/menu3.svg" width="12px" height="12px" title="Booking Details">
                                                        </a>
                                                    </div>
                                                </div>
        
                                            </div>
            
                                        </div> -->
                                    <div class="col-md-12 userDetails">
                                        <div *ngIf="item.bookingType === 1" class="job_id_body"
                                            (click)="getProviderForBooking(item , item.bookingId)">
                                            <div class="row customerName">
                                                <div class="col-md-12 zeroPadding margin-CustomerName">
                                                    &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons"
                                                        width="12px" height="12px">
                                                    <span class="customer-name">&nbsp;{{item.customerData.firstName}}
                                                        {{item.customerData.lastName}} ({{item.customerData.phone}})
                                                    </span>
                                                    <div id="CountDownUnassigned-{{item.bookingId}}"
                                                        style="float:right;display:none"
                                                        *ngIf="item.unassignedBookingsStatus == false && ForCreatManual != item.bookingId ">
                                                        <span *ngFor="let counter of countDownArray">
                                                            <span class="countDown Unassigned-countDown"
                                                                *ngIf="counter.bookingId === item.bookingId && duration > 0">
                                                                <countdown [config]="{leftTime:counter.duration}">$!h!h
                                                                    : $!m!m : $!s!s</countdown>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <span class="now-later">
                                                        <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                        <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                        <span class="now" *ngIf="item.bookingType == 3">Multiple
                                                            Shift</span>
                                                    </span>

                                                </div>
                                                <div class="col-md-12">
                                                    <div class="customerBookingDetails">
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/maintenance.svg"
                                                                    class="svgIcons" width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Category :
                                                            </span>
                                                            <span
                                                                class="col-md-7"><strong>{{item.category}}</strong></span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/worker.svg" class="svgIcons"
                                                                    width="12px" height="12px"> </span>
                                                            <span class="svgIcons  col-md-3 padding-0">Assigned For :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong
                                                                    *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                                <span
                                                                    *ngIf="item.providerData.hasOwnProperty('name') === false">--</span>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/calendar.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Booking Time :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/money.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons col-md-3 padding-0">Total Price :
                                                            </span>
                                                            <span class=" col-md-7"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-1 userDetail">
                                                                           <p><img src="assets/svgImages/maintenance.svg" class="svgIcons" width="12px" height="12px"></p>
                                                                           <p><img src="assets/svgImages/worker.svg" class="svgIcons" width="12px" height="12px"></p>
                                                                           <p><img src="assets/svgImages/calendar.svg" class="svgIcons" width="12px" height="12px"></p>
                                                                           <p><img src="assets/svgImages/money.svg" class="svgIcons" width="12px" height="12px"></p>
                                                                        </div>
                                                                        <div class="col-md-3 zeroPadding userDetail">
                                                                            <p class="svgIcons">Category :</p>
                                                                            <p class="svgIcons">Assigned For :</p>
                                                                            <p class="svgIcons">Booking Time :</p>
                                                                            <p class="svgIcons">Total Price :</p>
                                                                        </div>
                                                                        <div class="col-md-7 userDetail zeroPadding">
                                                                            <p><strong>{{item.category}}</strong></p>
                                                                            <p><strong *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                                            <span *ngIf="item.providerData.hasOwnProperty('name') === false">--</span></p>
                                                                            <p><strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong></p>
                                                                            <p><strong>{{item.currencySymbol}} {{item.totalAmount| number:'1.2-2'}}</strong></p>
                                                                        </div>
                                                                    </div>
                                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div *ngIf="item.bookingType == 1" class="job_id_body Unassigned"  (click)="getProviderForBooking(item , item.bookingId)">
                                <span class="customer-name">{{item.customerData.firstName}} {{item.customerData.lastName}} ({{item.customerData.phone}})</span>
                                <div  id="CountDownUnassigned-{{item.bookingId}}" style="float:right;display:none" *ngIf="item.unassignedBookingsStatus == false && ForCreatManual != item.bookingId ">
                                    <span *ngFor="let counter of countDownArray">
                                        <span class="countDown Unassigned-countDown" *ngIf="counter.bookingId === item.bookingId && duration > 0">
                                            <countdown [config]="{leftTime:counter.duration}">$!h!h : $!m!m : $!s!s</countdown>
                                        </span>
                                    </span> 
                                </div>


                                <div class="job_id_main_body">
                                    <div class="mb-2 mt-2">
                                        <span class="job_id_zone service"><span>Category : </span><strong class="customerData">{{item.category}}</strong></span>
                                        <div class="now-later">
                                            <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                            <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                            <span class="now" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                        </div>
                                    </div>
                                    <div class="mb-2 mt-2" *ngIf="item.providerData && item.providerData.name">
                                        <span class="job_id_zone service"><span>Assigned For : </span><strong class="customerData">{{item.providerData.name}}</strong></span>
                                    </div>
                                    <div class="job-details">
                                            <div class="job_id_date">
                                                <span class="points_text"> <img src="assets/svgImages/calender.svg" width="12px" height="12px"> Booking Time</span>
                                                <br>
                                                <span class="job_id_time"><strong class="customerData">{{item.bookingRequestedFor*1000 | date:'medium'}}</strong></span>
                                            </div>
                                            <div class="job_id_type">
                                                <span class="points_text">
                                                    <img src="assets/svgImages/money.svg" width="12px" height="12px">Total Price</span>
                                                <br>
                                                <span class="job_fare text-right"><strong class="customerData"> {{item.currencySymbol}} {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                            </div>
                                        </div>
                                </div>
                            </div> -->
                                <!-- <div *ngIf="item.bookingType == 1" class="job_id_body" (click)="getProviderForBooking(item , item.bookingId)">
                                    <div class="row customerName">
                                        <div class="col-md-12 zeroPadding margin-CustomerName">
                                            &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons" width="12px" height="12px">  
                                            <span class="customer-name">&nbsp;{{item.customerData.firstName}} {{item.customerData.lastName}} ({{item.customerData.phone}})
                                            </span>
                                            <span class="now-later">
                                                <div  id="CountDownUnassigned-{{item.bookingId}}" style="float:right;display:none" *ngIf="item.unassignedBookingsStatus == false && ForCreatManual != item.bookingId ">
                                                            <span id="CountDown-{{item.bookingId}}" class="countDown Unassigned-countDown"> </span>
                                                    <span *ngFor="let counter of countDownArray">
                                                        <span class="countDown Unassigned-countDown" *ngIf="counter.bookingId === item.bookingId && duration > 0">
                                                            <countdown [config]="{leftTime:counter.duration}">$!h!h : $!m!m : $!s!s</countdown>
                                                        </span>
                                                    </span> 
                                                </div>
                                            </span>
                                           
                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-1 userDetail">
                                                   <p><img src="assets/svgImages/maintenance.svg" class="svgIcons" width="12px" height="12px"></p>
                                                   <p><img src="assets/svgImages/worker.svg" class="svgIcons" width="12px" height="12px"></p>
                                                   <p><img src="assets/svgImages/calendar.svg" class="svgIcons" width="12px" height="12px"></p>
                                                   <p><img src="assets/svgImages/money.svg" class="svgIcons" width="12px" height="12px"></p>
                                                </div>
                                                <div class="col-md-3 zeroPadding userDetail">
                                                    <p class="svgIcons">Category :</p>
                                                    <p class="svgIcons">Assigned For :</p>
                                                    <p class="svgIcons">Booking Time :</p>
                                                    <p class="svgIcons">Total Price :</p>
                                                </div>
                                                <div class="col-md-7 userDetail zeroPadding">
                                                    <p><strong>{{item.category}}</strong> 
                                                        <span class="now-later">
                                                            <span class="watingReg" *ngIf="item.bookingType == 1">Now</span>
                                                            <span class="watingReg" *ngIf="item.bookingType == 2">Later</span>
                                                            <span class="watingReg" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                                        </span>
                                                    </p>
                                                    <p><strong *ngIf="item.providerData && item.providerData.hasOwnProperty('name')">{{item.providerData.name}}</strong>
                                                        <span *ngIf="item.providerData.hasOwnProperty('name') === false">--</span></p>
                                                    <p><strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong></p>
                                                    <p><strong>{{item.currencySymbol}} {{item.totalAmount| number:'1.2-2'}}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
           
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 left_window_body BookingList text-center" style="background: #bfc5af;">
                        <p class="sorting-header">Later Booking List</p>
                    </div>
                    <div class="scroll col-md-12 padding-0" id="unassigned" (scroll)="onScroll($event,1)">
                        <div *ngIf="unassigned.length <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>
                        <div class="BookingsContent">
                            <div *ngFor="let item of unassigned"
                                [ngClass]="{'bookingType1':item.bookingType == 1, 'bookingType2':item.bookingType == 2, 'bookingType3':item.bookingType == 3}"
                                id="booking-{{item.bookingId}}">
                                <div *ngIf="item.bookingType == 2 || item.bookingType == 3 "
                                    class="job_id_header col-md-12 padding-0">
                                    <div class="job_id_header_left col-md-12 padding-0">
                                        <div class="col-md-4 padding-0">
                                            <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                            <span title="Dispatch count" *ngIf="item.dispatchCount"
                                                class="dispatch_attempt">{{item.dispatchCount}}</span>
                                        </div>
                                        <div class="col-md-4 padding-0 text-center">
                                            <span class="job_id_task">{{item.statusMsg}}</span>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row pull-right">
                                                <div class="col-md-4 padding-5">
                                                    <a title="CancelBooking" (click)="cancelBooking(item,1)"
                                                        class="details_but" style="color:#fff">
                                                        <img src="assets/svgImages/cancel.svg" width="12px"
                                                            height="12px" title="CancelBooking">
                                                    </a>
                                                </div>
                                                <!-- <div class="col-md-4 padding-5" *ngIf="item.bookingModel != 3 && item.bookingType != 3">
                                                             <a  title="Edit Cart" (click)="editingCards(item)" class="details_but danger" style="color:#fff">
                                                                <img src="assets/svgImages/cart2.svg" width="12px" height="12px" title="Show Cart">
                                                            </a>
                                                        </div>  -->
                                                <div class="col-md-4 padding-5">
                                                    <a title="Details" (click)="getBookingDetailsPopUp(item)"
                                                        class="details_but danger" style="color:#fff;">
                                                        <img src="assets/svgImages/menu3.svg" width="12px" height="12px"
                                                            title="Booking Details">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 userDetails">
                                        <div *ngIf="item.bookingType === 2 || item.bookingType === 3"
                                            class="job_id_body" (click)="getProviderForBooking(item , item.bookingId)">
                                            <div class="row customerName">
                                                <div class="col-md-12 zeroPadding margin-CustomerName">
                                                    &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons"
                                                        width="12px" height="12px">
                                                    <span class="customer-name">&nbsp;{{item.customerData.firstName}}
                                                        {{item.customerData.lastName}} ({{item.customerData.phone}})
                                                    </span>
                                                    <div id="CountDownUnassigned-{{item.bookingId}}"
                                                        style="float:right;display:none"
                                                        *ngIf="item.unassignedBookingsStatus == false && ForCreatManual != item.bookingId ">
                                                        <span *ngFor="let counter of countDownArray">
                                                            <span class="countDown Unassigned-countDown"
                                                                *ngIf="counter.bookingId === item.bookingId && duration > 0">
                                                                <countdown [config]="{leftTime:counter.duration}">
                                                                    $!h!h:$!m!m:$!s!s</countdown>
                                                            </span>
                                                        </span>

                                                    </div>
                                                    <span class="now-later text-right">
                                                        <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                        <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                        <span class="now" *ngIf="item.bookingType == 3">Multiple
                                                            Shift</span>
                                                    </span>

                                                </div>
                                                <div class="col-md-12">
                                                    <div class="customerBookingDetails">
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/maintenance.svg"
                                                                    class="svgIcons" width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Category :
                                                            </span>
                                                            <span
                                                                class="col-md-7"><strong>{{item.category}}</strong></span>
                                                            <!-- <span class="now-later col-md-4 text-right">
                                                                                        <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                                                        <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                                                        <span class="now" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                                                                    </span>  -->
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/worker.svg" class="svgIcons"
                                                                    width="12px" height="12px"> </span>
                                                            <span class="svgIcons  col-md-3 padding-0">Assigned For :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong
                                                                    *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                                <span
                                                                    *ngIf="item.providerData.hasOwnProperty('name') === false">--</span>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row" *ngIf="item.bookingType !== 3">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/calendar.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Booking Time :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong>
                                                            </span>
                                                        </p>
                                                        <!-- For booking type 3 meand repeat booking show start to end date and time with no of shifts-->
                                                        <p class="marginBtmBooking row borderForRepeat"
                                                            *ngIf="item.bookingType === 3">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/calendar.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Start Date
                                                            </span>
                                                            <span class=" col-md-4 text-center">No Of Shift</span>
                                                            <span class=" col-md-4 text-right">End Date</span>
                                                            <span class="col-md-1 padding-0 svgIcons"></span>
                                                            <span
                                                                class="svgIcons  col-md-3 padding-0"><strong>{{getTimerInFormat(item.bookingRequestedFor*1000,1)}}</strong></span>
                                                            <span
                                                                class=" col-md-4 text-center colorRed"><strong>{{item.accounting.totalShiftBooking}}</strong></span>
                                                            <span
                                                                class=" col-md-4 text-right"><strong>{{getTimerInFormat(item.bookingEndtime*1000,1)}}</strong></span>

                                                        </p>
                                                        <p class="marginBtmBooking row borderForRepeat"
                                                            *ngIf="item.bookingType === 3">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/clock.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Start Time
                                                            </span>
                                                            <span
                                                                class=" col-md-4 text-center"><strong>----------</strong></span>
                                                            <span class=" col-md-4 text-right">End Time</span>
                                                            <span class="col-md-1 padding-0 svgIcons"></span>
                                                            <span
                                                                class="svgIcons  col-md-3 padding-0"><strong>{{getTimerInFormat(item.bookingRequestedFor*1000,2)}}</strong></span>
                                                            <span
                                                                class=" col-md-4 text-center"><strong>----------</strong></span>
                                                            <span
                                                                class=" col-md-4 text-right"><strong>{{getTimerInFormat(item.bookingEndtime*1000,2)}}</strong></span>
                                                        </p>
                                                        <p class="marginBtmBooking row" *ngIf="item.bookingType === 3">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/money.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons col-md-3 padding-0">Per Shift Price :
                                                            </span>
                                                            <span class=" col-md-7"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                                        </p>

                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/money.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons col-md-3 padding-0">Total Price :
                                                            </span>
                                                            <span class="col-md-7"
                                                                *ngIf="item.bookingType !== 3"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                                            <span class="col-md-7"
                                                                *ngIf="item.bookingType === 3"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount * item.accounting.totalShiftBooking| number:'1.2-2'}}</strong></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div *ngIf="item.bookingType == 2 || item.bookingType == 3" class="job_id_body Unassigned"  (click)="getProviderForBooking(item , item.bookingId)">
                                <span class="customer-name">{{item.customerData.firstName}} {{item.customerData.lastName}} ({{item.customerData.phone}})</span>
                                <div id="CountDownUnassigned-{{item.bookingId}}" style="float:right;display:none" *ngIf="item.unassignedBookingsStatus == false && ForCreatManual != item.bookingId ">
                                    <span *ngFor="let counter of countDownArray">
                                        <span class="countDown Unassigned-countDown" *ngIf="counter.bookingId === item.bookingId && duration > 0">
                                            <countdown [config]="{leftTime:counter.duration}">$!h!h:$!m!m:$!s!s</countdown>
                                        </span>
                                    </span> 
                                </div>
                              
                                <div class="job_id_main_body">
                                    <div class="mb-2 mt-2">
                                        <span class="job_id_zone service"><span>Category : </span><strong class="customerData">{{item.category}}</strong></span>
                                        <div class="now-later">
                                            <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                            <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                            <span class="now" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                        </div>
                                    </div>
                                    <div class="mb-2 mt-2" *ngIf="item.providerData && item.providerData.name">
                                        <span class="job_id_zone service"><span>Assigned For : </span><strong class="customerData">{{item.providerData.name}}</strong></span>
                                    </div>
                                    <div class="job-details">
                                            <div class="job_id_date">
                                                <span class="points_text"> <img src="assets/svgImages/calender.svg" width="12px" height="12px"> Booking Time</span>
                                                <br>
                                                <span class="job_id_time"><strong class="customerData">{{item.bookingRequestedFor*1000 | date:'medium'}}</strong></span>
                                            </div>
                                            <div class="job_id_type">
                                                <span class="points_text">
                                                    <img src="assets/svgImages/money.svg" width="12px" height="12px">Total Price</span>
                                                <br>
                                                <span class="job_fare text-right"><strong class="customerData"> {{item.currencySymbol}} {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                            </div>
                                        </div>
                                </div>
                            </div> -->


                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Dispatched">
                    <ng-template mat-tab-label class="countWindow">
                        <div class="joblist orderlist text-center padding-0 active">
                            <p class="numbers">{{dispatchedCount}}</p>
                            <p class="booking-status-header">Indispatch</p>
                        </div>
                    </ng-template>
                    <div class="col-md-12 padding-0">
                        <mat-progress-bar *ngIf="bookingMode == 'query'" class="example-margin" [color]="color"
                            [mode]="bookingMode" [value]="value" [bufferValue]="bufferValue">
                        </mat-progress-bar>
                    </div>
                    <div class="col-md-12 left_window_header twoDivsTab padding-0" style="color: #090909;">
                        <mat-tab-group style="background-color:#ffffff;">
                            <mat-tab label="Date Filter" style="background-color: #ffffff">
                                <ng-template mat-tab-label style="background-color: white">
                                    <div class="joblist orderlist text-center padding-0 active">
                                        <p class="booking-status-header" style="color: #090909;">Date Filter</p>
                                    </div>
                                </ng-template>
                                <div class="col-md-10 padding-0">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [matDatepicker]="picker1" (click)="picker1.open()"
                                                [formControl]="fromdate" [(ngModel)]="fromDateFilter"
                                                (ngModelChange)="observeDateFilter()" placeholder="From" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi="true" #picker1></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [matDatepicker]="picker3" (click)="picker3.open()"
                                                [attr.disabled]="!fromDateFilter" [min]="fromDateFilter"
                                                [formControl]="todate" [(ngModel)]="toDateFilter"
                                                (ngModelChange)="observeDateFilter()" placeholder="To" readonly
                                                style="color: #090909 !important;">
                                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi="true" #picker3></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-2 padding-0 text-center marginTop10" (click)="resetAll()">
                                    <i id="search_pickerOne" title="refresh" style="cursor:pointer"
                                        class="fas fa-sync-alt"></i>
                                </div>
                            </mat-tab>
                            <mat-tab label="Search Booking">
                                <ng-template mat-tab-label>
                                    <div class="joblist orderlist text-center padding-0 active">
                                        <p class="booking-status-header" style="color:#090909">Search</p>
                                    </div>
                                </ng-template>
                                <div class="col-md-10">
                                    <mat-form-field class="w100">
                                        <input matInput placeholder="Search Booking By Name/PhoneNumber/Email"
                                            [(ngModel)]="stringFilter" [formControl]="searchController"
                                            (change)="getAllBookings(0,0)" autocomplete="off">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 padding-0 text-center marginTop10" (click)="resetAll()">
                                    <i id="search_pickerOne" title="refresh" style="cursor:pointer"
                                        class="fas fa-sync-alt"></i>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <div class="col-md-12 left_window_body BookingList text-center">
                        <p class="sorting-header">Today's Booking List</p>
                    </div>
                    <div class="scroll  col-md-12 padding-0" id="indispatch" (scroll)="onScroll($event,2)">
                        <div *ngIf="dispatched.length <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>
                        <div class="BookingsContent">
                            <div *ngFor="let item of dispatched" [ngClass]="{'bookingType1':item.bookingType == 1}"
                                id="booking-{{item.bookingId}}">
                                <div *ngIf="item.bookingType == 1" class="job_id_header col-md-12 padding-0">
                                    <div class="job_id_header_left col-md-12 padding-0">
                                        <div class="col-md-4 padding-0">
                                            <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                            <span title="Dispatch count" *ngIf="item.dispatchCount"
                                                class="dispatch_attempt">{{item.dispatchCount}}</span>
                                        </div>
                                        <div class="col-md-4 padding-0 text-center">
                                            <span class="job_id_task">{{item.statusMsg}}</span>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row pull-right">
                                                <div class="col-md-3 padding-0" style="padding-right:10px">
                                                    <a title="Details" (click)="getBookingDetailsPopUp(item)"
                                                        class="details_but danger" style="color:#fff">
                                                        <img src="assets/svgImages/menu3.svg" width="12px" height="12px"
                                                            title="Booking Details">
                                                    </a>
                                                </div>
                                                <!-- <div class="col-md-3 padding-0" *ngIf="item.bookingModel != 3 && item.bookingType != 3" style="padding-right:10px">
                                                <a title="Undispatch Booking" (click)="editingCards(item)"class="details_but" style="color:#fff">
                                                    <img src="assets/svgImages/cart2.svg" width="12px" height="12px" title="Show Cart">
                                                </a>
                                            </div> -->
                                                <div class="col-md-3 padding-0" style="padding-right:5px">
                                                    <a title="Edit Cart" (click)="UndispatchBooking(item)"
                                                        class="details_but danger" style="color:#fff">
                                                        <img src="assets/svgImages/exchange.svg" width="12px"
                                                            height="12px" title="Not Interested For Booking">
                                                    </a>
                                                </div>
                                                <div class="col-md-3 padding-0">
                                                    <a title="CancelBooking" (click)="cancelBooking(item,2)"
                                                        class="details_but" style="color:#fff">
                                                        <img src="assets/svgImages/cancel.svg" width="12px"
                                                            height="12px" title="CancelBooking">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 userDetails">
                                        <div *ngIf="item.bookingType == 1" class="job_id_body"
                                            (click)="placeMarker(item)">
                                            <div class="row customerName">
                                                <div class="col-md-12 zeroPadding margin-CustomerName">
                                                    &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons"
                                                        width="12px" height="12px">
                                                    <span class="customer-name">&nbsp;{{item.customerData.firstName}}
                                                        {{item.customerData.lastName}} ({{item.customerData.phone}})
                                                    </span>

                                                    <!-- <div id="CountDownRapper-{{item.bookingId}}" style="display:none;float: right">
                                                                <span *ngFor="let counter of countDownArray">
                                                                    <span class="countDown Unassigned-countDown" *ngIf="counter.bookingId === item.bookingId && duration > 0">
                                                                        <countdown [config]="{leftTime:counter.duration}">$!h!h : $!m!m : $!s!s</countdown>
                                                                    </span>
                                                                </span> 
                                                               
                                                            </div>
                                                            <span class="now-later col-md-4 text-right">
                                                                <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                                <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                                <span class="now" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                                            </span> 
                                                        -->
                                                    <div id="CountDownRapper-{{item.bookingId}}"
                                                        style="float:right;display:none"
                                                        *ngIf="item.unassignedBookingsStatus == false && ForCreatManual != item.bookingId ">
                                                        <span *ngFor="let counter of countDownArray">
                                                            <span class="countDown Unassigned-countDown"
                                                                *ngIf="counter.bookingId === item.bookingId && duration > 0">
                                                                <countdown [config]="{leftTime:counter.duration}">$!h!h
                                                                    : $!m!m : $!s!s</countdown>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <span class="now-later">
                                                        <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                        <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                        <span class="now" *ngIf="item.bookingType == 3">Multiple
                                                            Shift</span>
                                                    </span>

                                                </div>
                                                <div class="col-md-12">
                                                    <div class="customerBookingDetails">
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/maintenance.svg"
                                                                    class="svgIcons" width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Category :
                                                            </span>
                                                            <span
                                                                class="col-md-7"><strong>{{item.category}}</strong></span>
                                                            <!-- <span class="col-md-4 text-right">
                                                                        <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                                        <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                                        <span class="now" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                                                    </span>  -->
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/worker.svg" class="svgIcons"
                                                                    width="12px" height="12px"> </span>
                                                            <span class="svgIcons  col-md-3 padding-0">Assigned For :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong
                                                                    *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                                <span
                                                                    *ngIf="item.providerData.hasOwnProperty('name') === false">--</span>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/calendar.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Booking Time :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/money.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons col-md-3 padding-0">Total Price :
                                                            </span>
                                                            <span class=" col-md-7"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div *ngIf="item.bookingType == 1" class="job_id_body userDetails" (click)="placeMarker(item)">
                                    <div class="row customerName">
                                            <div class="col-md-12 zeroPadding margin-CustomerName">
                                                    &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons" width="12px" height="12px"> 
                                                 <div id="CountDownRapper-{{item.bookingId}}" style="display:none;float: right">
                                                     <span *ngFor="let counter of countDownArray">
                                                         <span class="countDown Unassigned-countDown" *ngIf="counter.bookingId === item.bookingId && duration > 0">
                                                             <countdown [config]="{leftTime:counter.duration}">$!h!h : $!m!m : $!s!s</countdown>
                                                         </span>
                                                     </span> 
                                                 </div>
                                             </div>
                                             <div class="col-md-12">
                                                     <div class="row">
                                                         <div class="col-md-1 userDetail">
                                                            <p><img src="assets/svgImages/maintenance.svg" class="svgIcons" width="12px" height="12px"></p>
                                                            <p><img src="assets/svgImages/worker.svg" class="svgIcons" width="12px" height="12px"></p>
                                                            <p><img src="assets/svgImages/calendar.svg" class="svgIcons" width="12px" height="12px"></p>
                                                            <p><img src="assets/svgImages/money.svg" class="svgIcons" width="12px" height="12px"></p>
                                                         </div>
                                                         <div class="col-md-3 zeroPadding userDetail">
                                                             <p class="svgIcons">Category :</p>
                                                             <p class="svgIcons">Assigned For :</p>
                                                             <p class="svgIcons">Booking Time :</p>
                                                             <p class="svgIcons">Total Price :</p>
                                                         </div>
                                                         <div class="col-md-7 userDetail zeroPadding">
                                                             <p><strong>{{item.category}}</strong></p>
                                                             <p><strong *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                             <span *ngIf="!item.providerData && !item.providerData.name">--</span></p>
                                                             <p><strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong></p>
                                                             <p><strong>{{item.currencySymbol}} {{item.totalAmount| number:'1.2-2'}}</strong></p>
                                                         </div>
                                                     </div>
                                                 </div>
                                        </div>

                            </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 left_window_body BookingList text-center" style="background: #bfc5af;">
                        <p class="sorting-header">Later Booking List</p>
                    </div>
                    <div class="scroll  col-md-12 padding-0" id="indispatch" (scroll)="onScroll($event,2)">
                        <div *ngIf="dispatched.length <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>
                        <div class="BookingsContent">
                            <div *ngFor="let item of dispatched"
                                [ngClass]="{'bookingType2':item.bookingType == 2, 'bookingType3':item.bookingType == 3}"
                                id="booking-{{item.bookingId}}">
                                <div *ngIf="item.bookingType == 2 || item.bookingType == 3"
                                    class="job_id_header col-md-12 padding-0">
                                    <div class="job_id_header_left col-md-12 padding-0">
                                        <div class="col-md-4 padding-0">
                                            <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                            <span title="Dispatch count" *ngIf="item.dispatchCount"
                                                class="dispatch_attempt">{{item.dispatchCount}}</span>
                                        </div>
                                        <div class="col-md-4 text-center padding-0">
                                            <span class="job_id_task">{{item.statusMsg}}</span>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row pull-right">
                                                <div class="col-md-3 padding-0" style="padding-right:10px">
                                                    <a title="Details" (click)="getBookingDetailsPopUp(item)"
                                                        class="details_but danger" style="color:#fff">
                                                        <img src="assets/svgImages/menu3.svg" width="12px" height="12px"
                                                            title="Booking Details">
                                                    </a>
                                                </div>
                                                <div class="col-md-3 padding-0" style="padding-right:5px">
                                                    <a title="Undispatch Booking" (click)="UndispatchBooking(item)"
                                                        class="details_but" style="color:#fff">
                                                        <img src="assets/svgImages/exchange.svg" width="12px"
                                                            height="12px" title="Not Interested For Booking">
                                                    </a>
                                                </div>

                                                <!-- <div class="col-md-3 padding-0" *ngIf="item.bookingModel != 3 && item.bookingType != 3" style="padding-right:10px">
                                                <a  title="Edit Cart" (click)="editingCards(item)" class="details_but danger" style="color:#fff">
                                                    <img src="assets/svgImages/cart2.svg" width="12px" height="12px" title="Show cart">
                                                </a>
                                            </div> -->
                                                <div class="col-md-3 padding-0">
                                                    <a title="CancelBooking" (click)="cancelBooking(item,2)"
                                                        class="details_but" style="color:#fff">
                                                        <img src="assets/svgImages/cancel.svg" width="12px"
                                                            height="12px" title="CancelBooking">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 userDetails">
                                        <div *ngIf="item.bookingType == 2 || item.bookingType == 3" class="job_id_body"
                                            (click)="placeMarker(item)">
                                            <div class="row customerName">
                                                <div class="col-md-12 zeroPadding margin-CustomerName">
                                                    &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons"
                                                        width="12px" height="12px">
                                                    <span class="customer-name">&nbsp;{{item.customerData.firstName}}
                                                        {{item.customerData.lastName}} ({{item.customerData.phone}})
                                                    </span>
                                                    <div id="CountDownRapper-{{item.bookingId}}"
                                                        style="float:right;display:none"
                                                        *ngIf="item.unassignedBookingsStatus == false && ForCreatManual != item.bookingId ">
                                                        <span *ngFor="let counter of countDownArray">
                                                            <span class="countDown Unassigned-countDown"
                                                                *ngIf="counter.bookingId === item.bookingId && duration > 0">
                                                                <countdown [config]="{leftTime:counter.duration}">$!h!h
                                                                    : $!m!m : $!s!s</countdown>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <span class="now-later">
                                                        <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                        <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                        <span class="now" *ngIf="item.bookingType == 3">Multiple
                                                            Shift</span>
                                                    </span>

                                                </div>
                                                <div class="col-md-12">
                                                    <div class="customerBookingDetails">
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/maintenance.svg"
                                                                    class="svgIcons" width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Category :
                                                            </span>
                                                            <span
                                                                class="col-md-4"><strong>{{item.category}}</strong></span>
                                                            <!-- <span class="col-md-4 text-right">
                                                                    <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                                    <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                                    <span class="now" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                                                </span>  -->
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/worker.svg" class="svgIcons"
                                                                    width="12px" height="12px"> </span>
                                                            <span class="svgIcons  col-md-3 padding-0">Assigned For :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong
                                                                    *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                                <span
                                                                    *ngIf="item.providerData.hasOwnProperty('name') === false">--</span>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row" *ngIf="item.bookingType !== 3">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/calendar.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Booking Time :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong>
                                                            </span>
                                                        </p>
                                                        <!-- For booking type 3 meand repeat booking show start to end date and time with no of shifts-->
                                                        <p class="marginBtmBooking row borderForRepeat"
                                                            *ngIf="item.bookingType === 3">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/calendar.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Start Date
                                                            </span>
                                                            <span class=" col-md-4 text-center">No Of Shift</span>
                                                            <span class=" col-md-4 text-right">End Date</span>
                                                            <span class="col-md-1 padding-0 svgIcons"></span>
                                                            <span
                                                                class="svgIcons  col-md-3 padding-0"><strong>{{getTimerInFormat(item.bookingRequestedFor*1000,1)}}</strong></span>
                                                            <span
                                                                class=" col-md-4 text-center colorRed"><strong>{{item.accounting.totalShiftBooking}}</strong></span>
                                                            <span
                                                                class=" col-md-4 text-right"><strong>{{getTimerInFormat(item.bookingEndtime*1000,1)}}</strong></span>

                                                        </p>
                                                        <p class="marginBtmBooking row borderForRepeat"
                                                            *ngIf="item.bookingType === 3">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/clock.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Start Time
                                                            </span>
                                                            <span
                                                                class=" col-md-4 text-center"><strong>----------</strong></span>
                                                            <span class=" col-md-4 text-right">End Time</span>
                                                            <span class="col-md-1 padding-0 svgIcons"></span>
                                                            <span
                                                                class="svgIcons  col-md-3 padding-0"><strong>{{getTimerInFormat(item.bookingRequestedFor*1000,2)}}</strong></span>
                                                            <span
                                                                class=" col-md-4 text-center"><strong>----------</strong></span>
                                                            <span
                                                                class=" col-md-4 text-right"><strong>{{getTimerInFormat(item.bookingEndtime*1000,2)}}</strong></span>
                                                        </p>
                                                        <p class="marginBtmBooking row" *ngIf="item.bookingType === 3">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/money.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons col-md-3 padding-0">Per Shift Price :
                                                            </span>
                                                            <span class=" col-md-7"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                                        </p>

                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/money.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons col-md-3 padding-0">Total Price :
                                                            </span>
                                                            <span class="col-md-7"
                                                                *ngIf="item.bookingType !== 3"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                                            <span class="col-md-7"
                                                                *ngIf="item.bookingType === 3"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount * item.accounting.totalShiftBooking| number:'1.2-2'}}</strong></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-1 userDetail">
                                                           <p><img src="assets/svgImages/maintenance.svg" class="svgIcons" width="12px" height="12px"></p>
                                                           <p><img src="assets/svgImages/worker.svg" class="svgIcons" width="12px" height="12px"></p>
                                                           <p><img src="assets/svgImages/calendar.svg" *ngIf="item.bookingType !== 3" class="svgIcons" width="12px" height="12px"></p>
                                                           <p><img src="assets/svgImages/money.svg" class="svgIcons" width="12px" height="12px"></p>
                                                           <p><img src="assets/svgImages/money.svg" class="svgIcons" width="12px" height="12px"></p>
                                                           <p><img src="assets/svgImages/calendar.svg" *ngIf="item.bookingType === 3" class="svgIcons" width="12px" height="12px"></p>
                                                           <p class="svgIcons" style="width:12px;height: 12px"></p>
                                                           <p><img src="assets/svgImages/clock.svg" *ngIf="item.bookingType === 3" class="svgIcons" width="12px" height="12px"></p>
                                                        </div>
                                                        <div class="col-md-3 zeroPadding userDetail">
                                                            <p class="svgIcons">Category :</p>
                                                            <p class="svgIcons">Assigned For :</p>
                                                            <p class="svgIcons" *ngIf="item.bookingType !== 3">Booking Time :</p>
                                                            <p class="svgIcons" *ngIf="item.bookingType === 3">Per Shift Price :</p>
                                                            <p class="svgIcons">Total Price :</p>
                                                            <p class="svgIcons" *ngIf="item.bookingType === 3">
                                                                <span>Start Date</span>
                                                            </p>
                                                            <p class="svgIcons" *ngIf="item.bookingType === 3">
                                                                <strong>{{getTimerInFormat(item.bookingRequestedFor*1000,1)}}</strong>
                                                            </p>
                                                            <p class="svgIcons" *ngIf="item.bookingType === 3">
                                                                <span>Start Time</span>
                                                            </p>
                                                            <p class="svgIcons" *ngIf="item.bookingType === 3">
                                                                <strong>{{getTimerInFormat(item.bookingRequestedFor*1000,2)}}</strong>
                                                            </p>
    
                                                        </div>
                                                        <div class="col-md-7 userDetail zeroPadding">
                                                            <p><strong>{{item.category}}</strong> 
                                                                <span class="now-later scheduleStyle">
                                                                    <span class="watingReg" *ngIf="item.bookingType == 1">Now</span>
                                                                    <span class="watingReg" *ngIf="item.bookingType == 2">Later</span>
                                                                    <span class="watingReg" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                                                </span>
                                                            </p>
                                                            <p><strong *ngIf="item.providerData && item.providerData.hasOwnProperty('name')">{{item.providerData.name}}</strong>
                                                            <span *ngIf="item.providerData === {}">--</span></p>
                                                            <p *ngIf="item.bookingType !== 3"><strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong></p>
                                                            
                                                            <p *ngIf="item.bookingType == 2"><strong>{{item.currencySymbol}} {{item.totalAmount | number:'1.2-2'}}</strong></p>
                                                            <p *ngIf="item.bookingType == 3"><strong>{{item.currencySymbol}} {{item.totalAmount | number:'1.2-2'}}</strong></p>
                                                            <p *ngIf="item.bookingType == 3"><strong>{{item.currencySymbol}} {{item.totalAmount * item.accounting.totalShiftBooking| number:'1.2-2'}}</strong></p>
                                                            <p *ngIf="item.bookingType === 3">
                                                                <span class="pull-center">No Of Shifts</span>
                                                                <span class="pull-right">End Date</span>
                                                            </p>
                                                            <p *ngIf="item.bookingType === 3">
                                                                <strong class="pull-center">{{item.accounting.totalShiftBooking}}</strong>
                                                                <strong class="pull-right">{{getTimerInFormat(item.bookingEndtime*1000,1)}}</strong>
                                                            </p>
                                                            <p *ngIf="item.bookingType === 3">
                                                                <span class="pull-center">----------</span>
                                                                <span class="pull-right">End Time</span>
                                                            </p>
                                                            <p *ngIf="item.bookingType === 3">
                                                                <strong class="pull-center">----------</strong>
                                                                <strong class="pull-right">{{getTimerInFormat(item.bookingEndtime*1000,2)}}</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Assigned">
                    <ng-template mat-tab-label class="countWindow">
                        <div class="joblist orderlist text-center padding-0 active">
                            <p class="numbers">{{assignedCount}}</p>
                            <p class="booking-status-header">Assigned</p>
                        </div>
                    </ng-template>
                    <div class="col-md-12 padding-0">
                        <mat-progress-bar *ngIf="bookingMode == 'query'" class="example-margin" [color]="color"
                            [mode]="bookingMode" [value]="value" [bufferValue]="bufferValue">
                        </mat-progress-bar>
                    </div>
                    <div class="col-md-12 left_window_header twoDivsTab padding-0" style="color: #090909;">
                        <mat-tab-group style="background-color:#ffffff;">
                            <mat-tab label="Date Filter" style="background-color: #ffffff">
                                <ng-template mat-tab-label style="background-color: white">
                                    <div class="joblist orderlist text-center padding-0 active">
                                        <p class="booking-status-header" style="color: #090909;">Date Filter</p>
                                    </div>
                                </ng-template>
                                <div class="col-md-10 padding-0">
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [matDatepicker]="picker4" (click)="picker4.open()"
                                                [formControl]="fromdate" [(ngModel)]="fromDateFilter"
                                                (ngModelChange)="observeDateFilter()" placeholder="From" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi="true" #picker4></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="example-full-width">
                                            <input matInput [matDatepicker]="picker5" (click)="picker5.open()"
                                                [attr.disabled]="!fromDateFilter" [min]="fromDateFilter"
                                                [formControl]="todate" [(ngModel)]="toDateFilter"
                                                (ngModelChange)="observeDateFilter()" placeholder="To" readonly
                                                style="color: #090909!important;">
                                            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi="true" #picker5></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-2 padding-0 text-center marginTop10" (click)="resetAll()">
                                    <i id="search_pickerOne" title="refresh" style="cursor:pointer"
                                        class="fas fa-sync-alt"></i>
                                </div>
                            </mat-tab>
                            <mat-tab label="Search Booking">
                                <ng-template mat-tab-label>
                                    <div class="joblist orderlist text-center padding-0 active">
                                        <p class="booking-status-header" style="color:#090909">Search</p>
                                    </div>
                                </ng-template>
                                <div class="col-md-10">
                                    <mat-form-field class="w100">
                                        <input matInput placeholder="Search Booking By Name/PhoneNumber/Email"
                                            [(ngModel)]="stringFilter" [formControl]="searchController"
                                            (change)="getAllBookings(0,0)" autocomplete="off">
                                        <!-- <mat-hint><span style="color:#fff">search by Order-Id/ Customer Name/ Email/ Phone</span></mat-hint> -->
                                    </mat-form-field>
                                </div>
                                <div class="col-md-2 padding-0 text-center">
                                    <i id="search_pickerOne" title="refresh" style="cursor:pointer" (click)="resetAll()"
                                        class="fas fa-sync-alt"></i>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <div class="col-md-12 left_window_body BookingList text-center">
                        <p class="sorting-header">Today's Booking List</p>
                    </div>
                    <div class="scroll  col-md-12 padding-0" id="assigned" (scroll)="onScroll($event,3)">
                        <div *ngIf="assigned.length <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>

                        <div class="BookingsContent">
                            <div *ngFor="let item of assigned" (scroll)="onScroll($event,3)"
                                [ngClass]="{'bookingType1':item.bookingType == 1}" id="booking-{{item.bookingId}}">
                                <div *ngIf="item.bookingType == 1" class="job_id_header col-md-12 padding-0">
                                    <div class="job_id_header_left col-md-12 padding-0">
                                        <div class="col-md-4 padding-0">
                                            <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                            <span title="Dispatch count" *ngIf="item.dispatchCount"
                                                class="dispatch_attempt">{{item.dispatchCount}}</span>
                                        </div>
                                        <div class="col-md-4 padding-0 text-center">
                                            <span class="job_id_task">{{item.statusMsg}}</span>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row pull-right">
                                                <div class="col-md-3 padding-5">
                                                    <a title="CancelBooking" (click)="cancelBooking(item,3)"
                                                        class="details_but" style="color:#fff">
                                                        <img src="assets/svgImages/cancel.svg" width="12px"
                                                            height="12px" title="CancelBooking">
                                                    </a>
                                                </div>
                                                <div class="col-md-3 padding-5">
                                                    <a title="Details" (click)="getBookingDetailsPopUp(item)"
                                                        class="details_but danger" style="color:#fff;">
                                                        <img src="assets/svgImages/menu3.svg" width="12px" height="12px"
                                                            title="Booking Details">
                                                    </a>
                                                </div>
                                                <!-- <div class="col-md-3 padding-5" *ngIf="item.bookingModel != 3 && item.bookingType != 3">
                                                    <a  title="Edit Cart" (click)="editingCards(item)" class="details_but danger" style="color:#fff">
                                                     <img src="assets/svgImages/exchange.svg" width="12px" height="12px" title="Not Interested For Booking">
                                                        <img src="assets/svgImages/cart2.svg" width="12px" height="12px" title="Show Cart">
                                                    </a>
                                                </div> -->
                                                <div class="col-md-3 padding-5" *ngIf="item.status != 9">
                                                    <a title="Unassign" (click)="openUnassignPopUp(item)"
                                                        class="details_but danger1"
                                                        style="color:#fff;  background: transparent;">
                                                        <!-- <i class="material-icons">not_interested</i> -->
                                                        <img src="assets/svgImages/exchange.svg" width="12px"
                                                            height="12px" title="Not Interested For Booking">
                                                    </a>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-md-12 userDetails">
                                        <div *ngIf="item.bookingType == 1" class="job_id_body"
                                            (click)="assignedProvider(item)">
                                            <div class="row customerName">
                                                <div class="col-md-12 zeroPadding margin-CustomerName">
                                                    &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons"
                                                        width="12px" height="12px">
                                                    <span class="customer-name">&nbsp;{{item.customerData.firstName}}
                                                        {{item.customerData.lastName}} ({{item.customerData.phone}})
                                                    </span>
                                                    <span class="now-later">
                                                        <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                        <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                        <span class="now" *ngIf="item.bookingType == 3">Multiple
                                                            Shift</span>
                                                    </span>

                                                </div>
                                                <div class="col-md-12">
                                                    <div class="customerBookingDetails">
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/maintenance.svg"
                                                                    class="svgIcons" width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Category :
                                                            </span>
                                                            <span
                                                                class="col-md-7"><strong>{{item.category}}</strong></span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/worker.svg" class="svgIcons"
                                                                    width="12px" height="12px"> </span>
                                                            <span class="svgIcons  col-md-3 padding-0">Assigned For :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong
                                                                    *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                                <span
                                                                    *ngIf="item.providerData.hasOwnProperty('name') === false">--</span>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/calendar.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Booking Time :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/money.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons col-md-3 padding-0">Total Price :
                                                            </span>
                                                            <span class=" col-md-7"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- &nbsp; <img src="assets/svgImages/user.svg" class="svgIcons" width="12px" height="12px">  
                                                           <span class="customer-name">&nbsp; {{item.customerData.firstName}} {{item.customerData.lastName}} ({{item.customerData.phone}})</span>
                           
                                                           <div class="job_id_main_body">
                                                               <div class="mb-2 mt-2">
                                                                   <img src="assets/svgImages/maintenance.svg" class="svgIcons" width="12px" height="12px"> &nbsp;
                                                                   <span class="job_id_zone service"><span class="svgIcons">Category : </span><strong class="customerData">{{item.category}}</strong></span>
                                                               </div>
                                                               <div class="mb-2 mt-2" *ngIf="item.providerData && item.providerData.name">
                                                                   <img src="assets/svgImages/worker.svg" class="svgIcons" width="12px" height="12px"> &nbsp;
                                                                   <span class="job_id_zone service"><span class="svgIcons">Assigned For : </span><strong class="customerData">{{item.providerData.name}}</strong></span>
                                                               </div>
                                                                   <div class="job_id_date col-md-12 zeroPadding">
                                                                       <img src="assets/svgImages/calendar.svg" class="svgIcons" width="12px" height="12px"> &nbsp;
                                                                       <span class="points_text svgIcons">Booking Time : </span>
                                                                       <span class="job_id_time"><strong class="customerData">{{item.bookingRequestedFor*1000 | date:'medium'}}</strong></span>
                                                                   </div>
                                                                   <div class="">
                                                                        <img src="assets/svgImages/money.svg" class="svgIcons" width="12px" height="12px"> &nbsp;
                                                                        <span class="points_text svgIcons">
                                                                         Total Price :</span>
                                                                        <span class="job_fare text-right"><strong class="customerData"> {{item.currencySymbol}} {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                                                        <div class="now-later">
                                                                            <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                                            <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                                            <span class="now" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                                                        </div>
                                                                   </div>
                                                           </div> -->


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="col-md-12 left_window_body BookingList text-center" style="background: #bfc5af;">
                        <p class="sorting-header">Later Booking List</p>
                    </div>
                    <div class="scroll  col-md-12 padding-0" id="assigned" (scroll)="onScroll($event,3)">
                        <div *ngIf="assigned.length <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>
                        <div class="BookingsContent">

                            <div *ngFor="let item of assigned"
                                [ngClass]="{'bookingType2':item.bookingType == 2, 'bookingType3':item.bookingType == 3}"
                                id="booking-{{item.bookingId}}">
                                <div *ngIf="item.bookingType == 2 || item.bookingType == 3"
                                    class="job_id_header col-md-12 padding-0">
                                    <div class="job_id_header_left col-md-12 padding-0">
                                        <div class="col-md-4 padding-0">
                                            <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                            <span title="Dispatch count" *ngIf="item.dispatchCount"
                                                class="dispatch_attempt">{{item.dispatchCount}}</span>
                                        </div>
                                        <div class="col-md-4 padding-0 text-center">
                                            <span class="job_id_task">{{item.statusMsg}}</span>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="row pull-right">
                                                <div class="col-md-3 padding-5">
                                                    <a title="CancelBooking" (click)="cancelBooking(item,3)"
                                                        class="details_but" style="color:#fff">
                                                        <img src="assets/svgImages/cancel.svg" width="12px"
                                                            height="12px" title="CancelBooking">
                                                    </a>
                                                </div>
                                                <div class="col-md-3 padding-5">
                                                    <a title="Details" (click)="getBookingDetailsPopUp(item)"
                                                        class="details_but danger" style="color:#fff;">
                                                        <img src="assets/svgImages/menu3.svg" width="12px" height="12px"
                                                            title="Booking Details">
                                                    </a>
                                                </div>
                                                <!-- <div class="col-md-3 padding-5" *ngIf="item.bookingModel != 3 && item.bookingType != 3">
                                                <a  title="Edit Cart" (click)="editingCards(item)" class="details_but danger" style="color:#fff">
                                                    <img src="assets/svgImages/cart2.svg" width="12px" height="12px" title="Show Cart">
                                                </a>
                                            </div> -->
                                                <div class="col-md-3 padding-5" *ngIf="item.status != 9">
                                                    <a title="Unassign" (click)="openUnassignPopUp(item)"
                                                        class="details_but danger1"
                                                        style="color:#fff;background: transparent;">
                                                        <img src="assets/svgImages/exchange.svg" width="12px"
                                                            height="12px" title="Not Interested For Booking">
                                                    </a>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="col-md-12 userDetails">
                                        <div *ngIf="item.bookingType === 2 || item.bookingType === 3"
                                            class="job_id_body" (click)="assignedProvider(item)">
                                            <div class="row customerName">
                                                <div class="col-md-12 zeroPadding margin-CustomerName">
                                                    &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons"
                                                        width="12px" height="12px">
                                                    <span class="customer-name">&nbsp;{{item.customerData.firstName}}
                                                        {{item.customerData.lastName}} ({{item.customerData.phone}})
                                                    </span>
                                                    <span class="now-later">
                                                        <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                        <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                        <span class="now" *ngIf="item.bookingType == 3">Multiple
                                                            Shift</span>
                                                    </span>

                                                </div>
                                                <div class="col-md-12">
                                                    <div class="customerBookingDetails">
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/maintenance.svg"
                                                                    class="svgIcons" width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Category :
                                                            </span>
                                                            <span
                                                                class="col-md-7"><strong>{{item.category}}</strong></span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/worker.svg" class="svgIcons"
                                                                    width="12px" height="12px"> </span>
                                                            <span class="svgIcons  col-md-3 padding-0">Assigned For :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong
                                                                    *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                                <span
                                                                    *ngIf="item.providerData.hasOwnProperty('name') === false">--</span>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/calendar.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons  col-md-3 padding-0">Booking Time :
                                                            </span>
                                                            <span class=" col-md-7">
                                                                <strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong>
                                                            </span>
                                                        </p>
                                                        <p class="marginBtmBooking row">
                                                            <span class="col-md-1 padding-0"><img
                                                                    src="assets/svgImages/money.svg" class="svgIcons"
                                                                    width="12px" height="12px"></span>
                                                            <span class="svgIcons col-md-3 padding-0">Total Price :
                                                            </span>
                                                            <span class=" col-md-7"><strong>{{item.currencySymbol}}
                                                                    {{item.totalAmount| number:'1.2-2'}}</strong></span>
                                                        </p>
                                                    </div>
                                                    <!-- <div class="row">
                                                                    <div class="col-md-1 userDetail">
                                                                       <p><img src="assets/svgImages/maintenance.svg" class="svgIcons" width="12px" height="12px"></p>
                                                                       <p><img src="assets/svgImages/worker.svg" class="svgIcons" width="12px" height="12px"></p>
                                                                       <p><img src="assets/svgImages/calendar.svg" class="svgIcons" width="12px" height="12px"></p>
                                                                       <p><img src="assets/svgImages/money.svg" class="svgIcons" width="12px" height="12px"></p>
                                                                    </div>
                                                                    <div class="col-md-3 zeroPadding userDetail">
                                                                        <p class="svgIcons">Category :</p>
                                                                        <p class="svgIcons">Assigned For :</p>
                                                                        <p class="svgIcons">Booking Time :</p>
                                                                        <p class="svgIcons">Total Price :</p>
                                                                    </div>
                                                                    <div class="col-md-7 userDetail zeroPadding">
                                                                        <p><strong>{{item.category}}</strong></p>
                                                                        <p>
                                                                            <strong *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                                            <span *ngIf="item.providerData.hasOwnProperty('name') === false">--</span>
                                                                        </p>
                                                                        <p><strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong></p>
                                                                        <p><strong>{{item.currencySymbol}} {{item.totalAmount| number:'1.2-2'}}</strong></p>
                                                                    </div>
                                                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>

<!-- map -->

<div class="mapDiv mapFull folded">
    <div id='dispatchermap' style="height:94vh"></div>
</div>

<!-- provider -->
<div class="providerList">
    <div class="right_window_header col-md-12 padding-0">
        <div class="col-md-11 right-header-text" style="color:black;">
            Providers
        </div>
        <div class="col-md-1 closeInfo" id="closeInfo" style="top:10px;">
            <a (click)="closeInfoWindow()">
                <span><img src="assets/svgImages/cancel.svg" width="12px" height="12px" title="Close"></span>
            </a>
        </div>
        <!-- <mat-menu #menu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="RatingList">Rating</button>
            <button mat-menu-item [matMenuTriggerFor]="DistanceList">Distance</button>
            <button mat-menu-item [matMenuTriggerFor]="ReviewList">Review Count</button>
            <button mat-menu-item [matMenuTriggerFor]="JobList">Current Jobs</button>
        </mat-menu>
        <mat-menu #RatingList="matMenu">
            <button mat-menu-item>Highest</button>
            <button mat-menu-item>Lowest</button>
        </mat-menu>
        <mat-menu #DistanceList="matMenu">
            <button mat-menu-item>Far</button>
            <button mat-menu-item>Near</button>
        </mat-menu>
        <mat-menu #ReviewList="matMenu">
            <button mat-menu-item>Most Reviewed</button>
            <button mat-menu-item>Least Reviewed</button>
        </mat-menu>
        <mat-menu #JobList="matMenu">
            <button mat-menu-item>Highest Jobs</button>
            <button mat-menu-item>Least Jobs</button>
        </mat-menu> -->
    </div>
    <div class="col-md-12 padding-0">
        <mat-tab-group (selectedIndexChange)="selectedIndexChange($event)" [selectedIndex]="ProviderSelectedIndex">
            <mat-tab label="Available">
                <ng-template mat-tab-label>
                    <div class="joblist orderlist text-center padding-0 active">
                        <!-- <p class="numbers">{{available ? available.online.length : 0}}</p> -->
                        <p class="booking-status-header"><span
                                class="count danger1">{{available ? available.length : 0}}</span> Available</p>
                    </div>
                </ng-template>
                <div class="col-md-12 padding-0 left_window_header">
                    <div class="col-md-10">
                        <mat-form-field class="w100">
                            <input matInput placeholder="Search Provider" [(ngModel)]="providerFilterString"
                                [formControl]="providerSearchController" (ngModelChange)="searchProviderWithBookingId()"
                                autocomplete="off">
                            <!-- <mat-hint><span style="color:#fff">search by Order-Id/ Customer Name/ Email/ Phone</span></mat-hint> -->
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 padding-0 text-center marginTop10" (click)="refreshProviderSearch()">
                        <i id="search_pickerOne" title="refresh" style="cursor:pointer" class="fas fa-sync-alt"></i>
                        <!-- <span id="search_pickerOne" title="refresh" class="glyphicon glyphicon-refresh icn_left" style="cursor:pointer" (click)="refreshProviderSearch()"></span> -->
                    </div>
                </div>
                <div class="col-md-12 padding-0">
                    <mat-progress-bar *ngIf="mode == 'query'" class="example-margin" [color]="color" [mode]="mode"
                        [value]="value" [bufferValue]="bufferValue">
                    </mat-progress-bar>
                </div>
                <div class="col-md-12 padding-0">
                    <div *ngIf="available && available.length < 0" style="margin-top:15px">
                        <img src="assets/images/empty.png" width="100%" height="500px">
                    </div>
                    <!-- <div class="tab-content bglight"> -->
                    <!-- <div id="type4" class="tab-pane fade in active"> -->
                    <div class="col-md-12 zeroPadding scroll scrollBig" *ngIf="available && available.length > 0">
                        <div *ngFor="let item of available; let i = index;">
                            <div class="eachProvider eachProvider-{{i}} available" id="eachProvider-{{item._id}}">
                                <div class="provider-profile-main-container">
                                    <div class="provider-profile-image image-status-div" (click)="viewDetails(item)">
                                        <img src="{{item.image}}" alt="{{item.name}}" height="42" width="42"
                                            class="profile-pic aws-image" id="image-{{item.user}}">
                                        <div class="status-indicator">
                                            <div>
                                                <span class="indicator indicator-active pull-right">dd</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name-phone-div" (click)="dispatchJobHandler(item)">
                                        <p class="div_display provider-name">{{item.name}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.email">
                                            {{item.email}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.phone">
                                            ({{item.phone}})
                                        </p>
                                    </div>
                                    <div class="jobCounts">
                                        <!-- <p class="lastSeen" *ngIf="ServiceName">{{ServiceName}}</p> -->
                                        <p class="tot_job">{{item.bookingCount}} | jobs</p>
                                        <p class="lastSeen">{{item.time}}</p>

                                    </div>
                                    <div class="extraInfo col-md-12 padding-0">
                                        <div class="batry col-md-2 padding-0">
                                            <span>
                                                <img src="assets/images/star.png" style="width:13px">
                                            </span>
                                            <span>{{item.averageRating | number:'1.1-2'}}</span>
                                        </div>
                                        <div class="batry col-md-2 padding-0">
                                            <span>
                                                <img src="assets/images/battery.png">
                                            </span>
                                            <span *ngIf="item.batteryPercentage">{{item.batteryPercentage}}%</span>
                                            <span *ngIf="!item.batteryPercentage"> -- </span>
                                        </div>
                                        <div class="locChk col-md-2 padding-0">
                                            <span>
                                                <img src="assets/images/compass.png">
                                            </span>
                                            <span>On</span>
                                        </div>
                                        <div class="devSoft col-md-2 padding-0">
                                            <span>
                                                <img src="assets/images/smartphone.png">
                                            </span>
                                            <span *ngIf="item.deviceType == 2">
                                                <img src="assets/images/android.png">
                                            </span>
                                            <span *ngIf="item.deviceType == 1">
                                                <img src="assets/images/apple.png">
                                            </span>
                                        </div>
                                        <div class="devVer col-md-2 padding-0">
                                            <span>
                                                <img src="assets/images/hand-gesture.png">
                                            </span>
                                            <span>{{item.appversion}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Unavailable">
                <ng-template mat-tab-label>
                    <div class="joblist orderlist text-center padding-0 active">
                        <!-- <p class="numbers">{{unavailableCount}}</p> -->
                        <p class="booking-status-header"><span
                                class="count danger1">{{unavailable ? unavailable.length : 0}}</span>Unavailable</p>
                    </div>
                </ng-template>
                <div class="col-md-12 padding-0 left_window_header">
                    <div class="col-md-10">
                        <mat-form-field class="w100">
                            <input matInput placeholder="Search Provider" [(ngModel)]="providerFilterString"
                                [formControl]="providerSearchController" (ngModelChange)="searchProviderWithBookingId()"
                                autocomplete="off">
                            <!-- <mat-hint><span style="color:#fff">search by Order-Id/ Customer Name/ Email/ Phone</span></mat-hint> -->
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 padding-0 text-center marginTop10" (click)="refreshProviderSearch()">
                        <i id="search_pickerOne" title="refresh" style="cursor:pointer" class="fas fa-sync-alt"></i>
                        <!-- <span id="search_pickerOne" title="refresh" class="fas fa-sync-alt icn_left" style="cursor:pointer" (click)="refreshProviderSearch()"></span> -->
                    </div>
                </div>
                <div *ngIf="unavailableCount == 0" style="margin-top:15px">
                    <img src="assets/images/empty.png" width="100%" height="500px">
                </div>
                <div class="col-md-12 zeroPadding scroll scrollBig" *ngIf="unavailableCount > 0">
                    <div *ngFor="let item of unavailable; let i = index;"
                        class="eachProvider eachProvider-{{i}} available" id="eachProvider-{{item._id}}">
                        <div class="provider-profile-main-container">
                            <div class="provider-profile-image image-status-div" (click)="viewDetails(item)">
                                <img src="{{item.image}}" alt="{{item.name}}" height="42" width="42"
                                    class="profile-pic aws-image" id="image-{{item.user}}">
                                <div class="status-indicator">
                                    <div>
                                        <span class="indicator indicator-active pull-right">dd</span>
                                    </div>
                                </div>
                            </div>
                            <div class="name-phone-div" (click)="dispatchJobHandler(item)">
                                <p class="div_display provider-name">{{item.name}}
                                </p>
                                <p class="provider-phone" *ngIf="item.email">
                                    {{item.email}}
                                </p>
                                <p class="provider-phone" *ngIf="item.phone">
                                    ({{item.phone}})
                                </p>
                            </div>
                            <div class="jobCounts">
                                <p class="tot_job">{{item.bookingCount}} | jobs</p>
                                <p class="lastSeen">{{item.time}}</p>

                            </div>
                            <div class="extraInfo">
                                <div class="batry fLeft25">
                                    <span>
                                        <img src="assets/images/battery.png">
                                    </span>
                                    <span *ngIf="item.batteryPercentage">{{item.batteryPercentage}}%</span>
                                    <span *ngIf="!item.batteryPercentage"> -- </span>
                                </div>
                                <div class="locChk fLeft25">
                                    <span>
                                        <img src="assets/images/compass.png">
                                    </span>
                                    <span>On</span>
                                </div>
                                <div class="devSoft fLeft25">
                                    <span>
                                        <img src="assets/images/smartphone.png">
                                    </span>
                                    <span *ngIf="item.deviceType == 2">
                                        <img src="assets/images/android.png">
                                    </span>
                                    <span *ngIf="item.deviceType == 1">
                                        <img src="assets/images/apple.png">
                                    </span>
                                </div>
                                <div class="devVer fLeft25">
                                    <span>
                                        <img src="assets/images/hand-gesture.png">
                                    </span>
                                    <span>{{item.appversion}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>


<div class="ProviderInfo" style="display:none">
    <!-- <div class="right_marker"><span class="glyphicon glyphicon-menu-right marker_icns icn_right"></span> </div> -->

    <div *ngIf="selectedProvider">
        <div class="right_window_header agent_heading col-md-12 padding-0">
            <div class="closeInfo" id="closeInfo">
                <a (click)="closeInfoWindow()">
                    <span><img src="assets/svgImages/cancel.svg" width="12px" height="12px" title="Close"></span>
                </a>
            </div>
            <div class="right_header_name">
                <div class="row">
                    <div class="col-md-3">
                        <div class="agent_img">
                            <img src="{{selectedProvider.image}}" src="{{selectedProvider.image}}">
                        </div>
                    </div>
                    <div class="col-md-2 zeroPadding">
                        <p class="right_header_txt">
                            <img src="assets/svgImages/roundUser.svg" class="opacityChange" width="12px"
                                height="12px">&nbsp;Name :
                        </p>
                        <p class="right_header_txt">
                            <img src="assets/svgImages/email.svg" class="opacityChange" width="12px"
                                height="12px">&nbsp;Email :
                        </p>
                        <p class="right_header_txt">
                            <img src="assets/svgImages/phone.svg" class="opacityChange" width="12px"
                                height="12px">&nbsp;Phone :
                        </p>
                        <p class="right_header_txt">
                            <img src="assets/svgImages/offline.svg" class="opacityChange" width="12px"
                                height="12px">&nbsp;Status :
                        </p>
                    </div>
                    <div class="col-md-6 zeroPadding">
                        <p class="right_header_txt"> <strong>{{selectedProvider.name}}</strong></p>
                        <p class="right_header_txt"> <strong>{{selectedProvider.email}}</strong></p>
                        <p class="right_header_txt"> <strong>{{selectedProvider.phone}}</strong></p>
                        <p class="right_header_txt">
                            <strong>
                                <span *ngIf="selectedProvider.status == 3">
                                    <span id="status_indic" style="background:rgb(0, 138, 0)">dd</span>
                                    <span id="status_text"><strong>Online</strong></span>
                                </span>
                                <span *ngIf="selectedProvider.status == 4">
                                    <span id="status_indic" style="background:rgb(132, 134, 130)">dd</span>
                                    <span id="status_text"><strong>Offline</strong></span>
                                </span>
                                <span *ngIf="selectedProvider.status == 7">
                                    <span id="status_indic" style="background:rgb(226, 122, 4)">dd</span>
                                    <span id="status_text"><strong>Inactive</strong></span>
                                </span>
                                <span *ngIf="selectedProvider.status == 8">
                                    <span id="status_indic" style="background:rgb(150, 5, 5)">dd</span>
                                    <span id="status_text"><strong>Logged Out</strong></span>
                                </span>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>

            <span></span>
        </div>
        <div class="provider-profile-details-footer">
            <div class="providerStatus">
                <div class="device-status">
                    <p class="device-name">Battery</p>
                    <p *ngIf="selectedProvider.batteryPercentage" class="device-avail">
                        {{selectedProvider.batteryPercentage}}% </p>
                    <p *ngIf="!selectedProvider.batteryPercentage" class="device-avail"> -- </p>
                </div>
                <div class="device-status">
                    <p class="device-name">Location</p>
                    <p *ngIf="selectedProvider.locationCheck && selectedProvider.locationCheck == 1"
                        class="device-avail"> On </p>
                    <p *ngIf="selectedProvider.locationCheck && selectedProvider.locationCheck == N/A || selectedProvider.locationCheck == 0"
                        class="device-avail"> Off </p>
                </div>
                <div class="device-status">
                    <p class="device-name">Version</p>
                    <p *ngIf="selectedProvider.appversion" class="device-avail">{{selectedProvider.appversion}} </p>
                    <p *ngIf="!selectedProvider.appversion" class="device-avail"> -- </p>
                </div>
                <div class="device-status">
                    <p class="device-name">Device</p>
                    <p *ngIf="selectedProvider.deviceType == 1" class="device-avail">IOS</p>
                    <p *ngIf="selectedProvider.deviceType == 2" class="device-avail">Android</p>
                    <p *ngIf="!selectedProvider.deviceType" class="device-avail"> -- </p>
                    <!-- <p class="device-avail" *ngIf="eachProviderDetails.device_type == 2">Android</p> -->
                </div>
            </div>
        </div>
        <!-- <div *ngIf="noBooking" class="no-providers">
            No Bookings available
        </div> -->
        <div *ngIf="selectedProviderTimeline" class="col-md-12 padding-0">
            <div class="col-md-12 padding-right-0 highlight">
                <h5>Activity Timeline(Provider-Id:{{selectedProvider._id}})</h5>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <ul class="timeline" *ngFor="let timeList of selectedProviderTimeline" style="padding:2px 0px;">
                        <li class="timeline-event" *ngIf="timeList.status == 1">
                            <span class="timeline-event-date">Booking Requested At</span>
                            <!-- <span class="timeline-event-title">
                                       {{timeList.timeStamp *1000| date:'medium'}} </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 2">
                            <span class="timeline-event-date">Booking Received At</span>
                            <!-- <span class="timeline-event-title">
                                        &nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}} </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 3">
                            <span class="timeline-event-date">Accepted At</span>
                            <!-- <span class="timeline-event-title">
                                        &nbsp; &nbsp;&nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}} </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 4">
                            <span class="timeline-event-date">Rejected At</span>
                            <!-- <span class="timeline-event-title">
                                        &nbsp; &nbsp;&nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}} </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 5">
                            <span class="timeline-event-date">Expired At</span>
                            <!-- <span class="timeline-event-title">
                                        &nbsp; &nbsp;&nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}}
                                    </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 6">
                            <span class="timeline-event-date">On The Way</span>
                            <!-- <span class="timeline-event-title">
                                        &nbsp; &nbsp;&nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}}
                                    </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 7">
                            <span class="timeline-event-date">Arrived</span>
                            <!-- <span class="timeline-event-title">
                                    &nbsp; &nbsp;&nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}}
                                    </span> -->
                        </li>

                        <li class="timeline-event" *ngIf="timeList.status == 8">
                            <span class="timeline-event-date">Started</span>
                            <!-- <span class="timeline-event-title">
                                    &nbsp; &nbsp;&nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}}
                                    </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 9">
                            <span class="timeline-event-date">Completed</span>
                            <!-- <span class="timeline-event-title">
                                    &nbsp; &nbsp;&nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}}
                                    </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 10">
                            <span class="timeline-event-date">Invoice Raised</span>
                            <!-- <span class="timeline-event-title">
                                    &nbsp; &nbsp;&nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}}
                                    </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 11">
                            <span class="timeline-event-date">Booking Cancelled By Provider</span>
                            <!-- <span class="timeline-event-title">
                                    &nbsp; &nbsp;&nbsp; &nbsp;{{timeList.timeStamp *1000| date:'medium'}}
                                    </span> -->
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 12">
                            <span class="timeline-event-date">Booking Cancelled By Customer</span>
                            <!-- <span class="timeline-event-title">
                                    &nbsp; &nbsp;&nbsp; &nbsp; {{timeList.timeStamp *1000| date:'medium'}}
                                    </span> -->
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <ul class="timeline" *ngFor="let timeList of selectedProviderTimeline" style="padding:2px 0px;">
                        <li class="timeline-event" *ngIf="timeList.status == 1">
                            <!-- <span class="timeline-event-date">Booking Requested At</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}} </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 2">
                            <!-- <span class="timeline-event-date">Booking Received At</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}} </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 3">
                            <!-- <span class="timeline-event-date">Accepted At</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}} </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 4">
                            <!-- <span class="timeline-event-date">Rejected At</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}} </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 5">
                            <!-- <span class="timeline-event-date">Expired At</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}}
                            </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 6">
                            <!-- <span class="timeline-event-date">On The Way</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}}
                            </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 7">
                            <!-- <span class="timeline-event-date">Arrived</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}}
                            </span>
                        </li>

                        <li class="timeline-event" *ngIf="timeList.status == 8">
                            <!-- <span class="timeline-event-date">Started</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}}
                            </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 9">
                            <!-- <span class="timeline-event-date">Completed</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}}
                            </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 10">
                            <!-- <span class="timeline-event-date">Invoice Raised</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}}
                            </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 11">
                            <!-- <span class="timeline-event-date">Booking Cancelled By Provider</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}}
                            </span>
                        </li>
                        <li class="timeline-event" *ngIf="timeList.status == 12">
                            <!-- <span class="timeline-event-date">Booking Cancelled By Customer</span> -->
                            <span class="timeline-event-title">
                                {{timeList.timeStamp *1000| date:'medium'}}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- </div> -->
        </div>
        <div class="col-md-12 padding-0">
            <div class="col-md-12 padding-right-0 highlight">
                <h5>Current Jobs</h5>
            </div>
            <div class="col-md-12 spinner" *ngIf="!selectedProviderBooking">
                <mat-spinner></mat-spinner>
            </div>
            <div class="col-md-12 padding-0 providerbooklist scrollStyle" *ngIf="selectedProviderBooking">
                <div *ngIf="selectedProviderBooking.length == 0" class="col-md-12 padding-0 no-providers">
                    <img src="assets/images/empty.png" width="100%" height="100%">
                </div>
                <div class="BookingsContent">
                    <div *ngFor="let item of selectedProviderBooking">
                        <div class="job_id_header col-md-12 padding-0">
                            <div class="job_id_header_left col-md-12 padding-0">
                                <div class="col-md-5 padding-0">
                                    <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                    <span title="Dispatch count" *ngIf="item.dispatchCount"
                                        class="dispatch_attempt">{{item.dispatchCount}}</span>
                                </div>
                                <div class="col-md-5 padding-0 text-center">
                                    <span class="job_id_task">{{item.statusMsg}}</span>
                                </div>
                                <div class="col-md-2">
                                    <div class="row pull-right">
                                        <div class="col-md-4 padding-5">
                                            <a title="Details" (click)="getBookingDetailsPopUp(item)"
                                                class="details_but danger" style="color:#fff;">
                                                <img src="assets/svgImages/menu3.svg" width="12px" height="12px"
                                                    title="Booking Details">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 userDetails">
                                <div class="job_id_body">
                                    <div class="row customerName">
                                        <div class="col-md-12 zeroPadding margin-CustomerName">
                                            &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons"
                                                width="12px" height="12px">
                                            <span class="customer-name">&nbsp;{{item.customerData.firstName}}
                                                {{item.customerData.lastName}} ({{item.customerData.phone}})
                                            </span>
                                            <span class="now-later">
                                                <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                <span class="now" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                            </span>

                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-1 userDetail">
                                                    <p><img src="assets/svgImages/maintenance.svg" class="svgIcons"
                                                            width="12px" height="12px"></p>
                                                    <p><img src="assets/svgImages/worker.svg" class="svgIcons"
                                                            width="12px" height="12px"></p>
                                                    <p><img src="assets/svgImages/calendar.svg" class="svgIcons"
                                                            width="12px" height="12px"></p>
                                                    <p><img src="assets/svgImages/money.svg" class="svgIcons"
                                                            width="12px" height="12px"></p>
                                                </div>
                                                <div class="col-md-3 zeroPadding userDetail">
                                                    <p class="svgIcons">Category :</p>
                                                    <p class="svgIcons">Assigned For :</p>
                                                    <p class="svgIcons">Booking Time :</p>
                                                    <p class="svgIcons">Total Price :</p>
                                                </div>
                                                <div class="col-md-7 userDetail zeroPadding">
                                                    <p><strong>{{item.category}}</strong></p>
                                                    <p>
                                                        <strong
                                                            *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                        <span
                                                            *ngIf="item.providerData.hasOwnProperty('name') === false">--</span>
                                                    </p>
                                                    <p><strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong>
                                                    </p>
                                                    <p><strong>{{item.currencySymbol}}
                                                            {{item.totalAmount| number:'1.2-2'}}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="jobdetailModal" *ngIf="jobDetailsByBooking" role="dialog"
    style="padding:0px!important ; padding-left:0px!important ;">
    <div class="bookingDeatailsBody">
        <div id="details_task_header clear-fix">
            <div class="row">
                <div class="col-md-6">
                    <span id="details_task_header_left">Booking ID:{{jobDetailsByBooking.bookingId}}</span>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" data-dismiss="modal" title="Close">&times;</button>
                </div>
            </div>

        </div>
        <div class="clear-fix">
            <mat-tab-group>
                <mat-tab label="Job Details">
                    <div>
                        <div id="details_task_job_details_top row">
                            <div class="left-job-details width-50 col-md-6" style="margin-top:15px;">
                                <div class="location clear-fix">
                                    <span class="points_text"><b>Job Location</b></span>
                                    <br>

                                    <div>
                                        <span class="job_id_add2">
                                            {{jobDetailsByBooking.addLine1}}</span>
                                    </div>
                                </div>
                                <div class="booking booking-time clear-fix"
                                    *ngIf="jobDetailsByBooking.bookingRequestedAt">
                                    <span class="points_text"><b>Booking Started At</b></span>
                                    <br>
                                    <span>{{jobDetailsByBooking.bookingRequestedAt * 1000 | date : 'medium'}}</span>
                                </div>
                                <div class="booking booking-time clear-fix">
                                    <span class="points_text"><b>Actual Booking Accepted At</b></span>
                                    <br>
                                    <span>{{jobDetailsByBooking.bookingRequestedFor * 1000 | date : 'medium'}}</span>
                                </div>
                                <div class="booking booking-time clear-fix">
                                    <span class="points_text"><b>Payment Type</b></span>
                                    <br>
                                    <span
                                        *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.paymentMethodText">{{jobDetailsByBooking.accounting.paymentMethodText}}</span>
                                </div>
                                <div class="booking booking-time clear-fix">
                                    <span class="points_text"><b>Booking Type</b></span>
                                    <br>
                                    <span
                                        *ngIf="jobDetailsByBooking.bookingType == 1 && jobDetailsByBooking.bookingModel != 3">Book
                                        Now</span>
                                    <span
                                        *ngIf="jobDetailsByBooking.bookingType == 2 && jobDetailsByBooking.bookingModel != 3">Book
                                        Later</span>
                                    <span
                                        *ngIf="jobDetailsByBooking.bookingType == 3 && jobDetailsByBooking.bookingModel != 3">Repeat
                                        Booking</span>
                                    <span *ngIf="jobDetailsByBooking.bookingModel == 3">Bid Booking</span>
                                </div>
                                <div class="booking booking-time clear-fix"
                                    *ngIf="jobDetailsByBooking.bookingType === 3 &&  jobDetailsByBooking.status !== 3">
                                    <span class="points_text"><b>Booking Ending Date and Time</b></span>
                                    <br>
                                    <span>{{jobDetailsByBooking.bookingEndtime * 1000 | date : 'medium'}}</span>

                                </div>
                                <div class="booking booking-time clear-fix"
                                    *ngIf="jobDetailsByBooking.bookingType === 3 && jobDetailsByBooking.status !== 3">
                                    <span class="points_text"><b>No Of Shifts</b></span>
                                    <br>
                                    <span>{{jobDetailsByBooking.accounting.totalShiftBooking}}</span>
                                </div>

                            </div>
                            <div class="width-50 col-md-6" style="margin-top:15px;">
                                <div class="details_task_inner_details" style="overflow: hidden">

                                    <div class="details_task_ordered_det">
                                        <span class="points_text"><b>Customer Details</b></span>
                                        <br>
                                        <i class="fas fa-user"></i>
                                        <span class="details_task_ordered_name">
                                            {{jobDetailsByBooking.customerData.firstName}}
                                            {{jobDetailsByBooking.customerData.lastName}} </span>
                                        <br>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-phone-alt"></i>

                                        <span>
                                            {{jobDetailsByBooking.customerData.phone}}
                                        </span>
                                    </div>

                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-envelope"></i>
                                        <span>{{jobDetailsByBooking.customerData.email}}</span>
                                    </div>
                                </div>
                                <br>
                                <div class="details_task_inner_details" style="overflow: hidden"
                                    *ngIf="jobDetailsByBooking.providerData.name">

                                    <div class="details_task_ordered_det">
                                        <span class="points_text"><b>Provider Details</b></span>
                                        <br>
                                        <i class="fas fa-user"></i>
                                        <span class="details_task_ordered_name">
                                            {{jobDetailsByBooking.providerData.name}}</span>
                                        <br>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-phone-alt"></i>

                                        <span>
                                            {{jobDetailsByBooking.providerData.phone}}
                                        </span>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-envelope"></i>

                                        <span>
                                            {{jobDetailsByBooking.providerData.email}}
                                        </span>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 1'>On</span>
                                        <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 0'>Off</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Povide Details are added -->
                            <!-- <div class="width-25 col-md-4" style="margin-top:15px;" *ngIf='jobDetailsByBooking.providerData != true'>                                
                                <div class="details_task_inner_details" style="overflow: hidden">

                                    <div class="details_task_ordered_det">
                                        <span class="points_text">Provider Details</span>
                                        <br>
                                        <i class="fas fa-user"></i>
                                        <span class="details_task_ordered_name">
                                            {{jobDetailsByBooking.providerData.name}}</span>
                                        <br>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-phone-alt"></i>

                                        <span>
                                            {{jobDetailsByBooking.providerData.phone}}
                                        </span>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-envelope"></i>

                                        <span>
                                            {{jobDetailsByBooking.providerData.email}}
                                        </span>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 1'>On</span>
                                        <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 0'>Off</span>
                                    </div>
                                </div>
                            </div> -->

                        </div>
                        <div id="details_job_services" class="mrt-15 clear-fix">
                            <div class="width-50 fl-left">
                                <span class="points_text"><b>Service Category Name:</b></span>
                                <br>
                                <span class="details_task_ordered_name font12">
                                    {{jobDetailsByBooking.category}}
                                </span>
                            </div>
                            <div *ngIf="itemList" class="col-md-12 padding-0">

                                <div class="col-md-12 padding-0">
                                    <div class="col-md-12 subheader padding-0 points_text">
                                        <b>Booking Details:</b>
                                    </div>
                                    <br />
                                    <div class="col-md-12 padding-0">
                                        <div class="pad10">
                                            <div class="col-md-12 padding-0 mrg5">

                                                <div class="col-md-5 points_text">
                                                    <b>Service Name</b>
                                                </div>
                                                <div class="col-md-2 text-right points_text">
                                                    <b>Quantity</b>
                                                </div>
                                                <div class="col-md-3 text-right points_text">
                                                    <b>Unit Price</b>
                                                </div>
                                                <div class="col-md-2 text-right points_text">
                                                    <b>Total</b>
                                                </div>

                                            </div>
                                            <div class="col-md-12 booking-time padding-0 mrg5" *ngIf="!itemList">
                                                <div class="col-md-12 text-center">
                                                    <p>No Items Found</p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 padding-0 mrg5" *ngFor="let item of itemList">

                                                <div class="col-md-5 text-left booking-time">
                                                    <p>{{item.serviceName}}</p>
                                                    <!-- <p class="text-muted productComments">2 liter</p> -->
                                                </div>
                                                <div class="col-md-2 text-right booking-time">{{item.quntity}}</div>
                                                <div class="col-md-3 text-right booking-time">
                                                    {{ jobDetailsByBooking.currencySymbol }}
                                                    {{item.unitPrice | number:'2.1-3'}}</div>
                                                <div class="col-md-2 text-right booking-time">
                                                    {{ jobDetailsByBooking.currencySymbol }}
                                                    {{item.unitPrice * item.quntity| number:'2.1-3'}}</div>
                                            </div>

                                            <div
                                                *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.visitFee && jobDetailsByBooking.accounting.visitFee > 0">

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Visit Fees:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.visitFee | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.travelFee && jobDetailsByBooking.accounting.travelFee > 0">

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Travel Fees:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.travelFee | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.discount && jobDetailsByBooking.accounting.discount > 0">

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Discount:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.discount | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div
                                                *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.dues && jobDetailsByBooking.accounting.dues > 0">

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Dues:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.dues | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div
                                                *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.additionalServiceFee && jobDetailsByBooking.accounting.additionalServiceFee > 0">

                                                <div class="col-md-12 float-right">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Additional Service Fee:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.additionalServiceFee | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="jobDetailsByBooking.additionalService && jobDetailsByBooking.additionalService.length > 0">
                                                <div *ngFor="let account of jobDetailsByBooking.additionalService">

                                                    <div class="col-md-12 float-right mt-10">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>{{account.serviceName}}:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }}
                                                            {{account.price | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <!-- <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.dues && jobDetailsByBooking.accounting.dues > 0">

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Dues:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.dues | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.discount"> 

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Discount:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.discount | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.paidByWallet == 1">

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Paid by Wallet:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.captureAmount | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="col-md-12 float-right"
                                                *ngIf="jobDetailsByBooking.bookingType === 3 && jobDetailsByBooking.status !== 3">
                                                <div class="col-md-8"></div>
                                                <div class="col-md-2 padding-0 text-right points_text">
                                                    <b>Per Shift Total Price:</b>
                                                </div>
                                                <div class="col-md-2 padding-0 text-right booking-time">
                                                    {{ jobDetailsByBooking.currencySymbol }}
                                                    {{jobDetailsByBooking.accounting.total | number:'2.1-3'}}
                                                </div>
                                            </div>
                                            <div class="col-md-12 float-right ">
                                                <div class="col-md-8"></div>
                                                <div class="col-md-2 padding-0 text-right points_text">
                                                    <b>Final Total:</b>
                                                </div>
                                                <div class="col-md-2 padding-0 text-right booking-time"
                                                    *ngIf="jobDetailsByBooking.bookingType === 3 && jobDetailsByBooking.status === 3">
                                                    {{ jobDetailsByBooking.currencySymbol }}
                                                    {{jobDetailsByBooking.accounting.total | number:'2.1-3'}}
                                                </div>
                                                <div class="col-md-2 padding-0 text-right booking-time"
                                                    *ngIf="jobDetailsByBooking.bookingType === 3 && jobDetailsByBooking.status !== 3">
                                                    {{ jobDetailsByBooking.currencySymbol }}
                                                    {{jobDetailsByBooking.accounting.total * jobDetailsByBooking.accounting.totalShiftBooking| number:'2.1-3'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Actvity Timeline">
                    <div>
                        <div class="tabs_details details_description2 ">
                            <div *ngIf="jobDetailsByBooking && jobDetailsByBooking.jobStatusLogs">
                                <div *ngFor="let timeList of jobDetailsByBooking.jobStatusLogs">
                                    <!-- expiredTime -->
                                    <div class="provider_activity_body" *ngIf="timeList.status == 1">

                                        <span class="provider_bullet_points"
                                            style="background:#d10c78 !important"></span>
                                        <span class="provider_activity_status">Booking Requested At :
                                            {{timeList.timeStamp *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 2">
                                        <span class="provider_bullet_points"
                                            style="background:#ba68c8!important"></span>
                                        <span class="provider_activity_status">Booking Received By Provider At : {{timeList.timeStamp
                                                *1000 | date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 3">

                                        <span class="provider_bullet_points"
                                            style="background:#e191bd !important"></span>
                                        <span class="provider_activity_status">Booking Accepted By Provider At :
                                            {{timeList.timeStamp *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 4">

                                        <span class="provider_bullet_points"
                                            style="background:#82094b !important"></span>
                                        <span class="provider_activity_status">Booking Rejected At : {{timeList.timeStamp
                                            *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 5">

                                        <span class="provider_bullet_points"
                                            style="background:#923656!important"></span>
                                        <span class="provider_activity_status">Booking Expired At : {{timeList.timeStamp *1000|
                                            date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>

                                    <div class="provider_activity_body" *ngIf="timeList.status == 6">
                                        <span class="provider_bullet_points"
                                            style="background:#ffc700!important"></span>
                                        <span class="provider_activity_status">Provider on the way : {{timeList.timeStamp
                                            *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 7" id="onTheWay">

                                        <span class="provider_bullet_points"
                                            style="background:#c0392b!important"></span>
                                        <span class="provider_activity_status">Provider Arrived At Location :
                                            {{timeList.timeStamp *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 8">

                                        <span class="provider_bullet_points"
                                            style="background:#FC8344!important"></span>
                                        <span class="provider_activity_status">Provider Started Job On :
                                            {{timeList.timeStamp *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 9">
                                        <span class="provider_bullet_points"
                                            style="background:#0078ff!important"></span>
                                        <span class="provider_activity_status">Job Completed By Provider At : {{timeList.timeStamp *1000 |
                                                date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 10">
                                        <span class="provider_bullet_points"
                                            style="background:#89a2bf!important"></span>
                                        <span class="provider_activity_status">Provider Raised Job Invoice At : {{timeList.timeStamp *1000 |
                                                date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 11">
                                        <span class="provider_bullet_points"
                                            style="background:#187056!important"></span>
                                        <span class="provider_activity_status">Booking Cancelled By Provider At : {{timeList.timeStamp *1000 |
                                                date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 12">
                                        <span class="provider_bullet_points"
                                            style="background:#235a24!important"></span>
                                        <span class="provider_activity_status">Booking Cancelled By Customer At : {{timeList.timeStamp *1000 |
                                            date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <div *ngIf="AnswerDetails && AnswerDetails.length > 0">
                    <mat-tab label="Answered Questions" id="AnswersList" style="display:none;">
                        <div>
                            <div class="tabs_details details_description2 ">
                                <div class="provider_activity_body">
                                    <div *ngFor="let questions of AnswerDetails; let i = index;">
                                        <span class="col-md-12 padding-0"><b>{{i+1}})&nbsp;
                                                &nbsp;{{questions.name}}</b></span><br>
                                        <span *ngIf="questions.questionType === 10">
                                            <span class="listImg" *ngIf="questions.answer.length > 0">
                                                <span class="imagsProduct"
                                                    *ngFor="let img of questions.answer; let i = index;">
                                                    <img class="sellImg sellImgs" src="{{img}}"
                                                        onError="this.src='https://d2qb2fbt5wuzik.cloudfront.net/yelowebsite/images/default-thumnail.png';">
                                                </span>
                                            </span>
                                        </span>
                                        <span *ngIf="questions.questionType === 7"
                                            class="col-md-12 padding-0 {{questions.questionType}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <img src="assets/svgImages/check.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;&nbsp;
                                            <span class="" *ngIf="questions.answer.length === 1">
                                                <span class="checkbox1">{{questions.answer[0].name}}</span>
                                            </span>
                                            <span *ngIf="questions.answer.length > 1">
                                                <span *ngFor="let checkBox of questions.answer">
                                                    <span
                                                        class="{{checkBox.name}} checkbox1">{{checkBox.name}},&nbsp;&nbsp;</span>
                                                </span>
                                            </span>
                                        </span>
                                        <span *ngIf="questions.questionType !== 7 && questions.questionType !== 10"
                                            class="col-md-12 padding-0 {{questions.questionType}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <img src="assets/svgImages/check.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;&nbsp;
                                            {{questions.answer}}</span><br>
                                        <hr style="color:black"><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </div>
            </mat-tab-group>
        </div>
    </div>
</div>

<div id="jobAcceptPopup" class="backdrop" style="display:none">
    <div class="box-small">
        <div id="loggedOut" *ngIf="selectedbooking" class="col-md-12 padding-0"
            style="background:#fff ; border-radius:4px;">
            <div class="box-header col-md-612 padding-0">
                Dispatch Booking
                <span *ngIf="selectedbooking">:{{selectedbooking.bookingId}}</span>
            </div>
            <div class="closeModal-small">
                <a (click)="closePopup('jobAcceptPopup')">
                    <i class="material-icons">&#xE5CD;</i>
                </a>
            </div>
            <div class="col-md-12 padding-0">
                <mat-progress-bar *ngIf="dispatchMode == 'buffer'" class="example-margin" [color]="color"
                    [mode]="dispatchMode" [value]="value" [bufferValue]="bufferValue">
                </mat-progress-bar>
            </div>
            <div class="box-text col-md-12 padding-0">
                Do you want to dispatch this job for
                <span *ngIf="finalizedProvider"><b>{{finalizedProvider.name}}</b></span>
            </div>

            <div class="col-md-6 padding-0 text-center">
                <button class="w100 shaded" mat-button (click)="dispatchJob(1)">Request</button>
            </div>
            <div class="col-md-6 padding-0 text-center">
                <button class="w100 energetic1" mat-button (click)="dispatchJob(2)">Assign</button>
            </div>

        </div>
    </div>
</div>

<div id="unassignPopUp" class="backdrop" style="display:none">
    <div class="box-small">
        <div id="loggedOut" *ngIf="bookingForUnassign" class="col-md-12 padding-0"
            style="background:#fff ; border-radius:4px;">
            <div class="box-header col-md-612 padding-0">
                Unassign Booking
                <span>:{{bookingForUnassign.bookingId}}</span>
            </div>
            <div class="col-md-12 padding-0">
                <mat-progress-bar *ngIf="unAssignMode == 'buffer'" class="example-margin" [color]="color"
                    [mode]="unAssignMode" [value]="value" [bufferValue]="bufferValue">
                </mat-progress-bar>
            </div>
            <div class="closeModal-small">
                <a (click)="closePopup('unassignPopUp')">
                    <i class="material-icons">&#xE5CD;</i>
                </a>
            </div>
            <div class="box-text col-md-12 padding-0">
                Do you want to unassign this job from
                <span><b>{{bookingForUnassign.providerData.name}}</b> </span>
            </div>

            <div class="col-md-6 padding-0 text-center">
                <button class="w100 shaded" mat-button style="background-color:rgb(208, 255, 208)!important;"
                    (click)="unassignBooking(bookingForUnassign.bookingId)">Unassign</button>
            </div>
            <div class="col-md-6 padding-0 text-center">
                <button class="w100 energetic1" mat-button style="background-color: #76cf95;"
                    (click)="closePopup('unassignPopUp')">Cancel</button>
            </div>

        </div>
    </div>
</div>
<div *ngIf="shiftShow == 1 || shiftShow == 2 " id="EditMultiPleshiftCart" class="backdrop">
    <div class="box-small">
        <div id="loggedOut" class="col-md-12 padding-0" style="background:#fff ; border-radius:4px;">
            <div class="box-header col-md-612 padding-0">
                <span>Edit Shift Price</span>
            </div>
            <div class="closeModal-small">
                <a (click)="closePopup('EditMultiPleshiftCart')">
                    <i class="material-icons">&#xE5CD;</i>
                </a>
            </div>
            <div *ngIf="shiftShow == 1" class="box-text col-md-12 padding-0">
                Do you want to edit shift price
            </div>

            <div *ngIf="shiftShow == 1" class="col-md-6 padding-0 text-center">
                <button class="w100 shaded" mat-button color="primary" (click)="editShiftPricePopup()">Yes</button>
            </div>
            <div *ngIf="shiftShow == 1" class="col-md-6 padding-0 text-center">
                <button class="w100 energetic1" mat-button color="primary"
                    (click)="closePopup('EditMultiPleshiftCart')">No</button>
            </div>
            <div *ngIf="shiftShow == 2" class="box-text col-md-12 padding-0">
                <div class="col-md-12 padding-0 text-center" styel="font-size:14px;">
                    Assgined Shift Price is : {{ServiceName}}
                </div>
                <mat-form-field>
                    <label class="col-md-12 text-center" styel="font-size:14px;">Enter the price here</label>
                    <input matInput [formControl]="EditedShiftPrice" [(ngModel)]="shiftPrice" style="text-align:center;"
                        class="form-control col-md-12">
                </mat-form-field>
            </div>

            <div *ngIf="shiftShow == 2" class="col-md-12 padding-0 text-center">
                <button class="shaded text-center" style="bottom:10px;" mat-button color="primary"
                    (click)="closePopup()">Submit</button>
            </div>
        </div>
    </div>
</div>
<div id="EditCardsPopup" class="backdrop" style="display:none">
    <div class="box-small">
        <div id="loggedOut" *ngIf="bookingForUnassign" class="col-md-12 padding-0"
            style="background:#fff ; border-radius:4px;">
            <div class="box-header col-md-612 padding-0">
                <span>Edit Cart</span>
            </div>
            <div class="closeModal-small">
                <a (click)="closePopup('EditCardsPopup')">
                    <i class="material-icons">&#xE5CD;</i>
                </a>
            </div>
            <div class="box-text col-md-12 padding-0">
                Do you want to edit cart
            </div>

            <div class="col-md-6 padding-0 text-center">
                <button class="w100 shaded" mat-button color="primary"
                    (click)="editCardPopup(bookingForUnassign.bookingId,bookingForUnassign )">Yes</button>
            </div>
            <div class="col-md-6 padding-0 text-center">
                <button class="w100 energetic1" mat-button color="primary"
                    (click)="closePopup('EditCardsPopup')">Cancel</button>
            </div>

        </div>
    </div>
</div>
<div id="CardsPopup" class="backdrop" style="display:none">
    <div class="box-small">
        <div id="loggedOut" *ngIf="bookingForUnassign" class="col-md-12 padding-0"
            style="background:#fff ; border-radius:4px;width:500px; right: 114px">
            <div class="box-header col-md-612 padding-0">
                <span>Edit Cart</span>
            </div>
            <div class="closeModal-small">
                <a (click)="closePopup('CardsPopup')">
                    <i class="material-icons">&#xE5CD;</i>
                </a>
            </div>
            <mat-tab-group class="Editing_Cart" [selectedIndex]="selectedIndex">
                <mat-tab class="col-md-6 " label="Fixed" [disabled]="disableFixed">
                    <div class="col-md-12  padding-0 bglight focus" [hidden]="disableFixed">
                        <div class="col-md-12 CheckOutHeader backgroundFade">
                            <h5>Service List</h5>
                        </div>
                        <div class="col-md-12 pad5 bglight">
                            <div class="col-md-12 padding-0 serviceListSection">
                                <div *ngFor="let item of serviceList" class="serviceItem">
                                    <div class="col-md-12 allowSpace5 CartBill activeBill">

                                        <div class="col-md-6 padding-1 cartBillName billName">
                                            <p>{{item.serviceName}}</p>
                                        </div>
                                        <div *ngIf="item.status == 0">
                                            <div class="col-md-4 padItem text-center">
                                                <button mat-button mat-raised-button class="primaryButton" type="button"
                                                    (click)="myCart(item,1,1)">
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="item.status == 1">
                                            <div class="col-md-1 padItem padding-1 text-center">
                                                <div *ngIf="item.quantityAction == 1">
                                                    <button mat-button class="smallButton" type="button"
                                                        (click)="myCart(item,1,1)"
                                                        *ngIf="bookingForUnassign.bookingType != 3">
                                                        <i class="material-icons primary">&#xE146;</i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-md-2 padItem padding-1 text-right">
                                                <input class="input-number text-center" id="" type="number"
                                                    value="{{item.quntity}}" readonly>
                                            </div>
                                            <div class="col-md-1 padItem padding-1 text-center">
                                                <div *ngIf="item.quantityAction == 1">
                                                    <button mat-button class="smallButton" type="button"
                                                        (click)="myCart(item,2,1)"
                                                        *ngIf="bookingForUnassign.bookingType != 3">
                                                        <i class="material-icons danger-i">&#xE909;</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-2 padItem padding-1 text-right tTotal">
                                            <sup>{{item.currencySymbol}}</sup>{{item.amount}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 padding-0 serviceListSection"></div>
                        <div class="col-md-12 padding-1 text-center " *ngIf="">Total Price:
                            {{bookingForUnassign.currencySymbol}}{{TotalAmount.toFixed(2)}}</div>
                        <div class="col-md-12 padding-1 text-center hTotal hBillBox ">
                            <div class="padding-1 text-center padding-0">
                                <button mat-raised-button class="ButttonColor"
                                    style="background:#4b4a4a!important;color:white" (click)="SaveCart()">Save</button>
                            </div>
                        </div>
                        <div class="col-md-12 padding-0 serviceListSection"></div>
                    </div>
                </mat-tab>
                <mat-tab class="col-md-6" label="Hourly" [disabled]="disableHourly">
                    <div class="hourlyBill bglight billPadding" [hidden]="disableHourly">
                        <div class="text-center toHourly caps">
                            <p><b>Hourly Price</b></p>
                        </div>
                        <div class="col-md-12 padding-1 text-center hTotal hBillBox"></div>
                        <div class="col-md-12 padding-1 text-center hTotal hBillBox hPadding">
                            <sup>{{bookingForUnassign.currencySymbol}}</sup>{{bookingForUnassign.accounting.pricePerHour}}
                        </div>
                        <div class="col-md-12 text-center pad25 toHourly">
                            <p>Calculate your fare</p>
                        </div>
                        <div class="col-md-12 padding-1 text-center" style="height:50px;">
                            <div class="col-md-4 padding-0" style="padding:5px 0px">
                                <div class="col-md-4  padding-0 text-center">
                                    <div>
                                        <button mat-button class="smallButton" (click)="myCart(1,1,2)" type="button"
                                            *ngIf="bookingForUnassign.bookingType != 3">
                                            <i class="material-icons primary">&#xE146;</i>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-4  padding-0 text-right">
                                    <input class="input-number text-center" id="" type="number" value="{{hourlycount}}"
                                        style="border:0;width: 20px;" readonly>
                                </div>
                                <div class="col-md-4  padding-0 text-center">
                                    <div>
                                        <button mat-button class="smallButton" (click)="myCart(1,2,2)" type="button"
                                            *ngIf="bookingForUnassign.bookingType != 3">
                                            <i class="material-icons danger-i">&#xE909;</i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 padding-0 hbilltime">
                                <sub>Hours</sub>
                            </div>
                            <div class="col-md-1 hTotal padding-0">
                                =
                            </div>
                            <div class="col-md-4">
                                <div class="padding-1 text-center hTotal padding-0">
                                    <sup>{{bookingForUnassign.currencySymbol}}</sup>{{TotalAmount}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 padding-1 text-center "></div>
                        <div class="col-md-12 padding-1 text-center hTotal hBillBox ">
                            <div class="padding-1 text-center padding-0">
                                <button mat-raised-button class="ButttonColor"
                                    style="background:#4b4a4a!important;color:white" (click)="SaveCart()">Save</button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>