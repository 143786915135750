// import { ElementRef, Renderer2 } from '@angular/core';
// import { Component, OnInit, ChangeDetectorRef, NgModule, ViewContainerRef } from '@angular/core';
// import { ViewChild, AfterViewInit } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { environment } from '../../../environments/environment';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// // import { ToastModule, ToastsManager } from 'ng2-toastr/ng2-toastr';
// import { ToastrService } from 'ngx-toastr';
// import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
// import 'rxjs/add/operator/retry';
// import 'rxjs/Rx';
// import 'hammerjs';
// import { DispatcherComponent } from '../dispatcher.component';
// import { MatPaginator } from '@angular/material/paginator';
// import { MatTableDataSource } from '@angular/material/table';
// import { RouterModule, Routes } from '@angular/router';
// import { Router } from '@angular/router';
// import { DatePipe } from '@angular/common';
// import { DecimalPipe } from '@angular/common';
// import { FormControl } from '@angular/forms';
// import { Observable } from 'rxjs/Observable';
// import { Paho } from 'ng2-mqtt';
// import { apiServiceManager } from '../../service/api-service-manager';
// import { mqttManager } from '../../service/mqtt-manager';
// import swal from 'sweetalert2';
// import 'rxjs/add/operator/retry';
// import 'rxjs/Rx';
// import * as moment from 'moment';
// import { CookieService } from 'ngx-cookie-service';
// declare var $: any;
// declare var jQuery: any;
// declare var Fingerprint: any;
// declare var google: any;
// declare var placeMarkerAndPanTo: any;
// declare var value: any;
// declare var flatpickr: any;
// declare var flatpickr2: any;

// @Component({
//   selector: 'app-tasks',
//   templateUrl: './tasks.component.html',
//   styleUrls: ['./tasks.component.css']
// })


// export class TasksComponent implements OnInit {

//   onLoadLatitude: any;
//   onLoadLongitude: any;
//   dipatcherMap: any;
//   current_time: any;
//   current_time_var: any;
//   AnswerDetails:any=[];
//   QuestionAndAnswerDetails:any=[];
//   assigned: any = [];
//   unassigned: any = [];
//   completed: any = [];
//   dispatched: any = [];
//   assignedCount: any;
//   unassignedCount: any;
//   completedCount: any;
//   dispatchedCount: any;

//   jobDetailsByBooking: any;
//   itemList: any;

//   createdDate: any;
//   acceptedDate: any;
//   onTheWay: any;
//   arrivedAt: any;
//   jobStarted: any;

//   disablemat1: boolean = true;
//   disablemat2: boolean = true;
//   disablemat3: boolean = true;
//   disablemat4: boolean = true;

//   available: any = [];
//   availableCount: any = 0;

//   unavailable: any = [];
//   unavailableCount: any = 0;

//   selectedProvider: any;
//   selectedProviderBooking: any;
//   selectedProviderTimeline: any;

//   selectedbooking: any;
//   finalizedProvider: any;
//   homePageMap: any;

//   bookingForUnassign: any;

//   selectedmarker: any = [];
//   selectedmarkerStore: any = [];
//   onlineDriverMarkers: any = [];
//   placeStoreMarker: any = [];
//   assignedDriverMarker: any = [];
//   availableMarker: any = [];

//   providerJob: any = [];
//   stringFilter: any;

//   searchController = new FormControl();
//   providerSearchController = new FormControl();
//   fromDateFilter: any;
//   toDateFilter: any;
//   selectedDataFrom: any;
//   selectedDataTo: any;

//   indexOne: number = 0;
//   indexTwo: number = 0;
//   indexThree: number = 0;
//   indexFour: number = 0;

//   providerFilterString: any;

//   color = 'primary';
//   mode = 'determinate';
//   unAssignMode = 'determined';
//   dispatchMode = 'determined';
//   bookingMode = 'determined';
//   value = 33.33;
//   bufferValue = 66.66;

//   orderDetails: any;

//   pastOrderList: any = [];
//   statusSelected: any;



//   filterIndex: any = 0;
//   completeCount:any;
//   @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
//   constructor(private _formBuilder: FormBuilder, private _router: Router, private ref: ChangeDetectorRef,
//     private _apiServiceManager: apiServiceManager, private _mqttManager: mqttManager,
//     private _renderer: Renderer2, private _elementRef: ElementRef,private cookieService:CookieService,
//     //public toastr: ToastsManager, vcr: ViewContainerRef,
//     private ngxSmartModalService:NgxSmartModalService,
//     public toastrService: ToastrService,
//     public DispatcherComponent: DispatcherComponent) {
//     //this.toastr.setRootViewContainerRef(vcr);

//     this.DispatcherComponent.online$.subscribe((data) => {
//       ////conole.log("home component online", data)
//       if (data) {
//         ////conole.log("online");
//         this.getMqttMessage();
//         this.getAllBookings(0, 0);
//       }
//     })
//   }
//   getMqttMessage() {
//     this._mqttManager.getMessages().subscribe((message: Paho.MQTT.Message) => {
//       let bookingData = JSON.parse(message.payloadString);
//       this._mqttManager.getMqttMsg(bookingData);

//       switch(bookingData.destinationName){
//         case 'bookingStatus' : this.jobDetailsByBooking = bookingData;
//       }
//       // ////conole.log("payload bookingData", bookingData);
//     });
//   }
//   getAllBookings(index, toUpdate) {
//     this.bookingMode = 'query';
//     let data = {
//       pageIndex: index,
//     }
//     this.selectedDataFrom && this.selectedDataTo ? data['from'] = this.selectedDataFrom : data['from'] = '0';
//     this.selectedDataFrom && this.selectedDataTo ? data['to'] = this.selectedDataTo : data['to'] = '0';
//     this.stringFilter ? data['searchStr'] = this.stringFilter : data['searchStr'] = '0';
//     this._apiServiceManager.getBooking(data).subscribe(result => {
//       ////conole.log("getBooking", result);
//       let response = result.data;

//     },error =>{
//       (error.status == 498 || error.status === 440 ) ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();
//     });
//   }


//   ngOnInit() {
//     jQuery('#back_button').show();
//     jQuery('#godsView').show();
//     this.filterJobs(0, 0);
//     this.observeStatusFilter();
//   }

//   toppings = new FormControl();
//   fromdate = new FormControl();
//   todate = new FormControl();

//   toppingList = ['Unassigned', 'Indispatch', 'Assigned', 'Accepted', 'Cancelled', 'Completed'];

//   statusList = [
//     { value: 1, status: "Unassigned" },
//     { value: 3, status: "Assigned" },
//     { value: 4, status: "Declined" },
//     { value: 5, status: "Expired" },
//     { value: 6, status: "On The Way" },
//     { value: 7, status: "Arrived" },
//     { value: 8, status: "Job Started" },
//     { value: 9, status: "Job Completed" },
//     { value: 10, status: "Invoice Raised" },
//     { value: 11, status: "Cancelled by Provider" },
//     { value: 12, status: "Cancelled by Customer" },
//   ]


//   displayedColumns = ['Booking-Id', 'Name', 'Email', 'Drop-Location', 'Driver', 'OrderTime', 'DeliveryTime', 'Cart-Total', 'Status'];
//   dataSource: any;

//   applyFilter(filterValue: string) {
//     filterValue = filterValue.trim(); // Remove whitespace
//     filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
//     this.dataSource.filter = filterValue;
//   }

//   observeStatusFilter() {
//     this.filterJobs(this.filterIndex, 0);
//     ////conole.log("observeStatusFilter", this.statusSelected)
//     if (this.statusSelected) {
//       this.filterJobs(this.filterIndex, 0);
//     }
//   }

//   observeDateFilter() {
//     this.selectedDataFrom = null;
//     this.selectedDataTo = null;
//     ////conole.log("observeDateFilter", this.fromDateFilter, this.toDateFilter);
//     if (this.fromDateFilter && this.toDateFilter) {
//       setTimeout(() => {
//         this.selectedDataFrom = moment(this.fromDateFilter).format('YYYY-MM-DD');
//         this.selectedDataTo = moment(this.toDateFilter).format('YYYY-MM-DD');
//         ////conole.log("selcted", this.selectedDataFrom, this.selectedDataTo);
//         this.filterJobs(this.filterIndex, 0);
//       }, 10);
//     }

//   }

//   observeStringFilter() {
//      (this.stringFilter) ? this.filterJobs(this.filterIndex, 0):this.filterJobs(0,0);
//   }

//   filterJobs(filterIndex, dataManagerType) {
//     let filterData = {
//       pageIndex: filterIndex,
//     }
//     this.selectedDataFrom && this.selectedDataTo ? filterData["fromDate"] = this.selectedDataFrom : filterData["fromDate"] = "";
//     this.selectedDataFrom && this.selectedDataTo ? filterData["toDate"] = this.selectedDataTo : filterData["toDate"] = "";
//     this.statusSelected ? filterData["status"] = this.statusSelected : filterData["status"] = "";
//     this.stringFilter ? filterData["search"] = this.stringFilter : filterData["search"] = "";

//     this.bookingMode = 'query';
//     this._apiServiceManager.filterPastJob(filterData).subscribe(result => {
//       this.getPastJobDataManager(result, dataManagerType);
//       this.completeCount = result.data.completeCount;
//       this.dataSource = new MatTableDataSource(this.pastOrderList);
//       this.dataSource.paginator = this.paginator;
//       this.bookingMode = 'determined';
//       setTimeout(() => {
//         if (this.ref !== null && this.ref !== undefined &&
//           !(this.ref as ChangeDetectorRef)['destroyed']) {
//           this.ref.detectChanges();
//         }
//       }, 250);
//     },error =>{
//       error.status == 498 || error.status === 440  ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();
//     });
//   }

//   getPastJobDataManager(result, dataManagerType) {
//     switch (dataManagerType) {
//       case 0:
//         ////conole.log("filterPastJob result", result);
//         $(".rotate").attr("id", "");
//         this.pastOrderList = result.data.complete;
//         break;
//       case 1:
//         if (result.data.complete.length > 0) {
//           for (var i = 0; i < result.data.complete.length; i++) {
//             this.pastOrderList.push(result.data.complete[i]);
//             setTimeout(() => {
//               if (this.ref !== null && this.ref !== undefined &&
//                 !(this.ref as ChangeDetectorRef)['destroyed']) {
//                 this.ref.detectChanges();
//               }
//             }, 250);
//           }
//         }
//       break;
//     }
//   }


//   getPastJobs(filterIndex) {
//     this.toppings.reset();
//     this.fromdate.reset();
//     this.todate.reset();

//     var PastOrders = 0;
//     this.bookingMode = 'query';
//     $(".rotate").attr("id", "loading");
//     this._apiServiceManager.getPastJobs(filterIndex).subscribe(result => {
//       ////conole.log("getPastJobs result", result);
//       $(".rotate").attr("id", "");
//       this.pastOrderList = result.data.complete;
//       this.dataSource = new MatTableDataSource(this.pastOrderList);
//       this.dataSource.paginator = this.paginator;
//       this.bookingMode = 'determined';
//       setTimeout(() => {
//         if (this.ref !== null && this.ref !== undefined &&
//           !(this.ref as ChangeDetectorRef)['destroyed']) {
//           this.ref.detectChanges();
//         }
//       }, 250);
//     }, error => {
//       ////conole.log("ERROR", error);
//       var erRes = JSON.parse(error._body);
//       switch (error.status) {
//         case 498:case 440:
//           // this.logOutForcefully();
//           $("#appt_loader_loggedOut").show();

//           break;
//       }
//       $("#appt_loader_loggedOut").hide();
//     });
//   }
//   refresh(){
//     this.statusSelected = '';
//     this.stringFilter = '';
//     this.fromdate.reset();
//     this.todate.reset();
//     this.filterIndex = 0;
//     this.filterJobs(0,0);
//   }

//   logOutForcefully() {
//     swal.fire({
//       title: 'Sorry !',
//       text: "Your session has expired, please login again to access the dispatcher.",
//       type: 'warning',
//       showCancelButton: false,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Login',
//       confirmButtonClass: 'btn btn-success',
//       buttonsStyling: false,
//       reverseButtons: false
//     }).then((result) => {
//       $('.modal').appendTo("body").modal('hide');
//       this._router.navigate(['login']);
//       this.cookieService.delete('loginUser')
//       this.cookieService.delete('user-token')
//     });
//   }
//   show_details(item) {
//     // $(".decideJob").hide('slow');
//     this.orderDetails = "";
//     this.itemList = "";
//     this.orderDetails = item;
//     //conole.log(item);
//     this.jobDetailsByBooking = item;
//     this.AnswerDetails=item.questionAndAnswer;
//     this.itemList = this.jobDetailsByBooking.cartData;
//     //console.log("this.jobDetailsByBooking",this.jobDetailsByBooking)
//     // arrivedTime completedTime onthewayTime raiseInvoiceTime requestedTime startedTime
//     this.ngxSmartModalService.open('jobdetailModal');
//     // setTimeout(() => {
//     //   this.ngxSmartModalService.open('jobdetailModal');
//     //   // $('#jobdetailModal').appendTo("body").modal('show');
//     // }, 200)
//     //card#69 DISPATCHER > TASKS > ERROR COMES WHILE SEARCHING WITH BOOKING ID , fixedBy:sowmya
//     setTimeout(() => {
//       if (this.ref !== null && this.ref !== undefined &&
//         !(this.ref as ChangeDetectorRef)['destroyed']) {
//         this.ref.detectChanges();
//       }
//     }, 250);
//   }
//   onPaginateChange(event) {
//     let index = Math.ceil(event.length / event.pageSize) - 2;
//     //conole.log(index);

//     if (index == event.pageIndex - 1) {
//       this.filterIndex++;
//       this.filterJobs(this.filterIndex, 1)
//     }
//   }
//   emmualLogout() {
//     this._apiServiceManager.logOut().subscribe(result => {
//      this.clearAndMove();
//     },error=>{
//       ////conole.log("the error is : ", error);
//       error.status == 498 || error.status == 440 ? this.clearAndMove() : this.clearAndMove()
//     });
//   }

//   clearAndMove(){
//     this._router.navigate(['login']);
//     this.cookieService.delete('loginUser')
//     this.cookieService.delete('user-token')
//     // sessionStorage.removeItem("loginUser");
//     // sessionStorage.removeItem("user-token");
//   }

// }
import { ElementRef, Renderer2 } from '@angular/core';
import { Component, OnInit, ChangeDetectorRef, NgModule, ViewContainerRef } from '@angular/core';
import { ViewChild, AfterViewInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastModule, ToastsManager } from 'ng2-toastr/ng2-toastr';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import 'rxjs/add/operator/retry';
import 'rxjs/Rx';
import { DispatcherComponent } from '../dispatcher.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Paho } from 'ng2-mqtt';
import { apiServiceManager } from '../../service/api-service-manager';
import { mqttManager } from '../../service/mqtt-manager';
import swal from 'sweetalert2';
import 'rxjs/add/operator/retry';
import 'rxjs/Rx';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;
declare var jQuery: any;
declare var Fingerprint: any;
declare var google: any;
declare var placeMarkerAndPanTo: any;
declare var value: any;
declare var flatpickr: any;
declare var flatpickr2: any;

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})


export class TasksComponent implements OnInit {

  onLoadLatitude: any;
  onLoadLongitude: any;
  dipatcherMap: any;
  current_time: any;
  current_time_var: any;
  AnswerDetails: any = [];
  QuestionAndAnswerDetails: any = [];
  assigned: any = [];
  unassigned: any = [];
  completed: any = [];
  dispatched: any = [];
  assignedCount: any;
  unassignedCount: any;
  completedCount: any;
  dispatchedCount: any;

  jobDetailsByBooking: any;
  itemList: any;

  createdDate: any;
  acceptedDate: any;
  onTheWay: any;
  arrivedAt: any;
  jobStarted: any;

  disablemat1: boolean = true;
  disablemat2: boolean = true;
  disablemat3: boolean = true;
  disablemat4: boolean = true;

  available: any = [];
  availableCount: any = 0;

  unavailable: any = [];
  unavailableCount: any = 0;

  selectedProvider: any;
  selectedProviderBooking: any;
  selectedProviderTimeline: any;

  selectedbooking: any;
  finalizedProvider: any;
  homePageMap: any;

  bookingForUnassign: any;

  selectedmarker: any = [];
  selectedmarkerStore: any = [];
  onlineDriverMarkers: any = [];
  placeStoreMarker: any = [];
  assignedDriverMarker: any = [];
  availableMarker: any = [];

  providerJob: any = [];
  stringFilter: any;
  pageSize: any = 20;
  searchController = new FormControl();
  providerSearchController = new FormControl();
  fromDateFilter: any;
  toDateFilter: any;
  selectedDataFrom: any;
  selectedDataTo: any;

  indexOne: number = 0;
  indexTwo: number = 0;
  indexThree: number = 0;
  indexFour: number = 0;

  providerFilterString: any;

  color = 'primary';
  mode = 'determinate';
  unAssignMode = 'determined';
  dispatchMode = 'determined';
  bookingMode = 'determined';
  value = 33.33;
  bufferValue = 66.66;

  orderDetails: any;

  pastOrderList: any = [];
  statusSelected: any;



  filterIndex: any = 0;
  completeCount: any;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  constructor(private _formBuilder: FormBuilder, private _router: Router, private ref: ChangeDetectorRef,
    private _apiServiceManager: apiServiceManager, private _mqttManager: mqttManager,
    private _renderer: Renderer2, private _elementRef: ElementRef, private cookieService: CookieService,
    //public toastr: ToastsManager, vcr: ViewContainerRef,
    private ngxSmartModalService: NgxSmartModalService,
    public toastrService: ToastrService,
    public DispatcherComponent: DispatcherComponent) {
    //this.toastr.setRootViewContainerRef(vcr);

    this.DispatcherComponent.online$.subscribe((data) => {
      ////conole.log("home component online", data)
      if (data) {
        ////conole.log("online");
        this.getMqttMessage();
        this.getAllBookings(0, 0);
      }
    })
  }
  getMqttMessage() {
    this._mqttManager.getMessages().subscribe((message: Paho.MQTT.Message) => {
      let bookingData = JSON.parse(message.payloadString);
      this._mqttManager.getMqttMsg(bookingData);

      switch (bookingData.destinationName) {
        case 'bookingStatus': this.jobDetailsByBooking = bookingData;
      }
      // ////conole.log("payload bookingData", bookingData);
    });
  }
  getAllBookings(index, toUpdate) {
    this.bookingMode = 'query';
    let data = {
      pageIndex: index,
    }
    this.selectedDataFrom && this.selectedDataTo ? data['from'] = this.selectedDataFrom : data['from'] = '0';
    this.selectedDataFrom && this.selectedDataTo ? data['to'] = this.selectedDataTo : data['to'] = '0';
    this.stringFilter ? data['searchStr'] = this.stringFilter : data['searchStr'] = '0';
    this._apiServiceManager.getBooking(data).subscribe(result => {
      ////conole.log("getBooking", result);
      let response = result.data;

    }, error => {
      (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    });
  }


  ngOnInit() {
    jQuery('#back_button').show();
    jQuery('#godsView').show();
    this.filterJobs(0, 0);
    this.observeStatusFilter();
  }

  toppings = new FormControl();
  fromdate = new FormControl();
  todate = new FormControl();

  toppingList = ['Unassigned', 'Indispatch', 'Assigned', 'Accepted', 'Cancelled', 'Completed'];

  statusList = [
    { value: 1, status: "Unassigned" },
    { value: 3, status: "Assigned" },
    { value: 4, status: "Declined" },
    { value: 5, status: "Expired" },
    { value: 6, status: "On The Way" },
    { value: 7, status: "Arrived" },
    { value: 8, status: "Job Started" },
    { value: 9, status: "Job Completed" },
    { value: 10, status: "Invoice Raised" },
    { value: 11, status: "Cancelled by Provider" },
    { value: 12, status: "Cancelled by Customer" },
  ]


  displayedColumns = ['Booking-Id', 'Name', 'Email', 'Drop-Location', 'Driver', 'OrderTime', 'DeliveryTime', 'Cart-Total', 'Status', 'Details'];
  dataSource: any;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  observeStatusFilter() {
    this.filterJobs(this.filterIndex, 0);
    ////conole.log("observeStatusFilter", this.statusSelected)
    if (this.statusSelected) {
      this.filterJobs(this.filterIndex, 0);
    }
  }

  observeDateFilter() {
    this.selectedDataFrom = null;
    this.selectedDataTo = null;
    ////conole.log("observeDateFilter", this.fromDateFilter, this.toDateFilter);
    if (this.fromDateFilter && this.toDateFilter) {
      setTimeout(() => {
        this.selectedDataFrom = moment(this.fromDateFilter).format('YYYY-MM-DD');
        this.selectedDataTo = moment(this.toDateFilter).format('YYYY-MM-DD');
        ////conole.log("selcted", this.selectedDataFrom, this.selectedDataTo);
        this.filterJobs(this.filterIndex, 0);
      }, 10);
    }

  }

  observeStringFilter() {
    (this.stringFilter) ? this.filterJobs(this.filterIndex, 0) : this.filterJobs(0, 0);
  }

  filterJobs(filterIndex, dataManagerType) {
    let filterData = {
      pageIndex: filterIndex,
    }
    this.selectedDataFrom && this.selectedDataTo ? filterData["fromDate"] = this.selectedDataFrom : filterData["fromDate"] = "";
    this.selectedDataFrom && this.selectedDataTo ? filterData["toDate"] = this.selectedDataTo : filterData["toDate"] = "";
    this.statusSelected ? filterData["status"] = this.statusSelected : filterData["status"] = "";
    this.stringFilter ? filterData["search"] = this.stringFilter : filterData["search"] = "";

    this.bookingMode = 'query';
    this._apiServiceManager.filterPastJob(filterData).subscribe(result => {
      this.getPastJobDataManager(result, dataManagerType);
      this.completeCount = result.data.completeCount;
      if (this.pastOrderList && this.pastOrderList.length > 0) {
        this.pastOrderList.map((item, index) => {
          let completedTime = item.jobStatusLogs.filter((x => x.status == 9));
          completedTime && completedTime.length > 0 ? item['bookingCompletionTime'] = completedTime[0].timeStamp : '';
        })
      }
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.pastOrderList);
        this.dataSource.paginator = this.paginator;
      }, 1000)
      this.bookingMode = 'determined';
      setTimeout(() => {
        if (this.ref !== null && this.ref !== undefined &&
          !(this.ref as ChangeDetectorRef)['destroyed']) {
          this.ref.detectChanges();
        }
      }, 250);
    }, error => {
      error.status == 498 || error.status === 440 ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    });
  }

  getPastJobDataManager(result, dataManagerType) {
    switch (dataManagerType) {
      case 0:
        ////conole.log("filterPastJob result", result);
        $(".rotate").attr("id", "");
        this.pastOrderList = result.data.complete;
        break;
      case 1:
        if (result.data.complete.length > 0) {
          for (var i = 0; i < result.data.complete.length; i++) {
            this.pastOrderList.push(result.data.complete[i]);
            setTimeout(() => {
              if (this.ref !== null && this.ref !== undefined &&
                !(this.ref as ChangeDetectorRef)['destroyed']) {
                this.ref.detectChanges();
              }
            }, 250);
          }
        }
        break;
    }
  }


  getPastJobs(filterIndex) {
    this.toppings.reset();
    this.fromdate.reset();
    this.todate.reset();

    var PastOrders = 0;
    this.bookingMode = 'query';
    $(".rotate").attr("id", "loading");
    this._apiServiceManager.getPastJobs(filterIndex).subscribe(result => {
      ////conole.log("getPastJobs result", result);
      $(".rotate").attr("id", "");
      this.pastOrderList = result.data.complete;
      if (this.pastOrderList && this.pastOrderList.length > 0) {
        this.pastOrderList.map((item, index) => {
          let completedTime = item.jobStatusLogs.filter((x => x.status == 9));
          console.log('completedTime', completedTime)
          completedTime && completedTime.length > 0 ? item['bookingCompletionTime'] = completedTime[0].timeStamp : '';
        })
      }
      setTimeout(() => {
        this.dataSource = new MatTableDataSource(this.pastOrderList);
        this.dataSource.paginator = this.paginator;
      }, 1000);
      this.bookingMode = 'determined';
      setTimeout(() => {
        if (this.ref !== null && this.ref !== undefined &&
          !(this.ref as ChangeDetectorRef)['destroyed']) {
          this.ref.detectChanges();
        }
      }, 250);
    }, error => {
      ////conole.log("ERROR", error);
      var erRes = JSON.parse(error._body);
      switch (error.status) {
        case 498: case 440:
          // this.logOutForcefully();
          $("#appt_loader_loggedOut").show();

          break;
      }
      $("#appt_loader_loggedOut").hide();
    });
  }
  refresh() {
    this.statusSelected = '';
    this.stringFilter = '';
    this.fromdate.reset();
    this.todate.reset();
    this.filterIndex = 0;
    this.filterJobs(0, 0);
  }

  logOutForcefully() {
    swal.fire({
      title: 'Sorry !',
      text: "Your session has expired, please login again to access the dispatcher.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Login',
      // confirmButtonClass: 'btn btn-success',
      buttonsStyling: false,
      reverseButtons: false
    }).then((result) => {
      $('.modal').appendTo("body").modal('hide');
      this._router.navigate(['login']);
      this.cookieService.delete('loginUser')
      this.cookieService.delete('user-token')
    });
  }
  show_details(item) {
    // $(".decideJob").hide('slow');
    this.orderDetails = "";
    this.itemList = "";
    this.orderDetails = item;
    //conole.log(item);
    this.jobDetailsByBooking = item;
    this.AnswerDetails = item.questionAndAnswer;
    this.itemList = this.jobDetailsByBooking.cartData;
    console.log("this.jobDetailsByBooking", this.jobDetailsByBooking)
    // arrivedTime completedTime onthewayTime raiseInvoiceTime requestedTime startedTime
    this.ngxSmartModalService.open('jobdetailModal');
    // setTimeout(() => {
    //   this.ngxSmartModalService.open('jobdetailModal');
    //   // $('#jobdetailModal').appendTo("body").modal('show');
    // }, 200)
    //card#69 DISPATCHER > TASKS > ERROR COMES WHILE SEARCHING WITH BOOKING ID , fixedBy:sowmya
    setTimeout(() => {
      if (this.ref !== null && this.ref !== undefined &&
        !(this.ref as ChangeDetectorRef)['destroyed']) {
        this.ref.detectChanges();
      }
    }, 250);
  }
  onPaginateChange(event) {
    let index = Math.ceil(event.length / event.pageSize) - 2;
    // console.log('onPaginateChange', index, event);
    this.pageSize = event.pageSize;
    if (index == event.pageIndex - 1) {
      this.filterIndex++;
      this.filterJobs(this.filterIndex, 1)
    }
  }

  // ngAfterViewInit(): void {
  //   this.dataSource.paginator = this.paginator;
  // }
  emmualLogout() {
    this._apiServiceManager.logOut().subscribe(result => {
      this.clearAndMove();
    }, error => {
      ////conole.log("the error is : ", error);
      error.status == 498 || error.status == 440 ? this.clearAndMove() : this.clearAndMove()
    });
  }

  clearAndMove() {
    this._router.navigate(['login']);
    this.cookieService.delete('loginUser')
    this.cookieService.delete('user-token')
    // sessionStorage.removeItem("loginUser");
    // sessionStorage.removeItem("user-token");
  }

}