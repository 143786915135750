
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountdownModule } from 'ngx-countdown';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FileUploadModule } from 'ng2-file-upload';
import { LoginComponent } from './login/login.component';
import { DispatcherComponent } from './dispatcher/dispatcher.component';
import { DispatcherHomeComponent } from './dispatcher/dispatcher-home/dispatcher-home.component';
import { TasksComponent } from './dispatcher/tasks/tasks.component';
import { AccountsComponent } from './dispatcher/accounts/accounts.component';
import { GodsviewComponent } from './dispatcher/godsview/godsview.component';
import { AppRoutingModule } from './app-routing.module';
import { mqttManager } from './service/mqtt-manager';
import { apiServiceManager } from './service/api-service-manager';
import { MatStepperModule } from '@angular/material/stepper';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxScreenfullModule } from '@ngx-extensions/screenfull';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';


import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DispatcherComponent,
    LoginComponent,
    DispatcherHomeComponent,
    GodsviewComponent,
    AccountsComponent,
    TasksComponent
  ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot(
      {
        timeOut: 1500,
        // positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }
    ),
    FlatpickrModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CountdownModule,
    FileUploadModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatButtonModule, MatCheckboxModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    DragDropModule,
    NgxScreenfullModule,
    NgxMatIntlTelInputModule

  ],
  exports: [FormsModule,
    ReactiveFormsModule, NgxSmartModalModule],
  providers: [apiServiceManager, mqttManager, CookieService],
  bootstrap: [AppComponent],

})

export class AppModule { }   