<div id="appt_loader_loggedOut" class="backdrop" style="display:none">
    <div id="loggedOut" class="box-small height-200">
        <img src="assets/images/logout.png" id="drivers-busy_icn">
        <p>Your session has been expired , please login again</p>
        <button id="busy_drivers_ok" (click)="emmualLogout()">OK</button>
    </div>
</div>

<div class="col-md-12 mrg10">
    <div class="col-md-1 refresh pad0">
        <a (click)="refresh()">
            <i class="fas fa-retweet" title="Refresh Data"></i>
        </a>
    </div>
    <div class="col-md-1 pad0">
        <mat-form-field>
            <mat-select placeholder="Status" [formControl]="toppings" multiple [(ngModel)]="statusSelected"
                (ngModelChange)="observeStatusFilter()">
                <mat-option *ngFor="let item of statusList" [value]="item.value">{{item.status}}</mat-option>
            </mat-select>
            <mat-hint>Status Filter</mat-hint>
        </mat-form-field>
    </div>
    <div class="col-md-3 pad0">
        <div class="col-md-6">
            <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="picker" (click)="picker.open()" [formControl]="fromdate"
                    [(ngModel)]="fromDateFilter" (ngModelChange)="observeDateFilter()" placeholder="From" readonly>
                <mat-hint>Date Filter</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="picker2" (click)="picker2.open()" [disabled]="!fromDateFilter"
                    [min]="fromDateFilter" [formControl]="todate" [(ngModel)]="toDateFilter"
                    (ngModelChange)="observeDateFilter()" placeholder="To" readonly>
                <mat-hint>Date Filter</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #picker2></mat-datepicker>
            </mat-form-field>
        </div>
    </div>


    <div class="col-md-6">
        <mat-form-field class="matsub dudeNumber">
            <input matInput placeholder="Search Booking" [(ngModel)]="stringFilter"
                (ngModelChange)="observeStringFilter()" autocomplete="off">
            <mat-hint>search by Booking-Id/ Customer Name/ Email/ Phone</mat-hint>
        </mat-form-field>
    </div>
</div>

<!-- Details PopUp -->
<ngx-smart-modal #jobdetailModal identifier="jobdetailModal" [closable]="false" [dismissable]="false"
    [escapable]="false" customClass="medium-modal">
    <div class="" *ngIf="jobDetailsByBooking" role="dialog"
        style="padding:0px!important ; padding-left:0px!important ;">
        <div class="">
            <div id="details_task_header clear-fix">
                <!-- <span id="details_task_header_left">Booking ID:{{jobDetailsByBooking.bookingId}}</span> -->
                <div class="row">
                    <div class="col-md-6">
                        <span id="details_task_header_left">Booking ID:{{jobDetailsByBooking.bookingId}}</span>
                    </div>
                    <div class="col-md-6">
                        <button type="button" class="close" (click)="jobdetailModal.close()"
                            title="Close">&times;</button>
                    </div>
                </div>
            </div>
            <div class="clear-fix">
                <mat-tab-group>
                    <mat-tab label="Job Details">
                        <div>
                            <div id="details_task_job_details_top row">
                                <div class="left-job-details width-50 col-md-6" style="margin-top:15px;">
                                    <div class="location clear-fix">
                                        <span class="points_text"><b>Job Location</b></span>
                                        <br>

                                        <div>
                                            <span class="job_id_add2">
                                                {{jobDetailsByBooking.addLine1}}</span>
                                        </div>
                                    </div>
                                    <div class="booking booking-time clear-fix"
                                        *ngIf="jobDetailsByBooking.bookingRequestedAt">
                                        <span class="points_text"><b>Booking Started At</b></span>
                                        <br>
                                        <span>{{jobDetailsByBooking.bookingRequestedAt * 1000 | date : 'medium'}}</span>
                                    </div>
                                    <div class="booking booking-time clear-fix">
                                        <span class="points_text"><b>Actual Booking Accepted At</b></span>
                                        <br>
                                        <span>{{jobDetailsByBooking.bookingRequestedFor * 1000 | date : 'medium'}}</span>
                                    </div>
                                    <div class="booking booking-time clear-fix">
                                        <span class="points_text"><b>Payment Type</b></span>
                                        <br>
                                        <span
                                            *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.paymentMethodText">{{jobDetailsByBooking.accounting.paymentMethodText}}</span>
                                    </div>
                                    <div class="booking booking-time clear-fix">
                                        <span class="points_text"><b>Booking Type</b></span>
                                        <br>
                                        <span
                                            *ngIf="jobDetailsByBooking.bookingType == 1 && jobDetailsByBooking.bookingModel != 3">Book
                                            Now</span>
                                        <span
                                            *ngIf="jobDetailsByBooking.bookingType == 2 && jobDetailsByBooking.bookingModel != 3">Book
                                            Later</span>
                                        <span
                                            *ngIf="jobDetailsByBooking.bookingType == 3 && jobDetailsByBooking.bookingModel != 3">Repeat
                                            Booking</span>
                                        <span *ngIf="jobDetailsByBooking.bookingModel == 3">Bid Booking</span>
                                    </div>

                                </div>
                                <div class="width-50 col-md-6" style="margin-top:15px;">
                                    <div class="details_task_inner_details" style="overflow: hidden">

                                        <div class="details_task_ordered_det">
                                            <span class="points_text"><b>Customer Details</b></span>
                                            <br>
                                            <i class="fas fa-user"></i>
                                            <span class="details_task_ordered_name">
                                                {{jobDetailsByBooking.customerData.firstName}}
                                                {{jobDetailsByBooking.customerData.lastName}} </span>
                                            <br>
                                        </div>
                                        <div class="details_task_ordered_det mrt-0">
                                            <i class="fas fa-phone-alt"></i>

                                            <span>
                                                {{jobDetailsByBooking.customerData.phone}}
                                            </span>
                                        </div>

                                        <div class="details_task_ordered_det mrt-0">
                                            <i class="fas fa-envelope"></i>

                                            <span>
                                                {{jobDetailsByBooking.customerData.email}}
                                            </span>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="details_task_inner_details" style="overflow: hidden"
                                        *ngIf="jobDetailsByBooking.providerData.name">

                                        <div class="details_task_ordered_det">
                                            <span class="points_text"><b>Provider Details</b></span>
                                            <br>
                                            <i class="fas fa-user"></i>
                                            <span class="details_task_ordered_name">
                                                {{jobDetailsByBooking.providerData.name}}</span>
                                            <br>
                                        </div>
                                        <div class="details_task_ordered_det mrt-0">
                                            <i class="fas fa-phone-alt"></i>

                                            <span>
                                                {{jobDetailsByBooking.providerData.phone}}
                                            </span>
                                        </div>
                                        <div class="details_task_ordered_det mrt-0">
                                            <i class="fas fa-envelope"></i>

                                            <span>
                                                {{jobDetailsByBooking.providerData.email}}
                                            </span>
                                        </div>
                                        <div class="details_task_ordered_det mrt-0">
                                            <i class="fas fa-map-marker-alt"></i>
                                            <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 1'>On</span>
                                            <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 0'>Off</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- Povide Details are added -->
                                <!-- <div class="width-25 col-md-4" style="margin-top:15px;" *ngIf='jobDetailsByBooking.providerData != true'>                                
                                    <div class="details_task_inner_details" style="overflow: hidden">
    
                                        <div class="details_task_ordered_det">
                                            <span class="points_text">Provider Details</span>
                                            <br>
                                            <i class="fas fa-user"></i>
                                            <span class="details_task_ordered_name">
                                                {{jobDetailsByBooking.providerData.name}}</span>
                                            <br>
                                        </div>
                                        <div class="details_task_ordered_det mrt-0">
                                            <i class="fas fa-phone-alt"></i>
    
                                            <span>
                                                {{jobDetailsByBooking.providerData.phone}}
                                            </span>
                                        </div>
                                        <div class="details_task_ordered_det mrt-0">
                                            <i class="fas fa-envelope"></i>
    
                                            <span>
                                                {{jobDetailsByBooking.providerData.email}}
                                            </span>
                                        </div>
                                        <div class="details_task_ordered_det mrt-0">
                                            <i class="fas fa-map-marker-alt"></i>
                                            <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 1'>On</span>
                                            <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 0'>Off</span>
                                        </div>
                                    </div>
                                </div> -->

                            </div>
                            <div id="details_job_services" class="mrt-15 clear-fix">
                                <div class="width-50 fl-left">
                                    <span class="points_text"><b>Service Category Name:</b></span>
                                    <br>
                                    <span class="details_task_ordered_name font12">
                                        {{jobDetailsByBooking.category}}
                                    </span>
                                </div>
                                <div *ngIf="itemList" class="col-md-12 padding-0">

                                    <div class="col-md-12 padding-0">
                                        <div class="col-md-12 subheader padding-0 points_text">
                                            <b>Booking Details:</b>
                                        </div>
                                        <br />
                                        <div class="col-md-12 padding-0">
                                            <div class="pad10">
                                                <div class="col-md-12 padding-0 mrg5">

                                                    <div class="col-md-5 points_text">
                                                        <b>Service Name</b>
                                                    </div>
                                                    <div class="col-md-2 text-right points_text">
                                                        <b>Quantity</b>
                                                    </div>
                                                    <div class="col-md-3 text-right points_text">
                                                        <b>Unit Price</b>
                                                    </div>
                                                    <div class="col-md-2 text-right points_text">
                                                        <b>Total</b>
                                                    </div>

                                                </div>
                                                <div class="col-md-12 booking-time padding-0 mrg5" *ngIf="!itemList">
                                                    <div class="col-md-12 text-center">
                                                        <p>No Items Found</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 padding-0 mrg5" *ngFor="let item of itemList">

                                                    <div class="col-md-5 text-left booking-time">
                                                        <p>{{item.serviceName}}</p>
                                                        <!-- <p class="text-muted productComments">2 liter</p> -->
                                                    </div>
                                                    <div class="col-md-2 text-right booking-time">{{item.quntity}}</div>
                                                    <div class="col-md-3 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{item.unitPrice | number:'2.1-3'}}</div>
                                                    <div class="col-md-2 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{item.unitPrice * item.quntity| number:'2.1-3'}}</div>
                                                </div>

                                                <div
                                                    *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.visitFee && jobDetailsByBooking.accounting.visitFee > 0">

                                                    <div class="col-md-12 float-right mt-10">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>Visit Fees:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }}
                                                            {{jobDetailsByBooking.accounting.visitFee | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.travelFee && jobDetailsByBooking.accounting.travelFee > 0">

                                                    <div class="col-md-12 float-right mt-10">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>Travel Fees:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }}
                                                            {{jobDetailsByBooking.accounting.travelFee | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.discount && jobDetailsByBooking.accounting.discount > 0">

                                                    <div class="col-md-12 float-right mt-10">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>Discount:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }}
                                                            {{jobDetailsByBooking.accounting.discount | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div
                                                    *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.dues && jobDetailsByBooking.accounting.dues > 0">

                                                    <div class="col-md-12 float-right mt-10">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>Dues:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }}
                                                            {{jobDetailsByBooking.accounting.dues | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div
                                                    *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.additionalServiceFee && jobDetailsByBooking.accounting.additionalServiceFee > 0">

                                                    <div class="col-md-12 float-right">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>Additional Service Fee:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }}
                                                            {{jobDetailsByBooking.accounting.additionalServiceFee | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="jobDetailsByBooking.additionalService && jobDetailsByBooking.additionalService.length > 0">
                                                    <div *ngFor="let account of jobDetailsByBooking.additionalService">

                                                        <div class="col-md-12 float-right mt-10">
                                                            <div class="col-md-8"></div>
                                                            <div class="col-md-2 padding-0 text-right points_text">
                                                                <p>{{account.serviceName}}:</p>
                                                            </div>
                                                            <div class="col-md-2 padding-0 text-right booking-time">
                                                                {{ jobDetailsByBooking.currencySymbol }}
                                                                {{account.price | number:'2.1-3'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <!-- <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.dues && jobDetailsByBooking.accounting.dues > 0">
    
                                                    <div class="col-md-12 float-right mt-10">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>Dues:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.dues | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <!-- <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.discount"> 
    
                                                    <div class="col-md-12 float-right mt-10">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>Discount:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.discount | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <!-- <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.paidByWallet == 1">
    
                                                    <div class="col-md-12 float-right mt-10">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>Paid by Wallet:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.captureAmount | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-md-12 float-right ">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <b>Final Total:</b>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.total | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Actvity Timeline">
                        <div>
                            <div class="tabs_details details_description2 ">
                                <div *ngIf="this.jobDetailsByBooking.jobStatusLogs">
                                    <div *ngFor="let timeList of this.jobDetailsByBooking.jobStatusLogs">
                                        <!-- expiredTime -->
                                        <div class="provider_activity_body" *ngIf="timeList.status == 1">

                                            <span class="provider_bullet_points"
                                                style="background:#d10c78 !important"></span>
                                            <span class="provider_activity_status">Booking Requested At :
                                                {{timeList.timeStamp *1000| date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                            <span class="provider_bullet_line_new"></span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 2">
                                            <span class="provider_bullet_points"
                                                style="background:#ba68c8!important"></span>
                                            <span class="provider_activity_status">Booking Received By Provider At : {{timeList.timeStamp
                                                    *1000 | date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                            <span class="provider_bullet_line_new"></span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 3">

                                            <span class="provider_bullet_points"
                                                style="background:#e191bd !important"></span>
                                            <span class="provider_activity_status">Booking Accepted By Provider At :
                                                {{timeList.timeStamp *1000| date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                            <span class="provider_bullet_line_new"></span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 4">

                                            <span class="provider_bullet_points"
                                                style="background:#82094b !important"></span>
                                            <span class="provider_activity_status">Booking Rejected At : {{timeList.timeStamp
                                                *1000| date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                            <span class="provider_bullet_line_new"></span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 5">

                                            <span class="provider_bullet_points"
                                                style="background:#923656!important"></span>
                                            <span class="provider_activity_status">Booking Expired At : {{timeList.timeStamp *1000|
                                                date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                            <span class="provider_bullet_line_new"></span>
                                        </div>

                                        <div class="provider_activity_body" *ngIf="timeList.status == 6">
                                            <span class="provider_bullet_points"
                                                style="background:#ffc700!important"></span>
                                            <span class="provider_activity_status">Provider on the way : {{timeList.timeStamp
                                                *1000| date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                            <span class="provider_bullet_line_new"></span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 7" id="onTheWay">

                                            <span class="provider_bullet_points"
                                                style="background:#c0392b!important"></span>
                                            <span class="provider_activity_status">Provider Arrived At Location :
                                                {{timeList.timeStamp *1000| date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                            <span class="provider_bullet_line_new"></span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 8">

                                            <span class="provider_bullet_points"
                                                style="background:#FC8344!important"></span>
                                            <span class="provider_activity_status">Provider Started Job On :
                                                {{timeList.timeStamp *1000| date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                            <span class="provider_bullet_line_new"></span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 9">
                                            <span class="provider_bullet_points"
                                                style="background:#0078ff!important"></span>
                                            <span class="provider_activity_status">Job Completed By Provider At : {{timeList.timeStamp *1000 |
                                                    date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 10">
                                            <span class="provider_bullet_points"
                                                style="background:#89a2bf!important"></span>
                                            <span class="provider_activity_status">Provider Raised Job Invoice At : {{timeList.timeStamp *1000 |
                                                    date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 11">
                                            <span class="provider_bullet_points"
                                                style="background:#187056!important"></span>
                                            <span class="provider_activity_status">Booking Cancelled By Provider At : {{timeList.timeStamp *1000 |
                                                    date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                        </div>
                                        <div class="provider_activity_body" *ngIf="timeList.status == 12">
                                            <span class="provider_bullet_points"
                                                style="background:#235a24!important"></span>
                                            <span class="provider_activity_status">Booking Cancelled By Customer At : {{timeList.timeStamp *1000 |
                                                date:'medium'}}
                                                <span class="activity_status_text"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <div *ngIf="AnswerDetails && AnswerDetails.length > 0">
                        <mat-tab label="Answered Questions" id="AnswersList" style="display:none;">
                            <div>
                                <div class="tabs_details details_description2 ">
                                    <div class="provider_activity_body">
                                        <div *ngFor="let questions of AnswerDetails; let i = index;">
                                            <span class="col-md-12 padding-0"><b>{{i+1}})&nbsp;
                                                    &nbsp;{{questions.name}}</b></span><br>
                                            <span *ngIf="questions.questionType === 10">
                                                <span class="listImg" *ngIf="questions.answer.length > 0">
                                                    <span class="imagsProduct"
                                                        *ngFor="let img of questions.answer; let i = index;">
                                                        <img class="sellImg sellImgs" src="{{img}}"
                                                            onError="this.src='https://d2qb2fbt5wuzik.cloudfront.net/yelowebsite/images/default-thumnail.png';">
                                                    </span>
                                                </span>
                                            </span>
                                            <span *ngIf="questions.questionType === 7"
                                                class="col-md-12 padding-0 {{questions.questionType}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <img src="assets/svgImages/check.svg" class="opacityChange" width="12px"
                                                    height="12px">&nbsp;&nbsp;
                                                <span class="" *ngIf="questions.answer.length === 1">
                                                    <span class="checkbox1">{{questions.answer[0].name}}</span>
                                                </span>
                                                <span *ngIf="questions.answer.length > 1">
                                                    <span *ngFor="let checkBox of questions.answer">
                                                        <span
                                                            class="{{checkBox.name}} checkbox1">{{checkBox.name}},&nbsp;&nbsp;</span>
                                                    </span>
                                                </span>
                                            </span>
                                            <span *ngIf="questions.questionType !== 7 && questions.questionType !== 10"
                                                class="col-md-12 padding-0 {{questions.questionType}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <img src="assets/svgImages/check.svg" class="opacityChange" width="12px"
                                                    height="12px">&nbsp;&nbsp;
                                                {{questions.answer}}</span><br>
                                            <hr style="color:black"><br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </div>
                </mat-tab-group>
            </div>
        </div>
    </div>

</ngx-smart-modal>
<div class="example-container mat-elevation-z2">

    <div class="col-12" *ngIf="completeCount > 0">
        <mat-table class="taskScroller" #table [dataSource]="dataSource">

            <!-- Position Column -->
            <ng-container matColumnDef="Booking-Id">
                <mat-header-cell *matHeaderCellDef> Booking-Id </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <a class="detailClick">
                        <b>{{element.bookingId}}</b>
                    </a>
                </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="Name">
                <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="col-xs-12">
                        <span>{{element.customerData.firstName}} {{element.customerData.lastName}}</span>
                        <p>({{element.customerData.phone}})</p>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="Email">
                <mat-header-cell *matHeaderCellDef style="text-align:center"> Email Address </mat-header-cell>
                <mat-cell *matCellDef="let element" style="text-align:center">
                    <span *ngIf="element.customerData.email">{{element.customerData.email}}</span>
                    <span *ngIf="!element.customerData.email" class="text-center">--</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Drop-Location">
                <mat-header-cell *matHeaderCellDef> Location </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.addLine1}},{{element.addLine2}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Driver">
                <mat-header-cell *matHeaderCellDef style="text-align:center"> Provider Name </mat-header-cell>
                <mat-cell *matCellDef="let element" style="text-align:center">
                    <div class="col-xs-12">
                        <span *ngIf="element.providerData.name">{{element.providerData.name}}</span>
                        <span *ngIf="!element.providerData.name">--</span>
                        <p *ngIf="element.providerData.phone">({{element.providerData.phone}})</p>
                        <p *ngIf="!element.providerData.phone">--</p>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Status">
                <mat-header-cell *matHeaderCellDef style="text-align:center"> Status </mat-header-cell>
                <mat-cell *matCellDef="let element" style="text-align:center">
                    <span *ngIf="element.statusMsg">{{element.statusMsg}}</span>
                    <span *ngIf="!element.statusMsg" class="text-center">--</span>
                </mat-cell>
            </ng-container>
            <!-- Card #116 : the requested time is fixed -->
            <ng-container matColumnDef="OrderTime">
                <mat-header-cell *matHeaderCellDef style="text-align:center"> Requested At </mat-header-cell>
                <mat-cell *matCellDef="let element" style="text-align:center;">
                    <span *ngIf="element.bookingRequestedFor">{{element.bookingRequestedAt * 1000| date:'mediumDate'}},{{element.bookingRequestedFor * 1000 |
                            date:'shortTime'}}
                    </span>
                    <span *ngIf="!element.bookingRequestedFor" class="text-center"> -- </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="DeliveryTime">
                <mat-header-cell *matHeaderCellDef style="text-align:center"> Completed At </mat-header-cell>
                <mat-cell *matCellDef="let element" style="text-align:center">
                    <span *ngIf="element.bookingCompletionTime">{{element.bookingCompletionTime * 1000| date:'mediumDate'}},{{element.bookingCompletionTime * 1000|
                            date:'shortTime'}}
                    </span>
                    <span *ngIf="!element.bookingCompletionTime" class="text-center"> -- </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Cart-Total">
                <mat-header-cell *matHeaderCellDef style="text-align:right"> Total </mat-header-cell>
                <mat-cell *matCellDef="let element" style="text-align:right"> {{element.currencySymbol}}
                    {{element.totalAmount | number:'2.1-3'}} </mat-cell>
            </ng-container>
            <!-- issues : add details button , fixedBy:sowmyaSV, date: 26-12-19 -->
            <ng-container matColumnDef="Details">
                <mat-header-cell *matHeaderCellDef style="text-align:right"> Details </mat-header-cell>
                <mat-cell *matCellDef="let element" style="text-align:right">
                    <button class="btn btn-info" (click)="show_details(element)"
                        style="cursor:pointer; font-size: 11px !important;">View More</button> </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-progress-bar *ngIf="bookingMode == 'query'" class="example-margin" [color]="color" [mode]="bookingMode"
            [value]="value" [bufferValue]="bufferValue">
        </mat-progress-bar>
        <mat-paginator *ngIf="completeCount > 0" #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 15, 20]"
            (page)="onPaginateChange($event)">
        </mat-paginator>
    </div>
    <div class="col-12" *ngIf="completeCount <= 0">
        <img class="centerImg" src="assets/images/empty.png">
    </div>
</div>