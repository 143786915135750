import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import 'rxjs/Rx';
declare var jQuery: any;



@Injectable()
export class apiServiceManager {

    public newUrl = 'https://api.shegamuyadev.com/';
    public SetForDetails = [];
    token: string;
    public newStatus = {}
    constructor(
        private _router: Router,
        private _http: HttpClient,
        private cookieService: CookieService) {
        sessionStorage.getItem('ip') && sessionStorage.getItem('ip').length > 0 ? this.cookieService.set('ip', sessionStorage.getItem('ip')) : '';
        //conole.log("")

        this.token = this.cookieService.get('user-token');
    }

    getToken() {
        this.token = this.cookieService.get('user-token');
        return this.token;
    }
    createAuthorizationHeader(headers: HttpHeaders) {
        // var token = sessionStorage.getItem('user-token');
        const token: string = this.cookieService.get('user-token');
        headers.set('authorization', token);
    }


    logOut(): Observable<any> {
        let Url = this.newUrl + "dispatcher/logout";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        // this.createAuthorizationHeader(headers);
        return this._http.post(Url, '', {
            headers: headers
        });
        // .map(res => res.json());
    }

    login(loginDetails): Observable<any> {
        let Url = this.newUrl + "dispatcher/signIn";
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this._http.post(Url, loginDetails, {
            headers: headers
        });
        // .map(res => res.json());
    }


    checkPromoCode(params): Observable<any> {
        let Url = this.newUrl + "dispatcher/promoCodeValidation";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.post(Url, params, {
            headers: headers
        });
        // .map(res => res.json());
    }
    DoUnDispatcheBooking(params): Observable<any> {
        let Url = this.newUrl + "dispatcher/unDispatchBooking";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.patch(Url, params, {
            headers: headers
        });
        // .map(res => res.json());
    }
    getProList(searchData): Observable<any> {
        let Url = this.newUrl + "dispatcher/provider/" + searchData.searchStr + "/" + searchData.pageIndex;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }
    getProviderListByCity(searchData): Observable<any> {
        // console.log('search data', searchData)
        let Url = this.newUrl + "dispatcher/provider/" + searchData.searchStr + "/" + searchData.lat + "/" + searchData.long + "/" + searchData.cityId + "/" + searchData.ipAddress + "/" + searchData.pageIndex;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }
    getProviderListForAllCityes(searchData): Observable<any> {
        let Url = this.newUrl + "dispatcher/provider/" + searchData.searchStr + "/" + 0 + "/" + 0 + "/" + 1 + "/" + "0" + "/" + searchData.pageIndex;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }
    getProBookings(pro): Observable<any> {
        let Url = this.newUrl + "dispatcher/booking/" + pro + "/0";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getProStatus(filterData): Observable<any> {
        let Url = this.newUrl + "dispatcher/provider/" + filterData.status + "/" + filterData.searchStr + "/" + filterData.pageIndex;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getAccessToken(accessTok): Observable<any> {
        let Url = this.newUrl + "dispatcher/accessToken";
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        headers.append('authorization', accessTok);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    autocompleteCustomer(type, value): Observable<any> {
        let Url = this.newUrl + "dispatcher/customer/autoComplete/" + type + "/" + value;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getCategoryService(lat, long): Observable<any> {
        let ip = this.cookieService.get('ip');
        !ip ? ip = sessionStorage.getItem('ip') : '';
        let Url = this.newUrl + "dispatcher/categories/" + lat + "/" + long + "/" + ip;
        // let Url = this.newUrl + "dispatcher/categories/" + long.city + "/" + long.state + "/" + long.country;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }
    getCategoryServiceAutoComplete(lat, long, SearchCat): Observable<any> {
        let ip = this.cookieService.get('ip');
        !ip ? ip = sessionStorage.getItem('ip') : '';
        let Url = this.newUrl + "dispatcher/categories/" + lat + "/" + long + "/" + ip + "/" + SearchCat;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getCustomerAddress(customerId): Observable<any> {
        let Url = this.newUrl + "dispatcher/customer/address/" + customerId;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    addCustomerAddress(address): Observable<any> {
        let Url = this.newUrl + "dispatcher/customer/address";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.post(Url, address, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getFilteredProviders(data): Observable<any> {
        let Url = this.newUrl + "dispatcher/provider/filter";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.post(Url, data, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getService(cat, subCat, providerId): Observable<any> {
        let Url = this.newUrl + "dispatcher/services/" + cat + "/" + subCat + "/" + providerId;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // map(res => res.json());
    }

    cart(data): Observable<any> {
        let Url = this.newUrl + "dispatcher/cart";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.post(Url, data, {
            headers: headers
        });

        // .map(res => res.json());
    }

    deleteCart(data): Observable<any> {
        let Url = this.newUrl + "dispatcher/cart";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        let options = {
            headers: headers,
            body: data
        }
        this.createAuthorizationHeader(headers);
        return this._http.delete(Url, options);
        // .map(res => res.json());
    }

    getCart(categoryId, customerId, providerId, callType): Observable<any> {
        let Url = this.newUrl + "dispatcher/cart/" + categoryId + "/" + customerId + "/" + providerId + "/" + callType;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    // //dispatcher/checkOut

    checkOut(data): Observable<any> {
        let Url = this.newUrl + "dispatcher/checkOut";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.post(Url, data, {
            headers: headers
        });
        // .map(res => res.json());
    }

    registerCustomer(data): Observable<any> {
        let Url = this.newUrl + "dispatcher/customer/signUp";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.post(Url, data, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getBooking(data): Observable<any> {
        let Url = this.newUrl + "dispatcher/booking/" + data.pageIndex + "/" + data.from + "/" + data.to + "/" + data.searchStr;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getProviderForBooking(bookingData): Observable<any> {
        let Url = this.newUrl + "dispatcher/providers/booking";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.post(Url, bookingData, {
            headers: headers
        });
        // .map(res => res.json());
    }
    getProviderTimeSlots(bookingData): Observable<any> {
        let Url = this.newUrl + "customer/providerSlot/" + bookingData.providerId + '/' + bookingData.categoryId + '/' + bookingData.date + '/' + bookingData.callType;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    addJobServiceSearch(searchData): Observable<any> {
        let Url = this.newUrl + "dispatcher/services/" + searchData.searchStr + "/" + searchData.catId + "/" + searchData.subCatId + "/" + searchData.providerId
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    dispatchBooking(dispatchData): Observable<any> {
        let Url = this.newUrl + "dispatcher/booking";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.post(Url, dispatchData, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getPastJobs(pageIndex): Observable<any> {
        let Url = this.newUrl + "dispatcher/pastBooking/" + pageIndex;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }

    filterPastJob(filterData): Observable<any> {
        let Url = this.newUrl + "dispatcher/pastBooking/filter";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.post(Url, filterData, {
            headers: headers
        });
        // .map(res => res.json());
    }

    unassignBooking(data): Observable<any> {
        let Url = this.newUrl + "dispatcher/booking/unAssign";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.patch(Url, data, {
            headers: headers
        });
        // .map(res => res.json());
    }

    getCard(cid): Observable<any> {
        let Url = this.newUrl + "dispatcher/customer/card/" + cid;
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.get(Url, {
            headers: headers
        });
        // .map(res => res.json());
    }
    EditCart(data): Observable<any> {
        let Url = this.newUrl + "dispatcher/cart";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.patch(Url, data, {
            headers: headers
        });
        // .map(res => res.json());
    }
    CancelBooking(data): Observable<any> {
        let Url = this.newUrl + "dispatcher/cancelBooking";
        let headers = new HttpHeaders({
            'authorization': this.getToken()
        });
        this.createAuthorizationHeader(headers);
        return this._http.patch(Url, data, {
            // return this._http.patch(Url, {
            headers: headers,
            // body: data
        });
        // .map(res => res.json());
    }
    getMapStyles() {
        const mapStyles =
            [
                {
                    "stylers": [
                        {
                            "hue": "#ff1a00"
                        },
                        {
                            "invert_lightness": true
                        },
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 33
                        },
                        {
                            "gamma": 0.5
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#2D333C"
                        }
                    ]
                }
            ]
        return mapStyles;
    }

    imageUploaderOptions() {
        let options =
        {
            url: `https://api.helperbee.com/imageUpload`,
            disableMultipart: false,
            autoUpload: true,
            method: 'post',
            itemAlias: 'photo',
            allowedFileType: ['image']
        }
        return options;
    }

}