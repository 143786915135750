import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { apiServiceManager } from '../service/api-service-manager';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/map';
import * as moment from 'moment';
declare var jQuery: any;
declare var $: any;
declare var Fingerprint: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [apiServiceManager],
})


export class LoginComponent implements OnInit {
  userEmail: any;
  password: any;
  mailResponse_err: any;
  password_err: any;
  login_err: any = { message: "" };
  online$: Observable<boolean>;
  cookieValue = 'UNKNOWN';
  currentYear = moment().format('YYYY');
  constructor(private _apiServiceManager: apiServiceManager,
    private router: Router,
    private cookieService: CookieService) {
    this.login_err = {
      message: ""
    };
    const allCookies: {} = this.cookieService.getAll();
    console.log('allCookies', allCookies);
    // if (allCookies) {
    //   this.cookieService.delete('cityId');
    // }
    // this.cookieService.();
    // to check online or offline
    // for internet connection check
    // issue: When the Internet is disconnected the empty modal is shown , fixedBy:sowmyaSV, date:26-12-19
    this.online$ = Observable.merge(
      Observable.of(navigator.onLine),
      Observable.fromEvent(window, 'online').map((data) => { return true; }),
      Observable.fromEvent(window, 'offline').map((data) => { return false; })
    );
    this.online$.subscribe((data) => {
      !data ? this.login_err['message'] = "Please Check Interent Connection" : '';
    })
  }
  // card# 30, issue: after enter login button should hit, fixed :sowmyasv, date: 26th dec 19
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // console.log('key code', event.keyCode)
    event.keyCode == 13 ? this.dispatcherLogin() : '';

  }
  ngOnInit() {
    this.cookieValue = this.cookieService.get('user-token');
    //conole.log(this.cookieService.get('user-token'))
    // ////conole.log("the login credentials is :", this.cookieValue)
    if (this.cookieValue) {
      this.router.navigate(['dispatcher']);
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        sessionStorage.setItem('latitude', position.coords.latitude);
        sessionStorage.setItem('longitude', position.coords.longitude);
        this.cookieService.set('latitude', position.coords.latitude);
        this.cookieService.set('longitude', position.coords.longitude);
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        ////conole.log("*********positioin***********", pos);
      });
    } else {
      sessionStorage.setItem('latitude', '12.9716');
      sessionStorage.setItem('longitude', '77.5946');
    }
    $.getJSON('https://api.ipify.org?format=jsonp&callback=?', (data) => {
      this.cookieService.set('ip', data.ip);
    });
    let ipAddr = this.cookieService.get('ip');
    if (!ipAddr) {
      // $.get('https://ipapi.co/json').then((res)=>{
      //   this.cookieService.set('ip',res.ip);
      //   this.cookieService.set('countryCode', res.country);

      // }
      // ,error=>{
      //   $.getJSON('https://api.ipify.org?format=jsonp&callback=?', function(data) {
      //     this.cookieService.set('ip',data.ip);
      //   });
      // )
      // $.getJSON('https://api.ipify.org?format=jsonp&callback=?', function(data) {
      //   //conole.log("login", data);
      //   sessionStorage.setItem('ip', data['ip'])
      // });
      $.get('https://ipapi.co/json').then((res) => {
        // isssue: undefined is passing to api, fixedBy: sowmyaSV, date: 26-12-19
        sessionStorage.setItem('ip', res.ip);
        this.cookieService.set('countryCode', res.country);
        !this.cookieService.get('latitude') ? this.cookieService.set('latitude', res.latitude) : '';
        !this.cookieService.get('longitude') ? this.cookieService.set('longitude', res.longitude) : '';
      });
      this.cookieService.set('ip', sessionStorage.getItem('ip'));
      // //conole.log("login sessionStorage.getItem('ip')", sessionStorage.getItem('ip'));
      // sessionStorage.getItem('ip') ? this.cookieService.set('ip',sessionStorage.getItem('ip')) :'';
    }
  }
  ngOnDestroy() {
    $("app-login").hide();
  }
  validateEmail(email) {
    if (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let res = re.test(email.replace(/\s+/g, ''));
      console.log("res", res)
      return re.test(email);
    }
    // else{
    //   this.login_err['message'] = "Please fill the fields"
    // }
    // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // let res = re.test(email.replace(/\s+/g, ''));
    // console.log("res", res)
    // return re.test(email);
  }

  checkEmail() {
    if (this.userEmail) {
      var mailResponse = this.validateEmail(this.userEmail);
      if (!mailResponse) {
        this.mailResponse_err = "Enter a valid Mail ID"
      };
    }
    else {
      // this.login_err['message'] = "Please fill the fields"
    }
  }

  clearAll() {
    this.mailResponse_err = '';
    this.password_err = '';
    this.login_err = '';
  }
  loginDisabled = false;
  loaderDisabled = false;
  dispatcherLogin() {
    this.loginDisabled = true;
    this.loaderDisabled = true;
    $('.form-control').addClass("loadinggif");
    // isssue: Trim the white space in email, fixedBy: sowmyaSV, date: 26-12-19
    this.userEmail ? this.userEmail = this.userEmail.trim() : '';
    var mailResponse = this.validateEmail(this.userEmail);
    console.log("mailResponse", mailResponse)
    if (!mailResponse) {
      $('.form-control').removeClass("loadinggif");
      this.mailResponse_err = "Enter a valid Mail ID";
      this.loaderDisabled = false;
    } else if (!this.password) {
      $('.form-control').removeClass("loadinggif");
      this.password_err = " Password is Required";
      this.loaderDisabled = false;
    }

    if (this.password && mailResponse) {

      var data = {
        email: this.userEmail,
        password: this.password
      }

      this._apiServiceManager.login(data).subscribe(result => {
        this.loginDisabled = false;
        this.loaderDisabled = false;

        $('.form-control').removeClass("loadinggif");
        this.cookieService.set('user-token', result.data.token);
        this.cookieService.set('cityId', result.data.cityId);
        this.cookieService.set('loginUser', result.data.id);
        this.cookieService.set('CityName', result.data.cityName);
        this.router.navigate(['dispatcher']);
      }, error => {
        this.loginDisabled = false;
        this.loaderDisabled = false;
        console.log(error);
        this.login_err = {
        };
        if (typeof error['_body'] === "string") {
          this.login_err = JSON.parse(error._body);
        }
        else {
          (error.status === 0) ? this.login_err.message = "Please Check Interent Connection" : this.login_err.message = error.error.message;
        }
        $('.form-control').removeClass("loadinggif");
      }, () => {
      })
    }
    else {
      // isssue: Disabled Attr is not removed even after the password or email is entered, fixedBy: sowmyaSV, date: 26-12-19
      this.loginDisabled = false;
      this.loaderDisabled = false;
    }
  }


}