// import { Component, OnInit, NgModule } from '@angular/core';
// import { RouterModule, Routes } from '@angular/router';
// import { ChangeDetectorRef } from '@angular/core';
// import { Router } from '@angular/router';
// import { MatTabChangeEvent } from '@angular/material/tabs';
// import { Paho } from 'ng2-mqtt';
// import { DatePipe } from '@angular/common';
// import { FormControl } from '@angular/forms';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Observable } from 'rxjs';
// import { apiServiceManager } from '../../service/api-service-manager';
// import { mqttManager } from '../../service/mqtt-manager';
// import { CookieService } from 'ngx-cookie-service';
// import swal from 'sweetalert2';

// declare var jQuery: any;
// declare var $: any;
// declare var Fingerprint: any;
// declare var google: any;
// declare var placeMarkerAndPanTo: any;


// import * as moment from 'moment';
// import { throwToolbarMixedModesError } from '@angular/material';
// @Component({
//   selector: 'app-godsview',
//   templateUrl: './godsview.component.html',
//   styleUrls: ['./godsview.component.css', '../dispatcher-home/dispatcher-home.css', '../tasks/tasks.component.css']
// })
// export class GodsviewComponent implements OnInit {
//   ForPagination:boolean =false;
//   searchBox:any;
//   currentInfoWindow:any = null; 
//   cityBrokerID: any = "";
//   // ForSearchBox:boolean =false;
//   ForAutoComplete:any;
//   map1: any;
//   CitySelected:any= false;
//   TabCode:any;
//   origin_autocomplete1: any;
//   searchPoints: any;
//   markerSearch: any;
//   busyProCount: any;
//   offlineProCount: any;
//   onlineProCount: any;
//   logoutProCount: any;
//   inactiveProCount: any;
//   ProviderAll: any = [];
//   available: any = [];
//   busy: any = [];
//   offline: any = [];
//   loggedOut: any = [];
//   inactive: any = [];
//   markerAvailable: any = [];
//   markerBusy: any = [];
//   markerOffline: any = [];
//   markerloggedOut: any = [];
//   markerInactive: any = [];
//   availablePoints: any = [];
//   busyPoints: any = [];
//   offlinePoints: any = [];
//   loggedOutPoints: any = [];
//   searchResult: any = [];
//   windowLatLng: any = [];
//   availablePointsPaginated: any = [];
//   busyPointsPaginated: any = [];
//   offlinePointsPaginated: any = [];
//   loggedOutPointsPaginated: any = [];
//   actPind: any = 0;
//   busyPind: any = 0;
//   offPind: any = 0;
//   logoutPind: any = 0;
//   mUpdate: any = [];
//   bookingDetails: boolean = false;
//   detailsPopUp: boolean = false;
//   jobImageArray: any;
//   jobDetailsByBooking: any;
//   forDate: any;
//   thisServiceList: any;
//   selectedProvider: any;
//   selectedProviderBooking: any;
//   noBooking: boolean = false;
//   currency: any = 'USD';
//   selectedProviderAddress: any;
//   selectedJobAddress: any;
//   selectedOptions: any;
//   createdDate: any;
//   acceptedDate: any;
//   onTheWay: any;
//   arrivedAt: any;
//   jobStarted: any;
//   flightPlanCoordinates: any;
//   itemList: any;
//   timer: any;
//   indexOne:number = 0;
//   indexTwo:number = 0;
//   indexThree:number = 0;
//   indexFour:number = 0;
//   providerSearchString: any;
//   searchControll = new FormControl();
//   avai:any=[];
//   off:any=[];
//   on:any=[];
//   logged:any=[];
//   CitiesProviderList:any;
//   SearchCityLng:any=0;
//   SearchCityLat:any=0;
//   SearchCityId:any;
//   getCityLatLng:any;
//   // CitySearchString:any=String;
//   CityProviders=new FormControl('');
//   LatForAddr:any;
//   LngForAddr:any;
//   Latitude:any;
//   Longitude:any;
//   constructor(private _apiServiceManager: apiServiceManager, private router: Router, private _mqttManager: mqttManager, private ref: ChangeDetectorRef,
//     private cookieService:CookieService
// ) {

//   }


//   getMqttMessage() {
//     this.CitySelected = false;
//     this._mqttManager.getMessages().subscribe((message: Paho.MQTT.Message) => {
//       let mqttData = JSON.parse(message.payloadString);
//       this._mqttManager.getMqttMsg(mqttData);
//       switch (mqttData.destinationName) {
//          // Card Id: none, bug desc: Dynamically timeline and status msg should be update, fix desc: getting mqttdata in which case is BookingStatus, developer:sowmya sv, date : 26-11-18
//         case "bookingStatus": this.jobDetailsByBooking = mqttData;
//                               if(this.selectedProviderBooking){
//                                 let index = this.selectedProviderBooking.findIndex((item) => item.bookingId == mqttData.bookingId);
//                                 if(index > -1){
//                                   this.selectedProviderBooking[index].statusMsg = mqttData.statusMsg;
//                                 }
//                               }
//                               // this.selectedProviderBooking = mqttData;
//                               break;

//         case 'providerStatus':
//           //bug: dynamically show provider details, fixed desc: get mqttData based on provider status, developler: sowmya sv
//           // date: 27-11-18
//           if(this.selectedProvider){
//             if(this.selectedProvider._id == mqttData._id){
//               this.selectedProvider = mqttData;
//             }
//           }
//           this.providerChanges(mqttData);
//           break;

//         case 'providerLocation':
//         this.providerLocationUpdate(mqttData);
//           break;
//       }
//     if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//     });
//   }

//   clearMap() {
//     if (this.markerAvailable && this.markerAvailable.length > 0) {
//       for (var i = 0; i < this.markerAvailable.length; i++) {
//         this.markerAvailable[i].setMap(null);
//       }
//       this.markerAvailable = [];
//     }

//     else if (this.markerOffline && this.markerOffline.length > 0) {
//       for (var i = 0; i < this.markerOffline.length; i++) {
//         this.markerOffline[i].setMap(null);
//       }
//       this.markerOffline = [];
//     }

//     else if (this.markerloggedOut && this.markerloggedOut.length > 0) {
//       for (var i = 0; i < this.markerloggedOut.length; i++) {
//         this.markerloggedOut[i].setMap(null);
//       }
//       this.markerloggedOut = [];
//     }

//     else if (this.markerInactive && this.markerInactive.length > 0) {
//       for (var i = 0; i < this.markerInactive.length; i++) {
//         this.markerInactive[i].setMap(null);
//       }
//       this.markerInactive = [];
//     }
//     if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//   }

//   placeMarker(arrayCode) {
//     // this.clearMap();
//   // if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//     this.TabCode=arrayCode;
//     // setTimeout(() => {
//       switch (arrayCode) {
//         case 0:
//         this.clearMap();
//           var greenMarker = "assets/images/greenMarker.svg";
//           this.markerAvailable.length === 0 ? this.driverSorting(this.available, this.markerAvailable, greenMarker) :'';
//           //console.log("this.markerAvailable",this.markerAvailable)
//           break;
//         case 1:
//           this.clearMap();
//           var greyMarker = "assets/images/grayMarker.png";
//           this.markerOffline.length === 0 ? this.driverSorting(this.offline, this.markerOffline, greyMarker) :'';
//           //console.log("this.markerOffline",this.markerOffline)
//           break;
//         case 2:
//         this.clearMap();
//           var inact = "assets/images/inact40.png";
//           this.markerInactive.length === 0 ? this.driverSorting(this.inactive, this.markerInactive, inact) :'';
//           //console.log("this.markerInactive",this.markerInactive)
//           break;
//         case 3:
//           this.clearMap();
//           var redMarker = "assets/images/redMarker.png";
//           this.markerloggedOut.length === 0 ? this.driverSorting(this.loggedOut, this.markerloggedOut, redMarker) :'';
//           //console.log("this.markerloggedOut",this.markerloggedOut)
//           break;
//           default:break;
//       }
//   }

//   setmyMap(param1, param2) {
//     var map = new google.maps.Map(document.getElementById('godsviewmap'), {
//       center: new google.maps.LatLng(param1, param2),
//       zoom: 8,
//       styles: this._apiServiceManager.getMapStyles(),
//       controlSize: 20,
//           // minZoom: 1,
//       streetViewControl: false,
//       // minZoom: 1,
//       gestureHandling: 'cooperative'
//     });
//     this.map1 = map;
//   }

//   ngOnInit() {
//     this.CitySelected = false;
//     let latt = sessionStorage.getItem('latitude');
//     let long = sessionStorage.getItem('longitude');
//     sessionStorage.removeItem("LatForProvider");
//     sessionStorage.removeItem("LngForProvider");
//     this.ForAutoComplete = 2;
//       this.cityBrokerID = this.cookieService.get('cityId');
//       if(this.cityBrokerID.length == 1){
//           this.getAllProvider(0,0);
//       }
//       else if(this.cityBrokerID.length > 1){
//            this.getProviderByCity(this.cityBrokerID,1)
//       }
//       this.setmyMap(latt, long);
//       this.getMqttMessage();

//     jQuery('#appt_loader_parent').show();
//     jQuery('#back_button').show();
//     jQuery('#pac-input2').show();
//     jQuery('#godsView').hide();

//     $(document).ready(() => {
//       // $('#example-getting-started').multiselect();
//     });


//     // LEFT MARKER CLICK FUNCTION
//     jQuery(".left_marker").click(function () {
//       var k = jQuery('.left-window').width();
//       var y = jQuery(window).width();
//       var result = y - k;
//       if (jQuery(".left-window").hasClass('open')) {
//         jQuery(".left-window").animate({ left: -k });
//         jQuery(".left-window").removeClass('open');
//         jQuery(".icn_left").addClass('rotate_pointer');
//         jQuery('#map>div').animate({ marginLeft: '0%' });
//       }
//       else {
//         jQuery(".left-window").animate({ left: '0' });
//         jQuery(".left-window").addClass('open');
//         jQuery(".icn_left").removeClass('rotate_pointer');
//         jQuery('#map>div').animate({ marginLeft: k });
//       }
//     });

//     // MAP FILTER CLICK HIDE AND SHOW
//     $('#floating_button_body').hide();
//     $("#floating_button").click(function () {
//       $("#floating_button_body").toggle(
//         function () {
//           $('#floating_button_body').animate({
//             height: "auto",
//           });
//         });
//     })

//     var that = this;
//     // FOR HIDING THE SEARCH INPUT
//     jQuery('#sesarch_driver_close').click(function () {
//       $("#right_search_front").show();
//       $("#right_search_back").hide();
//       jQuery('#search_provider').val("");
//       // GETTING CLIENTS IP ADDRESS
//       var what = this;
//       // that.ngOnInit();
//       jQuery.get("//ipapi.co/json/", function (response) {
//         this.onLoadLatitude = (response.latitude);
//         this.onLoadLongitude = (response.longitude);
//         var that = this;
//         // INITIALIZING MAP
//         var map = new google.maps.Map(document.getElementById('godsviewmap'), {
//           center: new google.maps.LatLng(this.onLoadLatitude, this.onLoadLongitude),
//           zoom: 10,
//           styles: this._apiServiceManager.getMapStyles(),
//           // minZoom: 1,
//           streetViewControl: false,
//         });
//         what.map1 = map;
//       });
//     });
//     var x = this;
//     this.searchBox = document.getElementById('pac-input');
//     this.map1.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('pac-input'));

//     this.searchBox = document.getElementById('pac-input');
//     this.map1.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('pac-input'));
//     this.origin_autocomplete1 = new google.maps.places.Autocomplete(this.searchBox);
//     this.origin_autocomplete1.bindTo('bounds', this.map1);

//   }

//   ngOnDestroy() {
//     $("app-godsview").hide();
//   }

//   trackBooking(data) {

//     if (this.selectedProviderBooking.findIndex((item) => item.bookingId == data.bookingId) >= 0) {
//       var index = this.selectedProviderBooking.findIndex((item) => item.bookingId == data.bookingId);
//       this.selectedProviderBooking[index].statusMsg = data.statusMsg;
//       this.selectedProviderBooking[index].status = data.status;
//     }
//   if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//   }


//   change(value,caseValue) {
//     //conole.log("statusProvider",typeof value)
//     this.clearMap();
//     this.available = [];
//     var filter = value.toString();
//     value !== "1" ? this.filterProviderStatus(filter,caseValue): this.getAllProvider(0,0);
//   }

//   filterProviderStatus(value,caseValue) {
//     let filterData = {
//       pageIndex: 0,
//       status: value,
//     }
//     filterData['searchStr'] = this.checkProviderString(this.providerSearchString);
//     //conole.log("filter",filterData )
//     jQuery('#appt_loader_parent').show();
//     this._apiServiceManager.getProStatus(filterData).subscribe(result => {
//       jQuery('#appt_loader_parent').hide();
//       //conole.log("res from filter provider",result.data)
//       switch(caseValue){
//         case 1: this.onlineProCount = result.data.onlineProCount;
//                 this.available = result.data.onlinePro;
//                 break;

//         case 2: this.offlineProCount = result.data.offlineProCount;
//                 this.offline = result.data.offlinePro;
//                 break;

//         case 3: this.inactiveProCount = result.data.inactiveProCount;
//                 this.inactive = result.data.inactivePro;
//                 break;

//         case 4: this.logoutProCount = result.data.logoutProCount;
//                 this.loggedOut = result.data.logoutPro;
//                 break;

//       }
//       this.placeMarker(0);
//     }, error => {
//       jQuery('#appt_loader_parent').hide();
//       (error.status == 498 || error.status === 440 ) ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();

//     });
//   if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//   }

//   logOut() {
//     sessionStorage.removeItem("loginUser");
//     sessionStorage.removeItem("user-token");
//     this._mqttManager._client.disconnect();
//     this.router.navigate(['login']);
//   }

//   expireToken() {
//     this._apiServiceManager.logOut().subscribe(result => { });
//     $("#appt_loader_loggedOut").show();
//   }

//   checkCategory(array, id) {
//     for (var i = 0; i < array.length; i++) {
//       // (array[i].user == id);
//       if (array[i].user == id) {
//         return 1;
//       }
//     }
//     return 0;
//   }

//   // SEARCH PLACE ON MAP
//   searchPlace(event) {
//     // ("search place")
//     // this.ForSearchBox =true;
//     var marker = new google.maps.Marker({
//       map: this.map1,
//       anchorPoint: new google.maps.Point(0, -29)
//     });
//     marker.setVisible(false);
//     let Place = event.target.value;
//     // (Place == '') ? this.getAllProvider(0,0) : '';
//     // this.CityProviders.reset();
//     // this.ForAutoComplete = 0;
//     var place;
//     let cityId = '1';
//     var AutoCom = google.maps.event.addListener(this.origin_autocomplete1, 'place_changed', () => { // arrow function
//     place = this.origin_autocomplete1.getPlace();
//     // if (place.length == 0) 
//     //   return;
//     // else{
//     sessionStorage.setItem("LatForProvider", place.geometry.location.lat());
//     sessionStorage.setItem("LngForProvider", place.geometry.location.lng());
//     //conole.log("this.cityBrokerID",this.cityBrokerID,cityId,place);
//     place ? this.getProviderByCity(cityId,0) :'';
//     AutoCom.remove();
//     if (place.geometry.viewport) {
//       this.map1.fitBounds(place.geometry.viewport);
//     } else {
//       this.map1.setCenter(place.geometry.location);
//       this.map1.setZoom(10);  // Why 17? Because it looks good.
//     }
//     marker.setPosition(place.geometry.location);
//     marker.setVisible(true);

//     // this.cityBrokerID.length > 0 ? cityId = this.cityBrokerID : cityId = 1;
//     // //conole.log("this.cityBrokerID",this.cityBrokerID,cityId)
//     // place.length > 0 ? this.getProviderByCity(cityId.toString(),0) :'';
//     // this.getProviderByCity(cityId.toString(),0);
//     // }
//   });  

//   }

//   scrollTarget(val, stat) {
//     if (stat == 1) {
//       $("#available_providers").animate({
//         scrollTop: val
//       }, 100);
//     }
//     else
//       if (stat == 2) {
//         $("#busy_providers").animate({
//           scrollTop: val
//         }, 100);
//       }
//       else
//         if (stat == 3) {
//           $("#offline_providers").animate({
//             scrollTop: val
//           }, 100);
//         }
//         else
//           if (stat == 4) {
//             $("#loggedout_providers").animate({
//               scrollTop: val
//             }, 100);
//           }

//   }


//   setSearchImage(val) {
//     jQuery("#imageSearch-" + val).attr(
//       'src',
//       'http://iserve.ind.in/pics/driver_profile_pic.png'
//     );
//   }

//   executeAsynchronously(functions, timeout) {
//     for (var i = 0; i < functions.length; i++) {
//       setTimeout(functions[i], timeout);
//     }
//   }
//   refreshProviderSearch() {
//     this.providerSearchString = '';
//     $("#pac-input").val("");
//     this.searchControll.reset();
//     if(this.cityBrokerID.length == 1){
//       this.getAllProvider(0,0);
//     }
//     else if(this.cityBrokerID.length > 1){
//       this.getProviderByCity(this.cityBrokerID,1);
//     }
//     this.closeInfoWindow();
//   }
//   getAllProvider(initialIndex,toUpdate) {
//     if(this.cityBrokerID.length == 1){
//       if(this.CitySelected === false){
//       jQuery('#appt_loader_parent').show();
//       this.CitySelected = false;
//       let searchData = {
//         pageIndex: initialIndex,
//       }
//       searchData['searchStr'] = this.checkProviderString(this.providerSearchString)
//       this._apiServiceManager.getProviderListForAllCityes(searchData).subscribe(result => {
//         let response = result.data; this.ProviderAll = result.data;
//         this.CitiesProviderList=result.allCityData;
//         if(response.onlineProCount > 20)
//         {
//           this.getDetailsOfProvider(response,1);
//           var IndexValue = response.onlineProCount/20;
//           this.getBookingDataManager(1, Math.floor(IndexValue)); 
//         }
//         else
//         {
//           this.getDetailsOfProvider(result.data,1);
//         }
//         if(response.offlineProCount > 20)
//         {
//           this.getDetailsOfProvider(response,2);
//           var IndexValue = response.offlineProCount/20;
//           this.getBookingDataManager(2, Math.floor(IndexValue));
//         }
//         else
//         {
//           this.getDetailsOfProvider(result.data,2);
//         }
//         if(response.inactiveProCount > 20)
//         {
//           this.getDetailsOfProvider(response,3);
//           var IndexValue = response.inactiveProCount/20;
//           this.getBookingDataManager(3, Math.floor(IndexValue));
//         }
//         else
//         {
//           this.getDetailsOfProvider(result.data,3);
//         }
//         if(response.logoutProCount > 20)
//         {
//           this.getDetailsOfProvider(response,4);
//           var IndexValue = response.logoutProCount/20;
//           this.getBookingDataManager(4, parseInt(IndexValue.toString()));
//         }
//         else
//         {
//           this.getDetailsOfProvider(result.data,4);
//         }
//         this.placeMarker(0);
//         jQuery('#appt_loader_parent').hide();

//         // this.ref.detectChanges();
//       }, error => {
//         jQuery('#appt_loader_parent').hide();
//         var erRes = JSON.parse(error._body);
//         switch (error.status) {
//           case 440:
//             // call access token api to get new token
//             this._apiServiceManager.getAccessToken(erRes.data).subscribe(result => {
//               // call access provider api again to get data 
//               sessionStorage.setItem('user-token', result.data);
//               this._apiServiceManager.getProList(initialIndex).subscribe(result => { (" calling getProList "); },error =>{
//                 (error.status == 498 || error.status === 440 ) ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();
//               });
//             },error =>{
//               (error.status == 498 || error.status === 440 ) ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();
//             });
//             break;
//           case 498: case 502:
//             this.expireToken();

//             break;
//         }
//       }, () => {
//       });
//     }
//     else{
//       this.getProviderByCity(this.SearchCityId,1);
//     }
//   }
//     else{
//       this.getProviderByCity(this.cityBrokerID,0);
//     }
//   };
//   getBookingDataManager(toUpdate,PageIndex) {
//     for(let i=1;i<=PageIndex;i++)
//     {
//       // for Provider that is city is selected 
//       if(this.CitySelected == true)
//       {
//         let SearchData ={
//           ipAddress:this.cookieService.get('ip'),
//           pageIndex:i,
//           searchStr:"0",
//           lat:0,
//           long:parseFloat("0"),
//           cityId:this.SearchCityId,
//         }
//         SearchData['searchStr'] = this.checkProviderString(this.providerSearchString)
//         this._apiServiceManager.getProviderListByCity(SearchData).subscribe(result =>{
//           let resultData= result.data;
//           this.ResponseDataForPagination(toUpdate,result.data);
//         },error =>{
//           (error.status == 498 || error.status === 440 ) ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();
//         });
//       }
//     else
//      {
//         let searchData = {
//           pageIndex: i,
//         }
//         searchData['searchStr'] = this.checkProviderString(this.providerSearchString)
//         this._apiServiceManager.getProviderListForAllCityes(searchData).subscribe(result => {
//           // let response = result.data;
//           this.ResponseDataForPagination(toUpdate,result.data);
//         },error =>{
//           (error.status == 498 || error.status === 440 ) ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();
//         });
//       }
//     }
//   }
//   checkProviderString(Name){
//     if(typeof this.providerSearchString !== "undefined")
//       { 
//         if(this.providerSearchString == '' ) {
//           return '0';
//         }
//         else if(this.providerSearchString == null)
//         {
//         return '0';
//         }
//         else{
//           return Name;
//         }
//       }
//       else if(typeof this.providerSearchString == "undefined" ){
//         return '0';
//       }
//       else if(this.providerSearchString == null)
//       {
//         return '0';
//       }
//   }
//   ResponseDataForPagination(toUpdate,response)
//   {
//     switch (toUpdate) {
//       case 1:
//               this.pushPaginatedArray(response.onlinePro, this.available,toUpdate);
//               break;
//       case 2: 
//               this.pushPaginatedArray(response.offlinePro, this.offline,toUpdate);
//               break;
//       case 3:
//               this.pushPaginatedArray(response.inactivePro, this.inactive,toUpdate);
//               break;
//       case 4:
//               this.pushPaginatedArray(response.logoutPro, this.loggedOut,toUpdate);
//               break;
//     };
//   }
//   pushPaginatedArray(responseArray, destinationArray,toUpdate) {
//     if (responseArray.length > 0) {
//       for (var i = 0; i < responseArray.length; i++) {
//           destinationArray.push(responseArray[i]);
//         if(!this.ref['destroyed']){         
//           this.ref.detectChanges();       
//         }
//       }
//     }
//     this.assignForArrays(destinationArray,toUpdate);
//   }

//   assignForArrays(response,toUpdate) {
//     if(toUpdate ==1)
//     {
//       this.available = response;
//     }
//     if(toUpdate == 2)
//     {
//       this.offline = response;
//     }
//     if(toUpdate == 3)
//     {
//       this.inactive = response;
//     }
//     if(toUpdate == 4)
//     {
//       this.loggedOut= response;
//     }
//   }
//   getProviderListForAllCityes(initialPageIndex)
//   {
//     jQuery('#appt_loader_parent').show();
//      let SearchData={
//       ipAddress:this.cookieService.get('ip'),
//       pageIndex:initialPageIndex,
//      }
//      this._apiServiceManager.getProviderListForAllCityes(SearchData).subscribe(result =>{
//       //  var resultData= result.data;
//        this.CitiesProviderList = result.allCityData;
//       //  this.cityBrokerID = sessionStorage.getItem('cityId')
//        jQuery('#appt_loader_parent').hide();

//      }, error =>{
//        var Error = JSON.parse(error._body);
//        (error.status == 498 || error.status === 440 ) ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();
//        jQuery('#appt_loader_parent').hide();

//      });

//   }
//   getProviderByCity(initialCityId,value){
//     //conole.log("value",value,"initialCityId",initialCityId)
//     jQuery('#appt_loader_parent').show();
//     let SearchData ={
//       ipAddress:this.cookieService.get('ip'),
//       pageIndex:0,
//       lat:0,
//       long:parseFloat("0"),
//       cityId:initialCityId
//     }
//     if(value == 1){
//       $("#pac-input").val("");
//       sessionStorage.removeItem("LatForProvider");
//       sessionStorage.removeItem("LngForProvider");
//       this.ForAutoComplete = value;
//     }
//     else if(value == 0)
//     {
//       this.ForAutoComplete = value;
//       SearchData["cityId"]="0";
//       sessionStorage.getItem("LatForProvider") != null ? SearchData["lat"] =parseFloat(sessionStorage.getItem("LatForProvider")) : SearchData["lat"]=parseFloat("0");
//       sessionStorage.getItem("LngForProvider") != null ? SearchData["long"] =parseFloat(sessionStorage.getItem("LngForProvider")) : SearchData["long"]=parseFloat("0");
//     }
//     initialCityId !="1" ? this.CitySelected = true : this.CitySelected = false;
//     let stringofProvider = this.checkProviderString(this.providerSearchString)
//     SearchData['searchStr'] = this.checkProviderString(this.providerSearchString);
//     let indx = initialCityId;
//     this.SearchCityId = initialCityId;
//     //conole.log("SearchData--->",SearchData)
//     this._apiServiceManager.getProviderListByCity(SearchData).subscribe(result =>{
//       let resultData= result.data;
//       let CityList = result.allCityData;
//       this.CitiesProviderList= CityList;
//       this.getCityLatLng = result.selectedCityLocation;
//       jQuery('#appt_loader_parent').hide();
//       if(result.selectCityId != "1")
//       {
//         this.SearchCityId= result.selectCityId;
//         this.CitySelected= true;
//       }
//       this.available=[];
//       this.offline=[];
//       this.inactive=[];
//       this.loggedOut=[];
//       this.ProviderAll = result.data;
//       if(result.data.onlineProCount > 20)
//       {
//         this.getDetailsOfProvider(result.data,1);
//         var IndexValue = result.data.onlineProCount/20;
//         this.getBookingDataManager(1, Math.floor(IndexValue)); 
//       }
//       else
//       {
//         this.getDetailsOfProvider(result.data,1);
//       }
//       if(result.data.offlineProCount > 20)
//       {
//         this.getDetailsOfProvider(result.data,2);
//         var IndexValue = result.data.offlineProCount/20;
//         this.getBookingDataManager(2, Math.floor(IndexValue));
//       }
//       else
//       {
//         this.getDetailsOfProvider(result.data,2);
//       }
//       if(result.data.inactiveProCount > 20)
//       {
//         this.getDetailsOfProvider(result.data,3);
//         var IndexValue = result.data.inactiveProCount/20;
//         this.getBookingDataManager(3, Math.floor(IndexValue));
//       }
//       else
//       {
//         this.getDetailsOfProvider(result.data,3);
//       }
//       if(result.data.logoutProCount > 20)
//       {
//         this.getDetailsOfProvider(result.data,4);
//         var IndexValue = result.data.logoutProCount/20;
//         this.getBookingDataManager(4, parseInt(IndexValue.toString()));
//       }
//       else
//       {
//         this.getDetailsOfProvider(result.data,4);
//       }
//       this.placeMarker(0);
//    }, error =>{
//     jQuery('#appt_loader_parent').hide();     
//       var Error1 = JSON.parse(error._body);
//       if(error.status == 404)
//       {
//         swal.fire({text:Error1.message, type:"error"});
//       }
//       (error.status == 498 || error.status === 440 ) ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();
//     })

//   };

//   getDetailsOfProvider(ResponseData,UpdateFor)
//   {
//       switch(UpdateFor)
//       {
//           case 1: this.onlineProCount = ResponseData.onlineProCount;
//                   this.available =  ResponseData.onlinePro;
//                   break;

//           case 2: this.offlineProCount = ResponseData.offlineProCount; 
//                   this.offline = ResponseData.offlinePro;
//                   break;

//           case 3: 
//                   this.inactiveProCount =  ResponseData.inactiveProCount;
//                   this.inactive =  ResponseData.inactivePro;
//                   break;

//           case 4: this.logoutProCount = ResponseData.logoutProCount;
//                   this.loggedOut = ResponseData.logoutPro;
//                   break;
//       }
//     }
//   driverSorting(value1, value3, value4) {
//     var contentString;
//     var tracIcon = {
//       url: value4,  // url
//       scaledSize: new google.maps.Size(40, 40), // scaled size
//     };
//     var infowindow = new google.maps.InfoWindow();
//     var currentInfoWindow = null; 
//     let bounds = new google.maps.LatLngBounds();
//     value1.forEach((element,index) => {
//       let idx = value3.findIndex((item) => item.id == value1[index]._id);
//       if (idx < 0) {
//         value3.push(new google.maps.Marker({
//           position: { lat: value1[index].latitude, lng: value1[index].longitude },
//           map: this.map1,
//           icon: tracIcon,
//           animation: google.maps.Animation.DROP,
//           id: value1[index]._id
//         }));
//         //console.log("idx",idx,value3)

//         var name = value1[index].name;
//         var profilePic = value1[index].image || "assets/images/default.png";
//         var phone = value1[index].phone;
//         contentString = "<div class='infy' style='float:left' class='" + value1[index]._id + "'><img style='width:45px;height:45px;border-radius:50%;' src='" + profilePic + "'></div><div style='float:right; padding: 0px 0px 0px 11px;'><b>" + name + "</b></br><span style='font-size:11px'>" + phone + "</span></div>";
//         value3[index].setMap(this.map1);

//       }
//       //detects the image breaks and set it with default image 
//       var avlloc = new google.maps.LatLng(value1[index].latitude, value1[index].longitude);
//       bounds.extend(avlloc);
//       // this.map1.setZoom(5);
//       this.map1.panToBounds(bounds);
//       this.map1.fitBounds(bounds);
//       // this.map1.setZoom(10);
//     });
//   }

//   locate(obj) {
//     this.selectedProvider = '';
//     this.selectedProviderBooking = "";
//     this.selectedProviderAddress = '';
//     setTimeout(() => {
//       this.selectedProvider = obj;
//       // this.setdefaultImage();
//       this.toggleMapDiv(1);
//       $('.ProviderInfo').show(500);
//       this.getProviderBookings(obj._id);
//       //console.log("selected booking,",obj )
//       jQuery('#addressSearch').val('');
//       var map = this.map1.setOptions({
//         center: { lat: obj.latitude, lng: obj.longitude },
//         zoom:10,
//         styles: this._apiServiceManager.getMapStyles(),
//           // minZoom: 1,
//         streetViewControl: false,
//       });

//       // setTimeout(()=>{
//         this.findMarker(obj);
//       // },300)
//       // this.getProviderBookings(obj._id);
//       // this.geocode(obj);
//       // this.findMarker(obj);
//     if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//     }, 50)


//   }

//   toggleMapDiv(caseData) {
//     switch (caseData) {
//       case 1:
//         if ($(".mapDiv").hasClass('mapFull')) {
//           $(".mapDiv").removeClass('mapFull');
//           $('.mapDiv').addClass('mapHalf');
//         }
//         break;
//       case 2:
//         if ($(".mapDiv").hasClass('mapHalf')) {
//           $(".mapDiv").removeClass('mapHalf');
//           $('.mapDiv').addClass('mapFull');
//         }
//         break;
//     }
//   }
//   MarkerIndex:any = -1;
//   findMarker(toFind) {
//     let toFindAvailable;
//     this.markerAvailable && this.markerAvailable.length > 0 ?
//     toFindAvailable = this.markerAvailable.findIndex((item) => item.id == toFind._id) :toFindAvailable = this.available.findIndex((item) => item._id == toFind._id);

//     let toFindBusy;
//     this.markerBusy && this.markerBusy.length > 0 ?
//     toFindBusy = this.markerBusy.findIndex((item) => item.id == toFind._id) :toFindBusy = this.busy.findIndex((item) => item._id == toFind._id);

//     let toFindLoggedOut;
//     this.markerloggedOut && this.markerloggedOut.length > 0 ?
//     toFindLoggedOut = this.markerloggedOut.findIndex((item) => item.id == toFind._id) :toFindLoggedOut = this.loggedOut.findIndex((item) => item._id == toFind._id);

//     let toFindInactive;
//     this.markerInactive && this.markerInactive.length > 0 ?
//     toFindInactive = this.markerInactive.findIndex((item) => item.id == toFind._id) :toFindInactive = this.inactive.findIndex((item) => item._id == toFind._id)

//     let toFindOffline;
//     this.markerOffline && this.markerOffline.length > 0 ?
//     toFindOffline = this.markerOffline.findIndex((item) => item.id == toFind._id) : toFindOffline = this.offline.findIndex((item) => item._id == toFind._id);


//     var arrayMarks = [toFindAvailable, toFindOffline, toFindInactive, toFindBusy, toFindLoggedOut];
//     //console.log("toFindAvailable",arrayMarks)
//     for (var i = 0; i < arrayMarks.length; i++) {
//       var num = arrayMarks[i];
//       if (num >= 0) {
//         this.MarkerIndex = num;
//         this.switchForLocation(i, num, toFind);
//       }
//     }

//   }

//   switchForLocation(i, num, obj) {
//     switch (i) {
//       case 0: 
//         this.markerAvailable ? this.locateMarker(this.markerAvailable, num, obj): this.locateMarker(this.available, num, obj)
//         break;

//       case 1: 
//         this.markerOffline ? this.locateMarker(this.markerOffline, num, obj) : this.locateMarker(this.offline, num, obj)
//         break;

//       case 2: 
//         this.markerInactive ? this.locateMarker(this.markerInactive, num, obj) : this.locateMarker(this.inactive, num, obj)
//         break;

//       case 3: 
//         this.markerBusy ? this.locateMarker(this.markerBusy, num, obj) : this.locateMarker(this.busy, num, obj)
//         break;

//       case 4: 
//         this.markerloggedOut? this.locateMarker(this.markerloggedOut, num, obj) : this.locateMarker(this.loggedOut, num, obj)
//         break;
//     }
//   }
//   // Addrees need to show on map for Provider
//   locateMarker(param, num, obj) {
//     //conole.log("locate marker")
//     var infowindow = new google.maps.InfoWindow({
//       maxWidth: 200
//     });
//     if (param[num].id == obj._id) {
//       setTimeout(()=>{
//         this.geocode(obj);
//       })
//       setTimeout(() =>{
//         var profilePic = obj.image || "assets/images/default.png";
//         var phone = obj.phone || "NA";
//         if( this.currentInfoWindow ) {
//           this.currentInfoWindow.close();
//           param[num].setAnimation(null);
//         } 
//         param[num].setAnimation(google.maps.Animation.BOUNCE);
//         this.map1.setZoom(18);
//         let infoString = "<div class='infy' style='float:left' class='" + obj._id + "'><img style='width:45px;height:45px;border-radius:50%;' src='" + profilePic + "'></div><div style='float:right; padding: 0px 0px 0px 11px;'><b>" + obj.name + "</b></br><span style='font-size:11px'>" + phone + "</span><br/><span style='font-size:11px'>Drop Address : "+this.selectedJobAddress+"</span></div>"
//         infowindow.setContent(infoString);      
//         // infowindow.setContent("<div class='infy' style='float:left' class='" + obj._id + "'><img style='width:45px;height:45px;border-radius:50%;' src='" + profilePic + "'></div><div style='float:right; padding: 0px 0px 0px 11px;'><b>" + obj.name + "</b></br><span style='font-size:11px'>" + phone + "</span></div>");
//         infowindow.open(this.map1, param[num]);
//         this.currentInfoWindow = infowindow; 
//       },1000)
//     }
//     google.maps.event.addListener(infowindow, 'closeclick', () => {
//       param[num].setAnimation(null);
//       infowindow.close();
//     });
//   }

//   generateNoDataOnLateResponse() {
//     this.timer = setTimeout(() => {
//       this.selectedProviderBooking = [];
//     }, 2000)
//   };

//   getProviderBookings(value) {
//     this.noBooking = true;
//     // this.generateNoDataOnLateResponse();
//     this._apiServiceManager.getProBookings(value).subscribe(result => {
//       clearTimeout(this.timer);
//       this.selectedProviderBooking = result.data;
//       this.selectedProviderBooking = this.sortProviderBookignBasedOnDates(this.selectedProviderBooking);
//       this.noBooking = false;
//     }, error => {
//       var erRes = JSON.parse(error._body);
//       switch (error.status) {
//         case 440:
//           // call access token api to get new token
//           this._apiServiceManager.getAccessToken(erRes.data).subscribe(result => {
//             // call access getProviderBookings api again to get data 
//             sessionStorage.setItem('user-token', result.data);
//             this.getProviderBookings(value);
//           },error =>{
//             (error.status == 498 || error.status === 440 ) ?   $("#appt_loader_loggedOut").show() :  $("#appt_loader_loggedOut").hide();
//           });
//           break;
//         case 498:case 502:
//           this.expireToken()

//           break;
//       }
//     }, () => {
//      //conole.log("completed")
//     })
//   }
//   //card#90 ,bug : The bookings are not sorted according to date /There is no sorting order, fixedBy:sowmya
//   sortProviderBookignBasedOnDates(provideBookingList){
//     // let sortedList = provideBookingList.sort((a,b) => (moment(a.bookingRequestedFor, 'DD-MM-YYYY') > moment(b.bookingRequestedFor, 'DD-MM-YYYY')) ? 1 : ((moment(b.bookingRequestedFor, 'DD-MM-YYYY') > moment(a.bookingRequestedFor, 'DD-MM-YYYY')) ? -1 : moment(a.bookingRequestedFor, 'hh:mm A').isBefore(moment(b.bookingRequestedFor, 'hh:mm A')) ? -1 :  moment(b.bookingRequestedFor, 'hh:mm A').isBefore(moment(a.bookingRequestedFor, 'hh:mm A')) ? 1 : 0  )); 
//     let  sortedList = provideBookingList.sort(function (a, b) {
//       return  moment.utc(a.bookingRequestedFor).diff(moment.utc(b.bookingRequestedFor))
//     })
//     return sortedList;
//   }
//   closeInfoWindow() {

//     this.selectedProviderBooking = false;
//     $(".ProviderInfo").hide();
//     this.clearMap();
//     // this.setmyMap();
//     this.toggleMapDiv(2);
//     $("#floating_button_pat").addClass("right5");
//     $(".map-search").addClass("right5");
//   }
//   // provider movement changes online,offline,busy,loggedout
//   providerChanges(m) {
//     switch (parseInt(m.status)) {
//       // move provider to offline
//       case 4:
//       if(this.CitySelected == true)
//       {
//         if (this.offline.findIndex((item) =>item._id == m._id) < 0) {
//           var greyMarker = "assets/images/grayMarker.png";
//           // this.markerOffline = [];
//             if (this.available.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//               this.offlineProCount++; this.onlineProCount--;
//               this.driverTransfer(m, this.available, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerAvailable);
//             }
//             //check in loggedout
//             else if (this.loggedOut.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//               this.offlineProCount++; this.logoutProCount--;
//               this.driverTransfer(m, this.loggedOut, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerloggedOut);
//             }
//             //check in inactive
//             else if (this.inactive.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//               this.offlineProCount++; this.inactiveProCount--;
//               this.driverTransfer(m, this.inactive, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerInactive);
//             }

//             //add by default - select any array out of three
//             else {

//               // this.offlineProCount++;
//               if(this.SearchCityId === m.cityId)
//               {
//                 this.driverTransfer(m, this.available, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerAvailable);
//               }
//             }
//           if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//          }
//         }
//         else
//         {
//           if (this.offline.findIndex((item) => item._id == m._id) < 0) {
//             var greyMarker = "assets/images/grayMarker.png";
//             // this.markerOffline = [];
//             //check in online
//             if (this.available.findIndex((item) => item._id == m._id) >= 0) {
//               this.offlineProCount++; this.onlineProCount--;
//               this.driverTransfer(m, this.available, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerAvailable);
//             }
//             //check in loggedout
//             else if (this.loggedOut.findIndex((item) => item._id == m._id) >= 0) {
//               this.offlineProCount++; this.logoutProCount--;
//               this.driverTransfer(m, this.loggedOut, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerloggedOut);
//             }
//             //check in inactive
//             else if (this.inactive.findIndex((item) => item._id == m._id) >= 0) {
//               this.offlineProCount++; this.inactiveProCount--;
//               this.driverTransfer(m, this.inactive, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerInactive);
//             }

//             //add by default - select any array out of three
//             else {
//               this.offlineProCount++;
//               this.driverTransfer(m, this.available, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerAvailable);
//             }
//           if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//           }
//         }
//         break;

//       // move provider to Online
//       case 3:
//       if(this.CitySelected == true)
//       {
//         if (this.available.findIndex((item) => item._id == m._id) < 0) {
//           var greenMarker = "assets/images/greenMarker.svg";
//           // this.markerAvailable = [];
//           if (this.offline.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//             this.offlineProCount--; this.onlineProCount++;
//             this.driverTransfer(m, this.offline, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerOffline);
//           }
//           //check in inactive
//           else if (this.inactive.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//             this.onlineProCount++; this.inactiveProCount--;
//             this.driverTransfer(m, this.inactive, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerInactive);
//           }
//           //check in logged out
//           else if (this.loggedOut.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//             this.logoutProCount--; this.onlineProCount++;
//             this.driverTransfer(m, this.loggedOut, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerloggedOut);
//           }
//           //add by default
//           else {
//             if(this.SearchCityId === m.cityId)
//             {
//               this.driverTransfer(m, this.offline, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerOffline);
//             }
//           }
//         if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//       }
//     }
//     else
//       {
//         if (this.available.findIndex((item) => item._id == m._id) < 0) {
//           var greenMarker = "assets/images/greenMarker.svg";
//           //check in offline
//           if (this.offline.findIndex((item) => item._id == m._id) >= 0) {
//             this.offlineProCount--; this.onlineProCount++;
//             this.driverTransfer(m, this.offline, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerOffline);
//           }
//           //check in inactive
//           else if (this.inactive.findIndex((item) => item._id == m._id) >= 0) {
//             this.onlineProCount++; this.inactiveProCount--;
//             this.driverTransfer(m, this.inactive, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerInactive);
//           }
//           //check in logged out
//           else if (this.loggedOut.findIndex((item) => item._id == m._id) >= 0) {
//             this.logoutProCount--; this.onlineProCount++;
//             this.driverTransfer(m, this.loggedOut, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerloggedOut);
//           }
//           //add by default
//           else
//           {
//             this.onlineProCount++;
//             this.driverTransfer(m, this.offline, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerOffline);
//           }
//           }
//         if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//         }
//         break;

//       // move provider to Inactive
//       case 7:
//       if(this.CitySelected == true )
//       {
//         if (this.inactive.findIndex((item) => item._id == m._id) < 0) {
//           var inact = "assets/images/inact40.png";
//           // this.markerInactive = [];
//           //check in online
//           if(this.SearchCityId == m.cityId)
//           {
//           if (this.available.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//             this.onlineProCount--; this.inactiveProCount++;
//             this.driverTransfer(m, this.available, this.inactive, this.loggedOutPoints, this.markerInactive, inact, this.markerAvailable);
//           }
//           //add by default
//           else {
//             if(this.SearchCityId === m.cityId)
//             {
//             this.driverTransfer(m, this.available, this.inactive, this.loggedOutPoints, this.markerInactive, inact, this.markerAvailable);
//             }
//           }
//         }
//         if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//         }
//       }
//       else{
//         if (this.inactive.findIndex((item) => item._id == m._id) < 0) {
//           var inact = "assets/images/inact40.png";
//           this.avai =[];
//           //check in online
//           if (this.available.findIndex((item) => item._id == m._id) >= 0) {
//             this.onlineProCount--; this.inactiveProCount++;
//             this.driverTransfer(m, this.available, this.inactive, this.loggedOutPoints, this.markerInactive, inact, this.markerAvailable);
//           }
//           //add by default
//           else {
//             this.inactiveProCount++;
//             this.driverTransfer(m, this.available, this.inactive, this.loggedOutPoints, this.markerInactive, inact, this.markerAvailable);
//           }

//         if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//         }
//       }
//         break;

//       // move provider to logged-out
//       case 8:
//       if(this.CitySelected == true)
//       {
//         if (this.loggedOut.findIndex((item) =>  item._id == m._id) < 0) {
//           var redMarker = "assets/images/redMarker.png";
//           //check in online
//           if(this.SearchCityId == m.cityId)
//           {
//           if (this.available.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//             this.onlineProCount--; this.logoutProCount++;
//             this.driverTransfer(m, this.available, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerAvailable);
//           }
//           //check in inactive
//           else if (this.inactive.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//             this.logoutProCount++; this.inactiveProCount--;
//             this.driverTransfer(m, this.inactive, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerInactive);
//           }
//           //check in offline
//           else if (this.offline.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
//             this.offlineProCount--; this.logoutProCount++;
//             this.driverTransfer(m, this.offline, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerOffline);
//           }
//           //add by default
//           else {
//             // this.logoutProCount++;
//             if(this.SearchCityId === m.cityId)
//             {
//             this.driverTransfer(m, this.available, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerAvailable);
//             }
//           }
//         }
//         if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//         }
//       }
//       else{
//         if (this.loggedOut.findIndex((item) => item._id == m._id) < 0) {
//           var redMarker = "assets/images/redMarker.png";
//           this.avai =[];
//           //check in online
//           if (this.available.findIndex((item) => item._id == m._id) >= 0) {
//             this.onlineProCount--; this.logoutProCount++;
//             this.driverTransfer(m, this.available, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerAvailable);
//           }
//           //check in inactive
//           else if (this.inactive.findIndex((item) => item._id == m._id) >= 0) {
//             this.logoutProCount++; this.inactiveProCount--;
//             this.driverTransfer(m, this.inactive, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerInactive);
//           }
//           //check in offline
//           else if (this.offline.findIndex((item) => item._id == m._id) >= 0) {
//             this.offlineProCount--; this.logoutProCount++;
//             this.driverTransfer(m, this.offline, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerOffline);
//           }
//           //add by default
//           else {
//             this.logoutProCount++;
//             this.driverTransfer(m, this.available, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerAvailable);
//           }
//         if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//         }
//       }
//       break;
//     }
//   }

//   driverTransfer(m, value1, value2, value3, value4, value5, demote1) {
//     this.mUpdate = [];
//     //       m -> socket response
//   if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//     //  value1 -> From which array u should find the provider  
//     //  value2 -> To which array u should shift the provider
//     //checking if provider name is in  provider array                     
//     //  value3 -> value as per driverSorting function in ngOnInIt for that req array
//     //  value4 -> Marker Array , where u want to add new marker 
//     //  value5 -> marker image  
//     // demote1 -> Marker Array from u want to delete the marker
//     if (value1.findIndex((item) => item._id == m._id) >= 0) {
//       var indVal = value1.findIndex((item) => item._id == m._id);
//       value1.splice(value1.findIndex((item) => item._id == m._id), 1);

//       var findVal = value2.findIndex((item) => item._id == m._id);
//       if (findVal >= 0) {
//         value2.splice(findVal, 1);
//       }
//       value2.unshift(m);
//       value2.lastUpdatedMinutes = "Last updated " + Math.round(m.lastActive / 60) + "min ago";
//       var Id = demote1.findIndex((item) => item.id == m._id);
//       //checking if marker is existing in  marker list
//       if (Id >= 0) {
//         this.mUpdate[0] = m;
//         demote1[Id].setMap(null);   //remove marker
//         demote1.splice(Id, 1);
//         this.driverSorting(this.mUpdate, value4, value5);
//       }
//     if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//     } else {
//       this.mUpdate[0] = m;                                                              //if the provider is not in any list then he will be added to the list where u wanted to shift him 
//       value2.unshift(m);
//       this.driverSorting(this.mUpdate, value4, value5);
//     if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//     }
//   if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//   }

//   //live tracking code
//   providerLocationUpdate(m) {
//     let avai1=[];
//     switch (parseInt(m.status)) {
//       //online
//       case 3:
//           if(this.CitySelected)
//           {
//             for (let i =0; i < this.available.length; i++)
//             if (this.available[i].cityId !== this.SearchCityId) {
//               this.available.splice(i,1);
//                break;
//             }
//           }
//           else
//           {
//             if (this.markerAvailable.findIndex((item) => item.id == m._id) >= 0) {
//               let availableMarkerIndex = this.markerAvailable.findIndex((item) => item.id == m._id);
//               var latlng = new google.maps.LatLng(m.latitude, m.longitude);
//               this.markerAvailable[availableMarkerIndex].setPosition(latlng);
//                 if (this.available.findIndex((item) => item._id == m._id) >= 0) {
//                   var aIndex = this.available.findIndex((item) => item._id == m._id);
//                   this.available[aIndex].latitude = m.latitude;
//                   this.available[aIndex].longitude = m.longitude;
//                   this.available[aIndex].batteryPercentage = m.batteryPercentage;
//                   this.available[aIndex].appversion = m.appversion;
//                   this.available[aIndex].bookingCount = m.bookingCount;
//                   this.available[aIndex].deviceType = m.deviceType;
//                   this.available[aIndex].time = m.time;
//                   this.available[aIndex].locationCheck = m.locationCheck;
//                 }
//           }

//             if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//             }
//         break;

//       //busy
//       case 5:
//         if (this.markerBusy.findIndex((item) => item.id == m._id) >= 0) {
//           var busyMarkerIndex = this.markerBusy.findIndex((item) => item.id == m._id);
//           var latlng = new google.maps.LatLng(m.latitude, m.longitude);
//           this.markerBusy[busyMarkerIndex].setPosition(latlng); 
//           if(this.CitySelected == true)
//           {
//             if (this.busy.findIndex((item) => item._id == m._id && this.SearchCityId == m.cityId) >= 0) {
//               var bIndex = this.busy.findIndex((item) => item._id == m._id);
//               this.busy[bIndex].latitude = m.latitude;
//               this.busy[bIndex].longitude = m.longitude;
//               this.busy[bIndex].batteryPercentage = m.batteryPercentage;
//               this.busy[aIndex].appversion = m.appversion;
//               this.busy[aIndex].bookingCount = m.bookingCount;
//               this.busy[aIndex].deviceType = m.deviceType;
//               this.busy[aIndex].time = m.time;
//               this.available[aIndex].locationCheck = m.locationCheck;
//             }
//          }
//          else{
//           if (this.busy.findIndex((item) => item._id == m._id) >= 0) {
//             var bIndex = this.busy.findIndex((item) => item._id == m._id);
//             this.busy[bIndex].latitude = m.latitude;
//             this.busy[bIndex].longitude = m.longitude;
//             this.busy[bIndex].batteryPercentage = m.batteryPercentage;
//             this.busy[aIndex].appversion = m.appversion;
//             this.busy[aIndex].bookingCount = m.bookingCount;
//             this.busy[aIndex].deviceType = m.deviceType;
//             this.busy[aIndex].time = m.time;
//             this.available[aIndex].locationCheck = m.locationCheck;
//           }
//          }
//         if(!this.ref['destroyed']){this.ref.detectChanges(); 
//         }
//         }
//         break;
//     }
//   }
//   show_details(value) {
//     this.bookingDetails = true;
//     this.geocode(value.address);
//     this.getBookingDetailsPopUp(value);
//   }


//   getBookingDetailsPopUp(value) {
//     let d = new Date();
//     let n = d.getTimezoneOffset();

//     value.jobStatusLogs.startedTime = parseInt(value.jobStatusLogs.startedTime) - (n * 60);
//     value.jobStatusLogs.requestedTime = parseInt(value.jobStatusLogs.requestedTime) - (n * 60);
//     value.jobStatusLogs.onthewayTime = parseInt(value.jobStatusLogs.onthewayTime) - (n * 60);
//     value.jobStatusLogs.acceptedTime = parseInt(value.jobStatusLogs.acceptedTime) - (n * 60);
//     value.jobStatusLogs.receivedTime = parseInt(value.jobStatusLogs.receivedTime) - (n * 60) ;
//     this.jobDetailsByBooking = value;
//     this.itemList = this.jobDetailsByBooking.cartData;
//     setTimeout(() => {
//       $('#jobdetailModal').appendTo("body").modal('show');
//     }, 200)
//   if(!this.ref['destroyed']){         this.ref.detectChanges();       }
//   }

//   openDetailsPopUp() {
//     this.detailsPopUp = true;
//     setTimeout(() => {
//       $("#card").toggleClass("flipped");
//       jQuery(".container_flip_parent").addClass('show_div');
//     }, 1);
//     // $("#card").toggleClass("flipped");
//     // jQuery(".container_flip_parent").addClass('show_div');
//     var scrollBarWidth = window.innerWidth - document.body.offsetWidth;
//     document.body.style.overflow = 'hidden';
//     $(".container_flip_parent").css('display', 'block')
//   }

//   close_details() {
//     this.bookingDetails = false;
//     this.detailsPopUp = false;
//     $("#card").toggleClass("flipped");
//     jQuery(".container_flip_parent").removeClass('show_div');
//     document.body.style.margin = '';
//     document.body.style.overflow = '';
//     $(".container_flip_parent").css('display', ' ')
//   }

//   geocode(obj) {
//     var lat = parseFloat(obj.latitude);
//     var lng = parseFloat(obj.longitude);
//     var latlng = new google.maps.LatLng(lat, lng);
//     var geocoder = geocoder = new google.maps.Geocoder();
//     geocoder.geocode({ 'latLng': latlng }, (results, status) => {
//       if (status == google.maps.GeocoderStatus.OK) {
//         if (results[0]) {
//           this.selectedJobAddress = results[1].formatted_address;
//         }
//       };
//     if(!this.ref['destroyed']){         
//       this.ref.detectChanges();      
//     }
//     });
//   }
//   clearRightSide = (tabChangeEvent: MatTabChangeEvent): void => {
//     this.placeMarker(tabChangeEvent)
//   }
// }
import { Component, OnInit, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Paho } from 'ng2-mqtt';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { apiServiceManager } from '../../service/api-service-manager';
import { mqttManager } from '../../service/mqtt-manager';
import { CookieService } from 'ngx-cookie-service';
import swal from 'sweetalert2';

declare var jQuery: any;
declare var $: any;
declare var Fingerprint: any;
declare var google: any;
declare var placeMarkerAndPanTo: any;


import * as moment from 'moment';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
@Component({
  selector: 'app-godsview',
  templateUrl: './godsview.component.html',
  styleUrls: ['./godsview.component.css', '../dispatcher-home/dispatcher-home.css', '../tasks/tasks.component.css']
})
export class GodsviewComponent implements OnInit {
  ForPagination: boolean = false;
  searchBox: any;
  currentInfoWindow: any = null;
  cityBrokerID: any = "";
  dragDropProviderList: any;
  values: any;
  // ForSearchBox:boolean =false;
  ForAutoComplete: any;
  map1: any;
  CitySelected: any = false;
  TabCode: any;
  origin_autocomplete1: any;
  searchPoints: any;
  markerSearch: any;
  busyProCount: any;
  offlineProCount: any;
  onlineProCount: any;
  logoutProCount: any;
  inactiveProCount: any;
  ProviderAll: any = [];
  available: any = [];
  busy: any = [];
  offline: any = [];
  loggedOut: any = [];
  inactive: any = [];
  markerAvailable: any = [];
  markerBusy: any = [];
  markerOffline: any = [];
  markerloggedOut: any = [];
  markerInactive: any = [];
  availablePoints: any = [];
  busyPoints: any = [];
  offlinePoints: any = [];
  loggedOutPoints: any = [];
  searchResult: any = [];
  windowLatLng: any = [];
  availablePointsPaginated: any = [];
  busyPointsPaginated: any = [];
  offlinePointsPaginated: any = [];
  loggedOutPointsPaginated: any = [];
  actPind: any = 0;
  busyPind: any = 0;
  offPind: any = 0;
  logoutPind: any = 0;
  mUpdate: any = [];
  bookingDetails: boolean = false;
  detailsPopUp: boolean = false;
  jobImageArray: any;
  jobDetailsByBooking: any;
  forDate: any;
  thisServiceList: any;
  selectedProvider: any;
  selectedProviderBooking: any;
  noBooking: boolean = false;
  currency: any = 'USD';
  selectedProviderAddress: any;
  selectedJobAddress: any;
  selectedOptions: any;
  createdDate: any;
  acceptedDate: any;
  onTheWay: any;
  arrivedAt: any;
  jobStarted: any;
  flightPlanCoordinates: any;
  itemList: any;
  timer: any;
  indexOne: number = 0;
  indexTwo: number = 0;
  indexThree: number = 0;
  indexFour: number = 0;
  providerSearchString: any;
  searchControll = new FormControl();
  avai: any = [];
  off: any = [];
  on: any = [];
  logged: any = [];
  CitiesProviderList: any;
  SearchCityLng: any = 0;
  SearchCityLat: any = 0;
  SearchCityId: any;
  getCityLatLng: any;
  // CitySearchString:any=String;
  CityProviders = new FormControl('');
  LatForAddr: any;
  LngForAddr: any;
  Latitude: any;
  Longitude: any;
  constructor(private _apiServiceManager: apiServiceManager, private router: Router, private _mqttManager: mqttManager, private ref: ChangeDetectorRef,
    private cookieService: CookieService
  ) {

  }


  getMqttMessage() {
    this.CitySelected = false;
    this._mqttManager.getMessages().subscribe((message: Paho.MQTT.Message) => {
      let mqttData = JSON.parse(message.payloadString);
      this._mqttManager.getMqttMsg(mqttData);
      switch (mqttData.destinationName) {
        // Card Id: none, bug desc: Dynamically timeline and status msg should be update, fix desc: getting mqttdata in which case is BookingStatus, developer:sowmya sv, date : 26-11-18
        case "bookingStatus": this.jobDetailsByBooking = mqttData;
          if (this.selectedProviderBooking) {
            let index = this.selectedProviderBooking.findIndex((item) => item.bookingId == mqttData.bookingId);
            if (index > -1) {
              this.selectedProviderBooking[index].statusMsg = mqttData.statusMsg;
            }
          }
          // this.selectedProviderBooking = mqttData;
          break;

        case 'providerStatus':
          //bug: dynamically show provider details, fixed desc: get mqttData based on provider status, developler: sowmya sv
          // date: 27-11-18
          if (this.selectedProvider) {
            if (this.selectedProvider._id == mqttData._id) {
              this.selectedProvider = mqttData;
            }
          }
          // console.log("this.selectedProvider", mqttData)
          this.providerChanges(mqttData);
          break;

        case 'providerLocation':
          this.providerLocationUpdate(mqttData);
          break;
      }
      if (!this.ref['destroyed']) { this.ref.detectChanges(); }
    });
  }

  clearMap() {
    if (this.markerAvailable && this.markerAvailable.length > 0) {
      for (var i = 0; i < this.markerAvailable.length; i++) {
        this.markerAvailable[i].setMap(null);
      }
      this.markerAvailable = [];
    }

    else if (this.markerOffline && this.markerOffline.length > 0) {
      for (var i = 0; i < this.markerOffline.length; i++) {
        this.markerOffline[i].setMap(null);
      }
      this.markerOffline = [];
    }

    else if (this.markerloggedOut && this.markerloggedOut.length > 0) {
      for (var i = 0; i < this.markerloggedOut.length; i++) {
        this.markerloggedOut[i].setMap(null);
      }
      this.markerloggedOut = [];
    }

    else if (this.markerInactive && this.markerInactive.length > 0) {
      for (var i = 0; i < this.markerInactive.length; i++) {
        this.markerInactive[i].setMap(null);
      }
      this.markerInactive = [];
    }
    if (!this.ref['destroyed']) { this.ref.detectChanges(); }
  }

  placeMarker(arrayCode) {
    // this.clearMap();
    // if(!this.ref['destroyed']){         this.ref.detectChanges();       }
    this.TabCode = arrayCode;
    // setTimeout(() => {
    switch (arrayCode) {
      case 0:
        this.clearMap();
        var greenMarker = "assets/images/greenMarker.svg";
        this.markerAvailable.length === 0 ? this.driverSorting(this.available, this.markerAvailable, greenMarker) : '';
        //console.log("this.markerAvailable",this.markerAvailable)
        break;
      case 1:
        this.clearMap();
        var greyMarker = "assets/images/grayMarker.png";
        this.markerOffline.length === 0 ? this.driverSorting(this.offline, this.markerOffline, greyMarker) : '';
        //console.log("this.markerOffline",this.markerOffline)
        break;
      case 2:
        this.clearMap();
        var inact = "assets/images/inact40.png";
        this.markerInactive.length === 0 ? this.driverSorting(this.inactive, this.markerInactive, inact) : '';
        //console.log("this.markerInactive",this.markerInactive)
        break;
      case 3:
        this.clearMap();
        var redMarker = "assets/images/redMarker.png";
        this.markerloggedOut.length === 0 ? this.driverSorting(this.loggedOut, this.markerloggedOut, redMarker) : '';
        //console.log("this.markerloggedOut",this.markerloggedOut)
        break;
      default: break;
    }
  }

  setmyMap(param1, param2) {
    var map = new google.maps.Map(document.getElementById('godsviewmap'), {
      center: new google.maps.LatLng(param1, param2),
      zoom: 8,
      styles: this._apiServiceManager.getMapStyles(),
      controlSize: 20,
      // minZoom: 1,
      streetViewControl: false,
      // minZoom: 1,
      gestureHandling: 'cooperative'
    });
    this.map1 = map;
  }

  ngOnInit() {
    this.CitySelected = false;
    let latt = sessionStorage.getItem('latitude');
    let long = sessionStorage.getItem('longitude');
    sessionStorage.removeItem("LatForProvider");
    sessionStorage.removeItem("LngForProvider");
    this.ForAutoComplete = 2;
    this.cityBrokerID = this.cookieService.get('cityId');
    // if (this.cityBrokerID.length == 1) {
    if (this.cityBrokerID == '0') {
      // console.log('vnjdfvnjdfnjdfnbjgn 1')
      this.getAllProvider(0, 0);
    }
    // else if (this.cityBrokerID.length > 1) {
    else {
      // console.log('vnjdfvnjdfnjdfnbjgn')
      this.getProviderByCity(this.cityBrokerID, 1)
    }
    this.setmyMap(latt, long);
    this.getMqttMessage();

    jQuery('#appt_loader_parent').show();
    jQuery('#back_button').show();
    jQuery('#pac-input2').show();
    jQuery('#godsView').hide();

    $(document).ready(() => {
      // $('#example-getting-started').multiselect();
    });


    // LEFT MARKER CLICK FUNCTION
    jQuery(".left_marker").click(function () {
      var k = jQuery('.left-window').width();
      var y = jQuery(window).width();
      var result = y - k;
      if (jQuery(".left-window").hasClass('open')) {
        jQuery(".left-window").animate({ left: -k });
        jQuery(".left-window").removeClass('open');
        jQuery(".icn_left").addClass('rotate_pointer');
        jQuery('#map>div').animate({ marginLeft: '0%' });
      }
      else {
        jQuery(".left-window").animate({ left: '0' });
        jQuery(".left-window").addClass('open');
        jQuery(".icn_left").removeClass('rotate_pointer');
        jQuery('#map>div').animate({ marginLeft: k });
      }
    });

    // MAP FILTER CLICK HIDE AND SHOW
    $('#floating_button_body').hide();
    $("#floating_button").click(function () {
      $("#floating_button_body").toggle(
        function () {
          $('#floating_button_body').animate({
            height: "auto",
          });
        });
    })

    var that = this;
    // FOR HIDING THE SEARCH INPUT
    jQuery('#sesarch_driver_close').click(function () {
      $("#right_search_front").show();
      $("#right_search_back").hide();
      jQuery('#search_provider').val("");
      // GETTING CLIENTS IP ADDRESS
      var what = this;
      // that.ngOnInit();
      jQuery.get("//ipapi.co/json/", function (response) {
        this.onLoadLatitude = (response.latitude);
        this.onLoadLongitude = (response.longitude);
        var that = this;
        // INITIALIZING MAP
        var map = new google.maps.Map(document.getElementById('godsviewmap'), {
          center: new google.maps.LatLng(this.onLoadLatitude, this.onLoadLongitude),
          zoom: 10,
          styles: this._apiServiceManager.getMapStyles(),
          // minZoom: 1,
          streetViewControl: false,
        });
        what.map1 = map;
      });
    });
    var x = this;
    this.searchBox = document.getElementById('pac-input');
    this.map1.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('pac-input'));

    this.searchBox = document.getElementById('pac-input');
    this.map1.controls[google.maps.ControlPosition.TOP_CENTER].push(document.getElementById('pac-input'));
    this.origin_autocomplete1 = new google.maps.places.Autocomplete(this.searchBox);
    this.origin_autocomplete1.bindTo('bounds', this.map1);

  }

  ngOnDestroy() {
    $("app-godsview").hide();
  }

  trackBooking(data) {

    if (this.selectedProviderBooking.findIndex((item) => item.bookingId == data.bookingId) >= 0) {
      var index = this.selectedProviderBooking.findIndex((item) => item.bookingId == data.bookingId);
      this.selectedProviderBooking[index].statusMsg = data.statusMsg;
      this.selectedProviderBooking[index].status = data.status;
    }
    if (!this.ref['destroyed']) { this.ref.detectChanges(); }
  }

  filterValue = 1;
  change(value, caseValue) {
    //conole.log("statusProvider",typeof value)
    this.clearMap();
    this.available = [];
    var filter = value.toString();
    this.filterValue = value.toString();
    value !== "1" ? this.filterProviderStatus(filter, caseValue) : this.getAllProvider(0, 0);
  }

  filterProviderStatus(value, caseValue) {
    let filterData = {
      pageIndex: 0,
      status: value,
    }
    filterData['searchStr'] = this.checkProviderString(this.providerSearchString);
    //conole.log("filter",filterData )
    jQuery('#appt_loader_parent').show();
    this._apiServiceManager.getProStatus(filterData).subscribe(result => {
      jQuery('#appt_loader_parent').hide();
      //conole.log("res from filter provider",result.data)
      switch (caseValue) {
        case 1: this.onlineProCount = result.data.count;
          this.available = result.data.onlinePro;
          break;

        case 2: this.offlineProCount = result.data.count;
          this.offline = result.data.offlinePro;
          break;

        case 3: this.inactiveProCount = result.data.count;
          this.inactive = result.data.inactivePro;
          break;

        case 4: this.logoutProCount = result.data.count;
          this.loggedOut = result.data.logoutPro;
          break;

      }
      this.placeMarker(0);
    }, error => {
      jQuery('#appt_loader_parent').hide();
      (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();

    });
    if (!this.ref['destroyed']) { this.ref.detectChanges(); }
  }

  logOut() {
    sessionStorage.removeItem("loginUser");
    sessionStorage.removeItem("user-token");
    this._mqttManager._client.disconnect();
    this.router.navigate(['login']);
  }

  expireToken() {
    this._apiServiceManager.logOut().subscribe(result => { });
    $("#appt_loader_loggedOut").show();
  }

  checkCategory(array, id) {
    for (var i = 0; i < array.length; i++) {
      // (array[i].user == id);
      if (array[i].user == id) {
        return 1;
      }
    }
    return 0;
  }
  searchPlaced: any;
  // SEARCH PLACE ON MAP
  searchPlace(event) {
    // ("search place")
    // this.ForSearchBox =true;
    var marker = new google.maps.Marker({
      map: this.map1,
      anchorPoint: new google.maps.Point(0, -29)
    });
    marker.setVisible(false);
    let Place = event.target.value;
    // (Place == '') ? this.getAllProvider(0,0) : '';
    // this.CityProviders.reset();
    // this.ForAutoComplete = 0;
    var place;
    let cityId = '1';
    var AutoCom = google.maps.event.addListener(this.origin_autocomplete1, 'place_changed', () => { // arrow function
      place = this.origin_autocomplete1.getPlace();
      // if (place.length == 0) 
      //   return;
      // else{
      sessionStorage.setItem("LatForProvider", place.geometry.location.lat());
      sessionStorage.setItem("LngForProvider", place.geometry.location.lng());
      //conole.log("this.cityBrokerID",this.cityBrokerID,cityId,place);
      place ? this.getProviderByCity(cityId, 0) : '';
      AutoCom.remove();
      if (place.geometry.viewport) {
        this.map1.fitBounds(place.geometry.viewport);
      } else {
        this.map1.setCenter(place.geometry.location);
        this.map1.setZoom(10);  // Why 17? Because it looks good.
      }
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      // this.cityBrokerID.length > 0 ? cityId = this.cityBrokerID : cityId = 1;
      // //conole.log("this.cityBrokerID",this.cityBrokerID,cityId)
      // place.length > 0 ? this.getProviderByCity(cityId.toString(),0) :'';
      // this.getProviderByCity(cityId.toString(),0);
      // }
    });
    google.maps.event.addListener(this.origin_autocomplete1, 'click', function () {
      console.log("clicked")
      this.one("blur", function () {
        this.val("");
      });

      //[DO YOUR CODE HERE]

    });

  }

  scrollTarget(val, stat) {
    if (stat == 1) {
      $("#available_providers").animate({
        scrollTop: val
      }, 100);
    }
    else
      if (stat == 2) {
        $("#busy_providers").animate({
          scrollTop: val
        }, 100);
      }
      else
        if (stat == 3) {
          $("#offline_providers").animate({
            scrollTop: val
          }, 100);
        }
        else
          if (stat == 4) {
            $("#loggedout_providers").animate({
              scrollTop: val
            }, 100);
          }

  }


  setSearchImage(val) {
    jQuery("#imageSearch-" + val).attr(
      'src',
      'http://iserve.ind.in/pics/driver_profile_pic.png'
    );
  }

  executeAsynchronously(functions, timeout) {
    for (var i = 0; i < functions.length; i++) {
      setTimeout(functions[i], timeout);
    }
  }
  refreshProviderSearch() {
    this.providerSearchString = '';
    $("#pac-input").val("");
    this.searchControll.reset();
    this.filterValue = 1;
    if (this.cityBrokerID == '0') {
      this.getAllProvider(0, 0);
    }
    else {
      this.getProviderByCity(this.cityBrokerID, 1);
    }
    //if (this.cityBrokerID.length > 1)
    this.closeInfoWindow();
  }
  getAllProvider(initialIndex, toUpdate) {
    if (this.cityBrokerID == '0') {
      if (this.CitySelected === false) {
        jQuery('#appt_loader_parent').show();
        this.CitySelected = false;
        let searchData = {
          pageIndex: initialIndex,
        }
        searchData['searchStr'] = this.checkProviderString(this.providerSearchString)
        this._apiServiceManager.getProviderListForAllCityes(searchData).subscribe(result => {
          let response = result.data; this.ProviderAll = result.data;
          this.CitiesProviderList = result.allCityData;
          if (response.onlineProCount > 20) {
            this.getDetailsOfProvider(response, 1);
            var IndexValue = response.onlineProCount / 20;
            this.getBookingDataManager(1, Math.floor(IndexValue));
          }
          else {
            this.getDetailsOfProvider(result.data, 1);
          }
          if (response.offlineProCount > 20) {
            this.getDetailsOfProvider(response, 2);
            var IndexValue = response.offlineProCount / 20;
            this.getBookingDataManager(2, Math.floor(IndexValue));
          }
          else {
            this.getDetailsOfProvider(result.data, 2);
          }
          if (response.inactiveProCount > 20) {
            this.getDetailsOfProvider(response, 3);
            var IndexValue = response.inactiveProCount / 20;
            this.getBookingDataManager(3, Math.floor(IndexValue));
          }
          else {
            this.getDetailsOfProvider(result.data, 3);
          }
          if (response.logoutProCount > 20) {
            this.getDetailsOfProvider(response, 4);
            var IndexValue = response.logoutProCount / 20;
            this.getBookingDataManager(4, parseInt(IndexValue.toString()));
          }
          else {
            this.getDetailsOfProvider(result.data, 4);
          }
          this.placeMarker(0);
          jQuery('#appt_loader_parent').hide();

          // this.ref.detectChanges();
        }, error => {
          jQuery('#appt_loader_parent').hide();
          var erRes = JSON.parse(error._body);
          switch (error.status) {
            case 440:
              // call access token api to get new token
              this._apiServiceManager.getAccessToken(erRes.data).subscribe(result => {
                // call access provider api again to get data 
                sessionStorage.setItem('user-token', result.data);
                this._apiServiceManager.getProList(initialIndex).subscribe(result => { (" calling getProList "); }, error => {
                  (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
                });
              }, error => {
                (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
              });
              break;
            case 498: case 502:
              this.expireToken();

              break;
          }
        }, () => {
        });
      }
      else {
        // console.log('gt city')
        this.getProviderByCity(this.SearchCityId, 1);
      }
    }
    else {
      // console.log('get city')
      this.getProviderByCity(this.cityBrokerID, 1);
    }
  };
  getBookingDataManager(toUpdate, PageIndex) {
    // console.log('getBookingDataManager')
    for (let i = 1; i <= PageIndex; i++) {
      // for Provider that is city is selected 
      if (this.CitySelected == true) {
        let SearchData = {
          ipAddress: this.cookieService.get('ip'),
          pageIndex: i,
          searchStr: "0",
          lat: 0,
          long: parseFloat("0"),
          cityId: this.SearchCityId == '0' ? this.SearchCityId : this.cityBrokerID,
        }
        SearchData['searchStr'] = this.checkProviderString(this.providerSearchString)
        this._apiServiceManager.getProviderListByCity(SearchData).subscribe(result => {
          let resultData = result.data;
          this.ResponseDataForPagination(toUpdate, result.data);
        }, error => {
          (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
        });
      }
      else {
        let searchData = {
          pageIndex: i,
        }
        searchData['searchStr'] = this.checkProviderString(this.providerSearchString)
        this._apiServiceManager.getProviderListForAllCityes(searchData).subscribe(result => {
          // let response = result.data;
          this.ResponseDataForPagination(toUpdate, result.data);
        }, error => {
          (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
        });
      }
    }
  }
  checkProviderString(Name) {
    if (typeof this.providerSearchString !== "undefined") {
      if (this.providerSearchString == '') {
        return '0';
      }
      else if (this.providerSearchString == null) {
        return '0';
      }
      else {
        return Name;
      }
    }
    else if (typeof this.providerSearchString == "undefined") {
      return '0';
    }
    else if (this.providerSearchString == null) {
      return '0';
    }
  }
  ResponseDataForPagination(toUpdate, response) {
    switch (toUpdate) {
      case 1:
        this.pushPaginatedArray(response.onlinePro, this.available, toUpdate);
        break;
      case 2:
        this.pushPaginatedArray(response.offlinePro, this.offline, toUpdate);
        break;
      case 3:
        this.pushPaginatedArray(response.inactivePro, this.inactive, toUpdate);
        break;
      case 4:
        this.pushPaginatedArray(response.logoutPro, this.loggedOut, toUpdate);
        break;
    };
  }
  pushPaginatedArray(responseArray, destinationArray, toUpdate) {
    if (responseArray.length > 0) {
      for (var i = 0; i < responseArray.length; i++) {
        destinationArray.push(responseArray[i]);
        if (!this.ref['destroyed']) {
          this.ref.detectChanges();
        }
      }
    }
    this.assignForArrays(destinationArray, toUpdate);
  }

  assignForArrays(response, toUpdate) {
    if (toUpdate == 1) {
      this.available = response;
    }
    if (toUpdate == 2) {
      this.offline = response;
    }
    if (toUpdate == 3) {
      this.inactive = response;
    }
    if (toUpdate == 4) {
      this.loggedOut = response;
    }
  }
  getProviderListForAllCityes(initialPageIndex) {
    jQuery('#appt_loader_parent').show();
    let SearchData = {
      ipAddress: this.cookieService.get('ip'),
      pageIndex: initialPageIndex,
    }
    this._apiServiceManager.getProviderListForAllCityes(SearchData).subscribe(result => {
      //  var resultData= result.data;
      this.CitiesProviderList = result.allCityData;
      //  this.cityBrokerID = sessionStorage.getItem('cityId')
      jQuery('#appt_loader_parent').hide();

    }, error => {
      var Error = JSON.parse(error._body);
      (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
      jQuery('#appt_loader_parent').hide();

    });

  }
  getProviderByCity(initialCityId, value) {
    //conole.log("value",value,"initialCityId",initialCityId)
    jQuery('#appt_loader_parent').show();
    // console.log('values ', value)
    let SearchData = {
      ipAddress: this.cookieService.get('ip'),
      pageIndex: 0,
      lat: 0,
      long: parseFloat("0"),
      cityId: initialCityId
    }
    if (value == 1) {
      $("#pac-input").val("");
      sessionStorage.removeItem("LatForProvider");
      sessionStorage.removeItem("LngForProvider");
      this.ForAutoComplete = value;
    }
    else if (value == 0) {
      this.ForAutoComplete = value;
      SearchData["cityId"] = "0";
      sessionStorage.getItem("LatForProvider") != null ? SearchData["lat"] = parseFloat(sessionStorage.getItem("LatForProvider")) : SearchData["lat"] = parseFloat("0");
      sessionStorage.getItem("LngForProvider") != null ? SearchData["long"] = parseFloat(sessionStorage.getItem("LngForProvider")) : SearchData["long"] = parseFloat("0");
    }
    initialCityId != "1" ? this.CitySelected = true : this.CitySelected = false;
    let stringofProvider = this.checkProviderString(this.providerSearchString)
    SearchData['searchStr'] = this.checkProviderString(this.providerSearchString);
    let indx = initialCityId;
    this.SearchCityId = initialCityId;
    //conole.log("SearchData--->",SearchData)
    this._apiServiceManager.getProviderListByCity(SearchData).subscribe(result => {
      let resultData = result.data;
      let CityList = result.allCityData;
      this.CitiesProviderList = CityList;
      this.getCityLatLng = result.selectedCityLocation;
      jQuery('#appt_loader_parent').hide();
      if (result.selectCityId != "1") {
        this.SearchCityId = result.selectCityId;
        this.CitySelected = true;
      }
      this.available = [];
      this.offline = [];
      this.inactive = [];
      this.loggedOut = [];
      this.ProviderAll = result.data;
      if (result.data.onlineProCount > 20) {
        this.getDetailsOfProvider(result.data, 1);
        var IndexValue = result.data.onlineProCount / 20;
        this.getBookingDataManager(1, Math.floor(IndexValue));
      }
      else {
        this.getDetailsOfProvider(result.data, 1);
      }
      if (result.data.offlineProCount > 20) {
        this.getDetailsOfProvider(result.data, 2);
        var IndexValue = result.data.offlineProCount / 20;
        this.getBookingDataManager(2, Math.floor(IndexValue));
      }
      else {
        this.getDetailsOfProvider(result.data, 2);
      }
      if (result.data.inactiveProCount > 20) {
        this.getDetailsOfProvider(result.data, 3);
        var IndexValue = result.data.inactiveProCount / 20;
        this.getBookingDataManager(3, Math.floor(IndexValue));
      }
      else {
        this.getDetailsOfProvider(result.data, 3);
      }
      if (result.data.logoutProCount > 20) {
        this.getDetailsOfProvider(result.data, 4);
        var IndexValue = result.data.logoutProCount / 20;
        this.getBookingDataManager(4, parseInt(IndexValue.toString()));
      }
      else {
        this.getDetailsOfProvider(result.data, 4);
      }
      this.placeMarker(0);
    }, error => {
      jQuery('#appt_loader_parent').hide();
      var Error1 = JSON.parse(error._body);
      if (error.status == 404) {
        swal.fire({ text: Error1.message, icon: "error" });
      }
      (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    })

  };

  getDetailsOfProvider(ResponseData, UpdateFor) {
    switch (UpdateFor) {
      case 1: this.onlineProCount = ResponseData.onlineProCount;
        this.available = ResponseData.onlinePro;
        break;

      case 2: this.offlineProCount = ResponseData.offlineProCount;
        this.offline = ResponseData.offlinePro;
        break;

      case 3:
        this.inactiveProCount = ResponseData.inactiveProCount;
        this.inactive = ResponseData.inactivePro;
        break;

      case 4: this.logoutProCount = ResponseData.logoutProCount;
        this.loggedOut = ResponseData.logoutPro;
        break;
    }
  }
  driverSorting(value1, value3, value4) {
    var contentString;
    var tracIcon = {
      url: value4,  // url
      scaledSize: new google.maps.Size(40, 40), // scaled size
    };
    var infowindow = new google.maps.InfoWindow();
    var currentInfoWindow = null;
    let bounds = new google.maps.LatLngBounds();
    value1.forEach((element, index) => {
      let idx = value3.findIndex((item) => item.id == value1[index]._id);
      if (idx < 0) {
        value3.push(new google.maps.Marker({
          position: { lat: value1[index].latitude, lng: value1[index].longitude },
          map: this.map1,
          icon: tracIcon,
          animation: google.maps.Animation.DROP,
          id: value1[index]._id
        }));
        //console.log("idx",idx,value3)

        var name = value1[index].name;
        var profilePic = value1[index].image || "assets/images/default.png";
        var phone = value1[index].phone;
        contentString = "<div class='infy' style='float:left' class='" + value1[index]._id + "'><img style='width:45px;height:45px;border-radius:50%;' src='" + profilePic + "'></div><div style='float:right; padding: 0px 0px 0px 11px;'><b>" + name + "</b></br><span style='font-size:11px'>" + phone + "</span></div>";
        value3[index].setMap(this.map1);

      }
      //detects the image breaks and set it with default image 
      var avlloc = new google.maps.LatLng(value1[index].latitude, value1[index].longitude);
      bounds.extend(avlloc);
      // this.map1.setZoom(5);
      this.map1.panToBounds(bounds);
      this.map1.fitBounds(bounds);
      // this.map1.setZoom(10);
    });
  }

  locate(obj) {
    this.selectedProvider = '';
    this.selectedProviderBooking = "";
    this.selectedProviderAddress = '';
    setTimeout(() => {
      this.selectedProvider = obj;
      // this.setdefaultImage();
      this.toggleMapDiv(1);
      $('.ProviderInfo').show(500);
      this.getProviderBookings(obj._id);
      //console.log("selected booking,",obj )
      jQuery('#addressSearch').val('');
      var map = this.map1.setOptions({
        center: { lat: obj.latitude, lng: obj.longitude },
        zoom: 10,
        styles: this._apiServiceManager.getMapStyles(),
        // minZoom: 1,
        streetViewControl: false,
      });

      // setTimeout(()=>{
      this.findMarker(obj);
      // },300)
      // this.getProviderBookings(obj._id);
      // this.geocode(obj);
      // this.findMarker(obj);
      if (!this.ref['destroyed']) { this.ref.detectChanges(); }
    }, 50)


  }

  toggleMapDiv(caseData) {
    switch (caseData) {
      case 1:
        if ($(".mapDiv").hasClass('mapFull')) {
          $(".mapDiv").removeClass('mapFull');
          $('.mapDiv').addClass('mapHalf');
        }
        break;
      case 2:
        if ($(".mapDiv").hasClass('mapHalf')) {
          $(".mapDiv").removeClass('mapHalf');
          $('.mapDiv').addClass('mapFull');
        }
        break;
    }
  }
  MarkerIndex: any = -1;
  findMarker(toFind) {
    let toFindAvailable;
    this.markerAvailable && this.markerAvailable.length > 0 ?
      toFindAvailable = this.markerAvailable.findIndex((item) => item.id == toFind._id) : toFindAvailable = this.available.findIndex((item) => item._id == toFind._id);

    let toFindBusy;
    this.markerBusy && this.markerBusy.length > 0 ?
      toFindBusy = this.markerBusy.findIndex((item) => item.id == toFind._id) : toFindBusy = this.busy.findIndex((item) => item._id == toFind._id);

    let toFindLoggedOut;
    this.markerloggedOut && this.markerloggedOut.length > 0 ?
      toFindLoggedOut = this.markerloggedOut.findIndex((item) => item.id == toFind._id) : toFindLoggedOut = this.loggedOut.findIndex((item) => item._id == toFind._id);

    let toFindInactive;
    this.markerInactive && this.markerInactive.length > 0 ?
      toFindInactive = this.markerInactive.findIndex((item) => item.id == toFind._id) : toFindInactive = this.inactive.findIndex((item) => item._id == toFind._id)

    let toFindOffline;
    this.markerOffline && this.markerOffline.length > 0 ?
      toFindOffline = this.markerOffline.findIndex((item) => item.id == toFind._id) : toFindOffline = this.offline.findIndex((item) => item._id == toFind._id);


    var arrayMarks = [toFindAvailable, toFindOffline, toFindInactive, toFindBusy, toFindLoggedOut];
    //console.log("toFindAvailable",arrayMarks)
    for (var i = 0; i < arrayMarks.length; i++) {
      var num = arrayMarks[i];
      if (num >= 0) {
        this.MarkerIndex = num;
        this.switchForLocation(i, num, toFind);
      }
    }

  }

  switchForLocation(i, num, obj) {
    switch (i) {
      case 0:
        this.markerAvailable ? this.locateMarker(this.markerAvailable, num, obj) : this.locateMarker(this.available, num, obj)
        break;

      case 1:
        this.markerOffline ? this.locateMarker(this.markerOffline, num, obj) : this.locateMarker(this.offline, num, obj)
        break;

      case 2:
        this.markerInactive ? this.locateMarker(this.markerInactive, num, obj) : this.locateMarker(this.inactive, num, obj)
        break;

      case 3:
        this.markerBusy ? this.locateMarker(this.markerBusy, num, obj) : this.locateMarker(this.busy, num, obj)
        break;

      case 4:
        this.markerloggedOut ? this.locateMarker(this.markerloggedOut, num, obj) : this.locateMarker(this.loggedOut, num, obj)
        break;
    }
  }
  // Addrees need to show on map for Provider
  locateMarker(param, num, obj) {
    //conole.log("locate marker")
    var infowindow = new google.maps.InfoWindow({
      maxWidth: 200
    });
    if (param[num].id == obj._id) {
      setTimeout(() => {
        this.geocode(obj);
      })
      setTimeout(() => {
        var profilePic = obj.image || "assets/images/default.png";
        var phone = obj.phone || "NA";
        if (this.currentInfoWindow) {
          this.currentInfoWindow.close();
          param[num].setAnimation(null);
        }
        param[num].setAnimation(google.maps.Animation.BOUNCE);
        //card#148, issues: bounciness for marker is not stoping once it is started, fixedBy:sowmyaSV, date: 7-1-20 
        setTimeout(function () {
          param[num].setAnimation(null);
        }, 1500);
        this.map1.setZoom(18);
        let infoString = "<div class='infy' style='text-align:center' class='" + obj._id + "'><img style='width:45px;height:45px;border-radius:50%;' src='" + profilePic + "'></div><div style='float:right; padding: 0px 0px 0px 11px;text-align:center'><b>" + obj.name + "</b></br><span style='font-size:11px'> <strong>Phone Number: </strong>" + phone + " </strong></span><br/><span style='font-size:11px'><strong>Drop Address : </strong>" + this.selectedJobAddress + " </span></div>"
        infowindow.setContent(infoString);
        // infowindow.setContent("<div class='infy' style='float:left' class='" + obj._id + "'><img style='width:45px;height:45px;border-radius:50%;' src='" + profilePic + "'></div><div style='float:right; padding: 0px 0px 0px 11px;'><b>" + obj.name + "</b></br><span style='font-size:11px'>" + phone + "</span></div>");
        infowindow.open(this.map1, param[num]);
        this.currentInfoWindow = infowindow;
      }, 1000)
    }
    google.maps.event.addListener(infowindow, 'closeclick', () => {
      param[num].setAnimation(null);
      infowindow.close();
    });
  }

  generateNoDataOnLateResponse() {
    this.timer = setTimeout(() => {
      this.selectedProviderBooking = [];
    }, 2000)
  };

  getProviderBookings(value) {
    this.noBooking = true;
    // this.generateNoDataOnLateResponse();
    this._apiServiceManager.getProBookings(value).subscribe(result => {
      clearTimeout(this.timer);
      this.selectedProviderBooking = result.data;
      this.selectedProviderBooking = this.sortProviderBookignBasedOnDates(this.selectedProviderBooking);
      this.noBooking = false;
    }, error => {
      var erRes = JSON.parse(error._body);
      switch (error.status) {
        case 440:
          // call access token api to get new token
          this._apiServiceManager.getAccessToken(erRes.data).subscribe(result => {
            // call access getProviderBookings api again to get data 
            sessionStorage.setItem('user-token', result.data);
            this.getProviderBookings(value);
          }, error => {
            (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
          });
          break;
        case 498: case 502:
          this.expireToken()

          break;
      }
    }, () => {
      //conole.log("completed")
    })
  }
  //card#90 ,bug : The bookings are not sorted according to date /There is no sorting order, fixedBy:sowmya
  sortProviderBookignBasedOnDates(provideBookingList) {
    // let sortedList = provideBookingList.sort((a,b) => (moment(a.bookingRequestedFor, 'DD-MM-YYYY') > moment(b.bookingRequestedFor, 'DD-MM-YYYY')) ? 1 : ((moment(b.bookingRequestedFor, 'DD-MM-YYYY') > moment(a.bookingRequestedFor, 'DD-MM-YYYY')) ? -1 : moment(a.bookingRequestedFor, 'hh:mm A').isBefore(moment(b.bookingRequestedFor, 'hh:mm A')) ? -1 :  moment(b.bookingRequestedFor, 'hh:mm A').isBefore(moment(a.bookingRequestedFor, 'hh:mm A')) ? 1 : 0  )); 
    let sortedList = provideBookingList.sort(function (a, b) {
      return moment.utc(a.bookingRequestedFor).diff(moment.utc(b.bookingRequestedFor))
    })
    return sortedList;
  }
  closeInfoWindow() {

    this.selectedProviderBooking = false;
    $(".ProviderInfo").hide();
    this.clearMap();
    this.toggleMapDiv(2);
    $("#floating_button_pat").addClass("right5");
    $(".map-search").addClass("right5");
    this.placeMarker(this.tabChoosen);
  }
  // provider movement changes online,offline,busy,loggedout
  providerChanges(m) {
    switch (parseInt(m.status)) {
      // move provider to offline
      case 4:
        if (this.CitySelected == true) {
          if (this.offline.findIndex((item) => item._id == m._id) < 0) {
            var greyMarker = "assets/images/grayMarker.png";
            // this.markerOffline = [];
            if (this.available.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
              this.offlineProCount++; this.onlineProCount--;
              this.driverTransfer(m, this.available, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerAvailable);
            }
            //check in loggedout
            else if (this.loggedOut.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
              this.offlineProCount++; this.logoutProCount--;
              this.driverTransfer(m, this.loggedOut, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerloggedOut);
            }
            //check in inactive
            else if (this.inactive.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
              this.offlineProCount++; this.inactiveProCount--;
              this.driverTransfer(m, this.inactive, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerInactive);
            }

            //add by default - select any array out of three
            else {

              // this.offlineProCount++;
              if (this.SearchCityId === m.cityId) {
                this.driverTransfer(m, this.available, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerAvailable);
              }
            }
            if (!this.ref['destroyed']) { this.ref.detectChanges(); }
          }
        }
        else {
          if (!this.filterValue || this.filterValue == 1) {
            if (this.offline.findIndex((item) => item._id == m._id) < 0) {
              var greyMarker = "assets/images/grayMarker.png";
              // this.markerOffline = [];
              //check in online
              if (this.available.findIndex((item) => item._id == m._id) >= 0) {
                this.offlineProCount++; this.onlineProCount--;
                this.driverTransfer(m, this.available, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerAvailable);
              }
              //check in loggedout
              else if (this.loggedOut.findIndex((item) => item._id == m._id) >= 0) {
                this.offlineProCount++; this.logoutProCount--;
                this.driverTransfer(m, this.loggedOut, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerloggedOut);
              }
              //check in inactive
              else if (this.inactive.findIndex((item) => item._id == m._id) >= 0) {
                this.offlineProCount++; this.inactiveProCount--;
                this.driverTransfer(m, this.inactive, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerInactive);
              }

              //add by default - select any array out of three
              else {
                this.offlineProCount++;
                this.driverTransfer(m, this.available, this.offline, this.offlinePointsPaginated, this.markerOffline, greyMarker, this.markerAvailable);
              }
              if (!this.ref['destroyed']) { this.ref.detectChanges(); }
            }
          }
        }
        break;

      // move provider to Online
      case 3:
        if (this.CitySelected == true) {
          if (this.available.findIndex((item) => item._id == m._id) < 0) {
            var greenMarker = "assets/images/greenMarker.svg";
            // this.markerAvailable = [];
            if (this.offline.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
              this.offlineProCount--; this.onlineProCount++;
              this.driverTransfer(m, this.offline, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerOffline);
            }
            //check in inactive
            else if (this.inactive.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
              this.onlineProCount++; this.inactiveProCount--;
              this.driverTransfer(m, this.inactive, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerInactive);
            }
            //check in logged out
            else if (this.loggedOut.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
              this.logoutProCount--; this.onlineProCount++;
              this.driverTransfer(m, this.loggedOut, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerloggedOut);
            }
            //add by default
            else {
              if (this.SearchCityId === m.cityId) {
                this.driverTransfer(m, this.offline, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerOffline);
              }
            }
            if (!this.ref['destroyed']) { this.ref.detectChanges(); }
          }
        }
        else {
          if (!this.filterValue || this.filterValue == 1) {
            if (this.available.findIndex((item) => item._id == m._id) < 0) {
              var greenMarker = "assets/images/greenMarker.svg";
              //check in offline
              if (this.offline.findIndex((item) => item._id == m._id) >= 0) {
                this.offlineProCount--; this.onlineProCount++;
                this.driverTransfer(m, this.offline, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerOffline);
              }
              //check in inactive
              else if (this.inactive.findIndex((item) => item._id == m._id) >= 0) {
                this.onlineProCount++; this.inactiveProCount--;
                this.driverTransfer(m, this.inactive, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerInactive);
              }
              //check in logged out
              else if (this.loggedOut.findIndex((item) => item._id == m._id) >= 0) {
                this.logoutProCount--; this.onlineProCount++;
                this.driverTransfer(m, this.loggedOut, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerloggedOut);
              }
              //add by default
              else {
                this.onlineProCount++;
                this.driverTransfer(m, this.offline, this.available, this.availablePoints, this.markerAvailable, greenMarker, this.markerOffline);
              }
            }
          }
          if (!this.ref['destroyed']) { this.ref.detectChanges(); }
        }
        break;

      // move provider to Inactive
      case 7:
        if (this.CitySelected == true) {
          if (this.inactive.findIndex((item) => item._id == m._id) < 0) {
            var inact = "assets/images/inact40.png";
            // this.markerInactive = [];
            //check in online
            if (this.SearchCityId == m.cityId) {
              if (this.available.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
                this.onlineProCount--; this.inactiveProCount++;
                this.driverTransfer(m, this.available, this.inactive, this.loggedOutPoints, this.markerInactive, inact, this.markerAvailable);
              }
              //add by default
              else {
                if (this.SearchCityId === m.cityId) {
                  this.driverTransfer(m, this.available, this.inactive, this.loggedOutPoints, this.markerInactive, inact, this.markerAvailable);
                }
              }
            }
            if (!this.ref['destroyed']) { this.ref.detectChanges(); }
          }
        }
        else {
          if (!this.filterValue || this.filterValue == 1) {
            if (this.inactive.findIndex((item) => item._id == m._id) < 0) {
              var inact = "assets/images/inact40.png";
              this.avai = [];
              //check in online
              if (this.available.findIndex((item) => item._id == m._id) >= 0) {
                this.onlineProCount--; this.inactiveProCount++;
                this.driverTransfer(m, this.available, this.inactive, this.loggedOutPoints, this.markerInactive, inact, this.markerAvailable);
              }
              //add by default
              else {
                this.inactiveProCount++;
                this.driverTransfer(m, this.available, this.inactive, this.loggedOutPoints, this.markerInactive, inact, this.markerAvailable);
              }

              if (!this.ref['destroyed']) { this.ref.detectChanges(); }
            }
          }
        }
        break;

      // move provider to logged-out
      case 8:
        if (this.CitySelected == true) {
          if (this.loggedOut.findIndex((item) => item._id == m._id) < 0) {
            var redMarker = "assets/images/redMarker.png";
            //check in online
            if (this.SearchCityId == m.cityId) {
              if (this.available.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
                this.onlineProCount--; this.logoutProCount++;
                this.driverTransfer(m, this.available, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerAvailable);
              }
              //check in inactive
              else if (this.inactive.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
                this.logoutProCount++; this.inactiveProCount--;
                this.driverTransfer(m, this.inactive, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerInactive);
              }
              //check in offline
              else if (this.offline.findIndex((item) => (item._id == m._id && this.SearchCityId == m.cityId)) >= 0) {
                this.offlineProCount--; this.logoutProCount++;
                this.driverTransfer(m, this.offline, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerOffline);
              }
              //add by default
              else {
                // this.logoutProCount++;
                if (this.SearchCityId === m.cityId) {
                  this.driverTransfer(m, this.available, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerAvailable);
                }
              }
            }
            if (!this.ref['destroyed']) { this.ref.detectChanges(); }
          }
        }
        else {
          if (!this.filterValue || this.filterValue == 1) {
            if (this.loggedOut.findIndex((item) => item._id == m._id) < 0) {
              var redMarker = "assets/images/redMarker.png";
              this.avai = [];
              //check in online
              if (this.available.findIndex((item) => item._id == m._id) >= 0) {
                this.onlineProCount--; this.logoutProCount++;
                this.driverTransfer(m, this.available, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerAvailable);
              }
              //check in inactive
              else if (this.inactive.findIndex((item) => item._id == m._id) >= 0) {
                this.logoutProCount++; this.inactiveProCount--;
                this.driverTransfer(m, this.inactive, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerInactive);
              }
              //check in offline
              else if (this.offline.findIndex((item) => item._id == m._id) >= 0) {
                this.offlineProCount--; this.logoutProCount++;
                this.driverTransfer(m, this.offline, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerOffline);
              }
              //add by default
              else {
                this.logoutProCount++;
                this.driverTransfer(m, this.available, this.loggedOut, this.loggedOutPoints, this.markerloggedOut, redMarker, this.markerAvailable);
              }
            }
            if (!this.ref['destroyed']) { this.ref.detectChanges(); }
          }
        }
        break;
    }
  }

  driverTransfer(m, value1, value2, value3, value4, value5, demote1) {
    this.mUpdate = [];
    //       m -> socket response
    if (!this.ref['destroyed']) { this.ref.detectChanges(); }
    //  value1 -> From which array u should find the provider  
    //  value2 -> To which array u should shift the provider
    //checking if provider name is in  provider array                     
    //  value3 -> value as per driverSorting function in ngOnInIt for that req array
    //  value4 -> Marker Array , where u want to add new marker 
    //  value5 -> marker image  
    // demote1 -> Marker Array from u want to delete the marker
    if (value1.findIndex((item) => item._id == m._id) >= 0) {
      var indVal = value1.findIndex((item) => item._id == m._id);
      value1.splice(value1.findIndex((item) => item._id == m._id), 1);

      var findVal = value2.findIndex((item) => item._id == m._id);
      if (findVal >= 0) {
        value2.splice(findVal, 1);
      }
      value2.unshift(m);
      value2.lastUpdatedMinutes = "Last updated " + Math.round(m.lastActive / 60) + "min ago";
      var Id = demote1.findIndex((item) => item.id == m._id);
      //checking if marker is existing in  marker list
      if (Id >= 0) {
        this.mUpdate[0] = m;
        demote1[Id].setMap(null);   //remove marker
        demote1.splice(Id, 1);
        this.driverSorting(this.mUpdate, value4, value5);
      }
      if (!this.ref['destroyed']) { this.ref.detectChanges(); }
    } else {
      this.mUpdate[0] = m;                                                              //if the provider is not in any list then he will be added to the list where u wanted to shift him 
      value2.unshift(m);
      this.driverSorting(this.mUpdate, value4, value5);
      if (!this.ref['destroyed']) { this.ref.detectChanges(); }
    }
    if (!this.ref['destroyed']) { this.ref.detectChanges(); }
  }

  //live tracking code
  providerLocationUpdate(m) {
    let avai1 = [];
    switch (parseInt(m.status)) {
      //online
      case 3:
        if (this.CitySelected) {
          for (let i = 0; i < this.available.length; i++)
            if (this.available[i].cityId !== this.SearchCityId) {
              this.available.splice(i, 1);
              break;
            }
        }
        else {
          if (this.markerAvailable.findIndex((item) => item.id == m._id) >= 0) {
            let availableMarkerIndex = this.markerAvailable.findIndex((item) => item.id == m._id);
            var latlng = new google.maps.LatLng(m.latitude, m.longitude);
            this.markerAvailable[availableMarkerIndex].setPosition(latlng);
            if (this.available.findIndex((item) => item._id == m._id) >= 0) {
              var aIndex = this.available.findIndex((item) => item._id == m._id);
              this.available[aIndex].latitude = m.latitude;
              this.available[aIndex].longitude = m.longitude;
              this.available[aIndex].batteryPercentage = m.batteryPercentage;
              this.available[aIndex].appversion = m.appversion;
              this.available[aIndex].bookingCount = m.bookingCount;
              this.available[aIndex].deviceType = m.deviceType;
              this.available[aIndex].time = m.time;
              this.available[aIndex].locationCheck = m.locationCheck;
            }
          }

          if (!this.ref['destroyed']) { this.ref.detectChanges(); }
        }
        break;

      //busy
      case 5:
        if (this.markerBusy.findIndex((item) => item.id == m._id) >= 0) {
          var busyMarkerIndex = this.markerBusy.findIndex((item) => item.id == m._id);
          var latlng = new google.maps.LatLng(m.latitude, m.longitude);
          this.markerBusy[busyMarkerIndex].setPosition(latlng);
          if (this.CitySelected == true) {
            if (this.busy.findIndex((item) => item._id == m._id && this.SearchCityId == m.cityId) >= 0) {
              var bIndex = this.busy.findIndex((item) => item._id == m._id);
              this.busy[bIndex].latitude = m.latitude;
              this.busy[bIndex].longitude = m.longitude;
              this.busy[bIndex].batteryPercentage = m.batteryPercentage;
              this.busy[aIndex].appversion = m.appversion;
              this.busy[aIndex].bookingCount = m.bookingCount;
              this.busy[aIndex].deviceType = m.deviceType;
              this.busy[aIndex].time = m.time;
              this.available[aIndex].locationCheck = m.locationCheck;
            }
          }
          else {
            if (this.busy.findIndex((item) => item._id == m._id) >= 0) {
              var bIndex = this.busy.findIndex((item) => item._id == m._id);
              this.busy[bIndex].latitude = m.latitude;
              this.busy[bIndex].longitude = m.longitude;
              this.busy[bIndex].batteryPercentage = m.batteryPercentage;
              this.busy[aIndex].appversion = m.appversion;
              this.busy[aIndex].bookingCount = m.bookingCount;
              this.busy[aIndex].deviceType = m.deviceType;
              this.busy[aIndex].time = m.time;
              this.available[aIndex].locationCheck = m.locationCheck;
            }
          }
          if (!this.ref['destroyed']) {
            this.ref.detectChanges();
          }
        }
        break;
    }
  }
  show_details(value) {
    this.bookingDetails = true;
    this.geocode(value.address);
    this.getBookingDetailsPopUp(value);
  }


  getBookingDetailsPopUp(value) {
    let d = new Date();
    let n = d.getTimezoneOffset();

    value.jobStatusLogs.startedTime = parseInt(value.jobStatusLogs.startedTime) - (n * 60);
    value.jobStatusLogs.requestedTime = parseInt(value.jobStatusLogs.requestedTime) - (n * 60);
    value.jobStatusLogs.onthewayTime = parseInt(value.jobStatusLogs.onthewayTime) - (n * 60);
    value.jobStatusLogs.acceptedTime = parseInt(value.jobStatusLogs.acceptedTime) - (n * 60);
    value.jobStatusLogs.receivedTime = parseInt(value.jobStatusLogs.receivedTime) - (n * 60);
    this.jobDetailsByBooking = value;
    this.itemList = this.jobDetailsByBooking.cartData;
    setTimeout(() => {
      $('#jobdetailModal').appendTo("body").modal('show');
    }, 200)
    if (!this.ref['destroyed']) { this.ref.detectChanges(); }
  }

  openDetailsPopUp() {
    this.detailsPopUp = true;
    setTimeout(() => {
      $("#card").toggleClass("flipped");
      jQuery(".container_flip_parent").addClass('show_div');
    }, 1);
    // $("#card").toggleClass("flipped");
    // jQuery(".container_flip_parent").addClass('show_div');
    var scrollBarWidth = window.innerWidth - document.body.offsetWidth;
    document.body.style.overflow = 'hidden';
    $(".container_flip_parent").css('display', 'block')
  }

  close_details() {
    this.bookingDetails = false;
    this.detailsPopUp = false;
    $("#card").toggleClass("flipped");
    jQuery(".container_flip_parent").removeClass('show_div');
    document.body.style.margin = '';
    document.body.style.overflow = '';
    $(".container_flip_parent").css('display', ' ')
  }

  geocode(obj) {
    var lat = parseFloat(obj.latitude);
    var lng = parseFloat(obj.longitude);
    var latlng = new google.maps.LatLng(lat, lng);
    var geocoder = geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latlng }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          this.selectedJobAddress = results[1].formatted_address;
        }
      };
      if (!this.ref['destroyed']) {
        this.ref.detectChanges();
      }
    });
  }
  tabChoosen: any;
  clearRightSide = (tabChangeEvent: MatTabChangeEvent): void => {
    this.closeInfoWindow();
    this.tabChoosen = tabChangeEvent;
    this.placeMarker(tabChangeEvent);
  }
}