<div id="appt_loader_loggedOut" style="display:none">
    <div id="loggedOut">
        <img src="assets/images/logout.png" id="drivers-busy_icn">
        <p>Your session has been expired , please login again</p>
        <button id="busy_drivers_ok" (click)="logOut()">OK</button>
    </div>
</div>

<div class="left-window">
    <div id="appt_loader_parent" style="display:none">
        <div id="appt_loader"></div>
    </div>


    <div class="right_window_header">
        <div class="col-md-12 padding-0 left_window_header" style=" background-color: #ffffff">
            <div class="col-md-1 text-center icn_left">
                <i class="fas fa-search"></i>
            </div>
            <div class="col-md-9 zeroPadding">
                <mat-form-field class="w100">
                    <input matInput placeholder="Search Provider" [(ngModel)]="providerSearchString"
                        [formControl]="searchControll" (ngModelChange)="getAllProvider(0,0)">
                </mat-form-field>
            </div>
            <div class="col-md-2 padding-0 text-center marginTop10" (click)="refreshProviderSearch()">
                <i id="search_pickerOne" title="refresh" style="cursor:pointer" class="fas fa-sync-alt"></i>
                <!-- <span id="search_pickerOne" title="refresh" class="glyphicon glyphicon-refresh icn_left" style="cursor:pointer" (click)="refreshProviderSearch()"></span> -->
            </div>
        </div>
    </div>
    <!-- Select the provider based on cities -->
    <br>
    <div *ngIf="cityBrokerID == '0' " class="right_window_header_SearchCity">
        <div class="col-md-12  SerchCity">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <form>
                    <mat-form-field style="top:10px">
                        <mat-select placeholder="Select Provider By City" #city [formControl]="CityProviders"
                            name="City" (ngModelChange)="getProviderByCity(city.value,1)" style="color:white">
                            <mat-option *ngFor="let City of CitiesProviderList" [value]="City.cityId">
                                {{City.cityName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
            <div class="col-md-3"></div>
        </div>
    </div>
    <br *ngIf="cityBrokerID == '0' ">
    <hr *ngIf="cityBrokerID == '0' " style="color:black">

    <div class="col-md-12 padding-0 fourDivsTab1">
        <div class="col-md-12 padding-0">
            <mat-tab-group (selectedIndexChange)="clearRightSide($event)">
                <mat-tab label="Online">
                    <ng-template mat-tab-label>
                        <div class="joblist orderlist text-center padding-0 active">
                            <p class="numbers">{{onlineProCount}}</p>
                            <p class="booking-status-header">Online</p>
                        </div>
                    </ng-template>
                    <div class="common_providers available_providers" id="available_providers" style="height: 714px;">

                        <div class="selectionArea">

                            <div #statusProvider1 class="optionSlect f50">
                                <select #statusProvider1 [(ngModel)]="filterValue">
                                    <option value="1">All</option>
                                    <option value="0">Free</option>
                                    <option value="6">On The Way</option>
                                    <option value="7">Arrived</option>
                                    <option value="8">Started</option>
                                    <option value="3">Accepted</option>
                                </select>
                            </div>

                            <div class="optionConfirm f50">
                                <button class="btnView" (click)="change(statusProvider1.value,1)">Apply</button>
                            </div>

                        </div>
                        <div *ngIf="onlineProCount <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>
                        <div cdkDropList (cdkDropListDropped)="dragDropProviderList($event,1)">
                            <div *ngFor="let item of available; let i = index;" class="eachProvider eachProvider-{{i}}"
                                id="eachProvider-{{item._id}}" cdkDrag>
                                <div class="provider-profile-main-container" (click)="locate(item)">
                                    <div class="provider-profile-image image-status-div">
                                        <img src="{{item.image}}" alt="{{item.name}}" height="42" width="42"
                                            class="profile-pic aws-image" id="image-{{item.user}}">
                                        <div class="status-indicator">
                                            <div>
                                                <span class="indicator indicator-active pull-right">dd</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name-phone-div">
                                        <p class="div_display provider-name">
                                            <img src="assets/svgImages/roundUser.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;{{item.name}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.email">
                                            <img src="assets/svgImages/email.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;{{item.email}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.phone">
                                            <img src="assets/svgImages/phone.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;({{item.phone}})
                                        </p>

                                    </div>
                                    <div class="jobCounts text-right">
                                        <p class="tot_job text-right">
                                            <span *ngIf="item.bookingCount >= 0">{{item.bookingCount}} | jobs</span>
                                            <span *ngIf="item.bookingCount < 0">0 | jobs</span>
                                        </p>
                                        <p class="lastSeen">{{item.time}}</p>
                                    </div>
                                    <div class="extraInfo">
                                        <div class="batry fLeft25">
                                            <img src="assets/images/battery.png">&nbsp;
                                            <span *ngIf="item.batteryPercentage">{{item.batteryPercentage}}%</span>
                                            <span *ngIf="!item.batteryPercentage"> -- </span>
                                        </div>
                                        <div class="locChk fLeft25">
                                            <img src="assets/svgImages/compass2.svg" width="12px" height="12px"> &nbsp;
                                            <span *ngIf="item.locationCheck == 1">On</span>
                                            <span *ngIf="item.locationCheck == 0">Off</span>
                                            <!-- <span *ngIf="!item.locationCheck" > -- </span> -->
                                        </div>
                                        <div class="devSoft fLeft25">
                                            <img src="assets/svgImages/login.svg" width="12px" height="12px">&nbsp;
                                            <span *ngIf="item.deviceType == 2">
                                                <img src="assets/images/android.png" width="12px" height="12px">
                                            </span>
                                            <span *ngIf="item.deviceType == 1">
                                                <img src="assets/svgImages/apple.svg" width="12px" height="12px">
                                            </span>
                                            <span *ngIf="!item.deviceType"> -- </span>
                                            <span *ngIf="item.deviceType > 2"> -- </span>
                                        </div>
                                        <div class="devVer fLeft25">
                                            <img src="assets/images/hand-gesture.png">&nbsp;
                                            <span *ngIf="item.appversion">{{item.appversion}}</span>
                                            <span *ngIf="!item.appversion"> -- </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Offline">
                    <ng-template mat-tab-label>
                        <div class="joblist orderlist text-center padding-0">
                            <p class="numbers">{{offlineProCount}}</p>
                            <p class="booking-status-header">Offline</p>
                        </div>
                    </ng-template>
                    <div id="offline_providers" class="common_providers offline_providers" style="height: 714px;">
                        <div class="selectionArea">
                            <div class="optionSlect f50">
                                <select #statusProvider2>
                                    <option value="1">All</option>
                                    <option value="0">Free</option>
                                    <option value="6">On The Way</option>
                                    <option value="7">Arrived</option>
                                    <option value="8">Started</option>
                                    <option value="3">Accepted</option>
                                </select>
                            </div>
                            <div class="optionConfirm f50">
                                <button class="btnView" (click)="change(statusProvider2.value,2)">Apply</button>
                            </div>
                        </div>
                        <div *ngIf="offlineProCount <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>
                        <div cdkDropList (cdkDropListDropped)="dragDropProviderList($event,2)">
                            <div *ngFor="let item of offline; let i = index;"
                                class="eachProvider eachProviderOffline-{{i}}" id="eachProvider-{{item.user}}" cdkDrag>
                                <div class="provider-profile-main-container" (click)="locate(item)">
                                    <div class="provider-profile-image image-status-div">
                                        <img src="{{item.image}}" alt="{{item.name}}" height="42" width="42"
                                            class="profile-pic aws-image" id="image-{{item.user}}">
                                        <div class="status-indicator">
                                            <div>
                                                <span class="indicator indicator-offline pull-right">dd</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name-phone-div">
                                        <p class="div_display provider-name">
                                            <img src="assets/svgImages/roundUser.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;{{item.name}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.email">
                                            <img src="assets/svgImages/email.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;{{item.email}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.phone">
                                            <img src="assets/svgImages/phone.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;({{item.phone}})
                                        </p>

                                    </div>
                                    <div class="jobCounts text-right">
                                        <p class="tot_job text-right"><span
                                                *ngIf="item.bookingCount >= 0">{{item.bookingCount}} | jobs</span> <span
                                                *ngIf="item.bookingCount < 0">0 | jobs</span></p>
                                        <p class="lastSeen">{{item.time}}</p>
                                    </div>
                                    <div class="extraInfo">
                                        <div class="batry fLeft25">
                                            <img src="assets/images/battery.png">&nbsp;
                                            <span *ngIf="item.batteryPercentage">{{item.batteryPercentage}}%</span>
                                            <span *ngIf="!item.batteryPercentage"> -- </span>
                                        </div>
                                        <div class="locChk fLeft25">
                                            <img src="assets/svgImages/compass2.svg" width="12px" height="12px"> &nbsp;
                                            <span *ngIf="item.locationCheck == 1">On</span>
                                            <span *ngIf="item.locationCheck == 0">Off</span>
                                            <!-- <span *ngIf="!item.locationCheck" > -- </span> -->
                                        </div>
                                        <div class="devSoft fLeft25">
                                            <img src="assets/svgImages/login.svg" width="12px" height="12px">&nbsp;
                                            <span *ngIf="item.deviceType == 2">
                                                <img src="assets/images/android.png" width="12px" height="12px">
                                            </span>
                                            <span *ngIf="item.deviceType == 1">
                                                <img src="assets/svgImages/apple.svg" width="12px" height="12px">
                                            </span>
                                            <span *ngIf="!item.deviceType"> -- </span>
                                            <span *ngIf="item.deviceType > 2"> -- </span>
                                        </div>
                                        <div class="devVer fLeft25">
                                            <img src="assets/images/hand-gesture.png">&nbsp;
                                            <span *ngIf="item.appversion">{{item.appversion}}</span>
                                            <span *ngIf="!item.appversion"> -- </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Inactive">
                    <ng-template mat-tab-label>
                        <div class="joblist orderlist text-center padding-0">
                            <p class="numbers">{{inactiveProCount}}</p>
                            <p class="booking-status-header">Inactive</p>
                        </div>
                    </ng-template>
                    <div id="inactive_providers" class="common_providers inactive_providers" style="height: 714px;">
                        <div class="selectionArea">
                            <div class="optionSlect f50">
                                <select #statusProvider3>
                                    <option value="1">All</option>
                                    <option value="0">Free</option>
                                    <option value="6">On The Way</option>
                                    <option value="7">Arrived</option>
                                    <option value="8">Started</option>
                                    <option value="3">Accepted</option>
                                </select>
                            </div>
                            <div class="optionConfirm f50">
                                <button class="btnView" (click)="change(statusProvider3.value,3)">Apply</button>
                            </div>
                        </div>
                        <div *ngIf="inactiveProCount <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>
                        <!-- <div class="col-md-12 padding-0" id="unassigned" (scroll)="onScroll($event,1)"> -->
                        <div cdkDropList (cdkDropListDropped)="dragDropProviderList($event,3)">
                            <div *ngFor="let item of inactive; let i = index;"
                                class="eachProvider eachProviderLoggedOut-{{i}}" id="eachProvider-{{item.user}}"
                                cdkDrag>
                                <div class="provider-profile-main-container" (click)="locate(item)">
                                    <div class="provider-profile-image image-status-div">
                                        <img src="{{item.image}}" alt="{{item.name}}" height="42" width="42"
                                            class="profile-pic aws-image" id="image-{{item.user}}">
                                        <div class="status-indicator">
                                            <div>
                                                <span class="indicator indicator-inactive pull-right">dd</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name-phone-div">
                                        <p class="div_display provider-name">
                                            <img src="assets/svgImages/roundUser.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;{{item.name}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.email">
                                            <img src="assets/svgImages/email.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;{{item.email}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.phone">
                                            <img src="assets/svgImages/phone.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;({{item.phone}})
                                        </p>

                                    </div>
                                    <div class="jobCounts text-right">
                                        <p class="tot_job text-right"><span
                                                *ngIf="item.bookingCount >= 0">{{item.bookingCount}} | jobs</span> <span
                                                *ngIf="item.bookingCount < 0">0 | jobs</span></p>
                                        <p class="lastSeen">{{item.time}}</p>
                                    </div>
                                    <div class="extraInfo">
                                        <div class="batry fLeft25">
                                            <img src="assets/images/battery.png">&nbsp;
                                            <span *ngIf="item.batteryPercentage">{{item.batteryPercentage}}%</span>
                                            <span *ngIf="!item.batteryPercentage"> -- </span>
                                        </div>
                                        <div class="locChk fLeft25">
                                            <img src="assets/svgImages/compass2.svg" width="12px" height="12px"> &nbsp;
                                            <span *ngIf="item.locationCheck == 1">On</span>
                                            <span *ngIf="item.locationCheck == 0">Off</span>
                                            <!-- <span *ngIf="!item.locationCheck" > -- </span> -->
                                        </div>
                                        <div class="devSoft fLeft25">
                                            <img src="assets/svgImages/login.svg" width="12px" height="12px">&nbsp;
                                            <span *ngIf="item.deviceType == 2">
                                                <img src="assets/images/android.png" width="12px" height="12px">
                                            </span>
                                            <span *ngIf="item.deviceType == 1">
                                                <img src="assets/svgImages/apple.svg" width="12px" height="12px">
                                            </span>
                                            <span *ngIf="!item.deviceType"> -- </span>
                                            <span *ngIf="item.deviceType > 2"> -- </span>
                                        </div>
                                        <div class="devVer fLeft25">
                                            <img src="assets/images/hand-gesture.png">&nbsp;
                                            <span *ngIf="item.appversion">{{item.appversion}}</span>
                                            <span *ngIf="!item.appversion"> -- </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="LoggedOut">
                    <ng-template mat-tab-label>
                        <div class="joblist orderlist text-center padding-0">
                            <p class="numbers">{{logoutProCount}}</p>
                            <p class="booking-status-header">Logged Out</p>
                        </div>
                    </ng-template>
                    <div id="loggedout_providers" class="common_providers loggedout_providers" style="height: 714px;">
                        <!-- <div class="scroll col-md-12 padding-0" id="unassigned" (scroll)="onScroll($event,4)"> -->
                        <!-- <div cdkDropList (cdkDropListDropped)="drop($event,4)"> -->
                        <div class="selectionArea">
                            <div class="optionSlect f50">
                                <select #statusProvider4>
                                    <option value="1">All</option>
                                    <option value="0">Free</option>
                                    <option value="6">On The Way</option>
                                    <option value="7">Arrived</option>
                                    <option value="8">Started</option>
                                    <option value="3">Accepted</option>
                                </select>
                            </div>
                            <div class="optionConfirm f50">
                                <button class="btnView" (click)="change(statusProvider4.value,4)">Apply</button>
                            </div>
                        </div>
                        <div *ngIf="logoutProCount <= 0" class="emptyData col-md-12 padding-0">
                            <img src="assets/images/empty.png">
                        </div>
                        <div cdkDropList (cdkDropListDropped)="dragDropProviderList($event,4)">
                            <div *ngFor="let item of loggedOut; let i = index;"
                                class="eachProvider eachProviderLoggedOut-{{i}}" id="eachProvider-{{item.user}}"
                                cdkDrag>
                                <div class="provider-profile-main-container" (click)="locate(item)">
                                    <div class="provider-profile-image image-status-div">
                                        <img src="{{item.image}}" alt="{{item.name}}" height="42" width="42"
                                            class="profile-pic aws-image" id="image-{{item.user}}">
                                        <div class="status-indicator">
                                            <div>
                                                <span class="indicator indicator-loggedOut pull-right">dd</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name-phone-div">
                                        <p class="div_display provider-name">
                                            <img src="assets/svgImages/roundUser.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;{{item.name}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.email">
                                            <img src="assets/svgImages/email.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;{{item.email}}
                                        </p>
                                        <p class="provider-phone" *ngIf="item.phone">
                                            <img src="assets/svgImages/phone.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;({{item.phone}})
                                        </p>
                                    </div>
                                    <div class="jobCounts pull-right">
                                        <p class="tot_job text-right"><span
                                                *ngIf="item.bookingCount >= 0">{{item.bookingCount}} | jobs</span> <span
                                                *ngIf="item.bookingCount < 0">0 | jobs</span></p>
                                        <p class="lastSeen">{{item.time}}</p>
                                    </div>
                                    <div class="extraInfo">
                                        <div class="batry fLeft25">
                                            <span>
                                                <img src="assets/images/battery.png">
                                            </span>
                                            <span *ngIf="item.batteryPercentage">{{item.batteryPercentage}}%</span>
                                            <span *ngIf="!item.batteryPercentage"> -- </span>
                                        </div>
                                        <div class="locChk fLeft25">
                                            <span>
                                                <img src="assets/images/compass.png">
                                            </span>
                                            <span *ngIf="item.locationCheck == 1">On</span>
                                            <span *ngIf="item.locationCheck == 0">Off</span>
                                            <!-- <span *ngIf="!item.locationCheck" > -- </span> -->
                                        </div>
                                        <div class="devSoft fLeft25">
                                            <span>
                                                <img src="assets/images/smartphone.png">
                                            </span>
                                            <span *ngIf="item.deviceType == 2">
                                                <img src="assets/images/android.png">
                                            </span>
                                            <span *ngIf="item.deviceType == 1">
                                                <img src="assets/images/apple.png">
                                            </span>
                                        </div>
                                        <div class="devVer fLeft25">
                                            <span>
                                                <img src="assets/images/hand-gesture.png">
                                            </span>
                                            <span>{{item.appversion}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>


<!--MAP CONATAINER-->
<!-- -->
<div class="mapDiv mapFull folded">
    <div id='godsviewmap' style="height:94vh"></div>
    <input id="pac-input" class="Search" type="text" placeholder="Search Address" (change)="searchPlace($event)"
        style="top:10px;">
</div>

<div class="providerList"></div>
<div class="ProviderInfo" style="display:none">
    <!-- <div class="right_marker"><span class="glyphicon glyphicon-menu-right marker_icns icn_right"></span> </div> -->
    <div *ngIf="selectedProvider">
        <div class="right_window_header agent_heading col-md-12 padding-0">
            <div class="closeInfo" id="closeInfo">
                <a (click)="closeInfoWindow()">
                    <span><img src="assets/svgImages/cancel.svg" width="12px" height="12px" title="Close"></span>
                </a>
            </div>
            <div class="right_header_name">
                <div class="row">
                    <div class="col-md-3">
                        <div class="agent_img">
                            <img src="{{selectedProvider.image}}" src="{{selectedProvider.image}}">
                        </div>
                    </div>
                    <div class="col-md-2 zeroPadding">
                        <p class="right_header_txt">
                            <img src="assets/svgImages/roundUser.svg" class="opacityChange" width="12px"
                                height="12px">&nbsp;Name :
                        </p>
                        <p class="right_header_txt">
                            <img src="assets/svgImages/email.svg" class="opacityChange" width="12px"
                                height="12px">&nbsp;Email :
                        </p>
                        <p class="right_header_txt">
                            <img src="assets/svgImages/phone.svg" class="opacityChange" width="12px"
                                height="12px">&nbsp;Phone :
                        </p>
                        <p class="right_header_txt">
                            <img src="assets/svgImages/offline.svg" class="opacityChange" width="12px"
                                height="12px">&nbsp;Status :
                        </p>
                    </div>
                    <div class="col-md-6 zeroPadding">
                        <p class="right_header_txt"> <strong>{{selectedProvider.name}}</strong></p>
                        <p class="right_header_txt"> <strong>{{selectedProvider.email}}</strong></p>
                        <p class="right_header_txt"> <strong>{{selectedProvider.phone}}</strong></p>
                        <p class="right_header_txt">
                            <strong>
                                <span *ngIf="selectedProvider.status == 3">
                                    <span id="status_indic" style="background:rgb(0, 138, 0)">dd</span>
                                    <span id="status_text"><strong>Online</strong></span>
                                </span>
                                <span *ngIf="selectedProvider.status == 4">
                                    <span id="status_indic" style="background:rgb(132, 134, 130)">dd</span>
                                    <span id="status_text"><strong>Offline</strong></span>
                                </span>
                                <span *ngIf="selectedProvider.status == 7">
                                    <span id="status_indic" style="background:rgb(226, 122, 4)">dd</span>
                                    <span id="status_text"><strong>Inactive</strong></span>
                                </span>
                                <span *ngIf="selectedProvider.status == 8">
                                    <span id="status_indic" style="background:rgb(150, 5, 5)">dd</span>
                                    <span id="status_text"><strong>Logged Out</strong></span>
                                </span>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>

            <!-- <div id="right_header_name">
                    <span class="right_header_txt">
                        <img src="assets/svgImages/roundUser.svg" class="opacityChange" width="12px" height="12px">&nbsp;Name&nbsp;&nbsp;:<strong>&nbsp;&nbsp;{{selectedProvider.name}}</strong></span>
                    <br>
                    <span class="right_header_txt">
                        <img src="assets/svgImages/email.svg" class="opacityChange" width="12px" height="12px">&nbsp;Email&nbsp;&nbsp;&nbsp;:<strong>&nbsp;&nbsp;{{selectedProvider.email}}</strong></span>
                    <br>

                    <span class="right_header_txt">
                        <img src="assets/svgImages/phone.svg" class="opacityChange" width="12px" height="12px">&nbsp;Phone&nbsp;&nbsp;:<strong>&nbsp;&nbsp;{{selectedProvider.phone}} </strong></span>
                    <br>
                    <span class="right_header_txt">
                        <img src="assets/svgImages/offline.svg" class="opacityChange" width="12px" height="12px">&nbsp;Status&nbsp;&nbsp;:
                        <span *ngIf="selectedProvider.status == 3">
                            <span id="status_indic" style="background:rgb(0, 138, 0)">dd</span>
                            <span id="status_text"><strong>Online</strong></span>
                        </span>
                        <span *ngIf="selectedProvider.status == 4">
                            <span id="status_indic" style="background:rgb(132, 134, 130)">dd</span>
                            <span id="status_text"><strong>Offline</strong></span>
                        </span>
                        <span *ngIf="selectedProvider.status == 7">
                            <span id="status_indic" style="background:rgb(226, 122, 4)">dd</span>
                            <span id="status_text"><strong>Inactive</strong></span>
                        </span>
                        <span *ngIf="selectedProvider.status == 8">
                            <span id="status_indic" style="background:rgb(150, 5, 5)">dd</span>
                            <span id="status_text"><strong>Logged Out</strong></span>
                        </span>
                    </span>
                    <span class="right_header_txt" id="status_time" style="margin:0px"> | <strong>{{selectedProvider.time}}</strong></span>
                </div> -->


            <span></span>
        </div>
        <div class="provider-profile-details-footer">
            <div class="providerStatus">
                <div class="device-status">
                    <p class="device-name">Battery</p>
                    <p *ngIf="selectedProvider.batteryPercentage" class="device-avail">
                        {{selectedProvider.batteryPercentage}}% </p>
                    <p *ngIf="!selectedProvider.batteryPercentage" class="device-avail"> -- </p>
                </div>
                <div class="device-status">
                    <p class="device-name">Location</p>
                    <p *ngIf="selectedProvider.locationCheck && selectedProvider.locationCheck == 1"
                        class="device-avail"> On </p>
                    <p *ngIf="selectedProvider.locationCheck && selectedProvider.locationCheck == N/A || selectedProvider.locationCheck == 0"
                        class="device-avail"> Off </p>
                    <!-- <p *ngIf="!selectedProvider.locationCheck" class="device-avail"> -- </p> -->
                </div>
                <div class="device-status">
                    <p class="device-name">Version</p>
                    <p *ngIf="selectedProvider.appversion" class="device-avail">{{selectedProvider.appversion}} </p>
                    <p *ngIf="!selectedProvider.appversion" class="device-avail"> -- </p>
                </div>
                <div class="device-status">
                    <p class="device-name">Device</p>
                    <p *ngIf="selectedProvider.deviceType == 1" class="device-avail">IOS</p>
                    <p *ngIf="selectedProvider.deviceType == 2" class="device-avail">Android</p>
                    <p *ngIf="!selectedProvider.deviceType" class="device-avail"> -- </p>
                    <!-- <p class="device-avail" *ngIf="eachProviderDetails.device_type == 2">Android</p> -->
                </div>
            </div>
        </div>
        <div class="col-md-12 padding-0">
            <div class="col-md-12 padding-right-0 highlight">
                <h5>Current Jobs</h5>
            </div>
            <div class="col-md-12 spinner" *ngIf="!selectedProviderBooking">
                <mat-spinner></mat-spinner>
            </div>

            <div class="col-md-12 padding-0 providerbooklist scrollStyle" *ngIf="selectedProviderBooking">
                <div *ngIf="selectedProviderBooking.length == 0" class="col-md-12 no-providers">
                    Current job list is empty !!
                </div>
                <!-- <div *ngFor="let item of selectedProviderBooking" class="left_window_body col-md-12 padding-0" id="booking-{{item.bookingId}}">
                        <div class="job_id_header col-md-12 padding-0">
                            <div class="job_id_header_left col-md-12 padding-0">
                                <div class="col-md-4 padding-0">
                                    <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                    <span title="Dispatch count" *ngIf="item.dispatchCount" class="dispatch_attempt">{{item.dispatchCount}}</span>
                                </div>
                                <div class="col-md-4 padding-0">
                                    <span class="job_id_task">{{item.statusMsg}}</span>
                                </div>
                                <div class="col-md-4 padding-0">
                                    <a (click)="getBookingDetailsPopUp(item)" class="details_but">
                                        <span style="color:#fff">Details</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="job_id_body Unassigned">
                            <span class="customer-name">{{item.customerData.firstName}} {{item.customerData.lastName}} ({{item.customerData.phone}})</span>
                            <div id="CountDownRapper-{{item.bookingId}}" style="display:none;float: right">
                                <span id="countDown-{{item.bookingId}}" class="countDown Unassigned-countDown"> </span>
                            </div>

                            <div class="job_id_main_body">
                                <div class="mb-2 mt-2">
                                    <span class="job_id_zone service">Category : {{item.category}}</span>
                                    <div class="now-later">
                                        <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                        <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                    </div>
                                </div>
                                <div class="mb-2 mt-2" *ngIf="item.providerData && item.providerData.name">
                                    <span class="job_id_zone service">Assigned For : {{item.providerData.name}}</span>
                                </div>
                                <div class="job-details">
                                    <div class="job_id_date">
                                        <span class="points_text">Booking Time</span>
                                        <br>
                                        <div class="job_id_time">{{item.bookingRequestedAt*1000 | date:'medium'}}</div>
                                    </div>
                                    <div class="job_id_type">
                                        <span class="points_text">Total Price</span>
                                        <br>
                                        <div class="job_fare"> {{item.currencySymbol}} {{item.totalAmount}}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> -->
                <div class="BookingsContent">
                    <div *ngFor="let item of selectedProviderBooking">
                        <div class="job_id_header col-md-12 padding-0">
                            <div class="job_id_header_left col-md-12 padding-0">
                                <div class="col-md-5 padding-0">
                                    <span class="job_id_order_id">BID:{{item.bookingId}}</span>
                                    <span title="Dispatch count" *ngIf="item.dispatchCount"
                                        class="dispatch_attempt">{{item.dispatchCount}}</span>
                                </div>
                                <div class="col-md-5 padding-0 text-center">
                                    <span class="job_id_task">{{item.statusMsg}}</span>
                                </div>
                                <div class="col-md-2">
                                    <div class="row pull-right">

                                        <div class="col-md-4 padding-5">
                                            <a title="Details" (click)="getBookingDetailsPopUp(item)"
                                                class="details_but danger" style="color:#fff;">
                                                <img src="assets/svgImages/menu3.svg" width="12px" height="12px"
                                                    title="BookingDetails">
                                            </a>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div class="col-md-12 userDetails">
                                <div class="job_id_body">
                                    <div class="row customerName">
                                        <div class="col-md-12 zeroPadding margin-CustomerName">
                                            &nbsp;&nbsp;<img src="assets/svgImages/user.svg" class="svgIcons"
                                                width="12px" height="12px">
                                            <span class="customer-name">&nbsp;{{item.customerData.firstName}}
                                                {{item.customerData.lastName}} ({{item.customerData.phone}})
                                            </span>
                                            <span class="now-later">
                                                <span class="now" *ngIf="item.bookingType == 1">Now</span>
                                                <span class="now" *ngIf="item.bookingType == 2">Later</span>
                                                <span class="now" *ngIf="item.bookingType == 3">Multiple Shift</span>
                                            </span>

                                        </div>
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-1 userDetail">
                                                    <p><img src="assets/svgImages/maintenance.svg" class="svgIcons"
                                                            width="12px" height="12px"></p>
                                                    <p><img src="assets/svgImages/worker.svg" class="svgIcons"
                                                            width="12px" height="12px"></p>
                                                    <p><img src="assets/svgImages/calendar.svg" class="svgIcons"
                                                            width="12px" height="12px"></p>
                                                    <p><img src="assets/svgImages/money.svg" class="svgIcons"
                                                            width="12px" height="12px"></p>
                                                </div>
                                                <div class="col-md-3 zeroPadding userDetail">
                                                    <p class="svgIcons">Category :</p>
                                                    <p class="svgIcons">Assigned For :</p>
                                                    <p class="svgIcons">Booking Time :</p>
                                                    <p class="svgIcons">Total Price :</p>
                                                </div>
                                                <div class="col-md-7 userDetail zeroPadding">
                                                    <p><strong>{{item.category}}</strong></p>
                                                    <p>
                                                        <strong
                                                            *ngIf="item.providerData && item.providerData.name">{{item.providerData.name}}</strong>
                                                        <span
                                                            *ngIf="item.providerData.hasOwnProperty('name') === false">--</span>
                                                    </p>
                                                    <p><strong>{{item.bookingRequestedFor*1000 | date:'medium'}}</strong>
                                                    </p>
                                                    <p><strong>{{item.currencySymbol}}
                                                            {{item.totalAmount| number:'1.2-2'}}</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!----->
<div class="modal fade" id="jobdetailModal" *ngIf="jobDetailsByBooking" role="dialog"
    style="padding:0px!important ; padding-left:0px!important ;">
    <div class="bookingDeatailsBody">
        <div id="details_task_header clear-fix">
            <div class="row">
                <div class="col-md-6">
                    <span id="details_task_header_left">Booking ID:{{jobDetailsByBooking.bookingId}}</span>
                </div>
                <div class="col-md-6">
                    <button type="button" class="close" data-dismiss="modal" title="Close">&times;</button>
                </div>
            </div>
        </div>
        <div class="clear-fix">
            <mat-tab-group>
                <mat-tab label="Job Details">
                    <div>
                        <div id="details_task_job_details_top row">
                            <div class="left-job-details width-50 col-md-6" style="margin-top:15px;">
                                <div class="location clear-fix">
                                    <span class="points_text"><b>Job Location</b></span>
                                    <br>

                                    <div>
                                        <span class="job_id_add2">
                                            {{jobDetailsByBooking.addLine1}}</span>
                                    </div>
                                </div>
                                <div class="booking booking-time clear-fix"
                                    *ngIf="jobDetailsByBooking.bookingRequestedAt">
                                    <span class="points_text"><b>Booking Started At</b></span>
                                    <br>
                                    <span>{{jobDetailsByBooking.bookingRequestedAt * 1000 | date : 'medium'}}</span>
                                </div>
                                <div class="booking booking-time clear-fix">
                                    <span class="points_text"><b>Actual Booking Accepted At</b></span>
                                    <br>
                                    <span>{{jobDetailsByBooking.bookingRequestedFor * 1000 | date : 'medium'}}</span>
                                </div>
                                <div class="booking booking-time clear-fix">
                                    <span class="points_text"><b>Payment Type</b></span>
                                    <br>
                                    <span
                                        *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.paymentMethodText">{{jobDetailsByBooking.accounting.paymentMethodText}}</span>
                                </div>
                                <div class="booking booking-time clear-fix">
                                    <span class="points_text"><b>Booking Type</b></span>
                                    <br>
                                    <span
                                        *ngIf="jobDetailsByBooking.bookingType == 1 && jobDetailsByBooking.bookingModel != 3">Book
                                        Now</span>
                                    <span
                                        *ngIf="jobDetailsByBooking.bookingType == 2 && jobDetailsByBooking.bookingModel != 3">Book
                                        Later</span>
                                    <span
                                        *ngIf="jobDetailsByBooking.bookingType == 3 && jobDetailsByBooking.bookingModel != 3">Repeat
                                        Booking</span>
                                    <span *ngIf="jobDetailsByBooking.bookingModel == 3">Bid Booking</span>
                                </div>

                            </div>
                            <div class="width-50 col-md-6" style="margin-top:15px;">
                                <div class="details_task_inner_details" style="overflow: hidden">

                                    <div class="details_task_ordered_det">
                                        <span class="points_text"><b>Customer Details</b></span>
                                        <br>
                                        <i class="fas fa-user"></i>
                                        <span class="details_task_ordered_name">
                                            {{jobDetailsByBooking.customerData.firstName}}
                                            {{jobDetailsByBooking.customerData.lastName}} </span>
                                        <br>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-phone-alt"></i>

                                        <span>
                                            {{jobDetailsByBooking.customerData.phone}}
                                        </span>
                                    </div>

                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-envelope"></i>

                                        <span>
                                            {{jobDetailsByBooking.customerData.email}}
                                        </span>
                                    </div>
                                </div>
                                <br>
                                <div class="details_task_inner_details" style="overflow: hidden"
                                    *ngIf="jobDetailsByBooking.providerData.name">

                                    <div class="details_task_ordered_det">
                                        <span class="points_text"><b>Provider Details</b></span>
                                        <br>
                                        <i class="fas fa-user"></i>
                                        <span class="details_task_ordered_name">
                                            {{jobDetailsByBooking.providerData.name}}</span>
                                        <br>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-phone-alt"></i>

                                        <span>
                                            {{jobDetailsByBooking.providerData.phone}}
                                        </span>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-envelope"></i>

                                        <span>
                                            {{jobDetailsByBooking.providerData.email}}
                                        </span>
                                    </div>
                                    <div class="details_task_ordered_det mrt-0">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 1'>On</span>
                                        <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 0'>Off</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Povide Details are added -->
                            <!-- <div class="width-25 col-md-4" style="margin-top:15px;" *ngIf='jobDetailsByBooking.providerData != true'>                                
                                        <div class="details_task_inner_details" style="overflow: hidden">
        
                                            <div class="details_task_ordered_det">
                                                <span class="points_text">Provider Details</span>
                                                <br>
                                                <i class="fas fa-user"></i>
                                                <span class="details_task_ordered_name">
                                                    {{jobDetailsByBooking.providerData.name}}</span>
                                                <br>
                                            </div>
                                            <div class="details_task_ordered_det mrt-0">
                                                <i class="fas fa-phone-alt"></i>
        
                                                <span>
                                                    {{jobDetailsByBooking.providerData.phone}}
                                                </span>
                                            </div>
                                            <div class="details_task_ordered_det mrt-0">
                                                <i class="fas fa-envelope"></i>
        
                                                <span>
                                                    {{jobDetailsByBooking.providerData.email}}
                                                </span>
                                            </div>
                                            <div class="details_task_ordered_det mrt-0">
                                                <i class="fas fa-map-marker-alt"></i>
                                                <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 1'>On</span>
                                                <span *ngIf='jobDetailsByBooking.providerData.locationCheck == 0'>Off</span>
                                            </div>
                                        </div>
                                    </div> -->

                        </div>
                        <div id="details_job_services" class="mrt-15 clear-fix">
                            <div class="width-50 fl-left">
                                <span class="points_text"><b>Service Category Name:</b></span>
                                <br>
                                <span class="details_task_ordered_name font12">
                                    {{jobDetailsByBooking.category}}
                                </span>
                            </div>
                            <div *ngIf="itemList" class="col-md-12 padding-0">

                                <div class="col-md-12 padding-0">
                                    <div class="col-md-12 subheader padding-0 points_text">
                                        <b>Booking Details:</b>
                                    </div>
                                    <br />
                                    <div class="col-md-12 padding-0">
                                        <div class="pad10">
                                            <div class="col-md-12 padding-0 mrg5">

                                                <div class="col-md-5 points_text">
                                                    <b>Service Name</b>
                                                </div>
                                                <div class="col-md-2 text-right points_text">
                                                    <b>Quantity</b>
                                                </div>
                                                <div class="col-md-3 text-right points_text">
                                                    <b>Unit Price</b>
                                                </div>
                                                <div class="col-md-2 text-right points_text">
                                                    <b>Total</b>
                                                </div>

                                            </div>
                                            <div class="col-md-12 booking-time padding-0 mrg5" *ngIf="!itemList">
                                                <div class="col-md-12 text-center">
                                                    <p>No Items Found</p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 padding-0 mrg5" *ngFor="let item of itemList">

                                                <div class="col-md-5 text-left booking-time">
                                                    <p>{{item.serviceName}}</p>
                                                    <!-- <p class="text-muted productComments">2 liter</p> -->
                                                </div>
                                                <div class="col-md-2 text-right booking-time">{{item.quntity}}</div>
                                                <div class="col-md-3 text-right booking-time">
                                                    {{ jobDetailsByBooking.currencySymbol }}
                                                    {{item.unitPrice | number:'2.1-3'}}</div>
                                                <div class="col-md-2 text-right booking-time">
                                                    {{ jobDetailsByBooking.currencySymbol }}
                                                    {{item.unitPrice * item.quntity| number:'2.1-3'}}</div>
                                            </div>

                                            <div
                                                *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.visitFee && jobDetailsByBooking.accounting.visitFee > 0">

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Visit Fees:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.visitFee | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.travelFee && jobDetailsByBooking.accounting.travelFee > 0">

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Travel Fees:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.travelFee | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.discount && jobDetailsByBooking.accounting.discount > 0">

                                                <div class="col-md-12 float-right mt-10">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Discount:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.discount | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.dues && jobDetailsByBooking.accounting.dues > 0">
        
                                                        <div class="col-md-12 float-right mt-10">
                                                            <div class="col-md-8"></div>
                                                            <div class="col-md-2 padding-0 text-right points_text">
                                                                <p>Dues:</p>
                                                            </div>
                                                            <div class="col-md-2 padding-0 text-right booking-time">
                                                                {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.dues | number:'2.1-3'}}
                                                            </div>
                                                        </div>
                                                    </div> -->
                                            <div
                                                *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.additionalServiceFee && jobDetailsByBooking.accounting.additionalServiceFee > 0">

                                                <div class="col-md-12 float-right">
                                                    <div class="col-md-8"></div>
                                                    <div class="col-md-2 padding-0 text-right points_text">
                                                        <p>Additional Service Fee:</p>
                                                    </div>
                                                    <div class="col-md-2 padding-0 text-right booking-time">
                                                        {{ jobDetailsByBooking.currencySymbol }}
                                                        {{jobDetailsByBooking.accounting.additionalServiceFee | number:'2.1-3'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                *ngIf="jobDetailsByBooking.additionalService && jobDetailsByBooking.additionalService.length > 0">
                                                <div *ngFor="let account of jobDetailsByBooking.additionalService">

                                                    <div class="col-md-12 float-right mt-10">
                                                        <div class="col-md-8"></div>
                                                        <div class="col-md-2 padding-0 text-right points_text">
                                                            <p>{{account.serviceName}}:</p>
                                                        </div>
                                                        <div class="col-md-2 padding-0 text-right booking-time">
                                                            {{ jobDetailsByBooking.currencySymbol }}
                                                            {{account.price | number:'2.1-3'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <!-- <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.dues && jobDetailsByBooking.accounting.dues > 0">
        
                                                        <div class="col-md-12 float-right mt-10">
                                                            <div class="col-md-8"></div>
                                                            <div class="col-md-2 padding-0 text-right points_text">
                                                                <p>Dues:</p>
                                                            </div>
                                                            <div class="col-md-2 padding-0 text-right booking-time">
                                                                {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.dues | number:'2.1-3'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.discount"> 
        
                                                        <div class="col-md-12 float-right mt-10">
                                                            <div class="col-md-8"></div>
                                                            <div class="col-md-2 padding-0 text-right points_text">
                                                                <p>Discount:</p>
                                                            </div>
                                                            <div class="col-md-2 padding-0 text-right booking-time">
                                                                {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.discount | number:'2.1-3'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="jobDetailsByBooking.accounting && jobDetailsByBooking.accounting.paidByWallet == 1">
        
                                                        <div class="col-md-12 float-right mt-10">
                                                            <div class="col-md-8"></div>
                                                            <div class="col-md-2 padding-0 text-right points_text">
                                                                <p>Paid by Wallet:</p>
                                                            </div>
                                                            <div class="col-md-2 padding-0 text-right booking-time">
                                                                {{ jobDetailsByBooking.currencySymbol }} {{jobDetailsByBooking.accounting.captureAmount | number:'2.1-3'}}
                                                            </div>
                                                        </div>
                                                    </div> -->
                                            <div class="col-md-12 float-right ">
                                                <div class="col-md-8"></div>
                                                <div class="col-md-2 padding-0 text-right points_text">
                                                    <b>Final Total:</b>
                                                </div>
                                                <div class="col-md-2 padding-0 text-right booking-time">
                                                    {{ jobDetailsByBooking.currencySymbol }}
                                                    {{jobDetailsByBooking.accounting.total | number:'2.1-3'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Actvity Timeline">
                    <div>
                        <div class="tabs_details details_description2 ">
                            <div *ngIf="this.jobDetailsByBooking.jobStatusLogs">
                                <div *ngFor="let timeList of this.jobDetailsByBooking.jobStatusLogs">
                                    <!-- expiredTime -->
                                    <div class="provider_activity_body" *ngIf="timeList.status == 1">

                                        <span class="provider_bullet_points"
                                            style="background:#d10c78 !important"></span>
                                        <span class="provider_activity_status">Booking Requested At :
                                            {{timeList.timeStamp *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 2">
                                        <span class="provider_bullet_points"
                                            style="background:#ba68c8!important"></span>
                                        <span class="provider_activity_status">Booking Received By Provider At : {{timeList.timeStamp
                                                        *1000 | date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 3">

                                        <span class="provider_bullet_points"
                                            style="background:#e191bd !important"></span>
                                        <span class="provider_activity_status">Booking Accepted By Provider At :
                                            {{timeList.timeStamp *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 4">

                                        <span class="provider_bullet_points"
                                            style="background:#82094b !important"></span>
                                        <span class="provider_activity_status">Booking Rejected At : {{timeList.timeStamp
                                                    *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 5">

                                        <span class="provider_bullet_points"
                                            style="background:#923656!important"></span>
                                        <span class="provider_activity_status">Booking Expired At : {{timeList.timeStamp *1000|
                                                    date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>

                                    <div class="provider_activity_body" *ngIf="timeList.status == 6">
                                        <span class="provider_bullet_points"
                                            style="background:#ffc700!important"></span>
                                        <span class="provider_activity_status">Provider on the way : {{timeList.timeStamp
                                                    *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 7" id="onTheWay">

                                        <span class="provider_bullet_points"
                                            style="background:#c0392b!important"></span>
                                        <span class="provider_activity_status">Provider Arrived At Location :
                                            {{timeList.timeStamp *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 8">

                                        <span class="provider_bullet_points"
                                            style="background:#FC8344!important"></span>
                                        <span class="provider_activity_status">Provider Started Job On :
                                            {{timeList.timeStamp *1000| date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                        <span class="provider_bullet_line_new"></span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 9">
                                        <span class="provider_bullet_points"
                                            style="background:#0078ff!important"></span>
                                        <span class="provider_activity_status">Job Completed By Provider At : {{timeList.timeStamp *1000 |
                                                        date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 10">
                                        <span class="provider_bullet_points"
                                            style="background:#89a2bf!important"></span>
                                        <span class="provider_activity_status">Provider Raised Job Invoice At : {{timeList.timeStamp *1000 |
                                                        date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 11">
                                        <span class="provider_bullet_points"
                                            style="background:#187056!important"></span>
                                        <span class="provider_activity_status">Booking Cancelled By Provider At : {{timeList.timeStamp *1000 |
                                                        date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                    </div>
                                    <div class="provider_activity_body" *ngIf="timeList.status == 12">
                                        <span class="provider_bullet_points"
                                            style="background:#235a24!important"></span>
                                        <span class="provider_activity_status">Booking Cancelled By Customer At : {{timeList.timeStamp *1000 |
                                                    date:'medium'}}
                                            <span class="activity_status_text"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <div *ngIf="AnswerDetails && AnswerDetails.length > 0">
                    <mat-tab label="Answered Questions" id="AnswersList" style="display:none;">
                        <div>
                            <div class="tabs_details details_description2 ">
                                <div class="provider_activity_body">
                                    <div *ngFor="let questions of AnswerDetails; let i = index;">
                                        <span class="col-md-12 padding-0"><b>{{i+1}})&nbsp;
                                                &nbsp;{{questions.name}}</b></span><br>
                                        <span *ngIf="questions.questionType === 10">
                                            <span class="listImg" *ngIf="questions.answer.length > 0">
                                                <span class="imagsProduct"
                                                    *ngFor="let img of questions.answer; let i = index;">
                                                    <img class="sellImg sellImgs" src="{{img}}"
                                                        onError="this.src='https://d2qb2fbt5wuzik.cloudfront.net/yelowebsite/images/default-thumnail.png';">
                                                </span>
                                            </span>
                                        </span>
                                        <span *ngIf="questions.questionType === 7"
                                            class="col-md-12 padding-0 {{questions.questionType}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <img src="assets/svgImages/check.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;&nbsp;
                                            <span class="" *ngIf="questions.answer.length === 1">
                                                <span class="checkbox1">{{questions.answer[0].name}}</span>
                                            </span>
                                            <span *ngIf="questions.answer.length > 1">
                                                <span *ngFor="let checkBox of questions.answer">
                                                    <span
                                                        class="{{checkBox.name}} checkbox1">{{checkBox.name}},&nbsp;&nbsp;</span>
                                                </span>
                                            </span>
                                        </span>
                                        <span *ngIf="questions.questionType !== 7 && questions.questionType !== 10"
                                            class="col-md-12 padding-0 {{questions.questionType}}">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <img src="assets/svgImages/check.svg" class="opacityChange" width="12px"
                                                height="12px">&nbsp;&nbsp;
                                            {{questions.answer}}</span><br>
                                        <hr style="color:black"><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </div>
            </mat-tab-group>
        </div>
    </div>
</div>