import { Component, OnInit, ChangeDetectorRef, NgModule, ViewContainerRef } from '@angular/core';
import { ViewChild, AfterViewInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { ElementRef, Renderer2 } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DatePipe } from '@angular/common';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { environment } from '../../../environments/environment';
import { Paho } from 'ng2-mqtt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastModule, ToastsManager } from 'ng2-toastr/ng2-toastr';
import { ToastrService } from 'ngx-toastr';
import 'rxjs/add/operator/retry';
import 'rxjs/Rx';
import { CookieService } from 'ngx-cookie-service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { apiServiceManager } from '../../service/api-service-manager';
import { mqttManager } from '../../service/mqtt-manager';
import { DispatcherComponent } from '../dispatcher.component';
import { Ng2FileInputModule } from 'ng2-file-input';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import swal from 'sweetalert2'
import * as moment from 'moment';

declare var $: any;
declare var jQuery: any;
declare var Fingerprint: any;
declare var google: any;
declare var placeMarkerAndPanTo: any;
declare var value: any;
declare var flatpickr: any;
declare var flatpickr2: any;
export interface ExampleTab {
  label: string;
}

@Component({
  selector: 'app-dispatcher-home',
  templateUrl: './dispatcher-home.component.html',
  styleUrls: ['./dispatcher-home.css']
})

export class DispatcherHomeComponent {
  shiftPrice: any = "";
  BookingIdForDeatils: any;
  EditedShiftPrice = new FormControl();
  QuestionAndAnswerDetails: any = [];
  WalletBalance = 0;
  countDownArray = [];
  serviceList: any = [];
  jobStatusLogs: any = [];
  myCartList: any = [];
  checkOutList: any = [];
  TotalAmount = 0;
  NamestringFilter: any;
  EmailstringFilter: any;
  PhonestringFilter: any;
  WalletSliderChecked: boolean = false;
  selectedIndex: any;
  ProviderSelectedIndex: any;
  cartServiceType: any = '';
  onLoadLatitude: any;
  disableHourly: boolean = false;
  disableFixed: boolean = false;
  ForUnassgined: boolean = false;
  onLoadLongitude: any;
  dipatcherMap: any;
  current_time: any;
  hourlycount: any = 1;
  current_time_var: any;
  ExpiryTime: any = 0;
  assigned: any = [];
  unassigned: any = [];
  completed: any = [];
  dispatched: any = [];
  assignedCount: any;
  unassignedCount: any;
  completedCount: any;
  dispatchedCount: any;
  StatusMsag: any = String;
  jobDetailsByBooking: any;
  itemList: any;
  AnswerDetails: any = [];
  createdDate: any;
  acceptedDate: any;
  onTheWay: any;
  arrivedAt: any;
  jobStarted: any;
  bidId: any;
  disablemat1: boolean = true;
  disablemat2: boolean = true;
  disablemat3: boolean = true;
  disablemat4: boolean = true;
  NewBooking: number = 0;
  available: any = [];
  availableCount: any = 0;

  unavailable: any = [];
  unavailableCount: any = 0;
  MatTab = [];
  show: boolean = false;
  selectedProvider: any;
  selectedProviderBooking: any;
  selectedProviderTimeline: any;

  selectedbooking: any;
  finalizedProvider: any;
  homePageMap: any;

  bookingForUnassign: any;
  duration: any;
  selectedmarker: any = [];
  selectedmarkerStore: any = [];
  onlineDriverMarkers: any = [];
  placeStoreMarker: any = [];
  assignedDriverMarker: any = [];
  availableMarker: any = [];

  providerJob: any = [];
  stringFilter: any;
  fromdate = new FormControl();
  todate = new FormControl();
  searchController = new FormControl();
  providerSearchController = new FormControl();
  fromDateFilter: any;
  toDateFilter: any;
  selectedDataFrom: any;
  selectedDataTo: any;

  indexOne: number = 0;
  indexTwo: number = 0;
  indexThree: number = 0;
  indexFour: number = 0;

  providerFilterString: any;

  color = 'primary';
  mode = 'determinate';
  unAssignMode = 'determined';
  dispatchMode = 'determined';
  bookingMode = 'determined';
  value = 33.33;
  bufferValue = 66.66;
  asyncTabs: Observable<ExampleTab[]>;
  bounds = new google.maps.LatLngBounds();



  constructor(private _formBuilder: FormBuilder, private _router: Router, private ref: ChangeDetectorRef,
    private _apiServiceManager: apiServiceManager, private _mqttManager: mqttManager,
    private _renderer: Renderer2, private _elementRef: ElementRef,
    private cookieService: CookieService,
    private ngxSmartModalService: NgxSmartModalService,
    //public toastr: ToastsManager, 
    private toastrService: ToastrService, vcr: ViewContainerRef, public DispatcherComponent: DispatcherComponent) {
    // this.toastrService.setRootViewContainerRef(vcr);

    this.DispatcherComponent.online$.subscribe((data) => {
      ////conole.log("home component online", data)
      if (data) {
        ////conole.log("online");
        this.getAllBookings(0, 0);
      }
    });
    // this.asyncTabs = new Observable((observer: Observer<ExampleTab[]>) => {
    //   setTimeout(() => {
    //     observer.next([
    //       { label: 'Unassigned' },
    //       { label: 'Indispatch' },
    //       { label: 'Assigned' },
    //     ]);
    //   }, 1000);
    // });
  }

  getMqttMessage() {
    this._mqttManager.getMessages().subscribe((message: Paho.MQTT.Message) => {
      let bookingData = JSON.parse(message.payloadString);
      this.StatusMsag = bookingData.statusMsg;
      this._mqttManager.getMqttMsg(bookingData);
      // if (this.selectedProvider && this.selectedProvider._id == bookingData._id) {

      //   // if (this.selectedProvider._id == bookingData._id) {
      //     this.selectedProvider = bookingData;
      //   // }
      // }
      switch (bookingData.destinationName) {
        case "bookingStatus":
          //conole.log("new bookingStatus", bookingData);
          this.bookingStatusChanges(bookingData);
          break;
        case 'providerLocation':
          this.providerLocationUpdate(bookingData);
          break;

        case 'providerStatus':
          // console.log("providerStatus", bookingData, this.selectedProvider);
          //bug: dynamically show provider details, fixed desc: get mqttData based on provider status, developler: sowmya sv
          // date: 27-11-18
          // if (this.selectedProvider) { assigned
          if (this.assigned && this.assigned.length > 0) {
            let dataSelected = this.assigned.filter((item) => item.providerData._id == bookingData._id);
            // console.log('sdnvdfjvdfv', dataSelected)
            dataSelected && dataSelected.length > 0 ? this.assignedProvider(dataSelected[0]) : '';
            // this.selectedProvider = bookingData;
          }
          // }
          this.providerChanges(bookingData);
          break;
      }
    }
    )
  }

  providerLocationUpdate(m) {
    if (this.assignedDriverMarker.findIndex((item) => item.id == m._id) >= 0) {
      let availableMarkerIndex = this.assignedDriverMarker.findIndex((item) => item.id == m._id);
      var latlng = new google.maps.LatLng(m.latitude, m.longitude);
      var initialLatLong = new google.maps.LatLng(this.selectedbooking.providerData.latitude, this.selectedbooking.providerData.longitude)
      var lastPosn = new google.maps.LatLng(this.selectedbooking.latitude, this.selectedProvider.longitude)
      var directionsDisplay;
      var directionsService = new google.maps.DirectionsService;
      directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true });

      directionsDisplay.setMap(this.homePageMap);
      directionsDisplay.setDirections({ routes: [] });

      this.assignedDriverMarker[availableMarkerIndex].setPosition(latlng);
      this.assignedDriverMarker[availableMarkerIndex].setIcon(this.assignedDriverMarker[availableMarkerIndex].icon);
    }
  }
  clearProvider() {
    this.available = [];
    this.unavailable = [];
    this.clearMap();
  }
  ForCreatManual: any;
  unassignedBookingHandler(bookingData) {
    this.NewBooking = bookingData.newBookingStatus;
    //  if its a new booking 
    if (bookingData.newBookingStatus == 1) {
      this.bidId = bookingData.bookingId;
      // for incall booking 
      if (this.assigned.findIndex((item) => item.bookingId == bookingData.bookingId) < 0 && bookingData.callType == 1) {
        this.toastrService.success('Booking ID:' + bookingData.bookingId +
          ', from ' + bookingData.customerData.firstName + '' + bookingData.customerData.lastName, 'New Booking');
        this.assigned.unshift(bookingData);
        this.assignedCount++;
      }
      // check for duplicate entry
      else if (this.dispatched.findIndex((item) => item.bookingId == bookingData.bookingId) < 0) {
        this.toastrService.success('Booking ID:' + bookingData.bookingId +
          ', from ' + bookingData.customerData.firstName + '' + bookingData.customerData.lastName, 'New Booking');
        this.dispatched.unshift(bookingData);
        bookingData.bookingType === 3 ? this.dispatched = this.checkToDecreseShiftCount(this.dispatched) : '';
        this.autodispatch_timer(bookingData.bookingExpireTime, bookingData, 0);
        this.dispatchedCount++;
      }
    }
    else if (bookingData.newBookingStatus == 2) {
      this.ForCreatManual = bookingData.bookingId;
      if (this.unassigned.findIndex((item) => item.bookingId == bookingData.bookingId) < 0) {
        this.toastrService.success('Booking ID:' + bookingData.bookingId +
          ', from ' + bookingData.customerData.firstName + '' + bookingData.customerData.lastName, 'New Booking');
        this.unassigned.unshift(bookingData);
        bookingData.bookingType === 3 ? this.unassigned = this.checkToDecreseShiftCount(this.unassigned) : '';
        this.unassignedCount++;
        $("#CountDownUnassigned-" + bookingData.bookingId).hide();
      }
    }
    else if (bookingData.unassignedBookingsStatus == true) {
      $("#CountDownUnassigned-" + bookingData.bookingId).css('display', 'none');
      this.arrayShifter(this.assigned, this.unassigned, bookingData, 8);
      $("#CountDownUnassigned-" + bookingData.bookingId).addClass("active");

    }

    else {
      // move to indispatch array
      if (bookingData.inDispatch == true) {
        this.toClearProvider(bookingData);
        this.arrayShifter(this.unassigned, this.dispatched, bookingData, 1);
        this.autodispatch_timer(bookingData.bookingExpireTime, bookingData, 0);
      } else
        // move to unassigned array
        if (bookingData.inDispatch == false) {
          if (bookingData.status == 1) {
            this.arrayShifter(this.dispatched, this.unassigned, bookingData, 2);
            bookingData.bookingType === 3 ? this.unassigned = this.checkToDecreseShiftCount(this.unassigned) : '';
            this.autodispatch_timer(bookingData.bookingExpireTime, bookingData, 4);
          }
        }
    }
  }
  bookingStatusChanges(bookingData) {
    switch (bookingData.status) {
      // for Unaasigned and assigned bookings
      case 1: case 2:
        this.unassignedBookingHandler(bookingData);
        this.toClearProvider(bookingData);
        break;

      //move the booking to assigned array  
      case 3:
        if (bookingData.bookingType == 3) {
          this.getAllBookings(0, 0);
        } else if (bookingData.inDispatch == true) {
          this.arrayShifter(this.dispatched, this.assigned, bookingData, 3);
        } else {
          this.arrayShifter(this.unassigned, this.assigned, bookingData, 7);
        }
        this.toClearProvider(bookingData);
        break;

      // status update 
      case 6: case 7: case 8: case 9:
        // checking if the array is existing in the current assigned list or not 
        if (this.assigned.findIndex((item) => item.bookingId == bookingData.bookingId) >= 0) {
          // replacing with new data 
          this.toastrService.info('Booking ID:' + bookingData.bookingId + ',  ' + bookingData.statusMsg, 'Booking Status');
          this.assigned[this.assigned.findIndex((item) => item.bookingId == bookingData.bookingId)] = bookingData;
        }
        break;

      // booking got expired move to completed array
      case 5:
        if (bookingData.inDispatch == true) {
          this.arrayShifter(this.dispatched, this.completed, bookingData, 5);
        }
        else {
          setTimeout(() => {
            this.arrayShifter(this.unassigned, this.completed, bookingData, 4);
            this.toClearProvider(bookingData);
          }, 50);
        }
        break;
      // case 4 means provider declined and case 12 :cancelling booking from provider
      case 4: case 12:
        this.arrayShifter(this.dispatched, this.completed, bookingData, 5);
        this.toClearProvider(bookingData);
        break;
      // Case 11 means cancelling booking from Dispatcher
      case 10: case 11:
        this.arrayShifter(this.assigned, this.completed, bookingData, 6);
        this.toClearProvider(bookingData);
        break;
    }
    if (this.providerJob == bookingData.bookingId) {
      // this.selectedProvider = bookingData.providerData;
      this.selectedProviderTimeline = bookingData.jobStatusLogs;
      this.jobDetailsByBooking = bookingData;
      ////this.ref.detectChanges();
    }
  }

  toClearProvider(bookingData) {
    this.selectedbooking && this.selectedbooking.bookingId == bookingData.bookingId ? this.clearRightSide() : "";
  }

  arrayShifter(fromArray, toArray, bookingData, countType) {
    ////conole.log("the counttype ",bookingData.bookingId+" count"+ countType)


    // checking if the array is existing in the current fromArray list or not 
    if (fromArray.findIndex((item) => item.bookingId == bookingData.bookingId) >= 0) {
      // removing the array from the fromArray list
      fromArray.splice(fromArray.findIndex((item) => item.bookingId == bookingData.bookingId), 1);

      // this.toastrService.info('Booking ID:' + bookingData.bookingId + ',  ' + bookingData.statusMsg, 'Booking Status');
      this.arrayCountManager(countType);

      // checking if its a duplicate booking or not and remove if its duplicate
      let indexinDestiny = toArray.findIndex((item) => item.bookingId == bookingData.bookingId);
      if (indexinDestiny >= 0) {
        toArray.splice(indexinDestiny, 1);
      }

      // shifting the new array to the top of destiny 
      toArray.unshift(bookingData);

    }
    else if (toArray.findIndex((item) => item.bookingId == bookingData.bookingId) < 0) {
      // if the array is not in the toArray then directly add to the destiny
      toArray.unshift(bookingData);
      // countType == 4 ? this.toastrService.error('Booking ID:' + bookingData.bookingId + ',  ' + bookingData.statusMsg, 'Booking Status'):
      // this.toastrService.info('Booking ID:' + bookingData.bookingId + ',  ' + bookingData.statusMsg, 'Booking Status');
      // if(bookingData.status == 5){
      //   this.arrayCountManager(countType);
      // }
      if (countType < 4 && countType > 6) {
        this.arrayCountManager(countType);
      }

    }
    countType == 6 && bookingData.status === 11 ? this.toastrService.error('Booking ID:' + bookingData.bookingId + ',  ' + bookingData.statusMsg, 'Booking Status') :
      this.toastrService.info('Booking ID:' + bookingData.bookingId + ',  ' + bookingData.statusMsg, 'Booking Status');
  }

  //functionName: update provider dynamically, desc: update providerList when the provider going offline or online, developee:sowmya sv, date:28-11-18

  providerChanges(providerData) {
    // ////conole.log("providerChanges", providerData);
    switch (parseInt(providerData.status)) {

      // move provider to offline
      case 4:
        if (this.unavailable.findIndex((item) => item._id == providerData._id) < 0) {
          this.providerArrayShifter(this.available, this.unavailable, providerData);
          this.availableCount = this.available.length;
          this.unavailableCount = this.unavailable.length;
        };
        break;
      // move provider to online
      case 3:
        if (this.available.findIndex((item) => item._id == providerData._id) < 0) {
          this.providerArrayShifter(this.unavailable, this.available, providerData);
          this.availableCount = this.available.length;
          this.unavailableCount = this.unavailable.length;
        };
        break;

      case 7:
        if (this.unavailable.findIndex((item) => item._id == providerData._id) < 0) {
          this.providerArrayShifter(this.available, this.unavailable, providerData);
          this.availableCount = this.available.length;
          this.unavailableCount = this.unavailable.length;
        };
        break;
    }
  }

  providerArrayShifter(fromArray, toArray, data) {
    // checking if the array is existing in the current fromArray list or not 
    if (fromArray.findIndex((item) => item._id == data._id) >= 0) {

      // removing the array from the fromArray list
      fromArray.splice(fromArray.findIndex((item) => item._id == data._id), 1);

      // checking if its a duplicate booking or not and remove if its duplicate
      let indexinDestiny = toArray.findIndex((item) => item._id == data._id);

      if (indexinDestiny >= 0) {
        toArray.splice(indexinDestiny, 1);
      };
      // shifting the new array to the top of destiny 
      // toArray.unshift(data);
      toArray.splice(toArray.length - 1, 1);
      toArray.push(data);
    }
  }

  arrayCountManager(countType) {
    switch (countType) {
      case 1:
        this.dispatchedCount++; this.unassignedCount--;
        break;
      case 2:
        this.unassignedCount++; this.dispatchedCount--;
        break;
      case 3:
        this.assignedCount++; this.dispatchedCount--;
        break;
      case 4:
        this.completedCount++; this.unassignedCount--;
        break;
      case 5:
        this.completedCount++; this.dispatchedCount--;
        break;
      case 6:
        this.completedCount++; this.assignedCount--;
        break;
      case 7:
        this.assignedCount++; this.unassignedCount--;
        break;
      case 8:
        this.unassignedCount++; this.assignedCount--;
        break;
    }
  }

  ngOnInit() {
    this.getMqttMessage();
    this.getAllBookings(0, 0);
    $('.list-group-item').on("click", () => {
      if ($('.panel-collapse').hasClass("in")) {
        $('.panel-collapse').removeClass("in")
      }
    });


    jQuery('#godsView').show();
    $("#back_button").hide();
    let latt = sessionStorage.getItem('latitude');
    let long = sessionStorage.getItem('longitude');
    this.setmyMap(latt, long);
    // this.setmyMap("12.9716", "77.5946");

    // $.get("https://ipapi.co/json/", (response) => {
    //   ////conole.log("------------IP ADDRESS-----------------", response);
    //   this.cookieService.set('ip',response.ip)
    this.onLoadLatitude = this.cookieService.get('latitude');
    this.onLoadLongitude = this.cookieService.get('longitude');
    this.setmyMap(this.onLoadLatitude, this.onLoadLongitude);
    // })

    this.activateDatePicker();
  }

  ngOnDestroy() {
    $("app-dispatcher-home").hide();
  }

  setmyMap(param1, param2) {
    var map = new google.maps.Map(document.getElementById('dispatchermap'), {
      center: new google.maps.LatLng(param1, param2),
      zoom: 10,
      minZoom: 1,
      styles: this._apiServiceManager.getMapStyles(),
      streetViewControl: false,
      controlSize: 20,
    });
    this.homePageMap = map;
  }

  activateDatePicker() {
    this.current_time = new Date();
    this.current_time_var = this.current_time.getFullYear() + '-' + ("0" + (this.current_time.getMonth() + 1)).slice(-2) + '-' + this.current_time.getDate() + " " + (this.current_time.getHours() + 3) + ":" + this.current_time.getMinutes() + ":" + this.current_time.getSeconds();

    flatpickr(".flatpickr3", {
      defaultDate: 1477697199863, // Date objects and date strings are also accepted
      enableTime: true,
      enableSeconds: true,
      minDate: this.current_time_var, // "today" / "2016-12-20" / 1477673788975
      maxDate: "2018-12-20",
    },
    );
    flatpickr(".flatpickr2", {
      defaultDate: new Date(), // Date objects and date strings are also accepted
      enableTime: false,
      // minDate: new Date(), // "today" / "2016-12-20" / 1477673788975
      // maxDate: "2018-12-20",
      altFormat: "M j, Y"
    });

    flatpickr(".flatpickrTo", {
      defaultDate: new Date(), // Date objects and date strings are also accepted
      enableTime: false,
      // minDate: new Date(), // "today" / "2016-12-20" / 1477673788975
      // maxDate: "2018-12-20",
      altFormat: "M j, Y"
    });

  }


  getAllBookings(index, toUpdate) {
    ////conole.log("the index is from get all booking :", index,toUpdate);
    this.bookingMode = 'query';
    let data = {
      pageIndex: index,
    }
    this.stringFilter ? data['searchStr'] = this.stringFilter : data['searchStr'] = '0';
    this.selectedDataFrom && this.selectedDataTo ? data['from'] = this.selectedDataFrom : data['from'] = '0';
    this.selectedDataFrom && this.selectedDataTo ? data['to'] = this.selectedDataTo : data['to'] = '0';
    this._apiServiceManager.getBooking(data).subscribe(result => {
      let response = result.data;
      $("#AnswersList").hide();
      this.getBookingDataManager(toUpdate, response);
      if (this.dispatched.length > 0) {
        this.ForUnassgined = false;
        this.startTimerForDispatchedJob(this.dispatched);
        this.dispatched = this.checkToDecreseShiftCount(this.dispatched);
      }
      if (this.unassigned.length > 0) {
        this.ForUnassgined = true;
        this.startTimerForDispatchedJob(this.unassigned);
        this.unassigned = this.checkToDecreseShiftCount(this.unassigned);
      }
      console.log("this.unassigned", this.unassigned, this.dispatched, this.assigned)
      this.bookingMode = 'determined';
    }, error => {
      (error.status == 498 || error.status == 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    });
  }
  //to decrese shiftcount if today date selected with past time
  checkToDecreseShiftCount(responseArray) {
    let todaysDate = moment(new Date).format('DD-MM-YYYY');
    let currentTime = moment(new Date, 'hh:mm A');
    if (responseArray && responseArray.length > 0) {
      responseArray.map((item, index) => {
        if (!item.hasOwnProperty('countShift')) {
          let compareTime = moment(item.bookingRequestedFor * 1000).format('hh:mm A')
          moment(item.bookingRequestedFor * 1000).format('DD-MM-YYYY') === todaysDate && item.bookingType === 3 ?
            moment(compareTime, 'hh:mm A').isBefore(currentTime) ? (item['countShift'] = true, --item.accounting.totalShiftBooking) : 0 : 0;
        }
      });
    }
    return responseArray;
  }
  getBookingDataManager(toUpdate, response) {
    switch (toUpdate) {
      case 0:
        this.assignForArrays(response);
        break;
      case 1:
        this.pushPaginatedArray(response.unAssign, this.unassigned);
        break;
      case 2:
        this.pushPaginatedArray(response.dispatch, this.dispatched);
        break;
      case 3:
        this.pushPaginatedArray(response.assign, this.assigned);

        break;
      case 4:
        this.pushPaginatedArray(response.complete, this.completed);
        break;
    }
  }

  pushPaginatedArray(responseArray, destinationArray) {
    if (responseArray.length > 0) {
      for (var i = 0; i < responseArray.length; i++) {
        destinationArray.push(responseArray[i]);
      }
    }
  }

  assignForArrays(response) {
    if (response) {
      this.assigned = response.assign;
      // this.assigned = this.checkTodaysBooking(response.assign);
      this.assignedCount = response.assignCount;
      this.unassigned = response.unAssign;
      // this.unassigned = this.checkTodaysBooking(response.unAssign);
      this.unassignedCount = response.unAssignCount;


      this.completed = response.complete;
      // this.completed = this.checkTodaysBooking(response.complete);
      this.completedCount = response.completeCount;

      this.dispatched = response.dispatch;
      // this.dispatched = this.checkTodaysBooking(response.dispatch);
      this.dispatchedCount = response.dispatchCount;

      this.MatTab = [
        { label: 'Unassigned', TabContent: this.unassigned, count: this.unassigned.length },
        { label: 'Indispatch', TabContent: this.dispatched, count: this.dispatched.length },
        { label: 'Assigned', TabContent: this.assigned, count: this.assigned.length },
      ]
    }
    //console.log("unassinged")
  }



  startTimerForDispatchedJob(dispatched) {
    for (var i = 0; i < dispatched.length; i++) {
      if (this.ForUnassgined == false) {
        this.autodispatch_timer(dispatched[i].bookingExpireTime, dispatched[i], 0);
        this.SwitchBillingModel(dispatched[i]);
      }
      else {
        this.ForUnassgined == true;
        this.SwitchBillingModel(dispatched[i]);
        (dispatched[i].unassignedBookingsStatus == true) ? this.autodispatch_timer(dispatched[i].bookingExpireTime, dispatched[i], 2) :
          this.autodispatch_timer(dispatched[i].bookingExpireTime, dispatched[i], 1);
      }
    }
  }
  SwitchBillingModel(Dispatched) {
    if (Dispatched.accounting.serviceType == 1) {
      this.selectedIndex = 0;
      this.cartServiceType = 1;
      this.disableFixed = false;
      this.disableHourly = true;
    }
    else if (Dispatched.accounting.serviceType == 2) {
      this.selectedIndex = 1;
      this.cartServiceType = 2;
      this.disableFixed = true;
      this.disableHourly = false;
    }
  }
  getBookingDetailsPopUp(value) {
    this.jobDetailsByBooking = value;
    this.providerJob = value.bookingId;
    this.AnswerDetails = value.questionAndAnswer;
    this.itemList = value.cartData;
    //console.log("value",value)
    //conole.log("value",value);
    ////conole.log("the bookig details are : ",   this.jobDetailsByBooking,this.QuestionAndAnswerDetails);
    setTimeout(() => {
      $('#jobdetailModal').appendTo("body").modal('show');
      $("#AnswersList").hide();
      // value.unassignedBookingsStatus == false ? $("#AnswersList").show(): $("#AnswersList").hide();
    }, 200)

    //this.ref.detectChanges();
  }

  toggleMapDiv(caseData) {
    switch (caseData) {
      case 1:
        if ($(".mapDiv").hasClass('mapFull')) {
          $(".mapDiv").removeClass('mapFull');
          $('.mapDiv').addClass('mapHalf');
        }
        break;
      case 2:
        if ($(".mapDiv").hasClass('mapHalf')) {
          $(".mapDiv").removeClass('mapHalf');
          $('.mapDiv').addClass('mapFull');
        }
        break;
    }
  }
  BookingType: any;
  ServiceName: any;

  getProviderForBooking(bookingData, bid) {
    ////conole.log("the provider  list:",bookingData );
    this.providerJob = "";
    this.ServiceName = '';
    this.selectedbooking = bookingData;
    this.BookingType = bookingData.bookingType;
    if (bookingData.serviceType == 2) {
      //  this.ServiceName = bookingData.cartData[0].serviceName
      this.ServiceName = bookingData.currencySymbol + " " + bookingData.cartData[0].unitPrice;
    }
    $(".ProviderInfo").hide();
    this.mode = 'query';
    this.toggleMapDiv(1);
    let apiData = {
      bookingId: bid,
    }
    // bookingId , searchStr
    this.providerByBookingId(apiData);
  }

  searchProviderWithBookingId() {
    this.mode = 'query';
    let apiData = {
    }
    $(".ProviderInfo").hide();
    this.selectedbooking && this.selectedbooking.bookingId ? apiData['bookingId'] = this.selectedbooking.bookingId : "";
    this.providerFilterString ? apiData['searchStr'] = this.providerFilterString : "";
    this.selectedbooking && this.selectedbooking.bookingId ? this.providerByBookingId(apiData) : "";
  }

  refreshProviderSearch() {
    this.providerFilterString = "";
    this.providerSearchController.reset();
    this.searchProviderWithBookingId();
  }

  selectedProviders = [];
  providerByBookingId(apiData) {
    this._apiServiceManager.getProviderForBooking(apiData).subscribe(result => {
      let Available = [];
      if (result.data.available) {
        if (result.data.available.inactive) {
          for (let i = 0; i < result.data.available.inactive.length; i++) {
            Available.push(result.data.available.inactive[i]);
          }
        }
        if (result.data.available.online) {
          for (let i = 0; i < result.data.available.online.length; i++) {
            Available.push(result.data.available.online[i]);
          }
        }
        if (result.data.available.offline) {
          for (let i = 0; i < result.data.available.offline.length; i++) {
            Available.push(result.data.available.offline[i]);
          }
        }
        if (result.data.available.schedule) {
          for (let i = 0; i < result.data.available.schedule.length; i++) {
            Available.push(result.data.available.schedule[i]);
          }
        }
      }
      this.available = Available;
      this.unavailable = result.data.unAvailable;
      this.availableCount = this.available.length;
      this.unavailableCount = result.data.unAvailable.length;
      this.available.map((x, index) => {
        ////conole.log("the placeMaekr", this.available.length, this.available);
        this.placeProviderMarker(x, 0, index);
      })
      this.mode = 'determined';
    }, error => {
      //conole.log(error);
      let eMsg = JSON.parse(error._body);
      if (eMsg !== "Your session has been expired as you have logged in some other device.") {
        swal.fire(
          'Sorry!',
          eMsg.message,
          'error'
        );
        $('.providerList').hide();
        this.toggleMapDiv(1);
      }
      (error.status == 498 || error.status == 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    });
  }


  assignedProvider(item) {
    this.clearMap();
    this.toggleMapDiv(2);
    this.providerJob = "";
    this.selectedbooking = item;
    this.placeMarker(item);

    //conole.log("provider assigned", item);

    if (item.providerData.name) {

      this.getProviderBookings(item.providerData._id);
      $('.ProviderInfo').show(500);
      $('.providerList').hide();
      this.toggleMapDiv(1);
      this.selectedProvider = item.providerData;
      this.selectedProviderTimeline = item.jobStatusLogs;
      this.setdefaultImage();
    } else {
      this.closeInfoWindow();
    }

    //this.ref.detectChanges();
  }

  viewDetails(item) {
    this.getProviderBookings(item._id);
    this.selectedProvider = item;
    ////conole.log("the _id selected PPRovider :", this.selectedProvider)
    setTimeout(() => {
      $('.ProviderInfo').show(500);
    }, 50)
    this.setdefaultImage();
  }

  dispatchJobHandler(item) {
    this.finalizedProvider = item;
    // this.ngxSmartModalService.open('jobAcceptPopup');
    $("#jobAcceptPopup").fadeIn();
  }

  closeInfoWindow() {
    this.providerJob = "";
    this.selectedProviderTimeline = null;
    this.clearMap();
    this.setmyMap(this.onLoadLatitude, this.onLoadLongitude);
    $('.ProviderInfo').hide(500);
    setTimeout(() => {
      this.selectedProvider = "";
    }, 600);
    this.toggleMapDiv(2);
    $('.providerList').show(500);

  }

  getProviderBookings(value) {
    this._apiServiceManager.getProBookings(value).subscribe(result => {
      this.selectedProviderBooking = result.data;
      this.selectedProviderBooking = this.sortProviderBookignBasedOnDates(this.selectedProviderBooking);
      ////conole.log("the provider time jobs:",result.data[0].jobStatusLogs, result )
    }, error => {
      (error.status == 498 || error.status == 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    });
  }
  //card#90 ,bug : The bookings are not sorted according to date /There is no sorting order, fixedBy:sowmya
  sortProviderBookignBasedOnDates(provideBookingList) {
    let sortedList = provideBookingList.sort(function (a, b) {
      return moment.utc(a.bookingRequestedFor).diff(moment.utc(b.bookingRequestedFor))
    })
    // let sortedList = provideBookingList.sort(function (a, b) {
    //   return moment(a.bookingRequestedFor, 'DD-MM-YYYY')   moment(b.bookingRequestedFor, 'DD-MM-YYYY');
    // });
    // let sortedList = provideBookingList.sort((a,b) => (moment(a.bookingRequestedFor, 'DD-MM-YYYY') > moment(b.bookingRequestedFor, 'DD-MM-YYYY')) ? 1 : ((moment(b.bookingRequestedFor, 'DD-MM-YYYY') > moment(a.bookingRequestedFor, 'DD-MM-YYYY')) ? -1 : this.getTimingComapre(a,b) )); 
    //  let sortedList = provideBookingList.sort((a,b) => (moment(a.bookingRequestedFor, 'DD-MM-YYYY') > moment(b.bookingRequestedFor, 'DD-MM-YYYY')) ? 1 : ((moment(b.bookingRequestedFor, 'DD-MM-YYYY') > moment(a.bookingRequestedFor, 'DD-MM-YYYY')) ? -1 : this.getTimingComapre(a,b) )); 
    return sortedList;
  }

  openUnassignPopUp(item) {
    this.bookingForUnassign = item;
    ////conole.log("bookingForUnassign", item)
    $("#unassignPopUp").fadeIn();
  }

  unassignBooking(bid) {
    this.unAssignMode = 'buffer'
    let data = {
      bookingId: bid
    }
    this._apiServiceManager.unassignBooking(data).subscribe(result => {
      //conole.log("unassignBooking", result);
      this.unAssignMode = 'determined';
      this.closePopup('unassignPopUp');
    }, error => {
      this.unAssignMode = 'determined';
      this.closePopup('unassignPopUp');
      swal.fire(
        'Failed',
        'Sorry !! , Booking:' + bid + ' unassign is unsuccessful',
        'error'
      );
      (error.status == 498) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    });
  }

  dispatchJob(type) {
    this.dispatchMode = 'buffer';
    let dispatchData = {
      bookingId: this.selectedbooking.bookingId,
      sendBookingType: type,
      providerId: this.finalizedProvider._id
    }
    this._apiServiceManager.dispatchBooking(dispatchData).subscribe(result => {
      this.clearRightSide();
      this.dispatchMode = 'determined';
      this.closePopup('jobAcceptPopup');
      swal.fire(
        'Success',
        'Booking:' + this.selectedbooking.bookingId + ' dispatched',
        'success'
      )
      ////conole.log("******** dispatchBooking *********", result);
    }, error => {
      this.dispatchMode = 'determined';
      this.closePopup('jobAcceptPopup');
      swal.fire(
        'Failed',
        'Sorry:' + this.selectedbooking.bookingId + ' dispatch failed',
        'error'
      );
      (error.status == 498 || error.status === 440) ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    });


  }
  closePopup(divId) {
    $("#" + divId).fadeOut();
  }

  setdefaultImage() {
    setTimeout(function () {
      jQuery('img').each(function () {
        jQuery(this).attr('src', jQuery(this).attr('src'));
      });
      jQuery("img").error(function () {
        jQuery(this).attr('src', "assets/images/default.png");
      });
      jQuery("#requesting_loader").hide();
    }, 100)
  }

  autodispatch_timer(expirytime, bid, unassgined) {
    let duration: any;
    this.ExpiryTime = expirytime;
    let eventTime = expirytime;
    let diffTime;
    let currentTime = moment().unix();
    diffTime = eventTime - currentTime;           // Find the distance between now an the count down date
    if (diffTime > 0) {
      duration = moment.duration(diffTime * 1000, 'milliseconds');
      var durationTime = duration;
      var time = moment.duration(duration).asMinutes();
      var HalfDuration = (duration / 2);
      var halfTime = moment.duration(duration / 2).asSeconds();
      ////conole.log("the duration is : ", moment.duration(durationTime).asMinutes());
      let interval = 1000;
      let x = setInterval(() => {
        duration = moment.duration(duration - interval, 'milliseconds');
        var time = moment.duration(duration).asSeconds();
        this.duration = duration;
        if (duration <= 0) {
          clearInterval(x);
          $("#CountDownRapper-" + bid.bookingId).hide();
          if (this.countDownArray.findIndex((item) => item.bookingId === bid.bookingId) < 0) {
            this.countDownArray.push({ bookingId: bid.bookingId, duration: 0 });
          }
        }
        else {
          // Timer For Dispatched cloumn when new job is created
          // if( bid.unassignedBookingsStatus == false  && unassgined == 0)
          if (this.countDownArray.findIndex((item) => item.bookingId === bid.bookingId) < 0) {
            this.countDownArray.push({ bookingId: bid.bookingId, duration: moment.duration(duration).asSeconds() });
          }
          else {
            let index = this.countDownArray.findIndex((item) => item.bookingId === bid.bookingId);
            this.countDownArray[index].duration = moment.duration(duration).asSeconds();
          }

          if (unassgined == 0 && bid.unassignedBookingsStatus == false) {
            $("#CountDownRapper-" + bid.bookingId).show();
            $("#CountDownUnassigned-" + bid.bookingId).show();
          }
          // Timer for shifting of dispatched job into unassigned job
          if (unassgined == 4 && bid.unassignedBookingsStatus == false) {
            $("#CountDownUnassigned-" + bid.bookingId).show();
          }
          // Timer for job created manually 
          if (unassgined == 2 && bid.unassignedBookingsStatus == true) {
            $("#CountDownUnassigned-" + bid.bookingId).hide();
          }
          //Timer for unassnged column timer will show after refresh the page if timer is running
          if (unassgined == 1 && bid.unassignedBookingsStatus == false)
            $("#CountDownUnassigned-" + bid.bookingId).show();
        }

      }, interval);
    }
    else {
      setTimeout(() => {
        $("#CountDownRapper-" + bid.bookingId).hide();
      }, 50)
    }
  }

  clearMap() {
    for (var i = 0; i < this.selectedmarker.length; i++) {
      this.selectedmarker[i].setMap(null);
    }
    this.selectedmarker = [];

    for (var i = 0; i < this.selectedmarkerStore.length; i++) {
      this.selectedmarkerStore[i].setMap(null);
    }
    this.selectedmarkerStore = [];

    for (var i = 0; i < this.availableMarker.length; i++) {
      this.availableMarker[i].setMap(null);
    }
    this.availableMarker = [];

    for (var i = 0; i < this.placeStoreMarker.length; i++) {
      this.placeStoreMarker[i].setMap(null);
    }
    this.placeStoreMarker = [];

    for (var i = 0; i < this.assignedDriverMarker.length; i++) {
      this.assignedDriverMarker[i].setMap(null);
    }
    this.assignedDriverMarker = [];

    // this.setmyMap(this.onLoadLatitude, this.onLoadLongitude);
  }
  clearRightSide() {
    this.clearMap();
    this.closeInfoWindow();
    this.toggleMapDiv(2);
  }
  activeTabIndex: any = -1;
  bookingMode1 = "indeterminate";
  clearRightSide1(tabChangeEvent: MatTabChangeEvent) {
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, 1000);
    ////conole.log("the tab index is :", tabChangeEvent)
    this.clearMap();
    this.closeInfoWindow();
    this.toggleMapDiv(2);
  }

  placeMarker(newOrderList) {
    ////conole.log("location is:",newOrderList.latitude,newOrderList.longitude )
    this.clearMap();
    var map = this.homePageMap.setOptions({
      center: { lat: newOrderList.latitude, lng: newOrderList.longitude },
      styles: this._apiServiceManager.getMapStyles(),
      streetViewControl: false,
      minZoom: 5,
      maxZoom: 17
    });

    var marker = new google.maps.Marker({
      position: { lat: newOrderList.latitude, lng: newOrderList.longitude },
      map: this.homePageMap,
      animation: google.maps.Animation.DROP,
    });
    // this.selectedProviders.push(marker);
    var icon = {
      url: "https://www.shareicon.net/data/512x512/2016/05/20/768156_gps_512x512.png", // url
      scaledSize: new google.maps.Size(40, 40), // scaled size
    };


    // customer info window 
    google.maps.event.addListener(marker, "click", function (e) {
      var infoWindow = new google.maps.InfoWindow({
        content: '<b>Customer Name: </b>  ' + newOrderList.customerData.firstName + '' + newOrderList.customerData.lastName + '<br /><b>Customer Phone:  </b>  ' + newOrderList.customerData.phone
      });
      infoWindow.open(map, marker);
    });

    var bounds = new google.maps.LatLngBounds();

    var Storeloc = new google.maps.LatLng(newOrderList.latitude, newOrderList.longitude);
    bounds.extend(Storeloc);
    let onlineMrker = 'assets/images/pin.png';
    let inactiveMrker = 'assets/images/inact40.png';
    let offlineMrker = 'http://iserve.ind.in/superadmin/theme/icon/red.png';
    let scheduledMrker = 'http://iserve.ind.in/superadmin/theme/icon/yellow.png';
    let splitLat = newOrderList.providerData.latitude;
    let splitLng = newOrderList.providerData.longitude;
    if ((newOrderList.status == 1) || (newOrderList.status == 2)) {
      // this.available.online ? this.placeProviders(this.available.online, onlineMrker, bounds) : "";
      // this.available.offline ? this.placeProviders(this.available.offline, onlineMrker, bounds) : "";
      // this.available.inactive ? this.placeProviders(this.available.inactive, onlineMrker, bounds) : "";
      // this.available.schedule ? this.placeProviders(this.available.schedule, onlineMrker, bounds) : "";
      // this.unavailable ? this.placeProviders(this.unavailable , bounds) : "";
    }


    else if (newOrderList.status >= 2 && newOrderList.status != 4 && newOrderList.status != 5 && newOrderList.status <= 11 && newOrderList.providerData && splitLat !== 0 && splitLng !== 0) {

      var rad = function (x) {
        return x * Math.PI / 180;
      };

      var getDistance = function (p1, p2) {
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat() - p1.lat());
        var dLong = rad(p2.lng() - p1.lng());
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
          Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d; // returns the distance in meter
      };

      // var latlng = new google.maps.LatLng(m.latitude, m.longitude);
      var initialLatLong = new google.maps.LatLng(newOrderList.providerData.latitude, newOrderList.providerData.longitude)
      var lastPosn = new google.maps.LatLng(newOrderList.latitude, newOrderList.longitude);
      var directionsDisplay;
      let locationDistance = getDistance(initialLatLong, lastPosn);
      ////conole.log("get distance calculation", getDistance(initialLatLong, lastPosn));

      if (locationDistance > 100) {
        var directionsService = new google.maps.DirectionsService;
        directionsDisplay = new google.maps.DirectionsRenderer({ suppressMarkers: true });

        directionsDisplay.setMap(this.homePageMap);
        directionsDisplay.setDirections({ routes: [] });

        // direction service 
        directionsService.route({
          origin: initialLatLong,
          destination: lastPosn,
          travelMode: 'DRIVING'
        }, (response, status) => {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
          } else {

          }
        });
      }

      var tracIcon = {
        url: onlineMrker,  // url
        scaledSize: new google.maps.Size(40, 40), // scaled size
      };

      var tracIconMarker = new google.maps.Marker({
        position: new google.maps.LatLng(splitLat, splitLng),
        map: this.homePageMap,
        icon: tracIcon,
        animation: google.maps.Animation.DROP,
        id: newOrderList.providerData._id
      });

      this.assignedDriverMarker.push(tracIconMarker);
      // customer info window 
      google.maps.event.addListener(tracIconMarker, "click", function (e) {
        var infoWindow = new google.maps.InfoWindow({
          content: '<b>Name:</b>  ' + newOrderList.providerData.name + '<br /><b>Phone:  </b>  ' + newOrderList.providerData.phone
        });
        infoWindow.open(map, tracIconMarker);
      });
      var driverTrack = new google.maps.LatLng(splitLat, splitLng);
      bounds.extend(driverTrack);

    }
    this.homePageMap.fitBounds(bounds);
    this.homePageMap.panToBounds(bounds);
    // this.selectedmarkerStore.push(markerStore);
    this.selectedmarker.push(marker);
    //this.ref.detectChanges();
  }

  toggleBounce = () => {
    if (this.availableMarker.getAnimation() !== null) {
      this.availableMarker.setAnimation(null);
    } else {
      this.availableMarker.setAnimation(google.maps.Animation.BOUNCE);
    }
  }
  placeProviders(arrayToMark, mrker, bounds) {
    for (var i = 0; i < arrayToMark.length; i++) {
      var micon = {
        url: mrker,
        scaledSize: new google.maps.Size(40, 40), // scaled size
      };

      this.availableMarker.push(new google.maps.Marker({
        position: { lat: arrayToMark[i].latitude, lng: arrayToMark[i].longitude },
        map: this.homePageMap,
        icon: micon,
        animation: google.maps.Animation.DROP,
        id: arrayToMark[i]._id
      }));

      var name = "";
      var phone = '';
      name = arrayToMark[i].name;
      phone = arrayToMark[i].phone;

      var infowindow = new google.maps.InfoWindow();
      var value3 = this.availableMarker;
      var contentString;
      contentString = '<b>Provider Name: </b>' + name + '<br /><b>Provider Phone:</b>  ' + phone;

      google.maps.event.addListener(this.availableMarker[i], 'click', ((value3, contentString, infowindow) => {
        return () => {
          infowindow.setContent(contentString);
          infowindow.open(this.homePageMap, value3);
        };
      })(this.availableMarker[i], contentString, infowindow));

      var avlloc = new google.maps.LatLng(arrayToMark[i].latitude, arrayToMark[i].longitude);
      bounds.extend(avlloc);
      //this.ref.detectChanges();
    }
  }

  observeDateFilter() {
    this.selectedDataFrom = null;
    this.selectedDataTo = null;
    if (this.fromDateFilter && this.toDateFilter) {
      setTimeout(() => {
        this.selectedDataFrom = moment(this.fromDateFilter).format('YYYY-MM-DD');
        this.selectedDataTo = moment(this.toDateFilter).format('YYYY-MM-DD');
        ////conole.log("selcted", this.selectedDataFrom, this.selectedDataTo);
        this.getAllBookings(0, 0);
      }, 10);
    }
  }
  resetAll() {
    this.indexFour = 0;
    this.indexThree = 0
    this.indexTwo = 0
    this.indexOne = 0
    this.fromdate.reset();
    this.todate.reset();
    this.searchController.reset();
    this.getAllBookings(0, 0);
  }

  dontAllowSpace(e) {
    if (e.keyCode == 32) { return false; }
    //this.ref.detectChanges();
  }
  dontAllowAlphabets(e) {
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
    //this.ref.detectChanges();
  }
  onScroll(e, status) {
    ////conole.log("status", status);
    switch (status) {
      //pagination for Assigned Booking list , it will add the next list to the existing array
      case 1:
        if ($('#unassigned').scrollTop() + $('#unassigned').innerHeight() >= $('#unassigned')[0].scrollHeight) {
          this.indexOne++;
          this.getAllBookings(this.indexOne, 1);
        }
        break;
      case 2:
        if ($('#indispatch').scrollTop() + $('#indispatch').innerHeight() >= $('#indispatch')[0].scrollHeight) {
          this.indexTwo++;
          this.getAllBookings(this.indexTwo, 2);
        }
        break;
      case 3:
        if ($('#assigned').scrollTop() + $('#assigned').innerHeight() >= $('#assigned')[0].scrollHeight) {
          this.indexThree++;
          this.getAllBookings(this.indexThree, 3);
        }
        break;
      case 4:
        if ($('#completed').scrollTop() + $('#completed').innerHeight() >= $('#completed')[0].scrollHeight) {
          this.indexFour++;
          this.getAllBookings(this.indexFour, 4);
        }
        break;
    }
  }
  logOut() {
    this._apiServiceManager.logOut().subscribe(result => {
      this.redirect();
    }, error => {
      this.redirect();
    });
  }

  redirect() {
    this.cookieService.delete('loginUser')
    this.cookieService.delete('user-token')
    this._mqttManager._client.disconnect();
    this._router.navigate(['login']);
  }
  editingCards(item) {
    ////conole.log("the item is :", item);
    this.bookingForUnassign = item;
    this.SwitchBillingModel(item)
    $("#EditCardsPopup").fadeIn();

  }
  editCardPopup(Bid, item) {
    this.hourlycount = item.cartData[0].quntity;
    ////conole.log("count fro hour is : ",this.hourlycount);
    this.bookingForUnassign = item;
    //conole.log("this.bookingForUnassign",this.bookingForUnassign);
    (this.bookingForUnassign.accounting.serviceType == 1) ? (this.TotalAmount = this.bookingForUnassign.totalAmount.toFixed(2), this.checkOutList = this.bookingForUnassign.cartData, this.getServiceList()) : (this.TotalAmount = this.bookingForUnassign.cartData[0].amount.toFixed(2));
    $("#CardsPopup").fadeIn();
    $("#EditCardsPopup").fadeOut();
    // this.disableHourly=false;
    // this.disableFixed=false;

  }
  myCart(serviceItem, actionValue, bookingmodal) {
    let data;
    ////conole.log("the service is added :", serviceItem)
    data = {
      bookingId: this.bookingForUnassign.bookingId,
      quntity: 1,
      action: actionValue,
    }

    bookingmodal == 1 ? data['serviceId'] = serviceItem._id : data['serviceId'] = "1"
    ////conole.log("the dat afrom my?Cart :", data);
    this._apiServiceManager.EditCart(data).subscribe(result => {
      ////conole.log("Result----> Cart Added", result)
      this.myCartList = result.data;
      let CartArray = result.data.item
      this.checkOutList = result.data.item;
      this.TotalAmount = this.myCartList.totalAmount;
      if (bookingmodal == 1) {
        this.hourlycount = 1;
        let cartIndex = CartArray.findIndex((item) => item.serviceId == serviceItem.serviceId);
        let indx = this.serviceList.findIndex((item) => item.serviceId == serviceItem.serviceId);
        if (cartIndex > -1) {
          this.serviceList[indx].status = 1;
          this.serviceList[indx].quntity = CartArray[cartIndex].quntity;
          (CartArray[cartIndex].amount > 0) == true ? (this.serviceList[indx].amount = CartArray[cartIndex].amount) : (this.serviceList[indx].amount = CartArray[cartIndex].unitPrice);
          this.serviceList[indx].status = CartArray[cartIndex].status;
        }
        else {
          this.serviceList[indx].quntity = this.serviceList[indx].quntity;
          this.serviceList[indx].amount = this.serviceList[indx].amount;
          this.serviceList[indx].status = this.serviceList[indx].status;

          ////conole.log("After", this.serviceList[indx]);
        }
        // this.getOldCart();
      } else {

        this.hourlycount = result.data.item[0].quntity;

      }
      //this.ref.detectChanges();
    }, error => {
      ////conole.log("Error from rquest in Checkout ", error)
      let errorMessage = JSON.parse(error._body);
      error.status == 498 || error.status === 440 ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    });
  }
  SaveCart() {
    this.closePopup('CardsPopup');
    this.getAllBookings(0, 0);
  }
  getServiceList() {
    let subCat = 0;
    let providerId = 0;
    this._apiServiceManager.getService(this.bookingForUnassign.categoryId, subCat, providerId).subscribe(result => {
      // this.serviceList = result.data;
      this.serviceList = this.bookingForUnassign.cartData;
      ////conole.log("get Service List", result);
      for (let i = 0; i < this.bookingForUnassign.cartData.length; i++) {
        let cartIndex = this.serviceList.findIndex((item) => item.serviceId == this.bookingForUnassign.cartData[i].serviceId);
        if (!(cartIndex < 0)) {
          this.serviceList[cartIndex].quntity = this.bookingForUnassign.cartData[i].quntity;
          this.serviceList[cartIndex].amount = this.bookingForUnassign.cartData[i].amount;
          this.serviceList[cartIndex].status = 1;
        }
      }
      //this.ref.detectChanges();
    }, error => {
      ////conole.log("Error from rquest in Checkout ", error)
      let errorMessage = JSON.parse(error._body);
      error.status == 498 || error.status === 440 ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    })
    //this.ref.detectChanges();
  }
  serviceSearch(searchString) {
    let oldservice = this.serviceList;
    let subCat = 0;
    let providerId = this.selectedProvider._id || 0;
    let serviceSearchData = {
      searchStr: searchString,
      catId: this.bookingForUnassign.categoryId,
      subCatId: subCat,
      providerId: providerId
    }
    if (searchString.length > 0) {
      this._apiServiceManager.addJobServiceSearch(serviceSearchData).subscribe(result => {
        this.serviceList = result.data;
        ////conole.log("get Service List", result);
        //this.ref.detectChanges();
      }, error => {
        ////conole.log("Error from rquest in Checkout ", error)
        let errorMessage = JSON.parse(error._body);
        error.status == 498 || error.status === 440 ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
      })
      //this.ref.detectChanges();
    } else {
      this.getServiceList();
      //this.ref.detectChanges();
    }
  }
  cancelBooking(item, value) {
    swal.fire({
      title: 'Are you sure to cancel this booking?',
      // text: "You want to cancel this booking!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: "No, Don't cancel!",
      // confirmButtonClass: 'btn btn-success',
      // cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        let data = {
          bookingId: item.bookingId,
        }
        this._apiServiceManager.CancelBooking(data).subscribe(result => {
          ////conole.log("the response for cancel booking:", result);
          swal.fire({
            title: 'Cancelled!',
            text: "Your booking has been Cancelled.",
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ok',
            // confirmButtonClass: 'btn btn-success',
          }).then((result) => {
            this.ShiftCancelBooking(item, value);
          });
        })

      }

    }, error => {
      ////conole.log("Error from rquest in Checkout ", error)
      let errorMessage = JSON.parse(error._body);
      error.status == 498 || error.status == 440 ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    })
  }
  ShiftCancelBooking(Item, value) {
    ////conole.log("the value is for cancel booking:", value);
    switch (value) {
      case 1: let index1 = (this.unassigned.findIndex((item) => item.bookingId === Item.bookingId));
        if (index1 > -1) {
          this.unassigned.splice(index1, 1);
          this.unassignedCount = this.unassigned.length;
        }
        // else{
        //   let index1 = (this.unassigned.findIndex((item) => item.bookingId === Item.bookingId ));
        //   this.unassigned.splice(index1,1);
        //   this.unassignedCount = this.unassigned.length;
        // }
        break;

      case 2:
        let index2 = (this.dispatched.findIndex((item) => item.bookingId === Item.bookingId));
        if (index2 > -1) {
          this.dispatched.splice(index1, 1);
          this.dispatchedCount = this.dispatched.length;
        }
        break;

      case 3: let index3 = (this.assigned.findIndex((item) => item.bookingId === Item.bookingId));
        if (index3 > -1) {
          this.assigned.splice(index1, 1);
          this.assignedCount = this.assigned.length;
        }
        break;

    }
  }
  UndispatchBooking(BookingData) {
    let data = {
      bookingId: BookingData.bookingId,
    }
    this._apiServiceManager.DoUnDispatcheBooking(data).subscribe(result => {
      ////conole.log("get Service List for undispatche", result);
      //this.ref.detectChanges();
    }, error => {
      ////conole.log("Error from rquest in Checkout ", error)
      let errorMessage = JSON.parse(error._body);
      error.status == 498 || error.status === 440 ? $("#appt_loader_loggedOut").show() : $("#appt_loader_loggedOut").hide();
    })
    //this.ref.detectChanges();

  }
  shiftShow = 0;
  shiftPopup() {
    this.shiftShow = 1;
    ////conole.log("the edit option is :");
  }
  editShiftPricePopup() {
    ////conole.log("the edit option is :");
    // $("#editshiftpopup").fadeIn();
    this.shiftShow = 2;

  }
  //change of providerList like available or unavailable

  selectedIndexChange(event) {
    this.selectedProviders.forEach((x, index) => {
      this.selectedProviders[index].setMap(null);
    });
    this.selectedProviders = [];
    ////conole.log("the providert list changes 4 :",this.selectedProviders,this.selectedProviders.length);
    if (event === 0) {
      ////conole.log("the available code :", this.available)
      this.available.map((x, index) => {
        this.placeProviderMarker(x, event, index);
      });
    }
    else {
      ////conole.log("the available code :", this.unavailable)
      this.unavailable.map((x, index) => {
        this.placeProviderMarker(x, event, index);
      });
    }
  }
  //show available and unavailable providers on the map wnen unassigned booking is about to assign to some providers
  placeProviderMarker(newOrderList, selectedEvent, index) {
    var markerIcon = "";
    selectedEvent && selectedEvent === 0 ? markerIcon = "assets/images/greenMarker.svg" :
      markerIcon = "assets/images/redMarker.png";
    var icon = {
      url: markerIcon, // url
      scaledSize: new google.maps.Size(40, 40), // scaled size
    };
    var marker = new google.maps.Marker({
      position: { lat: newOrderList.latitude, lng: newOrderList.longitude },
      map: this.homePageMap,
      animation: google.maps.Animation.DROP,
      icon: icon,

    });
    this.selectedProviders.push(marker);
    this.selectedProviders[index].setMap(this.homePageMap);

    // customer info window 
    google.maps.event.addListener(marker, "click", function (e) {
      var infoWindow = new google.maps.InfoWindow({
        content: '<b>Provide Name: </b>  ' + newOrderList.name + '<br /><b>Provider Phone:  </b>  ' + newOrderList.phone + '<br /><b>Provider Email:  </b>  ' + newOrderList.email
      });
      infoWindow.open(this.homePageMap, marker);
    });


    var Storeloc = new google.maps.LatLng(newOrderList.latitude, newOrderList.longitude);
    this.bounds.extend(Storeloc);
    this.homePageMap.setCenter(this.bounds.getCenter());
    this.homePageMap.fitBounds(this.bounds);
    this.homePageMap.panToBounds(this.bounds);
  }
  //card:89 , Multiple shift have issue in price in dashboard page 
  getTimerInFormat(time, caseValue) {
    switch (caseValue) {
      case 1:
        return moment(time).format('DD-MM-YYYY');
        break;
      case 2:
        return moment(time).format('hh:mm A');
        break;

    }
  }

}