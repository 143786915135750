<div id="internet_checker" *ngIf="(online$ | async)==false">
  <div class="preload">
    <div class="preload-status">
      <div class="preload-status-bar">
        <img src="../../assets/images/internet-loader.gif">
      </div>
      <div class="preload-status-info">
        <p> Checking internet </p>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="whole-container">
    <div class="row">
      <div class="col-xs-9 zeroPadding" style="height: 100vh;">
        <!-- <img src="assets/images/banner.png" width="100%" height="100%"> -->
        <div class="background_image_whole" style="background: url('assets/images/homeBanner.png'); background-repeat: no-repeat;
    background-size: cover;"></div>
      </div>
      <div class="col-xs-3 zeroPadding">
        <div id="right-bar">

          <div class="right-bar-dummy-top">
          </div>

          <div class="logo-img">
            <img alt="Login Logo" src="assets/images/logo_02.png" alt="assets/images/logo_01.png">
          </div>

          <div class="loginInputArea">
            <span id="lblMessage" class="loginFail">&nbsp;</span>

            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-user"></i></span>
              <!-- issue: Placeholder Should be as Email, fixedBy:sowmyaSV, date: 26-12-19 -->
              <input type="text" (focus)="clearAll()" class="form-control" name="userName" [(ngModel)]="userEmail"
                placeholder="User Email">
            </div>
            <div *ngIf="mailResponse_err">
              <span class="error">{{mailResponse_err}}</span>
            </div>

            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-unlock-alt" aria-hidden="true"></i></span>
              <input class="form-control" (focus)="clearAll()" type="password" name="password" [(ngModel)]="password"
                placeholder="Password">

            </div>
            <div *ngIf="password_err">
              <span class="error">{{password_err}}</span>
            </div>

            <!-- <div class="forgot_Password">
              <span id="" class="" data-toggle="modal" data-target="#myModal">Forgot Password </span >
              <span class="checkbox">
                <label><input type="checkbox" value="">Remember me</label>
              </span>
            </div> -->

            <div class="btnLogin-wrapper">
              <button (click)="dispatcherLogin()" name="ImageButton1" id="ImageButton1" class="btnLogin"
                [disabled]="loginDisabled">
                Login
              </button>
              <div class="col-12 loading" *ngIf="loaderDisabled">
                <mat-progress-spinner class="example-margin" [color]="primary" [diameter]="30" [mode]="'indeterminate'">
                </mat-progress-spinner>
              </div>
            </div>
          </div>
          <div *ngIf="login_err">
            <span class="error">{{login_err.message}}</span>
          </div>


          <div class="right-bar-dummy-bottom">
          </div>


          <div class="bottomlinks text-center">
            <div class="bottomlinks__line bottomlinks__line--one text-center">
              <div class="col-12 adminLinks">
                <p>
                  <a target="_blank"
                    href="https://admin.shegamuyadev.com/index.php?/utilities/getPrivacyPolicyData/Customer/en">Privacy
                    Policy</a>
                </p>
                <p>
                  <a target="_blank"
                    href="https://admin.shegamuyadev.com/index.php?/utilities/getTermsData/Customer/en">Terms
                    of
                    Use</a>
                </p>
              </div>
            </div>
            <div class="bottomlinks__line bottomlinks__line--two">
              Copyright Shegamuya @{{currentYear}}, All Rights Reserved. Designed By Shegamuya.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>