<div id="internet_checker" *ngIf="(online$ | async)==false">
    <div class="preload">
        <div class="preload-status">
            <div class="preload-status-bar">
                <img src="assets/images/internet-loader.gif">
            </div>
            <div class="preload-status-info">
                <p> Checking internet </p>
            </div>
        </div>
    </div>
</div>
<div id="appt_loader_loggedOut" class="backdrop" style="display:none">
    <div id="loggedOut" class="box-small height-200">
        <img src="../../assets/images/internet-loader.gif" id="drivers-busy_icn">
        <p>Your session has been expired , please login again</p>
        <button id="busy_drivers_ok" (click)="emmualLogout()">OK</button>
    </div>
</div>
<nav>
    <div class="navbar-header-left">
        <div class="div_display">
            <button class="btn btn-default  text-center godView" type="button" id="godsView" style="margin-bottom:3px;"
                [routerLink]="['godsView']">GodsView </button>
        </div>
        <div class="div_display">
            <button class="btn btn-default  text-center godView" style="margin-bottom:3px;" id="back_button"
                [routerLink]="['dispatcherhome']">Back to Dashboard</button>
            <button id="trigger_noservice" data-toggle="modal" data-target="#error_popup"
                style="display:none">trigger_error</button>
            <span class="dispatcherName">{{DispatcherName}} Dispatcher</span>
        </div>
    </div>

    <div class="navbar-header-middle div_display">
        <img src="assets/images/logo_03.png" height="40px" [routerLink]="['dispatcherhome']">
    </div>

    <div class="navbar-header-right">
        <ul class="list-inline glphIconsCustom">
            <li>
                <a href="" data-toggle="modal" class="" (click)="myModal()">
                    <img data-toggle="tooltip" title="Add New Job" src="assets/svgImages/tab.svg" width="20"
                        height="20" />
                </a>
            </li>
            <li>
                <a href="" data-toggle="dropdown">
                    <img class="svgIcons" src="assets/svgImages/list1.svg" title="Bookings Menu" width="20"
                        height="20" />
                </a>
                <ul class="dropdown-menu right_margin" role="menu" data-dropdown-in="flipInX"
                    data-dropdown-out="flipOutX">
                    <li>
                        <a class="SubNav" [routerLink]="['tasks']">Tasks
                            <i class="fas fa-heart drop_icons"></i>
                            <!-- <span class="glyphicon glyphicon-heart  drop_icons"></span> -->
                        </a>
                    </li>
                </ul>
            </li>
            <li id="fullScreen" title="Full Screen" class="posRel">
                <a>
                    <img class="" *ngIf="FullScreenToggle" width="18" height="18" src="assets/svgImages/exitscreen.svg"
                        title="Exit Full Screen" (click)="fullScreen(1)" />
                    <img class="" *ngIf="!FullScreenToggle" src="assets/svgImages/fullscreen.svg" title="Full Screen"
                        width="18" height="18" (click)="fullScreen(2)" />
                </a>
            </li>
            <li style="position:relative" title="Notifications">
                <a href="" data-toggle="dropdown">
                    <img class="svgIcons" src="assets/svgImages/alarm.svg" title="Notifications" width="20"
                        height="20" />
                    <span id="notification_ripple"></span>
                </a>
                <ul class="notification dropdown-menu right_margin right_margin1" data-dropdown-in="flipInX"
                    data-dropdown-out="flipOutX" style="height:unset;padding: 10px 12px;">
                    <li class="SubNav">Notifications</li>
                    <li *ngFor="let msg of NotificationMsg;">
                        <span><b>{{msg.serialNo}})</b> {{msg.name}} {{msg.message}}</span>
                    </li>
                </ul>
                <!-- <div id="snackbar">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                    <div class="snackbar_body">
                        <span>Boooking ID: </span>
                        <br>
                    </div>
                </div>
                <div id="notification_body" style="display:none;overflow: hidden">
                    <div class="notification_box">
                        <h5>Notifications</h5>
                    </div>
                </div> -->
            </li>
            <li style="position:relative">
                <a href="" data-toggle="dropdown">
                    <img class="svgIcons" src="assets/svgImages/user3.svg" title="Logout User" width="20" height="20" />
                </a>
                <ul class="dropdown-menu right_margin">
                    <li>
                        <a class="SubNav" (click)="emmualLogout()">Logout
                            <i class="fas fa-sign-out-alt drop_icons"></i>
                            <!-- <span class="glyphicon glyphicon-log-out  drop_icons"></span> -->
                        </a>
                    </li>
                </ul>
            </li>

        </ul>
    </div>
</nav>

<!--add task popup-->
<ngx-smart-modal #AddNewJobModal identifier="AddNewJobModal" [closable]="false" [dismissable]="false"
    [escapable]="false" customClass="large-modal nsm-centered">

    <div id="appt_loader_parent" style="display:none">
        <div id="appt_loader"></div>
    </div>
    <div class="closeModal">
        <button mat-button mat-mini-fab class="CloseButton" title="Close" data-dismiss="#myModal"
            (click)="closeMyModal()">
            <i class="material-icons">&#xE5CD;</i>
        </button>
    </div>





    <div class="">
        <div class="row addJobBody">
            <div class="col-md-12 ">
                <!-- <form [formGroup]="firstFormGroup" autocomplete="new-password"> -->
                <div class="col-md-3 padding-0">
                    <div class="col-md-12  pad5 focus" id="focus1" (click)="boxFocus(1)">
                        <div class="col-md-12 padding-right-0 backgroundFade">
                            <div class="col-md-9 padding-0">
                                <h5>{{customerAddHeader}}</h5>
                            </div>
                            <div class="col-md-3 padding-0 text-right hideAutofill">
                                <button mat-button class="locateButton" (click)="refreshData()" title="Refresh">
                                    <i class="material-icons">&#xE5D5;</i>
                                </button>
                            </div>
                        </div>

                        <div class="col-md-12 bglight">
                            <label class="dudeLabel">
                                <i class="material-icons">&#xE0D0;</i>
                                <!-- <i class="material-icons">&#xE0BA;</i> -->
                            </label>
                            <mat-form-field class="matsub dudeMail">
                                <input autocomplete="new-password" matInput [(ngModel)]="customerMail"
                                    name="customerMail" placeholder="Email" #cmail (keydown)="dontAllowSpace($event)"
                                    (keyup)="autoFillCustomer($event , 2 , cmail.value)" [formControl]="Custemail"
                                    required>
                                <mat-error *ngIf="email.invalid">{{getErrorEmail()}}</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-12 bglight">

                            <div *ngIf="newUser == false">
                                <div class="col-md-1 pad15-v">
                                    <label class="dudeLabel">
                                        <i class="material-icons">&#xE0CF;</i>
                                    </label>
                                </div>
                                <div class="col-md-3 padding-0">
                                    <mat-form-field class="matsub dudeMail ">
                                        <input autocomplete="new-password" matInput [readonly]='true'
                                            placeholder="dial code" [(ngModel)]="customerPhoneCode"
                                            name="customerPhoneCode" [formControl]="CustRegisterCountryCode" required>
                                        <mat-error *ngIf="RegisterCountryCode.invalid">{{getErrorCcode()}}</mat-error>
                                    </mat-form-field>
                                </div>

                            </div>
                            <div *ngIf="newUser" class="animatedView">
                                <div class="col-md-4 padding-0 pad11-v ">
                                    <input id="phone" style="
                                    width: 0px !important;
                                    padding-top: 0px;
                                    padding-bottom: 5px;
                                    padding-right: 0px !important;
                                    border: 0px;
                                    background: transparent;
                                    border-bottom: 1px solid rgba(0,0,0,.42);" placeholder="Mobile">
                                </div>
                                <!-- <ngx-mat-intl-tel-input
                                placeholder="Enter Number"
                                name="phone" [formControl]="CustRegisterPhone" #phone>
                            </ngx-mat-intl-tel-input>
                            <mat-error *ngIf="CustRegisterPhone.errors?.required">Required Field</mat-error>
                            <mat-error *ngIf="CustRegisterPhone.errors?.validatePhoneNumber">Invalid Number</mat-error> -->
                            </div>
                            <div class="col-md-8">
                                <mat-form-field class="full">
                                    <input autocomplete="new-password" id="phonenumber" [(ngModel)]="customerPhone"
                                        name="customerPhone" #cmobile matInput placeholder="Mobile"
                                        (keydown)="dontAllowAlphabets($event)"
                                        (keyup)="autoFillCustomer($event , 3 , cmobile.value)"
                                        (mouseup)="autoFillCustomer($event , 3 , cmobile.value)"
                                        [formControl]="CustRegisterPhone" [maxLength]="15" required>
                                    <mat-error *ngIf="CustRegisterPhone.invalid && CustRegisterPhone.untouched">
                                        {{getErrorMobile()}}</mat-error>
                                    <mat-hint *ngIf="CustRegisterPhone.valid  && isphoneValid === false">Invalid Number
                                    </mat-hint>
                                    <mat-hint *ngIf="CustRegisterPhone.valid && isphoneValid === true">Valid Number
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-md-12 bglight">
                            <label class="dudeLabel">
                                <i class="material-icons">&#xE0BA;</i>
                                <!-- <i class="material-icons">&#xE0D0;</i> -->
                            </label>
                            <mat-form-field class="matsub dudeName">
                                <input autocomplete="new-password" matInput #cname [(ngModel)]="customerName"
                                    placeholder="Name" (keyup)="autoFillCustomer($event , 1 , cname.value)"
                                    [formControl]="CustRegisterName" required>
                                <mat-error *ngIf="CustRegisterName.invalid">{{getErrorName()}}</mat-error>
                                <!-- <mat-error *ngIf="CustRegisterName.minLength">{{getErrorName()}}</mat-error> -->
                            </mat-form-field>
                        </div>

                        <div *ngIf="newUser" class="animatedView col-md-12 bglight text-center">
                            <div>
                                <button mat-button title="Add Customer" (click)='validateRegistrationForm()'
                                    type="button">
                                    Add
                                    <mat-progress-bar class="example-margin" [color]="primary" [mode]="BookingMode">
                                    </mat-progress-bar>
                                </button>
                            </div>
                            <div class="col-md-12" style="color:red">
                                <span *ngIf="uniqueCustomerEmail">Email is already entered</span>
                                <span *ngIf="uniqueuniqueCustomerPhoneNumber">PhoneNumber is already entered</span>
                            </div>
                        </div>
                    </div>
                    <div class="autofillCustomer animatedView" style="display:none">
                        <div class="col-md-12 backgroundFade">
                            <div class="col-md-11 padding-0">
                                <h5>Select Customer</h5>
                            </div>
                            <div class="col-md-1 padding-0 text-right hideAutofill" (click)="closeAutoFill()">
                                <h5>
                                    <i class="fa fa-minus-square" aria-hidden="true"></i>
                                </h5>
                            </div>
                        </div>
                        <ul class="autoFillList col-md-12 padding-0">
                            <li class="col-md-12 addressbook" *ngFor="let item of autoFillData"
                                (click)="selectFromAutoComplete(item)">
                                <div class="autodetails col-md-12">
                                    <p class="autoFillName">
                                        <b>
                                            <i class="fas fa-id-card opacityChange"></i>
                                        </b> {{item.firstName}} {{item.lastName}}
                                    </p>
                                    <p>
                                        <span>
                                            <b>
                                                <i class="fas fa-phone opacityChange"></i>
                                            </b> ({{item.countryCode}}) {{item.phone}} </span>
                                        ,
                                        <span>
                                            <b>
                                                <i class="fas fa-envelope opacityChange"></i>
                                                &nbsp;</b>{{item.email}}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- Loaction Selection Code -->
                    <div class="col-md-12 pad5 focus" id="focus2" (click)="boxFocus(2)">
                        <div class="col-md-12 padding-0 bglight">
                            <div class="col-md-12  backgroundFade padding-right-0">
                                <div class="col-md-8 padding-0">
                                    <h5>{{addressHeader}}</h5>
                                </div>
                                <div class="col-md-4 padding-0 text-right">
                                    <button mat-button title="Add AddressTag" [disabled]="newUser"
                                        (click)="clearDefaultAddress(1)" type="button" class="locateButton">
                                        <i class="material-icons">&#xE567;</i>
                                    </button>
                                    <button mat-button title="Address Book" [disabled]="newUser"
                                        (click)="showAddressbook()" type="button" class="locateButton">
                                        <i class="material-icons">&#xE02F;</i>
                                    </button>
                                </div>
                            </div>


                            <div class="col-md-12 text-center">
                                <label class="dudeLabel">
                                    <!-- <i class="fa fa-thumb-tack" aria-hidden="true"></i> -->
                                    <i class="fas fa-map-marker-alt"></i>
                                </label>
                                <mat-form-field class="w95">
                                    <input matInput [disabled]="newUser" [readonly]="readOnlyAddress"
                                        placeholder="Location" (keyup)="setMap()" id="origin-input3"
                                        [(ngModel)]="customer_Location" name="customer_Location"
                                        (click)="clearDefaultAddress(2)">
                                </mat-form-field>
                            </div>

                            <div *ngIf="newLocation" class="col-md-12 animatedView mrg10">
                                <div class="col-md-8 text-left padding-0">
                                    <mat-form-field>
                                        <mat-select placeholder="Address Tag*" [disabled]="newUser"
                                            [(ngModel)]="addressTag">
                                            <mat-option *ngFor="let tag of tags" #Tag [value]="tag.value"
                                                (click)="AddressTag(Tag.value)">
                                                {{ tag.viewValue }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-md-4 text-right padding-0">
                                    <button mat-button title="Add Location" (click)="addAddress()" type="button"
                                        class="locateButton" id="SaveBtn">Save
                                        <!-- <i class="material-icons">&#xE567;</i> -->
                                    </button>
                                    <button mat-button title="Cancel" (click)="clearadressBar()" type="button"
                                        class="locateButton">
                                        <i class="material-icons markRed">&#xE5C9;</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- Card #23 : changed to address type -->
                        <div class="col-md-12 text-center" id="others" style="display:none">
                            <mat-form-field class="w95">
                                <input matInput [readonly]="readOnlyAddress" placeholder="SAVE AS" id="otherTag"
                                    [(ngModel)]="other_Location" name="other_Location">
                                <mat-hint>Ex: Dad's Place , John's Home</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="autoFillAddress" style="display:none">
                            <div class="col-md-12 backgroundFade">
                                <div class="col-md-11 padding-0">
                                    <h5>Select Address</h5>
                                </div>
                                <div class="col-md-1 padding-0 text-right hideAutofill" (click)="closeAddress()">
                                    <h5>
                                        <i class="fa fa-minus-square" aria-hidden="true"></i>
                                    </h5>
                                </div>
                            </div>
                            <ul class="autoFillList col-md-12">
                                <li class="col-md-12 addressbook" *ngFor="let address of previousLocationList"
                                    (click)="selectAddress(address)">
                                    <!-- card #25 : the address tag and symbol should be together -->
                                    <div class="autodetails row ">
                                        <!-- <div class="row"> -->
                                        <div class="col-md-1"><i style="font-size:13px;"
                                                class="material-icons">&#xE1B7;</i></div>
                                        <div class="col-md-11" *ngIf="address.taggedAs"><b
                                                style="font-family: italic">{{address.taggedAs}}</b></div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="col-md-9">
                                        <span>{{address.addLine1}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Category Selection Code -->
                    <div class="col-md-12 pad5 focus" id="focus3" (click)="boxFocus(3)">
                        <div class="col-md-12 backgroundFade ">
                            <h5>Category</h5>
                        </div>
                        <div class="col-md-12 bglight">
                            <div class="col-md-12 fullWidth mrg10 zeroPadding">
                                <hr />
                                <!-- <mat-progress-bar *ngIf="customer_Location"
                                    [mode]="'query' ? !categoryList : 'determinate'"></mat-progress-bar> -->
                                <form>
                                    <label class="dudeLabel">
                                        <i class="fas fa-search"></i>
                                    </label>
                                    <mat-form-field class="mat70 dudeName">
                                        <input type="text" [formControl]="myControl" [matAutocomplete]="auto"
                                            [(ngModel)]="SearchCategory" matInput placeholder="Search Category"
                                            #categorysearch (keyup)="getCategoryByAutoComplete(categorysearch.value)">
                                    </mat-form-field>
                                    <mat-autocomplete id="category" #auto="matAutocomplete">
                                        <mat-option *ngFor="let cat of categoryList" class="marginVertical"
                                            (click)="onSelectCat(cat._id)" [value]="cat.cat_name">
                                            <span>
                                                <img *ngIf="cat.bannerImageWeb" src="{{cat.bannerImageApp}}"
                                                    (error)="imgAlt($event)" width="30px" height="30px">
                                                <img *ngIf="!cat.bannerImageWeb" src="assets/images/imgNo.png"
                                                    width="30px" height="30px">
                                            </span>
                                            <span>&nbsp;&nbsp;&nbsp;{{cat.cat_name}}</span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </form>
                            </div>
                        </div>
                        <div class="col-md-12 bglight" *ngIf="CategoryError">
                            <div class="col-md-12 fullWidth mrg10">
                                <p style="color: red;/* font-size: 13px !important; */;">No such service found at
                                    selected location</p>
                            </div>
                        </div>
                    </div>
                    <!-- Call Type Booking-->
                    <div *ngIf="categoryOpted  && categoryOpted.hasOwnProperty('callType') && callTypeCount >= 2"
                        class="col-md-12 pad5 focus" id="focus10">
                        <div class="col-md-12 backgroundFade padding-0">
                            <h5 class="col-md-10">Select Call Type</h5>
                            <div class="col-md-2 padding-0 text-right"
                                *ngIf="answerAllError === false && reducedQuestionsCount >= 0 && questionAnsArr.length > 0">
                                <button mat-button title="Answered Questions" (click)="getAnsweredQuertionsPopup()"
                                    type="button" class="locateButton">
                                    <i class="material-icons">menu</i>
                                </button>
                            </div>
                        </div>

                        <div class="col-md-12 bglight">
                            <div class="col-md-12 fullWidth mrg10 zeroPadding">
                                <div class="col-md-12  padding-0 bglight focus">
                                    <!-- <div class="col-md-12  padding-0 bglight focus"> -->
                                    <!-- <div class="col-md-12 padding-0"> -->
                                    <!-- <div class="col-md-12"> -->
                                    <h5 style="color:black;">1) Which type of the booking you want to select?</h5>
                                    <!-- </div> -->
                                    <!-- <mat-radio-group class="col-md-12">
                                        <mat-radio-button class="col-md-12 padding-0" #callType
                                            *ngFor="let callList of callType;let inst = index"
                                            [value]="callList.checked" (change)="closeQuestionAnw(4,inst)">{{callList.viewValue}}
                                        </mat-radio-button>
                                    </mat-radio-group> -->
                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                        class="example-radio-group">
                                        <mat-radio-button class="example-radio-button" #callType
                                            *ngFor="let callList of callType;let inst = index" [value]="callList.value"
                                            (change)="closeQuestionAnw(4,inst)">
                                            {{callList.viewValue}}
                                        </mat-radio-button>
                                    </mat-radio-group>

                                    <!-- </div> -->
                                    <!-- </div> -->
                                </div>
                                <!-- <div class="col-md-12 text-center">
                                        <button type="button" 
                                            class="btn pull-center btn-success" data-dismiss="modal" (click)="closeQuestionAnw(3,1)">Save</button>
                                    </div> -->
                                <br><br>
                            </div>
                        </div>

                    </div>

                    <!-- For Bidding Category -->
                    <div *ngIf="Billing_model == 7" class="col-md-12 pad5 focus" id="focus8" (click)="boxFocus(8)">
                        <div class="col-md-12 backgroundFade ">
                            <h5>When is the visit<span style="color:red">*</span></h5>
                        </div>
                        <div class="tab-content bglight">
                            <div class="col-md-6 bglight">
                                <label class="dudeLabel">
                                    <i class="far fa-calendar-alt" aria-hidden="true"></i>
                                    <span class="fontWeight">Select Date*</span>
                                </label>
                                <!-- <mat-form-field class="w95"> -->
                                <input class="form-control" mwlFlatpickr placeholder="Please Select Date"
                                    [(ngModel)]="forBiddingDate" dateFormat="j M, Y" [altInput]="true"
                                    [minDate]="todaysDate" [convertModelValue]="true"
                                    (flatpickrChange)="FromToDateForMul($event,3)">
                                <!-- <input matInput class="text-center  pad5 flatpickr myDateForBid"  (change)="filteredProvider()" placeholder="Select Date*"> -->
                                <!-- </mat-form-field> (flatpickrOpen)="FromToDateForMul($event,3)"-->
                            </div>
                            <div class="col-md-6 bglight">
                                <label class="dudeLabel">
                                    <i class="far fa-clock" aria-hidden="true"></i>
                                    <span class="fontWeight">Select Time*</span>
                                </label>

                                <input class="text-center form-control" mwlFlatpickr placeholder="Please Select Time"
                                    [noCalendar]="true" [enableTime]="true" [minDate]="minTime" [dateFormat]="'h:i K'"
                                    [allowInput]="false" [enableSeconds]="false" [altFormat]="'M j, Y'"
                                    [minuteIncrement]="'15'" [time24hr]="false"
                                    (flatpickrChange)="FromToDateForMul($event, 4)"
                                    (flatpickrOpen)="FromToDateForMul($event,5)">

                            </div>
                        </div>
                    </div>

                    <!-- Book Now , Book Later , Repeat Booking Code -->
                    <div *ngIf="Billing_model != 7 && callTypeValue === 2" class="col-md-12 pad5 focus" id="focus4"
                        (click)="boxFocus(4)">
                        <div class="col-md-12 padding-0">
                            <div *ngIf="callTypeValue === 2" class="col-md-12 backgroundFade">
                                <h5>Booking Type</h5>
                            </div>
                        </div>
                        <!-- Card #42 all text in button should be in white -->
                        <ul class="nav nav-tabs jobBillNavTab bglight">
                            <li *ngIf="enableType1" class="col-md-4 padding-0 text-center listTab  ActiveTab"
                                id="bookNow" [class.active]="bookingType == 1">
                                <button class="w100" mat-button style="background: rgba(75, 77, 88, 0.21)"
                                    data-toggle="tab" href="#now" (click)="schedule(1)" [disabled]="!enableType1"><span
                                        style="color:white;">Book Now</span></button>
                            </li>
                            <li *ngIf="enableType2" class="col-md-4 padding-0 text-center listTab ActiveTab"
                                id="bookLater" [class.active]="bookingType == 2">
                                <button class="w100 BookType" mat-button data-toggle="tab" href="#later"
                                    (click)="schedule(2)" [disabled]="!enableType2"><span style="color:white;">Book
                                        Later</span></button>
                            </li>
                            <li *ngIf="enableType3" class="col-md-4 padding-0 listTab ActiveTab" id="repeatShift"
                                [class.active]="bookingType == 3">
                                <button class="w100 BookType" mat-button data-toggle="tab" href="#multiShft"
                                    (click)="schedule(3);" [disabled]="!enableType3"><span style="color:white;">Multiple
                                        Shift</span></button>
                            </li>
                        </ul>
                        <div class="tab-content bglight TabActive">
                            <div id="now" *ngIf="bookingType == 1" class="tab-pane fade"
                                [ngClass]="{'active in': (bookingType == 1 && enableType1)}">
                                <div class="col-md-12 bglight">
                                    <label class="dudeLabel">
                                        <i class="far fa-calendar-alt" aria-hidden="true"></i>
                                    </label>
                                    <mat-form-field class="w95">
                                        <br>
                                        <input class="text-center" matInput placeholder="Book Now"
                                            readonly>{{getCurrentDate()}}
                                    </mat-form-field>
                                </div>

                            </div>


                            <div id="later" *ngIf="bookingType == 2" class="tab-pane fade"
                                [ngClass]="{'active in': (bookingType == 2 && enableType2)}">
                                <div class="col-md-6 bglight">
                                    <label class="dudeLabel bookingTypes">
                                        <span><i class="far fa-calendar-alt"></i></span>
                                        <span class="fontWeight">Select Date*</span>
                                    </label>
                                    <input class="form-control flatPickerData" mwlFlatpickr
                                        placeholder="Please Select Date" dateFormat="j M, Y" [altInput]="true"
                                        [minDate]="todaysDate" [convertModelValue]="true"
                                        (flatpickrChange)="FromToDateForMul($event,3)"
                                        (flatpickrOpen)="FromToDateForMul($event,3)">
                                </div>
                                <div class="col-md-6 bglight" *ngIf="forBiddingDate.length > 0">
                                    <label class="dudeLabel bookingTypes">
                                        <span><i class="far fa-clock"></i></span>
                                        <span class="fontWeight">Select ScheduleTime*</span>
                                    </label>
                                    <input class="text-center form-control flatPickerData" mwlFlatpickr
                                        placeholder="Please Select Time" [minDate]="getCurrentDateFormat(bookingType)"
                                        [noCalendar]="true" [enableTime]="true" [dateFormat]="'h:i K'"
                                        [allowInput]="false" [enableSeconds]="false" [altFormat]="'M j, Y'"
                                        [minuteIncrement]="'15'" [(ngModel)]="currentDefaultTime" [time24hr]="false"
                                        [defaultMinute]="'00'" (flatpickrChange)="FromToDateForMul($event, 4)"
                                        (flatpickrOpen)="FromToDateForMul($event,5)">
                                </div>
                                <!-- <div class="col-12 text-center marginTop10" *ngIf="forBiddingDate.length <= 0">
                                    <span> Please Select Date </span>
                                </div> -->
                                <!-- <div class="col-md-6 bglight text-left LaterBookDuration">
                                    <label class="dudeLabel">
                                        <span><i class="far fa-clock"></i></span>
                                        <span class="fontWeight">Select Duration*</span>
                                    </label>
                                    <input class="text-center form-control LaterBookDuration" mwlFlatpickr
                                        placeholder="0" [noCalendar]="true" [enableTime]="true" [dateFormat]="'H'"
                                        [defaultHour]="'01'" 
                                        [allowInput]="false" [enableSeconds]="false" [altFormat]="'H'" [minDate]="'01:00'"
                                        [convertModelValue]="true" [altInput]="true" [minuteIncrement]="'60'"
                                        [time24hr]="true" (flatpickrChange)="FromToDateForMul($event, 7)"
                                        (flatpickrOpen)="FromToDateForMul($event,8)">
                                </div> -->
                            </div>
                            <div id="multiShft" class="tab-pane fade"
                                [ngClass]="{'active in': (bookingType == 3 && enableType3)}">
                                <div *ngIf="ConfirmMultipleShift == 1" class="col-md-12 bglight scrollable" style="overflow-y: scroll;
                                max-height:20vh;padding-top: 5px;">
                                    <div class="row bglight">
                                        <div class="col-md-1 bglight" style="padding-left: 7px;">
                                            <label class="">
                                                <i class="far fa-clock"></i>
                                            </label>
                                        </div>
                                        <div class="col-md-4 bglight" style="padding-left: 7px;"><b>Start Time:
                                            </b>{{TimePickerMul}}</div>
                                        <div class="col-md-3 bglight" style="padding-left: 7px;"><b>No. of Shifts:</b>
                                            {{ShiftCount}}</div>
                                        <div class="col-md-4 bglight" style="padding-left: 7px;"><b>End Time :</b>
                                            {{ResultantEndTime}}</div>
                                    </div>
                                    <hr>
                                    <div class="row bglight">
                                        <div class="col-md-1 bglight" style="padding-left: 7px;">
                                            <label class="">
                                                <i class="far fa-calendar-alt"></i>
                                            </label>
                                        </div>
                                        <div class="col-md-4 bglight" style="padding-left: 7px;">{{startDate}}</div>
                                        <div class="col-md-2 bglight" style="padding-left: 7px;"><b>To</b></div>
                                        <div class="col-md-5 bglight" style="padding-left: 7px;">{{endDate}}</div>
                                    </div>
                                    <hr>
                                    <div class="row bglight">
                                        <div class="col-md-1 bglight" style="padding-left: 7px;">
                                            <label class="">
                                                <i class="far fa-calendar-check"></i>
                                            </label>
                                        </div>
                                        <div class="col-md-11 bglight zeroPadding"><b>Selected Days</b></div>
                                    </div>
                                    <div class="row bglight">
                                        <div class="col-md-1 bglight"></div>
                                        <div class="col-md-11 bglight" style="width:100%;display:inline-block">
                                            <div class="col-md-12 bglight"
                                                style="display:inline-block;word-wrap: break-word;width: 80%;">
                                                {{ShowDays}}
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <br>
                                    <div *ngIf="this.ShiftOffers" class="row bglight">
                                        <div class="col-md-1 bglight" style="padding-left: 7px;">
                                            <label class="">
                                                <i class="far fa-calendar-check"></i>
                                            </label>
                                        </div>
                                        <div *ngIf="ShiftOffer" class="col-md-11 bglight zeroPadding">
                                            <b>Offer : </b>{{ShiftOffer.value}}%
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 
                     <div class="col-md-12 pad5 focus" id="focus5" (click)="boxFocus(5)">
                        <div class="col-md-12 backgroundFade ">
                            <h5>Details</h5>
                        </div>
                        <div class="col-md-12 bglight">
                            <label class="dudeLabel">
                                <i class="fa fa-info" aria-hidden="true"></i>
                            </label>
                            <mat-form-field class="w95">
                                <textarea matInput placeholder="Comments"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="col-md-12 content bglight ">

                            <div class="remove_details box image-upload" style="width:125%">
                                <input accept="image/*" autocomplete="new-password" class="inputfile inputfile-6 jobImage ng-untouched ng-pristine ng-valid" data-multiple-caption="{count} files selected"
                                    id="file-7" multiple="" name="imageFile" type="file" ng-reflect-name="imageFile" value="">
                                <label for="file-7" style="width:100%">
                                    <span id="choose_file_span" style="display: inline;line-height: 30px;"></span>

                                    <strong class="pull-right">
                                        <svg height="17" viewBox="0 0 20 17" width="20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                        </svg>Uplaod Job Image
                                    </strong>
                                </label>
                            </div>
                        </div>

                    </div>  -->

                </div>

                <!-- Porvider List -->
                <div *ngIf="Billing_model != 7" class="col-md-3 padding-0" id="SelectedProvider" style="left: 4px;">
                    <div *ngIf="availableList.length > 0 || unavailableList.length >0" class="col-md-12 pad5 focus">
                        <div class="col-md-12 padding-right-0 backgroundFade">
                            <div class="col-md-10 padding-0">
                                <h5>Selected Provider</h5>
                            </div>
                            <div class="col-md-2 padding-0 text-right">
                                <button mat-button class="locateButton" [disabled]="!haveSelectedProvider"
                                    title="Clear Selected Provider" (click)="removeSelected()">
                                    <i class="material-icons">&#xE15D;</i>
                                </button>
                            </div>
                        </div>

                        <div *ngIf="!haveSelectedProvider" class="providerError">
                            <p> Please select a provider from the list</p>

                        </div>
                        <div *ngIf="haveSelectedProvider" class="eachProvider" (click)="getProviderSlot()">
                            <div class="provider-profile-main-container">
                                <div class="provider-profile-image image-status-div">
                                    <img src="{{selectedProvider.image}}" alt="{{selectedProvider.name}}" height="42"
                                        width="42" class="profile-pic aws-image">
                                </div>
                                <div class="name-phone-div">
                                    <p class="div_display provider-name">{{selectedProvider.name}}
                                    </p>
                                    <p class="provider-phone" *ngIf="selectedProvider.email">
                                        {{selectedProvider.email}}
                                    </p>
                                    <p class="provider-phone" *ngIf="selectedProvider.phone">
                                        {{selectedProvider.phone}}
                                    </p>
                                </div>
                                <div class="jobCounts">
                                    <p class="tot_job">{{selectedProvider.bookingCount}} | jobs</p>
                                    <p class="lastSeen">{{selectedProvider.lastUpdatedMinutes}}</p>
                                </div>
                                <div class="extraInfo">
                                    <div class="batry fLeft25">
                                        <span>
                                            <img src="assets/images/battery.png">
                                        </span>
                                        <span
                                            *ngIf="selectedProvider.batteryPercentage">{{selectedProvider.batteryPercentage}}%</span>
                                        <span *ngIf="!selectedProvider.batteryPercentage"> -- </span>
                                    </div>
                                    <div class="locChk fLeft25">
                                        <span>
                                            <img src="assets/images/compass.png">
                                        </span>
                                        <span
                                            *ngIf="selectedProvider.locationCheck ; else other_content">{{selectedProvider.locationCheck}}</span>
                                        <ng-template #other_content> NA </ng-template>
                                    </div>
                                    <div class="devSoft fLeft25">
                                        <span>
                                            <img src="assets/images/smartphone.png">
                                        </span>
                                        <span *ngIf="selectedProvider.deviceType == 2">
                                            <img src="assets/images/android.png">
                                        </span>
                                        <span *ngIf="selectedProvider.deviceType == 1">
                                            <img src="assets/images/apple.png">
                                        </span>
                                    </div>
                                    <div class="devVer fLeft25">
                                        <span>
                                            <img src="assets/images/hand-gesture.png">
                                        </span>
                                        <span>{{selectedProvider.appversion}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 pad5 focus" id="focus6" (click)="boxFocus(6)">
                        <div class="col-md-12 padding-0 backgroundFade">
                            <div class="col-md-1 padding-0 text-left">
                            </div>
                            <div class="col-md-8">
                                <h5>Provider List</h5>
                            </div>
                            <div class="col-md-3 padding-0 text-right">
                                <button mat-button title="Provider List" type="button" class="locateButton"
                                    (click)="openProviderList()">
                                    <i class="material-icons">&#xE8EF;</i>
                                </button>
                            </div>

                            <mat-menu #menu="matMenu">
                                <button mat-menu-item [matMenuTriggerFor]="RatingList">Rating</button>
                                <button mat-menu-item [matMenuTriggerFor]="DistanceList">Distance</button>
                                <button mat-menu-item [matMenuTriggerFor]="ReviewList">Review Count</button>
                                <!-- <button mat-menu-item>Past Booked</button> -->
                                <button mat-menu-item [matMenuTriggerFor]="JobList">Current Jobs</button>
                            </mat-menu>
                            <mat-menu #RatingList="matMenu">
                                <button mat-menu-item>Highest</button>
                                <button mat-menu-item>Lowest</button>
                            </mat-menu>
                            <mat-menu #DistanceList="matMenu">
                                <button mat-menu-item>Far</button>
                                <button mat-menu-item>Near</button>
                            </mat-menu>
                            <mat-menu #ReviewList="matMenu">
                                <button mat-menu-item>Most Reviewed</button>
                                <button mat-menu-item>Least Reviewed</button>
                            </mat-menu>
                            <mat-menu #JobList="matMenu">
                                <button mat-menu-item>Highest Jobs</button>
                                <button mat-menu-item>Least Jobs</button>
                            </mat-menu>
                        </div>

                        <div class="filteredProList">
                            <div class="col-md-12">
                                <mat-form-field class="matsub dudeName">
                                    <input matInput placeholder="Search provider by name" [(ngModel)]="providersearch"
                                        (ngModelChange)="filteredProvider()">
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 padding-0">
                                <mat-tab-group>
                                    <mat-tab label="Available">
                                        <ng-template mat-tab-label>
                                            <div class="joblist orderlist text-center padding-0 active">
                                                <p class="booking-status-header">
                                                    <span class="count energetic">{{availableList ? availableList.length
                                                        : 0}}</span>Available
                                                </p>
                                            </div>
                                        </ng-template>
                                        <div class="col-md-12 padding-0 scrollable">
                                            <div *ngFor="let item of availableList; let i = index;"
                                                class="eachProvider eachProviderBusy-{{i}}"
                                                id="eachProvider-{{item.user}}" (click)="selectProvider(item)">
                                                <div class="provider-profile-main-container">
                                                    <div class="provider-profile-image image-status-div">
                                                        <img src="{{item.image}}" alt="{{item.name}}" height="42"
                                                            width="42" class="profile-pic aws-image"
                                                            id="image-{{item.user}}">
                                                    </div>
                                                    <div class="name-phone-div">
                                                        <p class="div_display provider-name">{{item.name}}
                                                        </p>
                                                        <p class="provider-phone" *ngIf="item.email">
                                                            {{item.email}}
                                                        </p>
                                                        <p class="provider-phone" *ngIf="item.phone">
                                                            {{item.phone}}
                                                        </p>
                                                    </div>
                                                    <div class="jobCounts">
                                                        <p class="tot_job">{{item.bookingCount}} | jobs</p>
                                                        <p class="lastSeen">{{item.lastUpdatedMinutes}}</p>
                                                    </div>
                                                    <div class="extraInfo">
                                                        <div class="batry fLeft25">
                                                            <span>
                                                                <img src="assets/images/battery.png">
                                                            </span>
                                                            <span
                                                                *ngIf="item.batteryPercentage">{{item.batteryPercentage}}%</span>
                                                            <span *ngIf="!item.batteryPercentage"> -- </span>
                                                        </div>
                                                        <div class="locChk fLeft25">
                                                            <span>
                                                                <img src="assets/images/compass.png">
                                                            </span>
                                                            <span
                                                                *ngIf="item.locationCheck ; else other_content">{{item.locationCheck}}</span>
                                                            <ng-template #other_content> NA </ng-template>
                                                        </div>
                                                        <div class="devSoft fLeft25">
                                                            <span>
                                                                <img src="assets/images/smartphone.png">
                                                            </span>
                                                            <span *ngIf="item.deviceType == 2">
                                                                <img src="assets/images/android.png">
                                                            </span>
                                                            <span *ngIf="item.deviceType == 1">
                                                                <img src="assets/images/apple.png">
                                                            </span>
                                                        </div>
                                                        <div class="devVer fLeft25">
                                                            <span>
                                                                <img src="assets/images/hand-gesture.png">
                                                            </span>
                                                            <span>{{item.appversion}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </mat-tab>
                                    <mat-tab label="Unavailable">
                                        <ng-template mat-tab-label>
                                            <div class="joblist orderlist text-center padding-0 active">
                                                <p class="booking-status-header">
                                                    <span class="count energetic">{{unavailableList ?
                                                        unavailableList.length : 0}}</span>Unavailable
                                                </p>
                                            </div>
                                        </ng-template>
                                        <div class="col-md-12 padding-0 scrollable">
                                            <div *ngFor="let item of unavailableList; let i = index;"
                                                class="eachProvider eachProviderBusy-{{i}}"
                                                id="eachProvider-{{item.user}}" (click)="selectProvider(item)">
                                                <div class="provider-profile-main-container">
                                                    <div class="provider-profile-image image-status-div">
                                                        <img src="{{item.image}}" alt="{{item.name}}" height="42"
                                                            width="42" class="profile-pic aws-image"
                                                            id="image-{{item.user}}">
                                                    </div>
                                                    <div class="name-phone-div">
                                                        <p class="div_display provider-name">{{item.name}}
                                                        </p>
                                                        <p class="provider-phone" *ngIf="item.email">
                                                            {{item.email}}
                                                        </p>
                                                        <p class="provider-phone" *ngIf="item.phone">
                                                            {{item.phone}}
                                                        </p>
                                                    </div>
                                                    <div class="jobCounts">
                                                        <p class="tot_job">{{item.bookingCount}} | jobs</p>
                                                        <p class="lastSeen">{{item.lastUpdatedMinutes}}</p>
                                                    </div>
                                                    <div class="extraInfo">
                                                        <div class="batry fLeft25">
                                                            <span>
                                                                <img src="assets/images/battery.png">
                                                            </span>
                                                            <span
                                                                *ngIf="item.batteryPercentage">{{item.batteryPercentage}}%</span>
                                                            <span *ngIf="!item.batteryPercentage"> -- </span>
                                                        </div>
                                                        <div class="locChk fLeft25">
                                                            <span>
                                                                <img src="assets/images/compass.png">
                                                            </span>
                                                            <span
                                                                *ngIf="item.locationCheck ; else other_content">{{item.locationCheck}}</span>
                                                            <ng-template #other_content> NA </ng-template>
                                                        </div>
                                                        <div class="devSoft fLeft25">
                                                            <span>
                                                                <img src="assets/images/smartphone.png">
                                                            </span>
                                                            <span *ngIf="item.deviceType == 2">
                                                                <img src="assets/images/android.png">
                                                            </span>
                                                            <span *ngIf="item.deviceType == 1">
                                                                <img src="assets/images/apple.png">
                                                            </span>
                                                        </div>
                                                        <div class="devVer fLeft25">
                                                            <span>
                                                                <img src="assets/images/hand-gesture.png">
                                                            </span>
                                                            <span>{{item.appversion}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </mat-tab>
                                </mat-tab-group>
                            </div>



                        </div>
                    </div>
                </div>


                <!-- Questions from bidding category -->

                <div *ngIf="Billing_model == 7" class="col-md-3  padding-0 jobBill1" id="QuestionsForBidding">
                    <div class="col-md-12  padding-0 bglight focus" style="height: 550px;"
                        [ngClass]="{disableBidding: enableQuestionsForBidding == true, enableBidding : enableQuestionsForBidding == false}">
                        <div class="col-md-12  padding-0 bglight focus">
                            <div class="col-md-12 CheckOutHeader backgroundFade" style="bottom:3px">
                                <h5>Questions Set</h5>
                            </div>
                            <div class="col-md-12 fullWidth mrg10 Question">
                                <div *ngFor="let questions of QuestionSet; let i = index;" class="Questions-{{i}}"
                                    id="Questions-{{i}}" (click)="DisplayQuestions(questions.questionType)">

                                    <div *ngIf="questions.questionType == 0">
                                        <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                            {{questions.question}}</span>
                                        <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                                {{questions.question}}</span><span style="color:red">*</span></span>
                                        <br>
                                        <br>
                                        <div class="col-md-12 padding-0">
                                            <input type="text" id="inputPromo" style="width:30%"
                                                class="inputPromo text-center" [(ngModel)]="promocode"
                                                (keyup)="AnswerForQuestion(i,$event,0,0)">
                                        </div>
                                        <div class="col-md-12 PromoFor" style="margin-top: 19px;"></div>
                                        <hr>
                                    </div>

                                    <div *ngIf="questions.questionType == 1">
                                        <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                            {{questions.question}}</span>
                                        <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                                {{questions.question}}</span><span style="color:red">*</span></span>
                                        <br>
                                        <div class="col-md-12 padding-0">
                                            <div class="col-md-12 bglight" style="height: 80px;">
                                                <label class="dudeLabel">{{categoryOpted.currencySymbol}}</label>
                                                <mat-form-field class="matsub1 dudeName">
                                                    <input matInput id="BidPrice" #bidAmount
                                                        [formControl]="BiddingPrice" [(ngModel)]="BidAmount"
                                                        (keyup)="AnswerForQuestion(i,$event,0,0)" required>
                                                    <mat-error *ngIf="BiddingPrice.invalid">{{getBiddingPriceError()}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <!-- <input type="text" id="inputPromo" class="inputPromo text-center" [(ngModel)]="biddingAmount" (keyup)="applyBidding($event)"> -->
                                        </div>
                                        <hr>
                                    </div>

                                    <div *ngIf="questions.questionType == 5">
                                        <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                            {{questions.question}}</span>
                                        <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                                {{questions.question}}</span><span style="color:red">*</span></span>
                                        <br>
                                        <mat-form-field>
                                            <textarea matInput [formControl]="jobDescriptionController"
                                                [(ngModel)]="jobDescription" id="TextAreaValue"
                                                (click)="AnswerForQuestion(i,$event,0,0)"></textarea>
                                        </mat-form-field>
                                        <hr>
                                    </div>

                                    <div class="{{questions.isManadatory}}" *ngIf="questions.questionType == 6 ">
                                        <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                            {{questions.question}}</span>
                                        <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                                {{questions.question}}</span><span style="color:red">*</span></span>
                                        <br>
                                        <mat-radio-group class="example-radio-group">
                                            <mat-radio-button class="example-radio-button"
                                                *ngFor="let options of questions.preDefined ; let indx1 = index"
                                                #RadioValue [value]="options"
                                                (change)="AnswerForQuestion(i,$event,indx1)">{{options.name}}
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <hr>
                                    </div>

                                    <div *ngIf="questions.questionType == 7">
                                        <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                            {{questions.question}}</span>
                                        <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                                {{questions.question}}</span><span style="color:red">*</span></span>
                                        <br>
                                        <br>
                                        <div *ngFor="let options of questions.preDefined; let indx2 = index">
                                            <mat-checkbox class="example-margin CheckBoxValue" #CheckBoxValue
                                                (change)="AnswerForQuestion(i,$event,indx2)" [value]="options">
                                                {{options.name}}</mat-checkbox>
                                        </div>
                                        <hr>
                                    </div>

                                    <!-- date and time option for (from current to future) -->
                                    <div *ngIf="questions.questionType == 8">
                                        <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                            {{questions.question}}</span>
                                        <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                                {{questions.question}}</span><span style="color:red">*</span></span>
                                        <br>
                                        <br>
                                        <div class="tab-content bglight">
                                            <div class="col-md-6 bglight">
                                                <label class="dudeLabel">
                                                    <i class="far fa-calendar-alt" aria-hidden="true"></i>
                                                </label>
                                                <mat-form-field class="w95">
                                                    <mat-form-field class="w95">
                                                        <input class="form-control" matInput mwlFlatpickr
                                                            [(ngModel)]="todaysDate" dateFormat="j M, Y"
                                                            [altInput]="true" [convertModelValue]="true"
                                                            [minDate]="todaysDate"
                                                            (flatpickrChange)="AnswerForQuestion(i,$event,0,0)"
                                                            (flatpickrOpen)="AnswerForQuestion(i,$event,0,0)">
                                                        <!-- <input matInput class="text-center pad5 flatpickr1 myDate1" id="myDate1" #DateValue1 placeholder="Select Date*" (change)="questionAnsweredByuser(0,questions.question,questions._id,questions.questionType,questions.isManadatory)"> -->
                                                    </mat-form-field>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- date and time option for (from past to current) -->
                                    <div *ngIf="questions.questionType == 9">
                                        <!-- <span> {{questions.num}} ) {{questions.question}}</span><br> -->
                                        <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                            {{questions.question}}</span>
                                        <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                                {{questions.question}}</span><span style="color:red">*</span></span>
                                        <br>
                                        <br>
                                        <div class="tab-content bglight">
                                            <div class="col-md-6 bglight">
                                                <label class="dudeLabel">
                                                    <i class="far fa-calendar-alt" aria-hidden="true"></i>
                                                </label>
                                                <mat-form-field class="w95">
                                                    <input class="form-control" matInput mwlFlatpickr
                                                        [(ngModel)]="todaysDate" dateFormat="j M, Y" [altInput]="true"
                                                        [convertModelValue]="true" [maxDate]="todaysDate"
                                                        (flatpickrChange)="AnswerForQuestion(i,$event,0,0)"
                                                        (flatpickrOpen)="ququestionAnsweredByuser(i,$event,0,0)">
                                                    <!-- <input matInput class="text-center pad5 flatpickr2 myDate2" id="myDate2" placeholder="Select Date*" (change)="questionAnsweredByuser(0,questions.question,questions._id,questions.questionType,questions.isManadatory)"> -->
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="questions.questionType == 10 ">
                                        <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                            {{questions.question}}</span>
                                        <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                                {{questions.question}}</span><span style="color:red">*</span></span>
                                        <br>
                                        <br>
                                        <div class="collectionImg col-xs-12">

                                            <div class="col-xs-12 editImagesell zeroPadding cursorPointer">
                                                <span class="listImg row" *ngIf="imgUrl && imgUrl.length > 0">
                                                    <span class="imagsProduct col-md-2 zeroPadding"
                                                        *ngFor="let img of imgUrl; let i = index;">
                                                        <div class="col-12 zeroPadding">
                                                            <img class="sellImg sellImgs" src="{{img}}"
                                                                onError="this.src='https://d2qb2fbt5wuzik.cloudfront.net/yelowebsite/images/default-thumnail.png';">
                                                            <button mat-button mat-mini-fab class="CloseButtonImg"
                                                                title="Close Image" (click)="removeImg(i)">
                                                                <i class="material-icons CloseImg">&#xE5CD;</i>
                                                            </button>
                                                        </div>
                                                    </span>
                                                </span>
                                                <span class="emptyImg col-md-2 zeroPadding">
                                                    <input type="file" #fileInput id="uploadSellInput" name="image"
                                                        accept="image/*" class="yeloProfileImage"
                                                        style="opacity: 0;
                                                                                                        width: 100px;height: 100px;"
                                                        (onFileSelected)="uploadImg($event,questions.question,questions._id)"
                                                        ng2FileSelect [uploader]="uploader" multiple>
                                                </span>
                                                <div class="col-12 p-2" *ngIf="ImageLoader" style="color:red;">
                                                    Image is Loading...
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="row text-center">
                                    <button class="btn btn-success" (click)='FromToDateForMul(0,6)'>Save</button>
                                </div><br>
                            </div>
                            <div class="col-md-12 text-center" *ngIf="allMandatoryQuestions">
                                <span style="color:red;font-size:13px;">Answer To All Mandatory Questions</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Job billing like fixed or hourly -->
                <div *ngIf="Billing_model != 7" class="col-md-3  padding-0 jobBill" id="jobBill">
                    <div class="col-md-12  padding-0 bglight focus" id="focus7" (click)="boxFocus(7)">
                        <mat-tab-group (selectedIndexChange)="selectedIndexChange($event)"
                            [selectedIndex]="selectedIndex">
                            <mat-tab label="Fixed" [disabled]="disableFixed">
                                <ng-template mat-tab-label>
                                    <div class="joblist orderlist text-center padding-0 active">
                                        <p class="booking-status-header">
                                            Fixed</p>
                                    </div>
                                </ng-template>
                                <div class="col-md-12  padding-0 bglight focus" [hidden]="disableFixed">
                                    <div class="col-md-12 CheckOutHeader backgroundFade">
                                        <h5>Service List</h5>
                                    </div>
                                    <div class="col-md-12 fullWidth mrg10" *ngIf="catSelected.length > 0">
                                        <form>
                                            <mat-form-field>
                                                <mat-select placeholder="Sub Category" [(ngModel)]="SubCategory"
                                                    name="SubCategory" (selectionChange)="onSelectSubCat(SubCategory)">
                                                    <mat-option *ngIf="catSelected && catSelected.length >0"
                                                        [value]="0">All</mat-option>
                                                    <mat-option *ngFor="let subCat of catSelected" [value]="subCat._id">
                                                        {{subCat.sub_cat_name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </form>
                                    </div>
                                    <div class="col-md-12 pad5 bglight">

                                        <div class="col-md-12">
                                            <hr />
                                            <label class="dudeLabel">
                                                <!-- <i className="material-icons">&#xE8B6;</i> -->
                                                <mat-icon>search</mat-icon>
                                            </label>
                                            <mat-form-field class="mat70 dudeName">
                                                <input matInput placeholder="Search service" #servicesearch
                                                    (keyup)="serviceSearch(servicesearch.value)">
                                            </mat-form-field>

                                        </div>
                                        <div class="col-md-12 padding-0 serviceListSection">
                                            <div *ngFor="let item of serviceList" class="serviceItem">
                                                <div class="col-md-12 allowSpace5 activeBill">

                                                    <div class="col-md-6 padding-1 billName">
                                                        <p>{{(item.serviceName).toLowerCase()}}</p>
                                                    </div>
                                                    <div *ngIf="item.status == 0">
                                                        <div class="col-md-4 padItem text-center">
                                                            <button mat-button mat-raised-button class="primaryButton"
                                                                type="button" (click)="myCart(item,1,1)">
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.status == 1">
                                                        <div class="col-md-1 padItem padding-0 text-center">
                                                            <div *ngIf="item.quantityAction == 1">
                                                                <button mat-button class="smallButton" type="button"
                                                                    (click)="myCart(item,1,1)" *ngIf="bookingType != 3">
                                                                    <i class="material-icons primary">&#xE146;</i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2 padItem padding-0 text-right">
                                                            <input class="input-number text-center" id="" type="number"
                                                                value="{{item.quntity}}" readonly>
                                                        </div>
                                                        <div class="col-md-1 padItem padding-0 text-center">
                                                            <div *ngIf="item.quantityAction == 1">
                                                                <button mat-button class="smallButton"
                                                                    *ngIf="bookingType != 3" type="button"
                                                                    (click)="myCart(item,2,1)">
                                                                    <i class="material-icons danger-i">&#xE909;</i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-2 padItem padding-0 text-right tTotal">
                                                        <span>{{item.currencySymbol}}</span>{{item.amount}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Hourly" [disabled]="disableHourly">
                                <ng-template mat-tab-label>
                                    <div class="joblist orderlist text-center padding-0 active">
                                        <p class="booking-status-header">
                                            Hourly</p>
                                    </div>
                                </ng-template>
                                <div class="hourlyBill bglight billPadding" [hidden]="disableHourly">
                                    <div class="text-center toHourly caps">
                                        <p>
                                            <b>Hourly Price</b>
                                        </p>
                                    </div>
                                    <div class="col-md-12 padding-1 text-center hTotal hBillBox hPadding">
                                        <span>{{categoryOpted.currencySymbol}}</span>{{hourlyCharge}}
                                    </div>
                                    <div class="col-md-12 text-center pad25 toHourly">
                                        <p>Calculate your fare</p>
                                    </div>
                                    <div class="col-md-12 padding-1 text-center">
                                        <div class="col-md-4 padding-0" style="padding:5px 0px">
                                            <div class="col-md-4  padding-0 text-center">
                                                <div>
                                                    <button mat-button class="smallButton" (click)="myCart(1,1,2)"
                                                        type="button" *ngIf="bookingType != 3">
                                                        <i class="material-icons primary">&#xE146;</i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-md-4  padding-0 text-right">
                                                <input class="input-number text-center" id="" type="number"
                                                    value="{{hourlyCount}}" readonly>
                                            </div>
                                            <div class="col-md-4  padding-0 text-center">
                                                <div>
                                                    <button mat-button class="smallButton" (click)="myCart(1,2,2)"
                                                        type="button" *ngIf="bookingType != 3">
                                                        <i class="material-icons danger-i">&#xE909;</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 padding-0 hbilltime">
                                            <sub>Hours</sub>
                                        </div>
                                        <div class="col-md-1 hTotal padding-0">
                                            =
                                        </div>
                                        <div class="col-md-4">
                                            <div class="padding-1 text-center hTotal padding-0">
                                                <span>{{categoryOpted.currencySymbol}}</span>{{hourlyTotalAmount |
                                                number:'1.2-2'}}
                                                <!-- <span *ngIf="HourlyPrice">{{categoryOpted.currencySymbol}}</span>{{(hourlyCharge).toFixed(2)}} -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>




                    <!-- for Job Image -->

                    <!-- <div class="col-md-12  padding-0 bglight focus" style="margin-top:5px" id="focus8" (click)="boxFocus(8)">
                        <div class="col-md-12 CheckOutHeader backgroundFade">
                            <h5>JOB DETAILS</h5>
                        </div>
                        <div class="col-md-12 padding-0 jobDetails">
                            <mat-tab-group>
                                <mat-tab label="Job Detail" active>
                                    <ng-template mat-tab-label>
                                        <div class="text-center padding-0 active">
                                            <p class="booking-status-header">Comments</p>
                                        </div>
                                    </ng-template>
                                    <div class="col-md-12 padItem">
                                        <mat-form-field class="example-full-width">
                                            <textarea matInput [formControl]="jobDescriptionController" [(ngModel)]="jobDescription" placeholder="Job Description"></textarea>
                                        </mat-form-field>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Upload Image">
                                    <ng-template mat-tab-label>
                                        <div class="text-center padding-0">
                                            <p class="booking-status-header">Job Image</p>
                                        </div>
                                    </ng-template>
                                    <div class="col-md-12 padding-0 box">
                                        <div class="js--image-preview">
                                        </div>
                                        <div class="upload-options">
                                            <label>
                                                <input type="file" class="image-upload" accept="image/*" (change)="readURL($event)" />
                                            </label>
                                        </div>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>

                        </div>
                    </div> -->
                </div>


                <div class="col-md-3  padding-0 CheckoutForm"
                    [ngClass]="{'showModal': allMandatoryQuestions == false && Billing_model == '7' , 'hideModal': allMandatoryQuestions == true && Billing_model =='7'}">
                    <div class="col-md-12">
                        <div class="col-md-12 pad5">
                            <div class="col-md-10 CheckOutHeader backgroundFade" id="regularCheckout">
                                <h5>CHECKOUT</h5>
                            </div>
                            <div class="col-md-10 CheckOutHeader backgroundFade" id="mandatoryCheckout"
                                style="display: none;width: 335px;">
                                <h5>CHECKOUT<span style="color:red">*</span></h5>
                            </div>
                            <div class="col-md-2 padding-0 text-right backgroundFade">
                                <button mat-button title="Clear Cart" type="button" id="RemoveCart" class="locateButton"
                                    [disabled]="!myCartList" (click)="removeCart()">
                                    <i class="material-icons">&#xE928;</i>
                                </button>
                            </div>
                        </div>


                        <!-- Check Out List -->
                        <div class="checkoutlist">
                            <div *ngFor="let item of checkOutList">
                                <div *ngIf="item.quntity > 0">
                                    <div class="col-md-12 allowSpace">
                                        <div class="col-md-5 padding-1">
                                            <span class="circleDot"></span>
                                            {{item.serviceName}}
                                        </div>
                                        <div class="col-md-1  padding-0">
                                            <div *ngIf="item.quantityAction == 1">
                                                <button mat-button class="smallButton" type="button"
                                                    (click)="myCart(item,1, item.serviceType)" *ngIf="bookingType != 3">
                                                    <i class="material-icons primary">&#xE146;</i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-2 padding-0">
                                            <div class=" backgroundFade">
                                                <input class="input-number showSpinner  text-center" id="" type="number"
                                                    value="{{item.quntity}}" min="1" max="10">
                                            </div>
                                        </div>
                                        <div class="col-md-1  padding-0">
                                            <div *ngIf="item.quantityAction == 1">
                                                <button mat-button class="smallButton" type="button"
                                                    (click)="myCart(item,2, item.serviceType)" *ngIf="bookingType != 3">
                                                    <i class="material-icons danger-i">&#xE909;</i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-2 padding-0 text-center" style="padding-top: 3px;">
                                            <span
                                                *ngIf="myCartList != undefined">{{myCartList.currencySymbol}}</span>{{item.amount}}
                                        </div>
                                        <div *ngIf="item.quantityAction != 1" class="col-md-1 padding-0 text-center">
                                            <a title="Remove" class="details_but danger" (click)="myCart(item,0,1)"
                                                style="color:#fff;background:#f8724c">
                                                <i class="fa fa-ban" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="bookingType !== 3" class="col-md-12" id="walletBalance">
                            <hr>
                            <div class="col-md-1 padding-0 pTop15">
                                <!-- <i class="fa fa-money billIcon" aria-hidden="true"></i> -->
                                <i class="fas fa-address-card billIcon"></i>
                            </div>
                            <div class="col-md-6 padding-0 pTop15">Wallet Balance :
                                <span
                                    *ngIf="walletDetails && walletDetails.walletAmount">{{walletDetails.currencySymbol}}
                                    {{walletDetails.walletAmount}}</span>
                            </div>
                        </div>

                        <div *ngIf="bookingType !== 3" class="col-md-12 allowSpace">
                            <div class="col-md-1 padding-0 pTop15">
                                <!-- <i class="fa fa-google-wallet billIcon" aria-hidden="true"></i> -->
                                <i class="fas fa-wallet billIcon"></i>
                            </div>
                            <div class="col-md-6 padding-0 pTop15">Use Wallet </div>
                            <div class="col-md-5 padding-0 pTop10 text-right" [attr.disabled]="newUser">
                                <mat-slide-toggle class="example-margin" [formControl]="slideController"
                                    (change)="matSlideToggleChange($event)" [color]="'primary'"
                                    [checked]="sliderChecked" [disabled]="WalleteDisable">
                                </mat-slide-toggle>
                            </div>
                        </div>

                        <div class="col-md-12 allowSpace">
                            <div *ngIf="Billing_model === 7" class="col-md-12 padding-0">
                                <hr>
                                <span>{{paymentQuestion}}<span style="color:red;">&#x2731;</span></span>
                            </div>
                            <div class="col-md-1 padding-0 pTop15">
                                <!-- <i class="fa fa-money billIcon" aria-hidden="true"></i> -->
                                <i class="far fa-credit-card billIcon"></i>
                            </div>
                            <div class="col-md-6 padding-0 pTop15">Select Payment*</div>
                            <div class="col-md-5 padding-0 semiSelector">
                                <mat-form-field>
                                    <mat-select placeholder="Pay by" [disabled]="newUser" [(value)]="paymentTypeValue"
                                        (selectionChange)="PaymentSelected($event,1)" [(ngModel)]="paymentTypeValue"
                                        (change)="DisableAutoManualDispatch(0)">
                                        <mat-option #Cash [value]="1" [disabled]="!CashSelectDisable">Cash</mat-option>
                                        <mat-option #Card [value]="2" [disabled]="!CardSelectDisable">Card </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="paymentTypeValue == 0">{{getPaymentError()}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="paymentTypeValue == 2" class="col-md-12" id="selectCards">
                            <div class="col-md-1 padding-0 pTop15">
                                <i class="fa fa-credit-card-alt billIcon" aria-hidden="true"></i>
                            </div>
                            <div class="col-md-6 padding-0 pTop15">Select Card*</div>
                            <div class="col-md-5 padding-0 semiSelector">
                                <mat-form-field>
                                    <mat-select placeholder="Select Card" (selectionChange)="PaymentSelected($event, 2)"
                                        [(ngModel)]="selectedCard"
                                        [disabled]="!paymentTypeValue || paymentTypeValue == 1">
                                        <mat-option *ngFor="let card of CardList" (click)="DisableAutoManualDispatch(0)"
                                            [value]="card.id">
                                            ****{{ card.last4 }} ({{ card.brand }} ,
                                            Exp:{{card.expMonth}}/{{card.expYear}})
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <hr>
                        </div>
                        <div class="col-md-12 allowSpace">
                            <div class="col-md-1 padding-0"></div>
                            <div class="col-md-5 padding-1">Item Total</div>
                            <div class="col-md-1 padding-0"></div>
                            <div *ngIf="myCartList && myCartList.totalAmount  > 0"
                                class="col-md-5 padding-1 text-right pull-right">
                                <span
                                    *ngIf="TotalAmount && callTypeValue == 1 && bookingType !== 3">{{myCartList.currencySymbol}}
                                    {{TotalAmount| number:'1.2-2'}}</span>
                                <span
                                    *ngIf="TotalAmount && callTypeValue == 2 && bookingType !== 3">{{myCartList.currencySymbol}}
                                    {{myCartList.totalAmount| number:'1.2-2'}}</span>
                                <!-- <span *ngIf="TotalAmount && callTypeValue == 2">{{myCartList.currencySymbol}} {{myCartList.totalAmount| number:'1.2-2'}}</span> -->
                                <span
                                    *ngIf="TotalAmount && callTypeValue == 1 && bookingType === 3">{{myCartList.currencySymbol}}
                                    {{hourlyCharge| number:'1.2-2'}}</span>
                                <span
                                    *ngIf="TotalAmount && callTypeValue == 2 && bookingType === 3">{{myCartList.currencySymbol}}
                                    {{hourlyCharge| number:'1.2-2'}}</span>
                                <span *ngIf="bookingType == 3 && ConfirmMultipleShift == 1"> X {{ShiftCount}}</span>
                            </div>
                            <div *ngIf="!myCartList" class="col-md-3 padding-1 text-right">--</div>
                            <div *ngIf="myCartList == []" class="col-md-3 padding-1 text-right">--</div>
                            <div *ngIf="myCartList && myCartList.totalAmount  == 0"
                                class="col-md-3 padding-1 text-right">--</div>
                            <div *ngIf="!ForBiddingTotalAmount && cartServiceType == 7 "
                                class="col-md-3 padding-1 text-right">--</div>
                            <div *ngIf="ForBiddingTotalAmount && cartServiceType == 7" id="biddingPrice"
                                class="col-md-3 padding-1 text-right">
                                {{categoryOpted.currencySymbol}}{{BiddingPrice.value}}</div>
                        </div>

                        <div class="col-md-12 allowSpace" id="VisitFees" style="display:none">
                            <div class="col-md-1 padding-0"></div>
                            <div class="col-md-5 padding-1">Visit Fee</div>
                            <div class="col-md-3 padding-0"></div>
                            <div *ngIf="!myCartList && cartServiceType == 2" class="col-md-3 padding-1 text-right">--
                            </div>
                            <div *ngIf="myCartList && cartServiceType == 2  && visitFee > 0"
                                class="col-md-3 padding-1 text-right">{{categoryOpted.currencySymbol}}{{visitFee}}</div>
                            <div *ngIf="!myCartList && cartServiceType == 1 && visitFee > 0"
                                class="col-md-3 padding-1 text-right">--</div>
                            <div *ngIf="!myCartList && cartServiceType == 1 && !visitFee"
                                class="col-md-3 padding-1 text-right">--</div>
                            <div *ngIf="myCartList && cartServiceType == 1 && visitFee > 0 "
                                class="col-md-3 padding-1 text-right">
                                {{myCartList.currencySymbol}}{{myCartList.visitFee}}</div>
                            <div *ngIf="myCartList && cartServiceType == 0" class="col-md-3 padding-1 text-right">--
                            </div>
                            <div *ngIf="!myCartList && cartServiceType == 0" class="col-md-3 padding-1 text-right">--
                            </div>
                        </div>

                        <div class="col-md-12 allowSpace"
                            *ngIf="bookingType == 3 && this.ConfirmMultipleShift == 1 && ShiftOffer">
                            <div class="col-md-1 padding-0"></div>
                            <div class="col-md-5 padding-1">OfferDiscount</div>
                            <div class="col-md-3 padding-0"></div>
                            <div class="col-md-3 padding-1 text-right">{{ShiftOffer.value}}%</div>
                        </div>
                        <div *ngIf="promoDiscountAmount && bookingType != 3" class="col-md-12 allowSpace">
                            <div class="col-md-1 padding-0"></div>
                            <div class="col-md-5 padding-1">Discount</div>
                            <div class="col-md-2 padding-0"></div>
                            <div class="col-md-4 padding-1 text-right">{{myCartList.currencySymbol}}
                                ({{promoDiscountAmount | number:'1.2-2'}})</div>
                        </div>
                        <hr id="LineForPromoCode" *ngIf="bookingType != 3">
                        <div [hidden]="promoDiscountAmount" *ngIf="bookingType != 3" class="col-md-12 allowSpace"
                            id="promoDiscountAmount">
                            <div class="col-md-1 padding-0">
                                <i class="fa fa-info billIcon" aria-hidden="true"></i>
                            </div>
                            <div class="col-md-6 padding-0">Apply Promocode</div>
                            <div class="col-md-4 padding-0">
                                <input type="text" class="inputPromo text-center" [disabled]="newUser"
                                    [(ngModel)]="promocode">
                            </div>
                            <div class="col-md-1 padding-1 text-right">
                                <button mat-button class="smallButton" type="button"
                                    (click)="checkPromoCode(promocode)">
                                    <i class="fa fa-check-circle billIcon" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div [hidden]="!PromoError" class="col-md-12 showPromoMessage">
                                <p>{{PromoError}}</p>
                            </div>
                        </div>

                        <hr [hidden]="promoDiscountAmount">
                        <div class="col-md-12 allowSpace">
                            <!-- <div class="col-md-1 padding-0"></div> -->
                            <div class="col-md-3 padding-1">TOTAL</div>
                            <div class="col-md-4 padding-0">
                                <span *ngIf="bookingType === 3">
                                    <p>Total Price - </p>
                                    <p>Per Shift Price - </p>
                                </span>
                            </div>

                            <div *ngIf="myCartList && myCartList.totalAmount && visitFee && cartServiceType != 7 && promoDiscountAmount"
                                class="col-md-5 padding-1 text-right tTotal">
                                <b *ngIf="bookingType !== 3"><span
                                        class="ToatalAmount">{{myCartList.currencySymbol}}</span>&nbsp;{{(TotalAmount+visitFee)|
                                    number:'1.2-2'}}</b>
                                <p>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3">{{myCartList.currencySymbol}}</span>&nbsp;{{(TotalAmount+visitFee)|
                                    number:'1.2-2'}}
                                </p>
                                <p>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)|
                                        number:'1.2-2'}}</span>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}</span>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)| number:'1.2-2'}}</span>
                                </p>
                            </div>
                            <div *ngIf="myCartList && myCartList.totalAmount && !visitFee && cartServiceType != 7 && promoDiscountAmount"
                                class="col-md-5 padding-1 text-right tTotal">
                                <!-- <b><span class="ToatalAmount">{{myCartList.currencySymbol}}</span>{{TotalAmount| number:'1.2-2'}}</b> -->
                                <b *ngIf="bookingType !== 3">
                                    <span
                                        class="ToatalAmount">{{myCartList.currencySymbol}}</span>&nbsp;{{(TotalAmount)|
                                    number:'1.2-2'}}</b>
                                <p>
                                    <span class="mulShift" *ngIf="bookingType === 3">{{myCartList.currencySymbol}}
                                        &nbsp;{{(TotalAmount)| number:'1.2-2'}}</span>
                                </p>
                                <p>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)|
                                        number:'1.2-2'}}</span>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}</span>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)| number:'1.2-2'}}</span>
                                </p>
                            </div>
                            <div *ngIf="myCartList && myCartList.totalAmount && visitFee && cartServiceType != 7 && !promoDiscountAmount"
                                class="col-md-5 padding-1 text-right tTotal">
                                <!-- <b><span class="ToatalAmount">{{myCartList.currencySymbol}}</span>{{(TotalAmount+visitFee)| number:'1.2-2'}}</b> -->
                                <b *ngIf="bookingType !== 3"><span
                                        class="ToatalAmount">{{myCartList.currencySymbol}}</span>&nbsp;{{(TotalAmount+visitFee)|
                                    number:'1.2-2'}}</b>
                                <p>
                                    <span class="mulShift" *ngIf="bookingType === 3">{{myCartList.currencySymbol}}
                                        &nbsp;{{(TotalAmount+visitFee)| number:'1.2-2'}}</span>
                                </p>
                                <p>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)|
                                        number:'1.2-2'}}</span>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}</span>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)| number:'1.2-2'}}</span>
                                </p>
                            </div>
                            <div *ngIf="myCartList && myCartList.totalAmount && !visitFee && cartServiceType != 7 && !promoDiscountAmount"
                                class="col-md-5 padding-1 text-right tTotal">
                                <!-- <b><span class="ToatalAmount">{{myCartList.currencySymbol}}</span>{{TotalAmount| number:'1.2-2'}}</b> -->
                                <b *ngIf="bookingType !== 3"><span
                                        class="ToatalAmount">{{myCartList.currencySymbol}}</span>&nbsp;{{(TotalAmount)|
                                    number:'1.2-2'}}</b>
                                <p>
                                    <span class="mulShift" *ngIf="bookingType === 3">{{myCartList.currencySymbol}}
                                        &nbsp;{{(TotalAmount)| number:'1.2-2'}}</span>
                                </p>
                                <p>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)|
                                        number:'1.2-2'}}</span>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}</span>
                                    <span class="mulShift"
                                        *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}
                                        &nbsp;{{(hourlyCharge)| number:'1.2-2'}}</span>
                                </p>
                            </div>
                            <div *ngIf="myCartList && !myCartList.totalAmount && cartServiceType != 7 && !promoDiscountAmount"
                                class="col-md-5 padding-1 text-right tTotal">
                                <span *ngIf="bookingType !== 3">
                                    <b><span *ngIf="TotalAmount && callTypeValue == 1"
                                            class="ToatalAmount">{{myCartList.currencySymbol}}
                                            &nbsp;{{TotalAmount| number:'1.2-2'}}</span></b>
                                    <b><span *ngIf="TotalAmount && callTypeValue == 2">--</span></b>
                                </span>
                                <span *ngIf="bookingType === 3">
                                    <p>
                                        <span class="mulShift" *ngIf="TotalAmount && callTypeValue == 1"
                                            class="ToatalAmount">{{myCartList.currencySymbol}}
                                            &nbsp;{{TotalAmount| number:'1.2-2'}}</span>
                                        <span class="mulShift" *ngIf="TotalAmount && callTypeValue == 2">--</span>
                                    </p>
                                    <p>
                                        <span class="mulShift" *ngIf="TotalAmount && callTypeValue == 1">
                                            <span class="mulShift"
                                                *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}
                                                &nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)|
                                                number:'1.2-2'}}</span>
                                            <span class="mulShift"
                                                *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}
                                                &nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}</span>
                                            <span class="mulShift"
                                                *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}
                                                &nbsp;{{(hourlyCharge)| number:'1.2-2'}}</span>
                                            <!-- {{myCartList.currencySymbol}}
                                            &nbsp;{{hourlyCharge| number:'1.2-2'}}
                                            <span 
                                            *ngIf="bookingType === 3 && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}</span>&nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)| number:'1.2-2'}}
                                            <span 
                                                *ngIf="bookingType === 3 && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}</span>&nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}
                                            <span 
                                            *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}</span>&nbsp;{{(hourlyCharge)| number:'1.2-2'}} -->
                                        </span>
                                        <span class="mulShift" *ngIf="TotalAmount && callTypeValue == 2">--</span>
                                    </p>
                                </span>
                            </div>
                            <div *ngIf="!myCartList && cartServiceType != 7 && !promoDiscountAmount"
                                class="col-md-5 padding-1 text-right tTotal">
                                <!-- <b><span *ngIf="TotalAmount && callTypeValue == 1" class="ToatalAmount">{{myCartList.currencySymbol}} {{TotalAmount| number:'1.2-2'}}</span></b>
                                <b><span *ngIf="TotalAmount && callTypeValue == 2">--</span></b> -->
                                <span *ngIf="bookingType !== 3">
                                    <b><span *ngIf="TotalAmount && callTypeValue == 1"
                                            class="ToatalAmount">{{myCartList.currencySymbol}}
                                            &nbsp;{{TotalAmount| number:'1.2-2'}}</span></b>
                                    <b><span *ngIf="TotalAmount && callTypeValue == 2">--</span></b>
                                </span>
                                <span *ngIf="bookingType === 3">
                                    <span class="mulShift" *ngIf="TotalAmount && callTypeValue == 1">
                                        <span class="mulShift"
                                            *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}
                                            &nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)|
                                            number:'1.2-2'}}</span>
                                        <span class="mulShift"
                                            *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}
                                            &nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}</span>
                                        <span class="mulShift"
                                            *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}
                                            &nbsp;{{(hourlyCharge)| number:'1.2-2'}}</span>
                                        <!-- {{myCartList.currencySymbol}}
                                        &nbsp;{{TotalAmount| number:'1.2-2'}}
                                        <span 
                                        *ngIf="bookingType === 3 && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}</span>&nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)| number:'1.2-2'}}
                                        <span 
                                            *ngIf="bookingType === 3 && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}</span>&nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}
                                        <span 
                                        *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}</span>&nbsp;{{(hourlyCharge)| number:'1.2-2'}} -->
                                    </span>
                                    <span class="mulShift" *ngIf="TotalAmount && callTypeValue == 2">--</span>
                                    <span class="mulShift" *ngIf="TotalAmount && callTypeValue == 1">
                                        <span class="mulShift"
                                            *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}
                                            &nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)|
                                            number:'1.2-2'}}</span>
                                        <span class="mulShift"
                                            *ngIf="bookingType === 3 && ShiftOffer && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}
                                            &nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}</span>
                                        <span class="mulShift"
                                            *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}
                                            &nbsp;{{(hourlyCharge)| number:'1.2-2'}}</span>
                                        <!-- {{myCartList.currencySymbol}}
                                        &nbsp;{{hourlyCharge| number:'1.2-2'}}
                                        <span c
                                        *ngIf="bookingType === 3 && ShiftOffer.discountType === 2">{{myCartList.currencySymbol}}</span>&nbsp;{{(hourlyCharge)-((hourlyCharge * ShiftOffer.value)/100)| number:'1.2-2'}}
                                        <span 
                                            *ngIf="bookingType === 3 && ShiftOffer.discountType === 1">{{myCartList.currencySymbol}}</span>&nbsp;{{(hourlyCharge)-(ShiftOffer.value)| number:'1.2-2'}}
                                        <span 
                                        *ngIf="bookingType === 3 && !ShiftOffer">{{myCartList.currencySymbol}}</span>&nbsp;{{(hourlyCharge)| number:'1.2-2'}} -->
                                    </span>
                                    <span class="mulShift" *ngIf="TotalAmount && callTypeValue == 2">--</span>
                                </span>
                            </div>
                            <div *ngIf="!ForBiddingTotalAmount && cartServiceType == 7 "
                                class="col-md-5 padding-1 text-right tTotal">
                                <b><span class="ToatalAmount">--</span></b>
                            </div>
                            <div *ngIf="ForBiddingTotalAmount && cartServiceType == 7 " id="biddingPrice"
                                class="col-md-5 padding-1 text-right tTotal">
                                <b><span class="ToatalAmount">{{categoryOpted.currencySymbol}}
                                        &nbsp;{{BiddingPrice.value}}</span></b>
                            </div>

                        </div>
                        <div id="EnableButtonsforCard">
                            <div class="col-md-6 allowSpace padding-1" *ngIf="enableAutoDispatch">
                                <button id="enableAutoDispatch" mat-button [disabled]="disabledButton"
                                    class="CheckOutbutton" (click)="checkOut(1)"><span style="color:white;">Auto
                                        Dispatch</span></button>
                            </div>
                            <div class="col-md-6 allowSpace padding-1" *ngIf="enableAutoDispatch">
                                <button id="enableManualDispatch" mat-button [disabled]="disabledButton"
                                    class="CheckOutbutton" (click)="checkOut(3)"><span style="color:white;">Create
                                        Manual</span></button>
                            </div>
                            <div class="col-md-12 allowSpace padding-1" *ngIf="enableManualDispatch">
                                <button id="ManualDispatch" mat-button [disabled]="disabledButton"
                                    class="CheckOutbutton" (click)="checkOut(2)"><span style="color:white;">Manual
                                        Dispatch</span></button>
                            </div>
                            <div class="col-md-12 allowSpace padding-1" *ngIf="enablePostDispatch">
                                <button id="PostDispatch" mat-button [disabled]="disabledButton" class="CheckOutbutton"
                                    (click)="checkOut(3)"><span style="color:white;">Post Dispatch</span></button>
                            </div>
                        </div>
                        <div id="DisableButtonsForCard" style="display:none">
                            <div class="col-md-6 allowSpace padding-1" *ngIf="enableAutoDispatch">
                                <button id="enableAutoDispatch" mat-button disabled class="Disablebutton">Auto
                                    Dispatch</button>
                            </div>
                            <div class="col-md-6 allowSpace padding-1" *ngIf="enableAutoDispatch">
                                <button id="enableManualDispatch" mat-button disabled class="Disablebutton">Create
                                    Manual</button>
                            </div>
                            <div class="col-md-12 allowSpace padding-1" *ngIf="enableManualDispatch">
                                <button id="ManualDispatch" mat-button disabled class="Disablebutton">Manual
                                    Dispatch</button>
                            </div>
                            <div class="col-md-12 allowSpace padding-1" *ngIf="enablePostDispatch">
                                <button id="PostDispatch" mat-button disabled class="Disablebutton">Post
                                    Dispatch</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- </form> -->
            </div>

        </div>
    </div>
    <!-- </div> -->
</ngx-smart-modal>
<!-- to show callType popup -->
<ngx-smart-modal #CallType identifier="CallType" [closable]="false" [dismissable]="false" [escapable]="false"
    customClass="small-modal">
    <div class="modal-header">
        <div class="col-md-12">
            <h5 class="modal-title text-center">Select Booking
                <button class='close' type="button" data-dismiss="modal" aria-label="Close"
                    (click)="closeQuestionAnw(2,1)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h5>
        </div>

    </div>
    <div class="modal-body" style="max-height: 300px;display: inline-block;width:100%;">
        <div class="col-md-12  padding-0 bglight focus">
            <div class="col-md-12  padding-0 bglight focus">
                <div class="col-md-12 CheckOutHeader backgroundFade" style="bottom:3px">
                    <h5 style="color:black;">Select Call Type</h5>
                </div>
                <div class="col-md-12 padding-0">
                    <div class="col-md-12">
                        <h5 style="color:black;">1) Which type of the booking you want to select?</h5>
                    </div>
                    <mat-radio-group class="col-md-12">
                        <mat-radio-button class="col-md-12 padding-0" #callType
                            *ngFor="let callList of callType;let inst = index" [checked]="callList.checked"
                            [value]="callList.checked" (change)="closeQuestionAnw(4,inst)">{{callList.viewValue}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div *ngIf="answerAllError" class="col-md-12 padding-0 text-center">
            <span style="color:red;">Select Any BookingType(* complusory)</span>
        </div>
        <br /><br />
    </div>
    <div class="modal-footer" style="position: relative;top: 8px;width:100%">
        <div class="col-md-12 form-group">
            <button type="button" *ngIf='questionAnsArr && questionAnsArr.length > 0' class="btn pull-right btn-success"
                data-dismiss="modal" (click)="closeQuestionAnw(3,1)">Next</button>
            <button type="button" *ngIf='!questionAnsArr' class="btn pull-right btn-success" data-dismiss="modal"
                (click)="closeQuestionAnw(3,1)">Save</button>
            <button type="button" *ngIf='questionAnsArr && questionAnsArr.length <= 0'
                class="btn pull-right btn-success" data-dismiss="modal" (click)="closeQuestionAnw(3,1)">Save</button>
        </div>
    </div>
</ngx-smart-modal>
<!-- Incall callType show Time slot for some categories -->
<ngx-smart-modal #InCallTimeSlot identifier="InCallTimeSlot" [closable]="false" [dismissable]="false"
    [escapable]="false" customClass="small-modal">
    <div class="modal-header">
        <div class="col-md-12 text-center">
            <h4 style='color:black'>Select Time Slot</h4>
        </div>
        <div class="col-md-12 padding-0">
            <div class="col-md-2" (click)='closeQuestionAnw(5,1)'>
                <span title="Previous Date" data-toggle="tooltip" data-placement="left" style='color:black'>
                    <i class="fas fa-angle-left"></i>
                </span>
            </div>
            <div class="col-md-8 text-center">
                {{CurrentDateTimSlot}}
            </div>
            <div class="col-md-2" (click)='closeQuestionAnw(5,2)'>
                <span class="" title="Next Date" data-toggle="tooltip" data-placement="right" style='color:black'>
                    <i class="fas fa-angle-right"></i>
                </span>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-md-12  padding-0 bglight focus">
            <div class="col-md-12 text-center" *ngIf="providerTimeSlot.length == 0">
                <h5 style="color:red;">There is no slot available at this date. Select another date.</h5>
            </div>
            <div class="col-md-12 text-center"
                *ngIf="providerTimeSlot.length > 0 && MorningSlots && MorningSlots.length <= 0 && AfterNoonSlots && AfterNoonSlots.length <= 0 && EveningSlot && EveningSlot.length <= 0 && NightSlot && NightSlot.length <= 0">
                <h5 style="color:red;">There is no slot available at this date. Select another date.</h5>
            </div>
            <!-- morning slots -->
            <div class="col-md-12  padding-0 bglight focus" *ngIf='MorningSlots && MorningSlots.length > 0'>
                <div class="col-md-12 pad10 backgroundFade">
                    Morning Slots
                </div>
                <div class='col-md-12 taskScroller EachTimeSlot padding-0'>
                    <div class="col-md-3 pad10" *ngFor="let slotList of MorningSlots; let i = index;"
                        (click)="closeQuestionAnw(6,$event)">
                        <button type="button" class="btn btn-info"
                            [attr.data-value]="slotList.from">{{slotList.from}}</button>
                    </div>
                </div>
            </div>
            <!-- AfterNoon slots -->
            <div class="col-md-12  padding-0 bglight focus" *ngIf='AfterNoonSlots && AfterNoonSlots.length > 0'>
                <div class="col-md-12 pad10 backgroundFade">
                    AfterNoon Slots
                </div>
                <div class='col-md-12 taskScroller EachTimeSlot padding-0'>
                    <div class="col-md-3 pad10" *ngFor="let slotList of AfterNoonSlots; let i = index;"
                        (click)="closeQuestionAnw(6,$event)">
                        <button type="button" class="btn btn-info" [attr.data-value]="slotList.from">{{slotList.from}}
                            <!-- <span></span> -->
                        </button>
                    </div>
                </div>
            </div>
            <!-- Evening slots -->
            <div class="col-md-12  padding-0 bglight focus" *ngIf='EveningSlot && EveningSlot.length > 0'>
                <div class="col-md-12 pad10 backgroundFade">
                    Evening Slots
                </div>
                <div class='col-md-12 taskScroller EachTimeSlot padding-0'>
                    <div class="col-md-3 pad10" *ngFor="let slotList of EveningSlot; let i = index;"
                        (click)="closeQuestionAnw(6,$event)">
                        <button type="button" class="btn btn-info"
                            [attr.data-value]="slotList.from">{{slotList.from}}</button>
                    </div>
                </div>
            </div>
            <!-- Night slots -->
            <div class="col-md-12  padding-0 bglight focus" *ngIf='NightSlot && NightSlot.length > 0'>
                <div class="col-md-12 pad10 backgroundFade">
                    Night Slots
                </div>
                <div class='col-md-12 taskScroller EachTimeSlot padding-0'>
                    <div class="col-md-3 pad10" *ngFor="let slotList of NightSlot; let i = index;"
                        (click)="closeQuestionAnw(6,$event)">
                        <button type="button" class="btn btn-info"
                            [attr.data-value]="slotList.from">{{slotList.from}}</button>
                    </div>
                </div>
            </div>
        </div>
        <br /><br />
    </div>
    <div *ngIf="answerAllError" class="col-md-12 padding-0 text-center">
        <span style="color:red;">{{timeSlotError}}</span>
    </div>
    <div class="col-md-12 modal-footer" style='padding-top:15px;'>
        <div class='form-group'>
            <button type="button" class="btn pull-left btn-success" data-dismiss="modal"
                (click)="closeQuestionAnw(7,1 )">Save</button>
            <button type="button" class="btn pull-right btn-default" data-dismiss="modal"
                (click)="closeQuestionAnw(2,3)">Close</button>
        </div>
    </div>
</ngx-smart-modal>
<!-- multipleShift -->

<ngx-smart-modal #mulTileShifting identifier="mulTileShifting" [closable]="false" [dismissable]="false"
    [escapable]="false" customClass="small-modal">
    <div style="top:100px;width:100%">

        <!-- Modal Header -->
        <div class="modal-header" style="width:100%;">
            <div class="col-md-10 text-center">
                <h4 class="model-title text-center" style="display:inline-block;"><b>Multiple Shift</b></h4>
            </div>
            <div class="col-md-2">
                <button class='close' type="button" data-dismiss="modal" aria-label="Close"
                    (click)="closeQuestionAnw(8,1)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>

        <!-- Modal body -->
        <div class="modal-body radioCheckBox" style="max-height: 300px;display: inline-block;width:100%;">
            <span [hidden]="ShifthNexts != 0" class="col-md-12 "
                style="padding: 20px;margin:0px 0px 0px 10px;font-size:20px; color:#4b4a4a !important;">How long do
                you want to booking ?</span>
            <div [hidden]="ShifthNexts != 0" class="col-md-12 bglight">
                <mat-radio-group class="example-radio-group" [(ngModel)]="ShiftToggle">
                    <mat-radio-button class="col-md-12 bglight padding1 shiftOptionClass"
                        *ngFor="let shifts of ShiftOptions;let indx = index" [checked]="shifts.checked" #Shift
                        [value]="shifts.value" (click)="MultipleShift(Shift.value, indx)">
                        {{shifts.viewValue}}
                    </mat-radio-button>
                </mat-radio-group>
                <div *ngIf="Showerror && shiftOption == 0 " class="col-md-12 bglight text-center"
                    style="width:100%;height:10%;border:1px solid #f8724c; background:#f8724c; padding-top:10px;">
                    <span class="text-center">{{ErrorMsg}}</span>
                </div>
                <br />
                <div class="col-md-12 pad5 bglight">
                    <div *ngIf="shiftOption == 1">
                        <span style="color:#398439"><b>{{startDate}} - {{endDate}}</b></span>
                    </div>
                </div>
                <div class="col-md-12 bglight">
                    <div *ngIf="shiftOption == 2">
                        <span style="color:#398439"><b>{{startDate}} - {{endDate}}</b></span>
                    </div>
                </div>
                <div class="col-md-12 bglight">
                    <div *ngIf="shiftOption == 3">
                        <div class="col-md-12 bglight">
                            <div class="col-md-6 bglight" style="padding-top: 10px;margin-top: 10px;">
                                <label class="dudeLabel1">
                                    <i class="far fa-calendar-alt"></i> <span>From*</span>
                                </label>
                                <div class="panel-body">
                                    <input class="form-control" mwlFlatpickr [(ngModel)]="CurrentDateMulti"
                                        dateFormat="j M, Y" [minDate]="modelValueAsDate" [convertModelValue]="true"
                                        (flatpickrChange)="FromToDateForMul($event,2)"
                                        (flatpickrOpen)="FromToDateForMul($event,2)">
                                </div>
                            </div>
                            <div class="col-md-6 bglight" style="padding-top: 10px;margin-top: 10px;">
                                <label class="dudeLabel1">
                                    <!-- <i class="far fa-calendar-alt" aria-hidden="true"> To*</i> -->
                                    <i class="far fa-calendar-alt"></i><span>To*</span>
                                </label>
                                <div class="panel-body">
                                    <input class="form-control" mwlFlatpickr placeholder="Select Date"
                                        [(ngModel)]="CurrentDateMultiEndDate" dateFormat="j M, Y" [minDate]="nextDate"
                                        [convertModelValue]="true" (flatpickrChange)="FromToDateForMul($event,1)"
                                        (flatpickrOpen)="FromToDateForMul($event,1)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="ShifthNexts != 1" class="col-md-12 padding-0 bglight">
                <div class="col-md-12 bglight" style="padding-left:6px; margin-top:5px;">
                    <mat-checkbox class="example-margin Allchecked CheckBoxValue " [(ngModel)]="Ischecked"
                        (click)="SelectAllDays(daysList,optionsChecked)">Select All Days</mat-checkbox>
                </div>
                <hr>
                <div class="col-md-12 bglight">
                    <div *ngFor="let day of daysList; let indx = index">
                        <mat-checkbox class="example-margin1 CheckBoxValue" [checked]="day.checked" #daysValue
                            [value]="day" (change)="daysSelected(daysValue.value, indx)">{{day.days}}</mat-checkbox>
                    </div>
                    <hr>
                </div>
            </div>
            <div [hidden]="ShifthNexts != 2" class="col-md-12 padding-0 bglight">
                <div class="col-md-12 bglight" style="padding-top: 10px;margin-top: 10px;">
                    <label class="dudeLabel1">
                        <i class="far fa-clock" aria-hidden="true"></i>
                        <span style="padding-left:5px;">Select Time*</span>
                    </label>
                    <div class="panel-body">
                        <input class="text-center form-control" mwlFlatpickr [(ngModel)]="TimePickerMul"
                            [noCalendar]="true" [enableTime]="true" [dateFormat]="'h:i K'" [allowInput]="false"
                            [enableSeconds]="false" [altFormat]="'M j, Y'" [minuteIncrement]="'15'" [time24hr]="false"
                            [defaultMinute]="'00'" [minDate]="getCurrentDateFormat(bookingType)"
                            (flatpickrChange)="flatPickrChanged($event, 1)">
                    </div>
                </div><br><br>
                <hr>
                <div class="col-md-12 bglight" style="padding-top: 10px;margin-top: 10px;">
                    <label class="dudeLabel1">
                        <i class="far fa-clock" aria-hidden="true"></i>
                        <span style="padding-left:5px;">Select Duration*</span>
                    </label>
                    <div class="panel-body">
                        <input class="text-center form-control" mwlFlatpickr [(ngModel)]="durationPicker"
                            [noCalendar]="true" [enableTime]="true" [dateFormat]="'H'" [allowInput]="false"
                            [enableSeconds]="false" [minuteIncrement]="'60'" [defaultHour]="'1'" [defaultMinute]="'00'"
                            [time24hr]="true" (flatpickrChange)="flatPickrChanged($event,2)"
                            (flatpickrOpen)="flatPickrChanged($event,2)">
                    </div>
                </div>
            </div>
            <div *ngIf="Showerror && shiftOption > 0" class="col-md-12 bglight text-center"
                style="width:100%;height:10%;border:1px solid #f8724c; background:#f8724c;">
                <span class="text-center">{{ErrorMsg}}</span>
            </div>
        </div><br>
        <!-- Modal footer -->
        <div class="modal-footer" style="position: relative;top: 8px;width:100%">
            <div class="form-group">
                <div *ngIf="!ConfirmMulShift" claas="col-xs-6">
                    <button type="button" class="btn btn-success btn-md pull-right" (click)="change(1)">Next</button>
                </div>
                <div *ngIf="ConfirmMulShift" claas="col-xs-6">
                    <!-- <button type="button" class="btn btn-success btn-md pull-right"
                    (click)="closeShift();">Confirm</button> -->
                    <button type="button" class="btn btn-success btn-md pull-right"
                        (click)="CloseConfirmationMul();">Confirm</button>
                </div>
                <div *ngIf="ShifthNexts > 0" claas="col-xs-6">
                    <button type="button" class="btn btn-success btn-md pull-left"
                        (click)="BackChange(-1)">Back</button>
                </div>
            </div>
        </div>
        <!-- </div>
        </div> -->
    </div>
</ngx-smart-modal>
<!-- Question and answers set for Some cateogries -->
<ngx-smart-modal #QuestionAns identifier="QuestionAns" [closable]="false" [dismissable]="false" [escapable]="false"
    customClass="medium-modal">
    <div class="modal-header">
        <div class="col-md-12">
            <h5 class="modal-title text-center">Questions Set
                <button class='close' type="button" data-dismiss="modal" aria-label="Close"
                    (click)="closeQuestionAnw(2,2)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h5>
        </div>
        <!-- <div class="col-md-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeQuestionAnw(2,2)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> -->
    </div>
    <div class="modal-body">
        <div class="col-md-12  padding-0 bglight focus" style='max-height:500px;overflow-y:auto;'>
            <div class="col-md-12  padding-0 bglight focus">
                <div class="col-md-12 CheckOutHeader backgroundFade" style="bottom:3px">
                    <h5 style="color:black;">Questions Set</h5>
                </div>
                <div class="col-md-12 fullWidth mrg10">
                    <div *ngFor="let questions of questionAnsArr; let i = index;" class="questions-{{i}}"
                        id="questions-{{i}}">
                        <div class="{{i}}" *ngIf="questions.questionType == 5">
                            <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                {{questions.question}}</span>
                            <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                    {{questions.question}}</span><span style="color:red">*</span></span> <br>
                            <mat-form-field>
                                <textarea matInput [formControl]="jobDescriptionController"
                                    (keyup)="questionAnsweredByuser(questions,i,$event,inx)"></textarea>
                            </mat-form-field>
                            <hr>
                        </div>
                        <div class="{{questions.isManadatory}} {{i}}" *ngIf="questions.questionType == 6 ">
                            <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                {{questions.question}}</span>
                            <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                    {{questions.question}}</span><span style="color:red">*</span></span> <br>
                            <mat-radio-group class="example-radio-group">
                                <mat-radio-button class="example-radio-button " [checked]="questions.checked"
                                    *ngFor="let options of questions.preDefined;let inx = index" #radioBtn
                                    [value]="options" (click)="questionAnsweredByuser(questions,i,radioBtn.value,inx)">
                                    {{options.name}}
                                </mat-radio-button>
                            </mat-radio-group>
                            <hr>
                        </div>

                        <div class="{{i}}" *ngIf="questions.questionType == 7">
                            <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                {{questions.question}}</span>
                            <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                    {{questions.question}}</span><span style="color:red">*</span></span> <br>
                            <br>
                            <div *ngFor="let options of questions.preDefined;let inx1 = index">
                                <mat-checkbox class="example-margin CheckBoxValue" [checked]="options.checked" #checkbtn
                                    [value]="options" (change)="questionAnsweredByuser(questions,i,$event,inx1)">
                                    {{options.name}}</mat-checkbox>
                            </div>
                            <hr>
                        </div>

                        <!-- date and time option for (from current to future) -->
                        <div class="{{i}}" *ngIf="questions.questionType == 8">
                            <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                {{questions.question}}</span>
                            <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                    {{questions.question}}</span><span style="color:red">*</span></span> <br>
                            <br>
                            <div class="tab-content bglight">
                                <div class="col-md-6 bglight">
                                    <label class="dudeLabel">
                                        <i class="far fa-calendar-alt" aria-hidden="true"></i>
                                    </label>
                                    <mat-form-field class="w95">
                                        <input class="form-control" matInput mwlFlatpickr [(ngModel)]="todaysDate"
                                            dateFormat="j M, Y" [altInput]="true" [convertModelValue]="true"
                                            [minDate]="todaysDate"
                                            (flatpickrChange)="questionAnsweredByuser(questions,i,$event,0)"
                                            (flatpickrOpen)="questionAnsweredByuser(questions,i,$event,0)">
                                        <!-- <input matInput class="text-center pad5 flatpickr1 myDate1" id="myDate1" #DateValue1 placeholder="Select Date*" (change)="questionAnsweredByuser(0,questions.question,questions._id,questions.questionType,questions.isManadatory)"> -->
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <!-- date and time option for (from past to current) -->
                        <div class="{{i}}" *ngIf="questions.questionType == 9">
                            <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                {{questions.question}}</span>
                            <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                    {{questions.question}}</span><span style="color:red">*</span></span> <br>
                            <br>
                            <div class="tab-content bglight">
                                <div class="col-md-6 bglight">
                                    <label class="dudeLabel">
                                        <i class="far fa-calendar-alt" aria-hidden="true"></i>
                                    </label>
                                    <mat-form-field class="w95">
                                        <input class="form-control" matInput mwlFlatpickr [(ngModel)]="todaysDate"
                                            dateFormat="j M, Y" [altInput]="true" [convertModelValue]="true"
                                            [maxDate]="todaysDate"
                                            (flatpickrChange)="questionAnsweredByuser(questions,i,$event,0)"
                                            (flatpickrOpen)="questionAnsweredByuser(questions,i,$event,0)">
                                        <!-- <input matInput class="text-center pad5 flatpickr2 myDate2" id="myDate2" placeholder="Select Date*" (change)="questionAnsweredByuser(0,questions.question,questions._id,questions.questionType,questions.isManadatory)"> -->
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="{{i}}" *ngIf="questions.questionType == 10 ">
                            <span *ngIf="questions.isManadatory == 0"> {{getSlNoForQuestions(i)}} )
                                {{questions.question}}</span>
                            <span *ngIf="questions.isManadatory == 1"> <span>{{getSlNoForQuestions(i)}} )
                                    {{questions.question}}</span><span style="color:red">*</span></span> <br>
                            <br>
                            <div class="collectionImg col-xs-12">
                                <div class="col-xs-12 editImagesell zeroPadding cursorPointer">
                                    <span class="listImg" *ngIf="imgUrl && imgUrl.length > 0">
                                        <span class="imagsProduct col-xs-2 zeroPadding"
                                            *ngFor="let img of imgUrl; let i = index;">
                                            <div class="col-md-12 zeroPadding">
                                                <img class="sellImg sellImgs" src="{{img}}"
                                                    onError="this.src='https://d2qb2fbt5wuzik.cloudfront.net/yelowebsite/images/default-thumnail.png';">
                                                <button mat-button mat-mini-fab class="CloseButtonImg"
                                                    title="Close Image" (click)="removeImg(i)">
                                                    <i class="material-icons CloseImg">&#xE5CD;</i>
                                                </button>
                                            </div>
                                        </span>
                                    </span>
                                    <span class="emptyImg col-xs-2 zeroPadding">
                                        <input type="file" #fileInput id="uploadSellInput" name="image" accept="image/*"
                                            class="yeloProfileImage"
                                            style="opacity: 0;
                                                                                                                                                width: 100px;height: 100px;"
                                            (onFileSelected)="uploadImg($event,questions.question,questions._id)"
                                            ng2FileSelect [uploader]="uploader" multiple>
                                    </span>
                                </div>
                                <div class="col-12 p-2" *ngIf="ImageLoader" style="color:red;">
                                    Image is Loading...
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br>
            </div>
        </div>
        <br />
        <div *ngIf="answerAllError" class="col-md-12 padding-0 text-center">
            <span><strong style="color:red;">Answer All Mandatory Questions</strong>(* complusory)</span>
        </div>
    </div>
    <div class="modal-footer" style='padding-top:15px;'>
        <div class="col-md-12 form-group">
            <button *ngIf="callTypeCount >= 2" type="button" class="btn pull-left btn-success" data-dismiss="modal"
                (click)="closeQuestionAnw(3,2)">Back</button>
            <button type="button" class="btn pull-right btn-success" data-dismiss="modal"
                (click)="closeQuestionAnw(1,0)">Save</button>
            <!-- <button type="button" class="btn pull-left btn-success" data-dismiss="modal" (click)="closeQuestionAnw(1,0)">Save</button>
            <button type="button" class="btn pull-right btn-default" data-dismiss="modal" (click)="closeQuestionAnw(2,2)">Close</button> -->
        </div>
    </div>
</ngx-smart-modal>

<div class="modal fade" id="mapModal" role="dialog" style="padding:0px!important ; padding-left:0px!important ;">
    <div class="floatMap">
        <div id='jobmap' style="height:500px"></div>
    </div>
</div>

<div class="modal fade" id="addCustomer" role="dialog" style="padding:0px!important ; padding-left:0px!important ;">
    <div class="registrationPanel bglight">
        <div class="col-md-12 backgroundFade">
            <div class="col-md-10 padding-0">
                <h5>Register New Customer</h5>
            </div>
            <div class="col-md-2 padding-0 text-right hideAutofill">
                <button mat-button class="locateButton" (click)="clearRegistrationForm()" title="Refresh">
                    <i class="material-icons">&#xE5D5;</i>
                </button>
            </div>
        </div>
        <div class="col-md-12">
            <label class="dudeLabel">
                <i class="material-icons">&#xE0BA;</i>
            </label>
            <mat-form-field class="matsub dudeMail">
                <input autocomplete="new-password" matInput [(ngModel)]="customerRegisterName" placeholder="Name"
                    [formControl]="RegisterName" required>
                <mat-error *ngIf="RegisterName.invalid">{{getErrorName()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-12 padding-0">
            <div class="col-md-1 pad15">
                <label class="dudeLabel">
                    <i class="material-icons">&#xE0CF;</i>
                </label>
            </div>
            <div class="col-md-2 padding-0">
                <mat-form-field class="matsub dudeMail ">
                    <span matPrefix>&#43;</span>
                    <input autocomplete="new-password" readonly matInput [(ngModel)]="customerRegisterCountryCode"
                        placeholder="dial code" [formControl]="RegisterCountryCode" required>
                    <mat-error *ngIf="RegisterCountryCode.invalid">{{getErrorCcode()}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-9 padding-0">
                <mat-form-field class="matsub dudeMail">
                    <input autocomplete="new-password" id="phonenumber" [(ngModel)]="customerRegisterPhone" matInput
                        placeholder="Mobile" [formControl]="RegisterPhone" required>
                    <mat-error *ngIf="RegisterPhone.invalid">{{getErrorMobile()}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-12">
            <label class="dudeLabel">
                <i class="material-icons">&#xE0D0;</i>
            </label>
            <mat-form-field class="matsub dudeMail">
                <input autocomplete="new-password" matInput [(ngModel)]="customerRegisterEmail" placeholder="Email"
                    [formControl]="email" required>
                <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-12 allowSpace padding-1">
            <button mat-button class="CheckOutbutton" (click)="validateRegistrationForm()">Register</button>
        </div>
    </div>
</div>

<router-outlet></router-outlet>